import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Components
import {
  Container,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import Inbox from './components/Inbox';
import Outbox from './components/Outbox';
import Contacts from './components/Contacts';
import UpdateContactGroupModal from './components/Contacts/ContactGroupModal';

// Actions
import {
  communicationsGetContactGroup,
  communicationsToggleContactGroupModal,
} from './redux/actions';
import { COMMUNICATIONS_GET_CONTACT_GROUP } from './redux/actionCreators';

class Communications extends Component {
  static propTypes = {
    // Actions
    communicationsGetContactGroup: PropTypes.func.isRequired,
    communicationsToggleContactGroupModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      viewingTextMessage: false,
      viewingEmailMessage: false,
      viewingAll: true,
    };
  }

  componentDidMount() {
    const { communicationsGetContactGroup: getContactGroup } = this.props;
    getContactGroup({ list: true });
  }

  setViewType = (viewType) => {
    switch (viewType) {
      case 'textMessage':
        this.setState({
          viewingTextMessage: true,
          viewingEmailMessage: false,
        });
        break;
      case 'emailMessage':
        this.setState({
          viewingTextMessage: false,
          viewingEmailMessage: true,
        });
        break;
      case 'none':
        this.setState({
          viewingTextMessage: false,
          viewingEmailMessage: false,
        });
        break;
      default:
        throw Error('Invalid view type.');
    }
  };

  setViewingAll = (viewingAll) => {
    this.setState({
      viewingAll,
    });
  };

  handleOnChangeTab = (event) => {
    event.preventDefault();
    const { tab } = event.currentTarget.dataset;
    this.setState({ activeTab: tab });
  };

  handleToggleCreateContactGroupModal = () => {
    const { communicationsToggleContactGroupModal: toggleContactGroupModal } = this.props;
    toggleContactGroupModal({ openMode: 'create' });
  };

  handleToggleUpdateContactGroupModal = () => {
    const { communicationsToggleContactGroupModal: toggleContactGroupModal } = this.props;
    toggleContactGroupModal({ openMode: 'update' });
  };

  handleContactGroupOnClick = (id, { list = false, includeContacts = true, includeMessages = true } = {}) => {
    const { communicationsGetContactGroup: getContactGroup } = this.props;
    this.setViewType('none');
    getContactGroup({
      list, includeContacts, includeMessages, id,
    })
      .then((action) => {
        if (action.type === COMMUNICATIONS_GET_CONTACT_GROUP.SUCCESS) {
          this.setViewingAll(action.response.instance.id === 1);
        }
      });
  };

  render() {
    // State
    const {
      activeTab,
      viewingTextMessage,
      viewingEmailMessage,
      viewingAll,
    } = this.state;

    return (
      <>
        <Container>
          <h3 className="page-title">Communications</h3>
          <p className="page-subhead">Connect with residents and staff.</p>
          <Card>
            <CardBody className="p-4" style={{ minHeight: 753 }}>
              <div className="tabs tabs--bordered-bottom overflow-hidden">
                <div className="tabs__wrap">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classNames({ active: activeTab === '1' })}
                        data-tab="1"
                        onClick={this.handleOnChangeTab}
                      >
                        Inbox
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classNames({ active: activeTab === '2' })}
                        data-tab="2"
                        onClick={this.handleOnChangeTab}
                      >
                        Outbox
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classNames({ active: activeTab === '3' })}
                        data-tab="3"
                        onClick={this.handleOnChangeTab}
                      >
                        Contacts
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      {activeTab === '1' && (
                        <Inbox />
                      )}
                    </TabPane>
                    <TabPane tabId="2">
                      {activeTab === '2' && (
                        <Outbox
                          viewingAll={viewingAll}
                          viewingTextMessage={viewingTextMessage}
                          viewingEmailMessage={viewingEmailMessage}

                          // Callbacks
                          setViewType={this.setViewType}
                          setViewingAll={this.setViewingAll}
                          toggleCreateContactGroupModal={this.handleToggleCreateContactGroupModal}
                          toggleUpdateContactGroupModal={this.handleToggleUpdateContactGroupModal}
                          contactGroupOnClick={this.handleContactGroupOnClick}
                        />
                      )}
                    </TabPane>
                    <TabPane tabId="3">
                      {activeTab === '3' && (
                        <Contacts
                          viewingAll={viewingAll}
                          viewingTextMessage={viewingTextMessage}
                          viewingEmailMessage={viewingEmailMessage}

                          // Callbacks
                          setViewType={this.setViewType}
                          setViewingAll={this.setViewingAll}
                          toggleCreateContactGroupModal={this.handleToggleCreateContactGroupModal}
                          toggleUpdateContactGroupModal={this.handleToggleUpdateContactGroupModal}
                          contactGroupOnClick={this.handleContactGroupOnClick}
                        />
                      )}
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </CardBody>
          </Card>
        </Container>

        {/* Modals */}
        <UpdateContactGroupModal />

      </>
    );
  }
}

const mapStateToProps = () => {};

const mapDispatchToProps = {
  communicationsGetContactGroup,
  communicationsToggleContactGroupModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Communications);
