import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Components
import Layout from '../layout';

import Dashboard from '../dashboard';
import ProtectedRoute from './ProtectedRoute';

import News from '../../apps/news';
import NewsEditor from '../../apps/news/components/Editor';

import Events from '../../apps/events';
import EventEditor from '../../apps/events/components/Editor';

import Sections from '../../apps/sections';
import SectionEditor from '../../apps/sections/components/Editor';

import Admin from '../../apps/submissions';
import AdminSubmissionView from '../../apps/submissions/components/SubmissionView';

import Repairs from '../../apps/repairs';
import RepairCategoryEditor from '../../apps/repairs/components/CategoryEditor';
import RepairRequestsManager from '../../apps/repairs/components/RequestsManager';
import RepairRequestViewer from '../../apps/repairs/components/RequestViewer';

import Images from '../../apps/images';

import Documents from '../../apps/documents';

import Integrations from '../../apps/integrations';

import RequestAccount from '../../apps/users/RequestAccount';
import VerifyEmail from '../../apps/users/VerifyEmail';
import ForgotPassword from '../../apps/users/ForgotPassword';
import ResetPassword from '../../apps/users/ResetPassword';
import Login from '../../apps/users/Login';
import Logout from '../../apps/users/Logout';
import Profile from '../../apps/users/Profile';

import Residents from '../../apps/users/residents';
import ResidentAccountRequestDetail from '../../apps/users/residents/components/ResidentAccountRequestDetail';

import Communications from '../../apps/communications';
import MessageEditor from '../../apps/communications/components/MessageEditor';

const news = () => (
  <div>
    <Route exact path="/news" component={News} />
    <Route exact path="/news/editor/:id" component={NewsEditor} />
  </div>
);

const events = () => (
  <div>
    <Route exact path="/events" component={Events} />
    <Route exact path="/events/editor/:id" component={EventEditor} />
  </div>
);

const sections = () => (
  <div>
    <Route exact path="/content" component={Sections} />
    <Route exact path="/content/editor/:slug" component={SectionEditor} />
  </div>
);

const admin = () => (
  <div>
    <Route exact path="/submissions" component={Admin} />
    <Route exact path="/submissions/:id" component={AdminSubmissionView} />
  </div>
);

const repairs = () => (
  <div>
    <Route exact path="/repairs" component={Repairs} />
    <Route exact path="/repairs/public-info" component={RepairCategoryEditor} />
    <Route exact path="/repairs/requests" component={RepairRequestsManager} />
    <Route exact path="/repairs/requests/:id" component={RepairRequestViewer} />
  </div>
);

const documents = () => (
  <div>
    <Route exact path="/documents" component={Documents} />
  </div>
);

const images = () => (
  <div>
    <Route exact path="/images" component={Images} />
  </div>
);

const integrations = () => (
  <div>
    <Route exact path="/integrations" component={Integrations} />
    <Route exact path="/integrations/:integration" component={Integrations} />
  </div>
);

const account = () => (
  <div>
    <Route exact path="/account/profile" component={Profile} />
  </div>
);

const residents = () => (
  <div>
    <Route exact path="/residents" component={Residents} />
    <Route exact path="/residents/requests/:id" component={ResidentAccountRequestDetail} />
  </div>
);

const communications = () => (
  <div>
    <Route exact path="/communications" component={Communications} />
    <Route exact path="/communications/new" component={MessageEditor} />
  </div>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route exact path="/" component={Dashboard} />
      <Route path="/news" component={news} />
      <Route path="/events" component={events} />
      <ProtectedRoute path="/content" protectionLevel="admin" component={sections} />
      <ProtectedRoute path="/submissions" protectionLevel="admin" component={admin} />
      <ProtectedRoute path="/repairs" protectionLevel="admin" component={repairs} />
      <ProtectedRoute path="/documents" protectionLevel="admin" component={documents} />
      <Route path="/images" component={images} />
      <ProtectedRoute path="/integrations" protectionLevel="admin" component={integrations} />
      <Route path="/account" component={account} />
      <ProtectedRoute path="/residents" protectionLevel="admin" component={residents} />
      <ProtectedRoute path="/communications" protectionLevel="admin" component={communications} />
    </div>
  </div>
);

const Router = () => (
  <div className="theme-light">
    <div className="wrapper">
      <main>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/request-account" component={RequestAccount} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/verify-email" component={VerifyEmail} />
          <Route exact path="/verify-email/:key" component={VerifyEmail} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password/:uid/:token" component={ResetPassword} />
          <ProtectedRoute path="/" component={wrappedRoutes} />
        </Switch>
      </main>
    </div>
  </div>
);

export default Router;
