import React from 'react';
import PropTypes from 'prop-types';
import { squashString } from 'ggtmo-utils';
import filesize from 'filesize';

// Components
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import TableCell from '@material-ui/core/TableCell';
import MatTable from '../table/MatTable';


const tableColumns = [
  {
    id: 'name', label: 'Name', disablePadding: false, numeric: false, align: 'left',
  },
  {
    id: 'size', label: 'Size', disablePadding: false, numeric: false, align: 'left',
  },
  {
    id: 'remove', label: '', disablePadding: false, numeric: false, align: 'left',
  },
];


const TableRow = ({
  name,
  size,
}, {
  onRemove,
  index,
}) => (
  <>
    <TableCell className="material-table__cell">{squashString(name, { filename: true })}</TableCell>
    <TableCell className="material-table__cell">{filesize(size)}</TableCell>
    <TableCell className="material-table__cell">
      <Button className="btn-link-style text-danger" onClick={e => onRemove(index, e)}>
        <FontAwesomeIcon icon={faTimes} fixedWidth className="mr-1" />remove
      </Button>
    </TableCell>
  </>
);

TableRow.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.bool.isRequired,
};

const UploadFilesTable = ({
  children,
  alwaysShowChildren,
  files,
  selected,
  page,
  count,

  // Callbacks
  onSelect,
  onRemove,
}) => (
  <div>
    <MatTable
      alwaysShowChildren={alwaysShowChildren}
      rowComponent={TableRow}
      columns={tableColumns}
      data={files}
      selected={selected}
      page={page}
      count={count}
      canSelect={false}
      pagination={false}
      emptyRows={false}

      // Callbacks
      onSelect={onSelect}
      onRemove={onRemove}
    >
      {children}
    </MatTable>
  </div>
);

UploadFilesTable.propTypes = {
  children: PropTypes.node,
  alwaysShowChildren: PropTypes.bool,
  files: PropTypes.instanceOf(Array).isRequired,
  selected: PropTypes.instanceOf(Array).isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,

  // Callbacks
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

UploadFilesTable.defaultProps = {
  children: null,
  alwaysShowChildren: false,
};

export default UploadFilesTable;
