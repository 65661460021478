import reduceReducers from 'reduce-reducers';
import { initializeActions } from '@tmosystems/core';

// Reducers
import baseReducer from './baseReducer';
import customReducer from './customReducer';

// Action creators
import {
  // Async action creators
  EVENTS__CREATE_EVENT,
  EVENTS__CREATE_AND_PUBLISH_EVENT,
  EVENTS__GET_EVENT,
  EVENTS__UPDATE_EVENT,
  EVENTS__UPDATE_AND_PUBLISH_EVENT,
  EVENTS__PUBLISH_EVENT,
  EVENTS__UNPUBLISH_EVENT,
  EVENTS__DELETE_EVENT,
  EVENTS__CONVERT_EVENT_TO_STORY,
} from './actionCreators';

export const initialState = {
  events: {
    instance: {},
    instances: [],
    selected: [],
    selectedIndices: [],
    modalMeta: {
      isOpen: false,
      openMode: 'create',
    },
    tableMeta: {
      page: 0,
      rowsPerPage: 10,
    },
    scrollMeta: {
      page: 0,
      size: 24,
      noMoreInstances: false,
    },
    searchMeta: {
      searchTerm: '',
    },
  },
  selectedCategory: -1,
  actions: initializeActions([
    EVENTS__CREATE_EVENT,
    EVENTS__CREATE_AND_PUBLISH_EVENT,
    EVENTS__GET_EVENT,
    EVENTS__UPDATE_EVENT,
    EVENTS__UPDATE_AND_PUBLISH_EVENT,
    EVENTS__PUBLISH_EVENT,
    EVENTS__UNPUBLISH_EVENT,
    EVENTS__DELETE_EVENT,
    EVENTS__CONVERT_EVENT_TO_STORY,
  ]),
};

export default reduceReducers(initialState, baseReducer, customReducer);
