import { generateAction as generate, reduxTypes as types } from 'ggtmo-utils';
import service from '../service';


// Action creator
import {
  INTEGRATIONS_GET_INTEGRATION,
  INTEGRATIONS_TWITTER_ENABLE,
  INTEGRATIONS_TWITTER_AUTHENTICATION_SUCCESS,
} from './actionCreators';


export const getIntegration = generate(INTEGRATIONS_GET_INTEGRATION, {
  type: types.ASYNC,
  service: service.GET.integration,
});


export const twitterEnable = generate(INTEGRATIONS_TWITTER_ENABLE, {
  type: types.ASYNC,
  service: service.twitter.GET.enable,
});


export const twitterAuthenticationSuccess = generate(INTEGRATIONS_TWITTER_AUTHENTICATION_SUCCESS, {
  type: types.ASYNC,
  service: service.twitter.POST.authenticationSuccess,
});
