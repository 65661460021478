import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ModalSidebarStyled = styled.div`
  background-color: ${({ theme }) => theme.color.whiteDark};
  height: 100%;
  color: black;
  border-bottom-left-radius: 0.75rem;
`;

const ModalSidebar = ({
  icon,
  heading,
  body,
  children,
}) => (
  <ModalSidebarStyled className={classNames({ 'p-3 pt-5': !children })}>
    <div className="text-black-50">
      {heading && (
        <h5 className="text-uppercase">
          {icon && (
            <FontAwesomeIcon icon={icon} className="mr-1" />
          )}
          <span>
            {heading}
          </span>
        </h5>
      )}
      {body && (
        <>
          <hr />
          <p className="lead">
            {body}
          </p>
        </>
      )}
    </div>
    {children}
  </ModalSidebarStyled>
);

ModalSidebar.propTypes = {
  icon: PropTypes.instanceOf(Object),
  heading: PropTypes.string,
  body: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  children: PropTypes.node,
};

ModalSidebar.defaultProps = {
  icon: undefined,
  heading: undefined,
  body: undefined,
  children: undefined,
};

export default ModalSidebar;
