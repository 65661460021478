import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Route, Redirect } from 'react-router-dom';

// Utils
import getUserType from '../../shared/utils/getUserType';


const ProtectedRoute = ({ component: PrivateComponent, protectionLevel, ...rest }) => {
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');
  const permitAdmin = protectionLevel === 'admin' && getUserType() === 'admin';
  const permitResident = protectionLevel === 'resident';

  if (!token) {
    return (
      <Route
        {...rest}
        render={() => (
          <Redirect to="/login" />
        )}
      />
    );
  }

  if (permitAdmin || permitResident) {
    return (
      <Route
        {...rest}
        render={props => (
          <PrivateComponent {...props} />
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={() => (
        <Redirect to="/" />
      )}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  protectionLevel: PropTypes.oneOf([
    'admin',
    'resident',
  ]),
};

ProtectedRoute.defaultProps = {
  protectionLevel: 'resident',
};

export default ProtectedRoute;
