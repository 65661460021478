import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { squashString } from '@tmosystems/core';

// Components
import {
  Row, Col, ButtonToolbar, Button,
} from 'reactstrap';
import BottomScrollListener from 'react-bottom-scroll-listener';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSpinnerThird, faTrash } from '@fortawesome/pro-light-svg-icons';
import AsyncComponent from '../../../shared/components/AsyncComponent';
import Centered from '../../../shared/components/Centered';

// Redux
import {
  imagesGetImage,
  imagesGetMoreImages,
  imagesUpdateImage,
  imagesSelectImage,
  imagesUpdateImageScrollMeta,
  imagesSelectCategory,
} from '../redux/actions';
import { IMAGES__GET_IMAGE, IMAGES__GET_MORE_IMAGES, IMAGES__UPDATE_IMAGE } from '../redux/actionCreators';

// Images
import noImagesFoundImage from '../../../shared/img/media.png';

const ImageGalleryWrapper = styled.div`
  height: 664px;
  padding-bottom: ${({ noMoreInstances }) => (noMoreInstances ? '0px' : '100px')};
  overflow-y: auto;
  overflow-x: hidden;
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 200px;
`;

const Image = styled.div`
  height: 150px;
  background-color: transparent;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  border: 2px solid ${({ theme, selected }) => (selected ? theme.color.primary : 'transparent')};
  
  ${({ theme, selected }) => selected && `
    &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 150px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${theme.color.primaryLighter};
    opacity: 0.2;
  }
  `}
`;

const ImageName = styled.p`
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 150px;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.15s ease-in-out;
  z-index: 9999;
`;

const ImageNameInput = styled.input`
  padding: 0.5rem;
`;

class ImageGallery extends Component {
  static propTypes = {
    // Images
    imagesActions: PropTypes.instanceOf(Object).isRequired,
    images: PropTypes.shape({
      instances: PropTypes.instanceOf(Array),
      instance: PropTypes.instanceOf(Object),
      selected: PropTypes.instanceOf(Array),
      count: PropTypes.number,
      tableMeta: PropTypes.instanceOf(Object),
    }).isRequired,
    categories: PropTypes.instanceOf(Object).isRequired,

    // Actions
    imagesGetImage: PropTypes.func.isRequired,
    imagesGetMoreImages: PropTypes.func.isRequired,
    imagesUpdateImage: PropTypes.func.isRequired,
    imagesSelectImage: PropTypes.func.isRequired,
    imagesUpdateImageScrollMeta: PropTypes.func.isRequired,
    imagesSelectCategory: PropTypes.func.isRequired,

    // Callbacks
    getImage: PropTypes.func,
  };

  static defaultProps = {
    // Callbacks
    getImage: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      idOfImageHovered: -1,
      idOfImageToEdit: -1,
      name: '',
      selectedIndices: [],
    };
  }

  componentDidMount() {
    const {
      getImage: getImageCallback,
      imagesGetImage: getImage,
      imagesSelectCategory: selectCategory,
    } = this.props;
    const requestParameters = { list: true, categoryId: -1 };
    let actionResponse;
    if (getImageCallback) {
      actionResponse = getImageCallback(requestParameters);
    } else {
      actionResponse = getImage(requestParameters);
    }
    actionResponse
      .then((action) => {
        if (action.type === IMAGES__GET_IMAGE.SUCCESS) {
          selectCategory({ selected: [action.response.categoryId] });
        }
      });
  }

  handleOnBottom = () => {
    const {
      imagesGetMoreImages: getMoreImages, images, imagesUpdateImageScrollMeta: updateScrollMeta, categories,
    } = this.props;
    const newPage = images.scrollMeta.page + 1;
    getMoreImages({ list: true, categoryId: categories.selected[0], page: newPage })
      .then((action) => {
        if (action.type === IMAGES__GET_MORE_IMAGES.SUCCESS) {
          if (action.response.instances.length) {
            updateScrollMeta({ page: newPage });
          } else {
            updateScrollMeta({ noMoreInstances: true });
          }
        }
      });
  };

  handleOnMouseEnter = (event) => {
    const { id } = event.currentTarget.dataset;
    this.setState({
      idOfImageHovered: parseInt(id, 10),
    });
  };

  handleOnMouseLeave = () => {
    this.setState({
      idOfImageHovered: -1,
    });
  };

  handleSetImageToEdit = (event) => {
    const { images } = this.props;
    const { id } = event.currentTarget.dataset;
    this.setState({
      idOfImageToEdit: parseInt(id, 10),
      name: images.instances.filter(image => image.id === parseInt(id, 10))[0].name,
    }, () => {
      const input = document.getElementById(`imageNameInput${id}`);
      if (input) {
        input.focus();
      }
    });
  };

  handleOnBlur = () => {
    this.setState({
      idOfImageToEdit: -1,
    });
  };

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleOnSaveEdit = () => {
    const { idOfImageToEdit, name } = this.state;
    const { imagesUpdateImage: updateImage } = this.props;
    updateImage({ id: idOfImageToEdit, name })
      .then((action) => {
        if (action.type === IMAGES__UPDATE_IMAGE.SUCCESS) {
          const input = document.getElementById(`imageNameInput${idOfImageToEdit}`);
          if (input) {
            input.blur();
          }
        }
      });
  };

  handleOnKeyDown = (event) => {
    const { key } = event;
    if (key === 'Enter') {
      this.handleOnSaveEdit();
    }
  };

  handleSelect = (selected, selectedIndices) => {
    const { imagesSelectImage: selectImage } = this.props;
    selectImage({ selected });
    this.setState({ selectedIndices });
  };

  handleImageOnClick = (event) => {
    const { selectedIndices } = this.state;
    const { images } = this.props;
    const id = parseInt(event.currentTarget.dataset.id, 10);
    const index = parseInt(event.currentTarget.dataset.index, 10);
    if (event.ctrlKey) {
      if (images.selected.includes(id)) {
        this.handleSelect(
          images.selected.filter(f => f !== id),
          selectedIndices.filter(f => f !== id),
        );
      } else {
        this.handleSelect(
          [...images.selected, id],
          [...selectedIndices, index],
        );
      }
    } else if (event.shiftKey) {
      const [mostRecentIndex] = selectedIndices.reverse();
      const indexSlice = Array(Math.abs(index - mostRecentIndex))
        .fill()
        .map((_, i) => ((index > mostRecentIndex ? mostRecentIndex : index) + i + (index > mostRecentIndex ? 1 : 0)));
      const idSlice = images.instances
        .slice(indexSlice[0], indexSlice.reverse()[0] + 1)
        .map(f => f.id);
      this.handleSelect(
        [...images.selected, ...idSlice],
        [...selectedIndices, ...indexSlice],
      );
    } else {
      this.handleSelect(
        [id],
        [index],
      );
    }
  };

  handleOnDragStart = (event) => {
    event.dataTransfer.setData('imageId', event.target.dataset.id);
    this.setState({
      idOfImageHovered: -1,
    });
  };

  renderImage = (instance, index) => {
    // State
    const {
      idOfImageHovered,
      idOfImageToEdit,
      name,
    } = this.state;

    // Props
    const {
      images,
    } = this.props;
    const hovered = idOfImageHovered === instance.id;

    return (
      <ImageWrapper
        onMouseEnter={this.handleOnMouseEnter}
        onMouseLeave={this.handleOnMouseLeave}
        onClick={this.handleImageOnClick}
        data-id={instance.id}
        data-index={index}
        draggable
        onDragStart={this.handleOnDragStart}
      >
        {hovered && (
          <ImageOverlay className="p-3">
            <ButtonToolbar className="justify-content-end">
              <Button size="sm" className="mr-2" onClick={this.handleSetImageToEdit} data-id={instance.id}>
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <Button size="sm">
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </ButtonToolbar>
          </ImageOverlay>
        )}
        <Image
          selected={images.selected.includes(instance.id)}
          style={{
            backgroundImage: `url(${instance.url})`,
          }}
        />
        {instance.id === idOfImageToEdit ? (
          <ImageNameInput
            type="text"
            name="name"
            className="border rounded"
            onBlur={this.handleOnBlur}
            id={`imageNameInput${instance.id}`}
            onKeyDown={this.handleOnKeyDown}
            onChange={this.handleOnChange}
            value={name}
          />
        ) : (
          <ImageName className="text-black-50">
            {squashString(instance.name, { filename: true, length: 20 })}
          </ImageName>
        )}
      </ImageWrapper>
    );
  };

  filterByCategory = (instances) => {
    const { categories } = this.props;
    const [selectedCategory] = categories.selected;
    if (selectedCategory === -1) {
      return instances;
    }
    return instances.filter(instance => parseInt(instance.category, 10) === selectedCategory);
  };

  render() {
    // Props
    const {
      imagesActions: {
        IMAGES__GET_IMAGE: getImageAction,
        IMAGES__GET_MORE_IMAGES: getMoreImagesAction,
      },
      images: {
        instances,
        scrollMeta,
      },
    } = this.props;

    return (
      <div style={{ height: 664 }}>
        <AsyncComponent action={getImageAction} centered>
          {instances.length > 0 ? (
            <BottomScrollListener onBottom={this.handleOnBottom}>
              {scrollRef => (
                <ImageGalleryWrapper ref={scrollRef} noMoreInstances={scrollMeta.noMoreInstances}>
                  <Row>
                    {this.filterByCategory(instances).map((instance, index) => (
                      <Col lg={3} xl={2} key={instance.id}>
                        {this.renderImage(instance, index)}
                      </Col>
                    ))}
                  </Row>
                  {getMoreImagesAction.loading ? (
                    <div className="text-center text-primary">
                      <FontAwesomeIcon className="my-4" icon={faSpinnerThird} size="4x" spin fixedWidth />
                    </div>
                  ) : scrollMeta.noMoreInstances && (
                    <div className="text-center text-black-50 my-4">
                      <hr />
                      <h4>No more images</h4>
                    </div>
                  )}
                </ImageGalleryWrapper>
              )}
            </BottomScrollListener>
          ) : (
            <div className="text-center position-relative border rounded" style={{ height: 664 }}>
              <Centered>
                <p className="lead">
                  No images found.
                </p>
                <img className="w-50" src={noImagesFoundImage} alt="No images found" />
              </Centered>
            </div>
          )}
        </AsyncComponent>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    editorForm,
  } = state.form;

  const {
    actions: imagesActions,
    images,
    categories,
  } = state.images;

  return {
    // Form
    editorForm,

    // Images
    imagesActions,
    images,
    categories,
  };
};

const mapDispatchToProps = {
  imagesGetImage,
  imagesGetMoreImages,
  imagesUpdateImage,
  imagesSelectImage,
  imagesUpdateImageScrollMeta,
  imagesSelectCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageGallery);
