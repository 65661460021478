import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import Modal from './components/Modal';

class BaseModal extends PureComponent {
  static propTypes = {
    modalMeta: PropTypes.instanceOf(Object).isRequired,

    // Actions
    toggle: PropTypes.func.isRequired,
  };

  properties = {};

  handleToggle = () => {
    const { toggle } = this.props;
    toggle();
  };

  handleOnOpened = () => {};

  handleOnClosed = () => {};

  renderCreateModal = () => {};

  renderUpdateModal = () => {};

  renderDeleteConfirmModal = () => {};

  renderModalContent = () => {
    // Props
    const {
      modalMeta,
    } = this.props;

    switch (modalMeta.openMode) {
      case 'create':
        return this.renderCreateModal();
      case 'update':
        return this.renderUpdateModal();
      case 'deleteConfirm':
        return this.renderDeleteConfirmModal();
      default:
        return null;
    }
  };

  render() {
    // Props
    const {
      modalMeta,
    } = this.props;

    return (
      <Modal
        open={modalMeta.isOpen}
        {...this.properties}
        noPadding

        // Callbacks
        toggle={this.handleToggle}
        onOpened={this.handleOnOpened}
        onClosed={this.handleOnClosed}
      >
        {this.renderModalContent()}
      </Modal>
    );
  }
}

export default BaseModal;
