import { generateReducerElement as generate, reduxTypes as types, adjust } from '@tmosystems/core';

// Action creators
import {
  // Async action creators
  EVENTS__CREATE_EVENT,
  EVENTS__CREATE_AND_PUBLISH_EVENT,
  EVENTS__GET_EVENT,
  EVENTS__UPDATE_EVENT,
  EVENTS__UPDATE_AND_PUBLISH_EVENT,
  EVENTS__PUBLISH_EVENT,
  EVENTS__UNPUBLISH_EVENT,
  EVENTS__DELETE_EVENT,
  EVENTS__CONVERT_EVENT_TO_STORY,

  // Sync action creators
  EVENTS__SELECT_EVENT,
  EVENTS__UPDATE_EVENT_TABLE_META,
  EVENTS__REINITIALIZE_EVENT_STATE,
} from './actionCreators';

import { initialState } from './reducer';

export default (state, action) => ({
  // Async
  ...generate(state, action, EVENTS__CREATE_EVENT, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, EVENTS__CREATE_EVENT.SUCCESS) && {
        events: {
          ...state.events,
          instance: action.response.instance,
          instances: [
            ...state.events.instances,
            action.response.instance,
          ],
        },
      },
    },
  }),
  ...generate(state, action, EVENTS__CREATE_AND_PUBLISH_EVENT, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, EVENTS__CREATE_AND_PUBLISH_EVENT.SUCCESS) && {
        events: {
          ...state.events,
          instance: action.response.instance,
          instances: [
            ...state.events.instances,
            action.response.instance,
          ],
        },
      },
    },
  }),
  ...generate(state, action, EVENTS__GET_EVENT, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, EVENTS__GET_EVENT.SUCCESS) && {
        events: action.response.append ? {
          ...state.events,
          instance: action.response.instance || state.events.instance,
          instances: [
            ...state.events.instances,
            ...action.response.instances,
          ],
          count: action.response.count,
        } : {
          ...state.events,
          instance: action.response.instance || state.events.instance,
          instances: action.response.instances || state.events.instances,
          count: action.response.count,
        },
      },
    },
  }),
  ...generate(state, action, EVENTS__UPDATE_EVENT, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, EVENTS__UPDATE_EVENT.SUCCESS) && {
        events: {
          ...state.events,
          instance: action.response.instance,
          instances: state.events.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.events.selected,
        },
      },
    },
  }),
  ...generate(state, action, EVENTS__UPDATE_AND_PUBLISH_EVENT, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, EVENTS__UPDATE_AND_PUBLISH_EVENT.SUCCESS) && {
        events: {
          ...state.events,
          instance: action.response.instance,
          instances: state.events.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.events.selected,
        },
      },
    },
  }),
  ...generate(state, action, EVENTS__PUBLISH_EVENT, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, EVENTS__PUBLISH_EVENT.SUCCESS) && {
        events: {
          ...state.events,
          instance: action.response.instance,
          instances: state.events.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.events.selected,
        },
      },
    },
  }),
  ...generate(state, action, EVENTS__UNPUBLISH_EVENT, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, EVENTS__UNPUBLISH_EVENT.SUCCESS) && {
        events: {
          ...state.events,
          instance: action.response.instance,
          instances: state.events.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.events.selected,
        },
      },
    },
  }),
  ...generate(state, action, EVENTS__DELETE_EVENT, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, EVENTS__DELETE_EVENT.SUCCESS) && {
        events: {
          ...state.events,
          instance: state.events.instance,
          instances: state.events.instances
            .filter(instance => !action.response.deletedIds.includes(instance.id)),
          selected: initialState.events.selected,
        },
      },
    },
  }),
  ...generate(state, action, EVENTS__CONVERT_EVENT_TO_STORY, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, EVENTS__CONVERT_EVENT_TO_STORY.SUCCESS) && {
        events: {
          ...state.events,
          instance: action.response.instance,
          instances: state.events.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.events.selected,
        },
      },
    },
  }),

  // Sync
  ...generate(state, action, EVENTS__SELECT_EVENT, {
    type: types.SYNC,
    adjustment: {
      events: {
        ...state.events,
        selected: action.selected,
        selectedIndices: action.selectedIndices,
      },
    },
  }),
  ...generate(state, action, EVENTS__UPDATE_EVENT_TABLE_META, {
    type: types.SYNC,
    adjustment: {
      events: {
        ...state.events,
        tableMeta: {
          ...state.events.tableMeta,
          ...action,
        },
      },
    },
  }),
  ...generate(state, action, EVENTS__REINITIALIZE_EVENT_STATE, {
    type: types.SYNC,
    adjustment: {
      events: initialState.events,
    },
  }),
}[action.type] || state);
