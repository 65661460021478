import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { wrapper } from 'ggtmo-utils';
import { toast } from 'react-toastify';

// Components
import {
  Row, Col, ButtonToolbar, Button,
} from 'reactstrap';
import { faUserPlus, faUserEdit } from '@fortawesome/pro-light-svg-icons';
import CreateContactForm from './CreateContactForm';
import AsyncButton from '../../../../shared/components/AsyncButton';
import ModalHeader from '../../../../shared/components/ModalHeader';
import ModalSidebar from '../../../../shared/components/ModalSidebar';
import ModalContent from '../../../../shared/components/ModalContent';
import ModalFooter from '../../../../shared/components/ModalFooter';

// Classes
import BaseModal from '../../../../shared/BaseModal';

// Actions
import {
  communicationsToggleContactModal,
  communicationsCreateContact,
  communicationsUpdateContact,
} from '../../redux/actions';
import {
  COMMUNICATIONS_CREATE_CONTACT,
  COMMUNICATIONS_UPDATE_CONTACT,
} from '../../redux/actionCreators';

class ContactModal extends BaseModal {
  static propTypes = {
    modalMeta: PropTypes.instanceOf(Object).isRequired,

    // Form
    createContactForm: PropTypes.instanceOf(Object).isRequired,

    // Redux
    communicationsActions: PropTypes.instanceOf(Object).isRequired,
    contacts: PropTypes.instanceOf(Object).isRequired,
    communicationsToggleContactModal: PropTypes.func.isRequired,
    communicationsCreateContact: PropTypes.func.isRequired,
    communicationsUpdateContact: PropTypes.func.isRequired,
  };

  properties = {
    centered: true,
    noPadding: true,
    width: 'xl',
    contentClassName: 'rounded',
  };

  handleOnCreate = (event) => {
    event.preventDefault();
    const { communicationsCreateContact: createContact, createContactForm } = this.props;
    createContact(createContactForm.values)
      .then((action) => {
        if (action.type === COMMUNICATIONS_CREATE_CONTACT.SUCCESS) {
          this.handleToggle();
          toast('Successfully created contact.', { type: toast.TYPE.SUCCESS });
        }
      });
  };

  handleOnUpdate = (event) => {
    event.preventDefault();
    const {
      communicationsUpdateContact: updateContact,
      contacts,
      createContactForm,
    } = this.props;
    updateContact({
      ...contacts.instance,
      ...createContactForm.values,
    })
      .then((action) => {
        if (action.type === COMMUNICATIONS_UPDATE_CONTACT.SUCCESS) {
          this.handleToggle();
          toast('Successfully updated contact.', { type: toast.TYPE.SUCCESS });
        }
      });
  };

  renderCreateModal = () => {
    // Props
    const {
      communicationsActions: {
        COMMUNICATIONS_CREATE_CONTACT: createContactAction,
      },
    } = this.props;

    return (
      <div>
        <ModalHeader
          title="Create contact"

          // Callbacks
          onClose={this.handleToggle}
        />
        <Row noGutters>
          <Col xs={4}>
            <ModalSidebar
              icon={faUserPlus}
              heading="Create contact"
              body="You can communicate with contacts via text messages and emails."
            />
          </Col>
          <Col>
            <ModalContent>
              <div className="my-4">
                <CreateContactForm />
              </div>
              <ModalFooter>
                <ButtonToolbar className="justify-content-end">
                  <Button size="sm" outline onClick={this.handleToggle}>Cancel</Button>
                  <AsyncButton
                    size="sm"
                    outline
                    color="primary"
                    onClick={this.handleOnCreate}
                    action={createContactAction}
                  >
                    Create
                  </AsyncButton>
                </ButtonToolbar>
              </ModalFooter>
            </ModalContent>
          </Col>
        </Row>
      </div>
    );
  };

  renderUpdateModal = () => {
    // Props
    const {
      communicationsActions: {
        COMMUNICATIONS_UPDATE_CONTACT: updateContactAction,
      },
      contacts,
    } = this.props;

    return (
      <div>
        <ModalHeader
          title="Update contact"

          // Callbacks
          onClose={this.handleToggle}
        />
        <Row noGutters>
          <Col xs={4}>
            <ModalSidebar
              icon={faUserEdit}
              heading="Update contact"
              body={(
                <>
                  Update the contact information for:
                  <p className="font-weight-bold">
                    {contacts.instance.name}
                  </p>
                </>
              )}
            />
          </Col>
          <Col>
            <ModalContent>
              <div className="my-4">
                <CreateContactForm
                  initialValues={contacts.instance}
                  enableReinitialize
                />
              </div>
              <ModalFooter>
                <ButtonToolbar className="justify-content-end">
                  <Button size="sm" outline onClick={this.handleToggle}>Cancel</Button>
                  <AsyncButton
                    size="sm"
                    outline
                    color="primary"
                    onClick={this.handleOnUpdate}
                    action={updateContactAction}
                  >
                    Save
                  </AsyncButton>
                </ButtonToolbar>
              </ModalFooter>
            </ModalContent>
          </Col>
        </Row>
      </div>
    );
  };

  renderDeleteConfirmModal = () => {};
}

const mapStateToProps = (state) => {
  const {
    createContactForm,
  } = state.form;

  const {
    actions: communicationsActions,
    contacts,
  } = state.communications;

  return {
    // Form
    createContactForm,

    // Communications
    communicationsActions,
    contacts,
    modalMeta: contacts.modalMeta,
  };
};

const mapDispatchToProps = {
  toggle: communicationsToggleContactModal,
  communicationsCreateContact,
  communicationsUpdateContact,
};

const withForm = {
  form: 'createContactForm',
};

export default wrapper({
  component: ContactModal,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(withForm),
  ],
});
