import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import { Row, Col } from 'reactstrap';
import { faWrench } from '@fortawesome/pro-light-svg-icons/faWrench';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import ModalHeader from '../../../shared/components/ModalHeader';
import ModalSidebar from '../../../shared/components/ModalSidebar';
import ModalContent from '../../../shared/components/ModalContent';
import CreateCategoryForm from './CreateCategoryForm';
import EditableImage from '../../../shared/components/EditableImage';
import EditableText from '../../../shared/components/EditableText';
import TypesTable from './TypesTable';

// Classes
import BaseModal from '../../../shared/BaseModal';

// Actions
import {
  repairsCreateCategory,
  repairsUpdateCategory,
  repairsUpdateCategoryModalMeta,
  repairsUpdateTypeTableMeta,
  repairsUpdateTypeSearchMeta,
} from '../redux/actions';
import { REPAIRS__CREATE_CATEGORY } from '../redux/actionCreators';

class CategoryModal extends BaseModal {
  static propTypes = {
    // Repairs
    repairsActions: PropTypes.instanceOf(Object).isRequired,
    categories: PropTypes.instanceOf(Object).isRequired,

    // Actions
    createCategory: PropTypes.func.isRequired,
    updateCategory: PropTypes.func.isRequired,
    updateCategoryModalMeta: PropTypes.func.isRequired,
    repairsUpdateTypeTableMeta: PropTypes.func.isRequired,
    updateTypeSearchMeta: PropTypes.func.isRequired,
  };

  properties = {
    width: 'xxl',
    centered: true,
  };

  handleOnCreateCategory = (values) => {
    const { createCategory, updateCategoryModalMeta } = this.props;
    return createCategory({
      name: values.name,
      imageSet: { images: [values.icon] },
    })
      .then((action) => {
        if (action.type === REPAIRS__CREATE_CATEGORY.SUCCESS) {
          updateCategoryModalMeta({ isOpen: true, openMode: 'update' });
        }
        return action;
      });
  };

  handleOnUpdateCategoryName = (value) => {
    const { updateCategory, categories } = this.props;
    return updateCategory({
      id: categories.instance.id,
      name: value,
      updateImageSet: false,
      updateDocumentSet: false,
    }, { asyncId: 'name' });
  };

  handleOnUpdateCategoryImage = (value) => {
    const { updateCategory, categories } = this.props;
    return updateCategory({
      id: categories.instance.id,
      imageSet: {
        images: [value],
      },
      updateDocumentSet: false,
    }, { asyncId: 'image' });
  };

  handleOnClosed = () => {
    const { updateTypeTableMeta, updateTypeSearchMeta } = this.props;
    updateTypeTableMeta({ page: 0 });
    updateTypeSearchMeta({ searchTerm: '', previousSearchTerm: '' });
  };

  renderCreateModal = () => {
    // Props
    const {
      repairsActions: {
        REPAIRS__CREATE_CATEGORY: createCategoryAction,
      },
    } = this.props;

    return (
      <>
        <ModalHeader
          title="Create category"

          // Callbacks
          onClose={this.handleToggle}
        />
        <Row noGutters>
          <Col xs={2}>
            <ModalSidebar
              icon={faWrench}
              heading="Create category"
              body="Organise repair request types by categorising them."
            />
          </Col>
          <Col>
            <ModalContent>
              <div className="w-25">
                <CreateCategoryForm
                  action={createCategoryAction}

                  // Callbacks
                  onSubmit={this.handleOnCreateCategory}
                />
              </div>
            </ModalContent>
          </Col>
        </Row>
      </>
    );
  };

  renderUpdateModal = () => {
    // Props
    const {
      repairsActions: {
        REPAIRS__UPDATE_CATEGORY: updateCategoryAction,
      },
      categories: {
        instance,
      },
    } = this.props;

    return (
      <>
        <ModalHeader
          title="Edit category"

          // Callbacks
          onClose={this.handleToggle}
        />
        <Row noGutters>
          <Col xs={2}>
            <ModalSidebar
              icon={faEdit}
              heading="Edit category"
              body="Add repair types to this category. A repair type represents something that may go wrong
              within a residence."
            />
          </Col>
          <Col>
            <ModalContent>
              <div className="d-flex mb-4">
                <div className="mr-2">
                  <EditableImage
                    value={instance.imageSet.images[0]}
                    updateAction={updateCategoryAction.image || { loading: false }}
                    imgStyle={{
                      width: 48,
                      height: 48,
                    }}

                    // Callbacks
                    onSubmit={this.handleOnUpdateCategoryImage}
                  />
                </div>
                <EditableText
                  tagName="h1"
                  value={instance.name}
                  updateAction={updateCategoryAction.name || { loading: false }}

                  // Callbacks
                  onSubmit={this.handleOnUpdateCategoryName}
                />
              </div>
              <TypesTable />
            </ModalContent>
          </Col>
        </Row>
      </>
    );
  };
}

const mapStateToProps = (state) => {
  const {
    actions: repairsActions,
    categories,
  } = state.repairs;

  return {
    // Repairs
    repairsActions,
    categories,
  };
};

const mapDispatchToProps = {
  createCategory: repairsCreateCategory,
  updateCategory: repairsUpdateCategory,
  updateCategoryModalMeta: repairsUpdateCategoryModalMeta,
  updateTypeTableMeta: repairsUpdateTypeTableMeta,
  updateTypeSearchMeta: repairsUpdateTypeSearchMeta,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryModal);
