import {
  generateReducerElement as generate, reduxTypes as types, initializeActions, adjust,
} from 'ggtmo-utils';

// Action Creators
import {
  ADMIN_GET_SUBMISSION_GROUP,
  ADMIN_UPDATE_SUBMISSION_GROUP,
  ADMIN_SELECT_SUBMISSION_GROUP,
  ADMIN_CREATE_SUBMISSION,
} from './actionCreators';


const defaultSnapshot = {
  snapshot: {
    imageSet: {
      images: [],
    },
  },
};


const initialState = {
  submissionGroups: [],
  selectedSubmissionGroups: [],
  submissionGroup: {
    submissions: [
      defaultSnapshot,
      defaultSnapshot,
    ],
  },
  submission: {},
  actions: initializeActions([
    ADMIN_GET_SUBMISSION_GROUP,
    ADMIN_UPDATE_SUBMISSION_GROUP,
    ADMIN_CREATE_SUBMISSION,
  ]),
};


export default (state = initialState, action) => ({
  ...generate(state, action, ADMIN_GET_SUBMISSION_GROUP, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, ADMIN_GET_SUBMISSION_GROUP.SUCCESS) && {
        ...action.response,
      },
    },
  }),

  ...generate(state, action, ADMIN_UPDATE_SUBMISSION_GROUP, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, ADMIN_UPDATE_SUBMISSION_GROUP.SUCCESS) && {
        ...action.response,
      },
    },
  }),

  ...generate(state, action, ADMIN_SELECT_SUBMISSION_GROUP, {
    type: types.SYNC,
    adjustment: {
      selectedSubmissionGroups: action.selected,
    },
  }),

  ...generate(state, action, ADMIN_CREATE_SUBMISSION, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, ADMIN_CREATE_SUBMISSION.SUCCESS) && {
        ...action.response,
      },
    },
  }),
}[action.type] || state);
