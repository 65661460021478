import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { wrapper } from 'ggtmo-utils';
import { toast } from 'react-toastify';

// Components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
} from 'reactstrap';
import ResidentAccountRequestForm from './ResidentAccountRequestForm';
import AsyncButton from '../../../../shared/components/AsyncButton';

// Redux
import { getResidentAccountRequest, updateResidentAccountRequest } from '../redux/actions';
import { RESIDENTS_UPDATE_ACCOUNT_REQUEST } from '../redux/actionCreators';

class ResidentAccountRequestDetail extends PureComponent {
  static propTypes = {
    // History
    match: RouterPropTypes.match.isRequired,
    history: RouterPropTypes.history.isRequired,

    // Form
    residentsAccountRequestForm: PropTypes.instanceOf(Object).isRequired,
    dirty: PropTypes.bool.isRequired,

    // Residents
    residentAccountRequest: PropTypes.instanceOf(Object).isRequired,

    // Actions
    getResidentAccountRequest: PropTypes.func.isRequired,
    updateResidentAccountRequest: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { getResidentAccountRequest: get, match } = this.props;
    get({ list: false, id: match.params.id });
  }

  handleOnRespond = (responseType) => {
    const {
      updateResidentAccountRequest: update, residentAccountRequest, dirty, residentsAccountRequestForm, history,
    } = this.props;
    update({
      id: residentAccountRequest.id,
      responseType,
      changes: dirty ? residentsAccountRequestForm.values : null,
    })
      .then((action) => {
        if (action.type === RESIDENTS_UPDATE_ACCOUNT_REQUEST.SUCCESS) {
          history.push('/residents');
          if (action.response.responseType === 'approve') {
            toast('Successfully approved request.', {
              type: toast.TYPE.SUCCESS,
            });
          }
          if (action.response.responseType === 'reject') {
            toast('Successfully rejected request.', {
              type: toast.TYPE.SUCCESS,
            });
          }
        }
      });
  };

  handleOnApprove = () => {
    this.handleOnRespond('approve');
  };

  handleOnReject = () => {
    this.handleOnReject('reject');
  };

  render() {
    // Props
    const {
      dirty,
      residentAccountRequest: {
        resident,
      },
    } = this.props;

    return (
      <Container>
        <h3 className="page-title">Respond to Resident Account Request</h3>
        <p className="page-subhead">
          Confirm that the resident has entered the correct information and click either approve or reject.
        </p>
        <Row>
          <Col xs={6}>
            <Card>
              <CardBody>
                <ResidentAccountRequestForm
                  initialValues={{
                    name: resident.user.name,
                    email: resident.user.email,
                    address: resident.address,
                    rentAccountNumber: resident.rentAccountNumber,
                    mobileNumber: resident.mobileNumber,
                    homePhoneNumber: resident.homePhoneNumber,
                  }}
                  enableReinitialize
                />
                <ButtonToolbar className="justify-content-end">
                  <AsyncButton
                    color="danger"
                    outline
                    className="rounded-0"
                    onClick={this.handleOnReject}
                  >
                    Reject
                  </AsyncButton>
                  <AsyncButton
                    color="primary"
                    outline
                    className="rounded-0"
                    onClick={this.handleOnApprove}
                  >
                    {dirty ? 'Save changes and approve' : 'Approve'}
                  </AsyncButton>
                </ButtonToolbar>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    residentAccountRequest,
  } = state.residents;

  return {
    // Residents
    residentAccountRequest,
  };
};

const mapDispatchToProps = {
  getResidentAccountRequest,
  updateResidentAccountRequest,
};

const withForm = {
  form: 'residentAccountRequestForm',
};

export default wrapper({
  component: ResidentAccountRequestDetail,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(withForm),
  ],
});
