import { generateReducerElement as generate, reduxTypes as types, adjust } from '@tmosystems/core';

// Action creators
import {
  // Async action creators
  NEWS__CREATE_STORY,
  NEWS__CREATE_AND_PUBLISH_STORY,
  NEWS__GET_STORY,
  NEWS__UPDATE_STORY,
  NEWS__UPDATE_AND_PUBLISH_STORY,
  NEWS__PUBLISH_STORY,
  NEWS__UNPUBLISH_STORY,
  NEWS__DELETE_STORY,
  NEWS__PIN_STORY,

  // Sync action creators
  NEWS__SELECT_STORY,
  NEWS__UPDATE_STORY_TABLE_META,
  NEWS__REINITIALIZE_STORY_STATE,
} from './actionCreators';

import { initialState } from './reducer';

export default (state, action) => ({
  // Async
  ...generate(state, action, NEWS__CREATE_STORY, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, NEWS__CREATE_STORY.SUCCESS) && {
        stories: {
          ...state.stories,
          instance: action.response.instance,
          instances: [
            ...state.stories.instances,
            action.response.instance,
          ],
        },
      },
    },
  }),
  ...generate(state, action, NEWS__CREATE_AND_PUBLISH_STORY, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, NEWS__CREATE_AND_PUBLISH_STORY.SUCCESS) && {
        stories: {
          ...state.stories,
          instance: action.response.instance,
          instances: [
            ...state.stories.instances,
            action.response.instance,
          ],
        },
      },
    },
  }),
  ...generate(state, action, NEWS__GET_STORY, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, NEWS__GET_STORY.SUCCESS) && {
        stories: action.response.append ? {
          ...state.stories,
          instance: action.response.instance || state.stories.instance,
          instances: [
            ...state.stories.instances,
            ...action.response.instances,
          ],
          count: action.response.count,
        } : {
          ...state.stories,
          instance: action.response.instance || state.stories.instance,
          instances: action.response.instances || state.stories.instances,
          count: action.response.count,
        },
      },
    },
  }),
  ...generate(state, action, NEWS__UPDATE_STORY, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, NEWS__UPDATE_STORY.SUCCESS) && {
        stories: {
          ...state.stories,
          instance: action.response.instance,
          instances: state.stories.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.stories.selected,
        },
      },
    },
  }),
  ...generate(state, action, NEWS__UPDATE_AND_PUBLISH_STORY, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, NEWS__UPDATE_AND_PUBLISH_STORY.SUCCESS) && {
        stories: {
          ...state.stories,
          instance: action.response.instance,
          instances: state.stories.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.stories.selected,
        },
      },
    },
  }),
  ...generate(state, action, NEWS__PUBLISH_STORY, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, NEWS__PUBLISH_STORY.SUCCESS) && {
        stories: {
          ...state.stories,
          instance: action.response.instance,
          instances: state.stories.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.stories.selected,
        },
      },
    },
  }),
  ...generate(state, action, NEWS__UNPUBLISH_STORY, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, NEWS__UNPUBLISH_STORY.SUCCESS) && {
        stories: {
          ...state.stories,
          instance: action.response.instance,
          instances: state.stories.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.stories.selected,
        },
      },
    },
  }),
  ...generate(state, action, NEWS__DELETE_STORY, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, NEWS__DELETE_STORY.SUCCESS) && {
        stories: {
          ...state.stories,
          instance: state.stories.instance,
          instances: state.stories.instances
            .filter(instance => !action.response.deletedIds.includes(instance.id)),
          selected: initialState.stories.selected,
        },
      },
    },
  }),
  ...generate(state, action, NEWS__PIN_STORY, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, NEWS__PIN_STORY.SUCCESS) && {
        stories: {
          ...state.stories,
          instance: action.response.instance,
          instances: state.stories.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.stories.selected,
        },
      },
    },
  }),

  // Sync
  ...generate(state, action, NEWS__SELECT_STORY, {
    type: types.SYNC,
    adjustment: {
      stories: {
        ...state.stories,
        selected: action.selected,
        selectedIndices: action.selectedIndices,
      },
    },
  }),
  ...generate(state, action, NEWS__UPDATE_STORY_TABLE_META, {
    type: types.SYNC,
    adjustment: {
      stories: {
        ...state.stories,
        tableMeta: {
          ...state.stories.tableMeta,
          ...action,
        },
      },
    },
  }),
  ...generate(state, action, NEWS__REINITIALIZE_STORY_STATE, {
    type: types.SYNC,
    adjustment: {
      stories: initialState.stories,
    },
  }),
}[action.type] || state);
