import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import {
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';


class VerticalTabs extends PureComponent {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
    activeTab: PropTypes.number.isRequired,

    // Callbacks
    toggle: PropTypes.func.isRequired,
  };

  render() {
    const { activeTab, children, toggle } = this.props;
    const childrenArray = React.Children.toArray(children);

    return (
      <div className="tabs tabs--vertical">
        <div className="tabs__wrap">
          <Nav tabs>
            {childrenArray.map(({ props }, index) => (
              <NavItem key={props['data-name']}>
                <NavLink
                  className={classNames({
                    active: activeTab === index,
                    'text-black-50': activeTab !== index,
                  })}
                  onClick={() => {
                    toggle(index);
                  }}
                >
                  {props['data-name']}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={activeTab}>
              {childrenArray[parseInt(activeTab, 10)]}
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  }
}

export default VerticalTabs;
