import { generateActionCreator as generate, reduxTypes as types } from 'ggtmo-utils';


export const ACCOUNT_REGISTER = generate('ACCOUNT_REGISTER', { type: types.ASYNC });
export const ACCOUNT_VERIFY = generate('ACCOUNT_VERIFY', { type: types.ASYNC });
export const ACCOUNT_LOGIN = generate('ACCOUNT_LOGIN', { type: types.ASYNC });
export const ACCOUNT_LOGOUT = generate('ACCOUNT_LOGOUT', { type: types.ASYNC });
export const ACCOUNT_GET_PROFILE = generate('ACCOUNT_GET_PROFILE', { type: types.ASYNC });
export const ACCOUNT_UPDATE_PROFILE = generate('ACCOUNT_UPDATE_PROFILE', { type: types.ASYNC });
export const ACCOUNT_FORGOT_PASSWORD = generate('ACCOUNT_FORGOT_PASSWORD', { type: types.ASYNC });
export const ACCOUNT_RESET_PASSWORD = generate('ACCOUNT_RESET_PASSWORD', { type: types.ASYNC });
