import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { toast } from 'react-toastify';

class BaseEditor extends PureComponent {
  static propTypes = {
    instance: PropTypes.instanceOf(Object).isRequired,

    // Async actions
    get: PropTypes.func.isRequired,
    create: PropTypes.func.isRequired,
    createAndPublish: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    updateAndPublish: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    publish: PropTypes.func.isRequired,
    unpublish: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,

    // Sync actions
    reinitialize: PropTypes.func.isRequired,
    toggleDeleteConfirmModal: PropTypes.func.isRequired,

    // Router
    match: RouterPropTypes.match.isRequired,
    history: RouterPropTypes.history.isRequired,
  };

  appName = '';

  modelName = '';

  formName = 'editorForm';

  actionCreators = {
    get: undefined,
    create: undefined,
    createAndPublish: undefined,
    update: undefined,
    updateAndPublish: undefined,
    delete: undefined,
    publish: undefined,
    unpublish: undefined,
    submit: undefined,
  };

  toastMessages = {
    get: undefined,
    create: undefined,
    createAndPublish: undefined,
    update: undefined,
    updateAndPublish: undefined,
    delete: undefined,
    publish: undefined,
    unpublish: undefined,
    submit: undefined,
  };

  extraRequestParameters = {
    get: {},
    create: {},
    createAndPublish: {},
    update: {},
    updateAndPublish: {},
    delete: {},
    publish: {},
    unpublish: {},
    submit: {},
  };

  componentDidMount() {
    const { get, match } = this.props;
    // Get the data for the instance being edited. If a new
    // instance is being created don't fetch anything and
    // open a blank editor.
    const { id } = match.params;
    if (id !== 'new') {
      get({ id, ...this.extraRequestParameters.get });
    }
  }

  componentWillUnmount() {
    const { reinitialize } = this.props;
    reinitialize();
  }

  _redirectOnCreate = (id) => {
    const { history } = this.props;
    history.replace(`/${this.redirectName || this.appName}/editor/${id}`);
  };

  _titleize = str => (str.length > 0 ? `${str[0].toUpperCase()}${str.slice(1)}` : '');

  _parseErrorResponse = (error, title) => {
    const { data, status, statusText } = error.response;
    if (status >= 500) {
      // If we get a server error Django will send some
      // HTML as ``error.response.data`` so we render
      // ``error.response.statusText`` with the error
      // code instead otherwise the toast message becomes
      // very, very long.
      return (
        <div>
          <h4>{title}</h4>
          <p>{statusText} (Error code: {status})</p>
        </div>
      );
    }
    if (typeof data === 'object') {
      return (
        <div>
          <h4>{title}</h4>
          {Object
            .entries(data)
            .map(([key, value]) => (
              <p key={key}>
                {this._titleize(key)}: {value}
              </p>
            ))}
        </div>
      );
    }
    return null;
  };

  _getFormValues = () => {
    const { [this.formName]: { values } } = this.props;
    return values;
  };

  _onCreatePromiseCallback = (action, actionCreator, toastMessage) => {
    const { type, response, error } = action;
    if (type === actionCreator.SUCCESS) {
      toast(toastMessage.SUCCESS, { type: toast.TYPE.SUCCESS });
      this._redirectOnCreate(response[this.modelName].id);
    }
    if (type === actionCreator.ERROR) {
      toast(this._parseErrorResponse(error, toastMessage.ERROR), { type: toast.TYPE.ERROR, autoClose: 5000 });
    }
    return action;
  };

  handleOnCreate = () => {
    const { create } = this.props;
    return create({ ...this._getFormValues(), ...this.extraRequestParameters.create })
      .then(action => this._onCreatePromiseCallback(action, this.actionCreators.create, this.toastMessages.create));
  };

  handleOnCreateAndPublish = () => {
    const { createAndPublish } = this.props;
    createAndPublish({ ...this._getFormValues(), ...this.extraRequestParameters.createAndPublish })
      .then(action => this._onCreatePromiseCallback(action, this.actionCreators.createAndPublish,
        this.toastMessages.createAndPublish));
  };

  _onUpdatePromiseCallback = (action, actionCreator, toastMessage) => {
    const { type, error } = action;
    if (type === actionCreator.SUCCESS) {
      toast(toastMessage.SUCCESS, { type: toast.TYPE.SUCCESS });
    }
    if (type === actionCreator.ERROR) {
      toast(this._parseErrorResponse(error, toastMessage.ERROR), { type: toast.TYPE.ERROR, autoClose: 5000 });
    }
  };

  handleOnUpdate = () => {
    const { update } = this.props;
    return update({ ...this._getFormValues(), ...this.extraRequestParameters.update })
      .then((action) => {
        this._onUpdatePromiseCallback(action, this.actionCreators.update, this.toastMessages.update);
        return action;
      });
  };

  handleOnUpdateAndPublish = () => {
    const { updateAndPublish } = this.props;
    return updateAndPublish({ ...this._getFormValues(), ...this.extraRequestParameters.updateAndPublish })
      .then(action => this._onUpdatePromiseCallback(action, this.actionCreators.updateAndPublish,
        this.toastMessages.updateAndPublish));
  };

  handleOnToggleDeleteConfirmModal = () => {
    const { toggleDeleteConfirmModal } = this.props;
    toggleDeleteConfirmModal();
  };

  handleOnDelete = () => {
    const {
      delete: delete_, toggleDeleteConfirmModal, match, history,
    } = this.props;
    const { delete: deleteToastMessage } = this.toastMessages;
    return delete_({ id: match.params.id })
      .then(({ type, error }) => {
        if (type === this.actionCreators.delete.SUCCESS) {
          toggleDeleteConfirmModal();
          history.push(`/${this.rediretcName || this.appName}`);
          toast(deleteToastMessage.SUCCESS, { type: toast.TYPE.SUCCESS });
        }
        if (type === this.actionCreators.delete.ERROR) {
          toast(this._parseErrorResponse(error, deleteToastMessage.ERROR),
            { type: toast.TYPE.ERROR, autoClose: 5000 });
        }
      });
  };

  handleOnPublish = () => {
    const { publish: publishActionCreator } = this.actionCreators;
    const { publish: publishToastMessage } = this.toastMessages;
    const { publish, match } = this.props;
    return publish({ id: match.params.id })
      .then((action) => {
        const { type, error } = action;
        if (type === publishActionCreator.SUCCESS) {
          toast(publishToastMessage.SUCCESS, { type: toast.TYPE.SUCCESS });
        }
        if (type === publishActionCreator.ERROR) {
          toast(this._parseErrorResponse(error, publishToastMessage.ERROR),
            { type: toast.TYPE.ERROR, autoClose: 5000 });
        }
        return action;
      });
  };

  handleOnUnpublish = () => {
    const { unpublish: unpublishActionCreator } = this.actionCreators;
    const { unpublish: unpublishToastMessage } = this.toastMessages;
    const { unpublish, match } = this.props;
    return unpublish({ id: match.params.id })
      .then(({ type, error }) => {
        if (type === unpublishActionCreator.SUCCESS) {
          toast(unpublishToastMessage.SUCCESS, { type: toast.TYPE.SUCCESS });
        }
        if (type === unpublishActionCreator.ERROR) {
          toast(this._parseErrorResponse(error, unpublishToastMessage.ERROR),
            { type: toast.TYPE.ERROR, autoClose: 5000 });
        }
      });
  };

  handleOnSubmit = () => {
    const { submit: submitActionCreator } = this.actionCreators;
    const { submit: submitToastMessage } = this.toastMessages;
    const { submit, instance } = this.props;
    return submit({
      contentObjectModelLabel: instance.contentObjectModelLabel,
      contentId: instance.contentId,
    })
      .then((action) => {
        if (action.type === submitActionCreator.SUCCESS) {
          toast(submitToastMessage.SUCCESS, {
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        }
      });
  };
}

export default BaseEditor;
