import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reducer as reduxFormReducer } from 'redux-form';
import { composeWithDevTools } from 'redux-devtools-extension';

// Reducers
import { sidebarReducer } from '../../redux/reducers';
import newsReducer from '../../apps/news/redux/reducer';
import eventsReducer from '../../apps/events/redux/reducer';
import sectionsReducer from '../../apps/sections/redux/reducer';
import adminReducer from '../../apps/submissions/redux/reducer';
import repairsReducer from '../../apps/repairs/redux/reducer';
import documentsReducer from '../../apps/documents/redux/reducer';
import integrationsReducer from '../../apps/integrations/redux/reducer';
import accountReducer from '../../apps/users/redux/reducer';
import editorReducer from '../../redux/reducers/editorReducer';
import residentsReducer from '../../apps/users/residents/redux/reducer';
import communicationsReducer from '../../apps/communications/redux/reducer';
import imagesReducer from '../../apps/images/redux/reducer';

const reducer = combineReducers({
  form: reduxFormReducer,
  sidebar: sidebarReducer,
  news: newsReducer,
  events: eventsReducer,
  sections: sectionsReducer,
  admin: adminReducer,
  repairs: repairsReducer,
  documents: documentsReducer,
  account: accountReducer,
  integrations: integrationsReducer,
  editor: editorReducer,
  residents: residentsReducer,
  communications: communicationsReducer,
  images: imagesReducer,
});

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(thunk)),
);

export default store;
