import {
  generateReducerElement as generate, reduxTypes as types, initializeActions, adjust,
} from 'ggtmo-utils';

// Action Creators
import {
  RESIDENTS_GET_RESIDENT,
  RESIDENTS_GET_ACCOUNT_REQUEST,
  RESIDENTS_UPDATE_ACCOUNT_REQUEST,
  RESIDENTS_SELECT_RESIDENT,
} from './actionCreators';

const initialState = {
  residents: [],
  selectedResidents: [],
  residentAccountRequests: [],
  residentAccountRequest: {
    resident: {
      user: {},
    },
  },
  selectedResidentAccountRequests: [],
  actions: initializeActions([
    RESIDENTS_GET_RESIDENT,
    RESIDENTS_GET_ACCOUNT_REQUEST,
    RESIDENTS_UPDATE_ACCOUNT_REQUEST,
  ]),
};

export default (state = initialState, action) => ({
  ...generate(state, action, RESIDENTS_GET_RESIDENT, { type: types.ASYNC }),
  ...generate(state, action, RESIDENTS_GET_ACCOUNT_REQUEST, { type: types.ASYNC }),
  ...generate(state, action, RESIDENTS_UPDATE_ACCOUNT_REQUEST, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, RESIDENTS_UPDATE_ACCOUNT_REQUEST.SUCCESS) && {
        residents: [
          ...state.residents,
          action.response.residentAccountRequest.resident,
        ],
      },
    },
  }),
  ...generate(state, action, RESIDENTS_SELECT_RESIDENT, {
    type: types.SYNC,
    adjustment: {
      selectedResidents: action.selected,
    },
  }),
}[action.type] || state);
