import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Components
import DefaultFroalaEditor from 'react-froala-wysiwyg';
import { Label } from 'reactstrap';
import { getHeaders } from '@tmosystems/core';

class FroalaEditor extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    showLabel: PropTypes.bool,
    value: PropTypes.string,
    values: PropTypes.instanceOf(Object),
    errors: PropTypes.instanceOf(Object).isRequired,
    refreshKey: PropTypes.string.isRequired,
    config: PropTypes.instanceOf(Object).isRequired,

    // Callbacks
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    label: '',
    showLabel: true,
    value: null,
    values: {},
  };

  handleOnModelChange = (model) => {
    const { onChange, name } = this.props;
    onChange({ target: { name, value: model } });
  };

  render() {
    // Env
    const {
      REACT_APP_BASE_URL: baseUrl,
      REACT_APP_FROALA_KEY: froalaKey,
    } = process.env;

    // Props
    const {
      name,
      label,
      showLabel,
      value,
      values,
      errors: {
        sync: syncErrors,
        async: asyncErrors,
      },
      refreshKey,
      config,
      ...rest
    } = this.props;
    const mediaApiUrl = `${baseUrl}/images/api/v1/`;
    const mediaParams = {
      froala: true,
    };
    const hasSyncErrors = !!syncErrors[name];
    const hasAsyncErrors = !!asyncErrors[name];
    return (
      <>
        {showLabel && (
          // Base Reactstrap label component
          <Label className={classNames('text-capitalize', { 'text-danger': hasAsyncErrors })}>
            {/* Append sync error to label text */}
            {label || name.split(/(?=[A-Z])/).join(' ')}
            {hasSyncErrors && (
              <span className="text-danger text-lowercase">
                {' '}- {syncErrors[name]}
              </span>
            )}
          </Label>
        )}

        {/* Async error list */}
        {hasAsyncErrors && (
          <ul>
            {asyncErrors[name].map(asyncError => (
              <li className="text-danger">{asyncError}</li>
            ))}
          </ul>
        )}

        {/* Base FroalaEditor input component */}
        <DefaultFroalaEditor
          key={refreshKey}
          model={values[name] || value}
          onModelChange={this.handleOnModelChange}
          config={{
            heightMin: 500,
            toolbarSticky: true,
            toolbarStickyOffset: 60,
            imageManagerLoadMethod: 'GET',
            imageManagerLoadURL: mediaApiUrl,
            imageManagerLoadParams: mediaParams,
            imageManagerDeleteMethod: 'DELETE',
            imageManagerDeleteURL: mediaApiUrl,
            imageManagerDeleteParams: mediaParams,
            imageUploadURL: mediaApiUrl,
            imageUploadParams: mediaParams,
            requestHeaders: { ...getHeaders().headers },
            requestWithCORS: false,
            key: froalaKey,
            attribution: false,
            ...config,
          }}
          {...rest}
        />

        {hasSyncErrors && !showLabel ? (
          <span className="text-danger pl-2">
            {syncErrors[name]}
          </span>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    refreshKey,
  } = state.sections;

  return {
    // Sections
    refreshKey,
  };
};

export default connect(mapStateToProps)(FroalaEditor);
