import React from 'react';
import { reduxForm, Form, Field } from 'redux-form';

// Components
import TextField from '../../../shared/components/form/TextField';
import ImageSetField from '../../images/components/ImageSetField';
import FroalaEditorField from '../../../shared/components/form/FroalaEditor/ReduxFormFroalaEditor';
import DateField from '../../../shared/components/form/DateField';

const EditorForm = () => (
  <Form className="material-form events__editor__form form--horizontal">
    <div className="form__form-group">
      <span className="form__form-group-label">Title</span>
      <Field
        className="material-form__field events__editor__form__title"
        name="title"
        component={TextField}
      />
    </div>
    <div className="form__form-group mt-4 mb-5">
      <Field
        className="material-form__field events__editor__form__datepicker"
        name="date"
        component={DateField}
      />
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Images</span>
      <Field
        name="imageSet"
        component={ImageSetField}
      />
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Body</span>
      <Field
        className="pt-3 rounded"
        name="body"
        component={FroalaEditorField}
      />
    </div>
  </Form>
);

export default reduxForm({ form: 'editorForm' })(EditorForm);
