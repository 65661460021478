import React from 'react';
import PropTypes from 'prop-types';

// Components
import DefaultSelect from 'react-select';

const defaultStyles = {
  control: provided => ({
    ...provided,
    borderRadius: 3,
  }),
};

const Select = ({ styles, ...rest }) => (
  <DefaultSelect
    {...rest}
    styles={{
      ...defaultStyles,
      ...styles,
    }}
  />
);

Select.propTypes = {
  styles: PropTypes.instanceOf(Object),
};

Select.defaultProps = {
  styles: {},
};

export default Select;
