import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import pluralize from 'pluralize';

// Components
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/pro-light-svg-icons';

// Actions
import {
  imagesGetImage,
  imagesUpdateImage,
  imagesSelectImage,
  imagesGetCategory,
  imagesSelectCategory,
  imagesUpdateImageModalMeta,
  imagesUpdateImageScrollMeta,
  imagesUpdateImageSearchMeta,
} from '../redux/actions';
import { IMAGES__GET_IMAGE } from '../redux/actionCreators';

const CategoryListItemStyled = styled.div`
  text-transform: capitalize;
  padding: 0.5rem 0 0.5rem 1.5rem;
  transition: all 0.15s ease-in-out;
  margin-bottom: 1px;
  ${({ theme, selected, hovered }) => {
    if (selected) {
      return `
        background-color: ${theme.color.primary}
        color: white;
      `;
    }
    if (hovered) {
      return `
        background-color: ${theme.color.primaryLighter}
        color: white;
      `;
    }
    return 'transparent';
  }};

  h5 {
    color: ${({ selected }) => (selected ? 'white' : 'black')};
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.primaryLightest};
    cursor: pointer;
    
    color: white;
    h5 {
      color: white;
    }
  }
`;

const CategoryList = ({
  items,
  selectedItem,
  hoveredItem,

  // Callbacks
  itemOnClick,
  onDragEnter,
  onDragOver,
  onDragLeave,
  onDrop,
}) => (
  /* A simple list component to mount
  *  several sidebar categories. */
  <div>
    {items.map(item => (
      <CategoryListItem
        item={item}
        selectedItem={selectedItem}
        hoveredItem={hoveredItem}

        // Callbacks
        onClick={itemOnClick}
        onDragEnter={onDragEnter}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      />
    ))}
  </div>
);

CategoryList.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  selectedItem: PropTypes.number.isRequired,
  hoveredItem: PropTypes.number.isRequired,

  // Callbacks
  itemOnClick: PropTypes.func.isRequired,
  onDragEnter: PropTypes.func.isRequired,
  onDragOver: PropTypes.func.isRequired,
  onDragLeave: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
};

const CategoryListItem = ({
  item: {
    id,
    name,
  },
  selectedItem,
  hoveredItem,

  // Callbacks
  onClick,
  onDragEnter,
  onDragOver,
  onDragLeave,
  onDrop,
}) => (
  /* A simple list item component to
  * display an image category */
  <CategoryListItemStyled
    selected={id === selectedItem}
    hovered={id === hoveredItem}
    data-id={id}

    // Callbacks
    onClick={onClick}
    onDragEnter={onDragEnter}
    onDragOver={onDragOver}
    onDragLeave={onDragLeave}
    onDrop={onDrop}
  >
    {pluralize(name)}
  </CategoryListItemStyled>
);

CategoryListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  selectedItem: PropTypes.number.isRequired,
  hoveredItem: PropTypes.number.isRequired,

  // Callbacks
  onClick: PropTypes.func.isRequired,
  onDragEnter: PropTypes.func.isRequired,
  onDragOver: PropTypes.func.isRequired,
  onDragLeave: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
};

class ImagesSidebar extends Component {
  /* The Sidebar component will house a list of image categories as well
   * as some additional UI elements such as an "upload" button. */

  static propTypes = {
    // Images
    images: PropTypes.instanceOf(Object).isRequired,
    categories: PropTypes.instanceOf(Array).isRequired,

    // Actions
    imagesGetImage: PropTypes.func.isRequired,
    imagesUpdateImage: PropTypes.func.isRequired,
    imagesSelectImage: PropTypes.func.isRequired,
    imagesGetCategory: PropTypes.func.isRequired,
    imagesSelectCategory: PropTypes.func.isRequired,
    imagesUpdateImageModalMeta: PropTypes.func.isRequired,
    imagesUpdateImageScrollMeta: PropTypes.func.isRequired,
    imagesUpdateImageSearchMeta: PropTypes.func.isRequired,

    // Callbacks
    getImage: PropTypes.func,
  };

  static defaultProps = {
    // Callbacks
    getImage: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      hoveredCategory: -1,
    };
  }

  componentDidMount() {
    const { imagesGetCategory: getCategory } = this.props;
    getCategory({ list: true });
  }

  handleCategoryListItemOnClick = (event) => {
    // When a sidebar item is click we need to
    // grab the images pertaining to the selected
    // category and update the selected image category
    // in the Redux store to let other components know.
    const {
      getImage: getImageCallback,
      imagesGetImage: getImage,
      imagesSelectImage: selectImage,
      imagesSelectCategory: selectCategory,
      imagesUpdateImageScrollMeta: updateImageScrollMeta,
      imagesUpdateImageSearchMeta: updateImageSearchMeta,
    } = this.props;
    const { id: categoryId } = event.currentTarget.dataset;
    const requestParameters = { list: true, categoryId };
    let actionResponse;
    if (getImageCallback) {
      actionResponse = getImageCallback(requestParameters);
    } else {
      actionResponse = getImage(requestParameters);
    }
    actionResponse
      .then((action) => {
        if (action.type === IMAGES__GET_IMAGE.SUCCESS) {
          selectCategory({ selected: [parseInt(categoryId, 10)] });
          selectImage({ selected: [] });
          updateImageScrollMeta({ page: 0, noMoreInstances: false });
          updateImageSearchMeta({ searchTerm: '' });
        }
      });
  };

  handleOnDragEnter = (event) => {
    event.preventDefault();
    const categoryId = parseInt(event.target.dataset.id, 10);
    this.setState({
      hoveredCategory: categoryId,
    });
  };

  handleOnDragOver = (event) => {
    event.preventDefault();
  };

  handleOnDragExit = () => {
    this.setState({
      hoveredCategory: -1,
    });
  };

  handleOnDrop = (event) => {
    // When dropped on a given category we want
    // to update the image's category on the server.
    const { imagesUpdateImage: updateImage, images } = this.props;
    const categoryId = parseInt(event.target.dataset.id, 10);
    const imageId = event.dataTransfer.getData('imageId');
    const currentCategoryId = images.instances.filter(image => image.id === parseInt(imageId, 10))[0].category;
    if (categoryId !== currentCategoryId) {
      updateImage({
        id: imageId,
        categoryId,
      });
    }
    this.setState({
      hoveredCategory: -1,
    });
  };

  handleToggleUploadModal = () => {
    const { imagesUpdateImageModalMeta: updateImageModalMeta } = this.props;
    updateImageModalMeta({ isOpen: true, openMode: 'upload' });
  };

  render() {
    // State
    const {
      hoveredCategory,
    } = this.state;

    // Props
    const {
      // Images
      categories: {
        instances,
        selected,
      },
    } = this.props;

    return (
      <div>
        <div className="p-4">
          <Button onClick={this.handleToggleUploadModal} className="w-100" color="primary">
            <FontAwesomeIcon icon={faUpload} className="mr-2" />
            Upload images
          </Button>
        </div>
        <div>
          <CategoryListItemStyled
            selected={selected[0] === -1}
            data-id={-1}
            className="mb-5"

            // Callbacks
            onClick={this.handleCategoryListItemOnClick}
          >
            <h5 className="text-uppercase">All images</h5>
          </CategoryListItemStyled>
          <div className="px-4">
            <h5 className="text-uppercase">Categories</h5>
            <hr />
          </div>
          <CategoryList
            items={instances}
            selectedItem={selected[0]}
            hoveredItem={hoveredCategory}

            // Callbacks
            itemOnClick={this.handleCategoryListItemOnClick}
            onDragEnter={this.handleOnDragEnter}
            onDragOver={this.handleOnDragOver}
            onDragLeave={this.handleOnDragExit}
            onDrop={this.handleOnDrop}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    images,
    categories,
    selectedCategory,
  } = state.images;

  return {
    // Images
    images,
    categories,
    selectedCategory,
  };
};

const mapDispatchToProps = {
  imagesGetImage,
  imagesUpdateImage,
  imagesSelectImage,
  imagesGetCategory,
  imagesSelectCategory,
  imagesUpdateImageModalMeta,
  imagesUpdateImageScrollMeta,
  imagesUpdateImageSearchMeta,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImagesSidebar);
