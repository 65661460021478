import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import { Row, Col } from 'reactstrap';
import { faTag } from '@fortawesome/pro-light-svg-icons';
import ReceivedMessageLabelForm from './ReceivedMessageLabelForm';
import Modal from '../../../../shared/components/Modal';
import ModalHeader from '../../../../shared/components/ModalHeader';
import ModalSidebar from '../../../../shared/components/ModalSidebar';
import ModalContent from '../../../../shared/components/ModalContent';

// Redux
import {
  communicationsCreateReceivedMessageLabel,
  communicationsToggleReceivedMessageLabelModal,
} from '../../redux/actions';
import { COMMUNICATIONS_CREATE_RECEIVED_MESSAGE_LABEL } from '../../redux/actionCreators';

class ReceivedMessageLabelModal extends PureComponent {
  static propTypes = {
    // Communications
    communicationsActions: PropTypes.instanceOf(Object).isRequired,
    receivedMessageLabels: PropTypes.instanceOf(Object).isRequired,

    // Redux
    communicationsCreateReceivedMessageLabel: PropTypes.func.isRequired,

    // Callbacks
    toggle: PropTypes.func.isRequired,
  };

  handleOnSubmit = (values) => {
    const { communicationsCreateReceivedMessageLabel: createReceivedMessageLabel, toggle } = this.props;
    return createReceivedMessageLabel(values)
      .then((action) => {
        if (action.type === COMMUNICATIONS_CREATE_RECEIVED_MESSAGE_LABEL.SUCCESS) {
          toggle();
        }
        return action;
      });
  };

  renderCreateModal() {
    // Props
    const {
      communicationsActions: {
        COMMUNICATIONS_CREATE_RECEIVED_MESSAGE_LABEL: createReceivedMessageLabelAction,
      },

      // Callbacks
      toggle,
    } = this.props;

    return (
      <>
        <ModalHeader
          title="Create label"

          // Callbacks
          onClose={toggle}
        />
        <Row noGutters>
          <Col xs={4}>
            <ModalSidebar
              icon={faTag}
              heading="Create label"
              body="Use labels to categorise received messages."
            />
          </Col>
          <Col>
            <ModalContent>
              <ReceivedMessageLabelForm
                action={createReceivedMessageLabelAction}

                // Callbacks
                onSubmit={this.handleOnSubmit}
              />
            </ModalContent>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    // Props
    const {
      receivedMessageLabels: {
        modalMeta: {
          isOpen,
          openMode,
        },
      },

      // Callbacks
      toggle,
    } = this.props;

    return (
      <Modal
        open={isOpen}
        width="xl"
        toggle={toggle}
        noPadding
        centered
      >
        {openMode === 'create' && this.renderCreateModal()}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    actions: communicationsActions,
    receivedMessageLabels,
  } = state.communications;

  return {
    // Communications
    communicationsActions,
    receivedMessageLabels,
  };
};

const mapDispatchToProps = {
  communicationsCreateReceivedMessageLabel,
  communicationsToggleReceivedMessageLabelModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReceivedMessageLabelModal);
