import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Button } from 'reactstrap';
import AsyncText from './AsyncText';


const AsyncButton = ({
  children, action, keepTextWithSpinner, icon, ...rest
}) => (
  <Button {...rest}>
    <AsyncText action={action} keepTextWithSpinner={keepTextWithSpinner} icon={icon}>
      {children}
    </AsyncText>
  </Button>
);

AsyncButton.propTypes = {
  children: PropTypes.node.isRequired,
  action: PropTypes.instanceOf(Object),
  keepTextWithSpinner: PropTypes.bool,
  icon: PropTypes.instanceOf(Object),
};

AsyncButton.defaultProps = {
  action: {
    loading: false,
  },
  keepTextWithSpinner: false,
  icon: null,
};

export default AsyncButton;
