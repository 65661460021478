import { generateAction as generate, reduxTypes as types } from 'ggtmo-utils';
import {
  ACCOUNT_REGISTER,
  ACCOUNT_VERIFY,
  ACCOUNT_LOGIN,
  ACCOUNT_LOGOUT,
  ACCOUNT_GET_PROFILE,
  ACCOUNT_UPDATE_PROFILE,
  ACCOUNT_FORGOT_PASSWORD,
  ACCOUNT_RESET_PASSWORD,
} from './actionCreators';
import service from '../service';


export const register = generate(ACCOUNT_REGISTER, {
  type: types.ASYNC,
  service: service.post.register,
});

export const verify = generate(ACCOUNT_VERIFY, {
  type: types.ASYNC,
  service: service.post.verify,
});

export const login = generate(ACCOUNT_LOGIN, {
  type: types.ASYNC,
  service: service.post.login,
});

export const logout = generate(ACCOUNT_LOGOUT, {
  type: types.ASYNC,
  service: service.post.logout,
});

export const getProfile = generate(ACCOUNT_GET_PROFILE, {
  type: types.ASYNC,
  service: service.get.profile,
});

export const updateProfile = generate(ACCOUNT_UPDATE_PROFILE, {
  type: types.ASYNC,
  service: service.put.profile,
});

export const forgotPassword = generate(ACCOUNT_FORGOT_PASSWORD, {
  type: types.ASYNC,
  service: service.post.forgotPassword,
});

export const resetPassword = generate(ACCOUNT_RESET_PASSWORD, {
  type: types.ASYNC,
  service: service.post.resetPassword,
});
