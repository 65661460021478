import PropTypes from 'prop-types';


export const modalPropTypes = {
  isOpen: PropTypes.bool.isRequired,

  // Callbacks
  toggle: PropTypes.func.isRequired,
  onOpened: PropTypes.func,
  onClosed: PropTypes.func,
};


export const modalDefaultProps = {
  // Callbacks
  onOpened: () => {},
  onClosed: () => {},
};
