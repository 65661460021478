import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import { ContactSearchBarWrapper, ContactSearchBarInput } from './styled';

// Redux
import { communicationsGetContact } from '../../redux/actions';
import { COMMUNICATIONS_GET_CONTACT } from '../../redux/actionCreators';

class ContactSearchBar extends Component {
  static propTypes = {
    // Redux
    communicationsGetContact: PropTypes.func.isRequired,

    // Callbacks
    onSearchSuccess: PropTypes.func,
  };

  static defaultProps = {
    // Callbacks
    onSearchSuccess: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
    };
  }

  handleOnChange = (event) => {
    const { communicationsGetContact: getContact, onSearchSuccess } = this.props;
    const { value } = event.target;
    this.setState({ searchTerm: value });
    getContact({ search: true, searchTerm: value })
      .then((action) => {
        if (action.type === COMMUNICATIONS_GET_CONTACT.SUCCESS) {
          onSearchSuccess(value);
        }
      });
  };

  render() {
    // State
    const {
      searchTerm,
    } = this.state;

    return (
      <ContactSearchBarWrapper>
        <ContactSearchBarInput
          name="searchTerm"
          type="text"
          value={searchTerm}
          onChange={this.handleOnChange}
          placeholder="Search for contacts..."
          autoComplete="off"
        />
      </ContactSearchBarWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    contactSuggestions,
  } = state.communications;

  return {
    // Communications
    contactSuggestions,
  };
};

const mapDispatchToProps = {
  communicationsGetContact,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactSearchBar);
