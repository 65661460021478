import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import { Row, Col } from 'reactstrap';
import AsyncComponent from '../../../shared/components/AsyncComponent';
import HomeForm from './HomeForm';
import EditorControls from '../../../shared/components/EditorControls';

// Redux
import { sectionsUpdatePage, sectionsUpdateAndPublishPage } from '../redux/actions';
import { SECTIONS__UPDATE_PAGE, SECTIONS__UPDATE_AND_PUBLISH_PAGE } from '../redux/actionCreators';

class HomeEditor extends PureComponent {
  static propTypes = {
    // Form
    homeForm: PropTypes.instanceOf(Object).isRequired,

    // Sections
    sectionsActions: PropTypes.instanceOf(Object).isRequired,
    sections: PropTypes.instanceOf(Object).isRequired,
    pages: PropTypes.instanceOf(Object).isRequired,

    // Actions
    sectionsUpdatePage: PropTypes.func.isRequired,
    sectionsUpdateAndPublishPage: PropTypes.func.isRequired,
  };

  handleOnUpdate = () => {
    const { sectionsUpdatePage: updatePage, homeForm } = this.props;
    updatePage({
      slug: '',
      updateDocumentSet: false,
      imageSet: homeForm.values.imageSet || { images: [] },
      noticeSet: homeForm.values.noticeSet || { notices: [] },
    })
      .then((action) => {
        if (action.type === SECTIONS__UPDATE_PAGE.SUCCESS) {
          // Do something.
        }
      });
  };

  handleOnUpdateAndPublish = () => {
    const { sectionsUpdateAndPublishPage: updateAndPublishPage, homeForm } = this.props;
    updateAndPublishPage({
      slug: '',
      updateDocumentSet: false,
      imageSet: homeForm.values.imageSet || { images: [] },
      noticeSet: homeForm.values.noticeSet || { notices: [] },
    })
      .then((action) => {
        if (action.type === SECTIONS__UPDATE_AND_PUBLISH_PAGE.SUCCESS) {
          // Do something.
        }
      });
  };

  handleGetHelpText = () => 'Test';

  render() {
    // Props
    const {
      sectionsActions: {
        SECTIONS__GET_PAGE: getPageAction,
        SECTIONS__UPDATE_PAGE: updatePageAction,
        SECTIONS__UPDATE_AND_PUBLISH_PAGE: updateAndPublishPageAction,
      },
      sections,
      pages,
    } = this.props;

    return (
      <Row>
        <Col xl={9}>
          <AsyncComponent action={getPageAction}>
            <HomeForm
              initialValues={pages.instance}
              enableReinitialize
            />
          </AsyncComponent>
        </Col>
        <Col className="border-left">
          <EditorControls
            userType="admin"
            instanceType="Home page"
            instance={sections.instance}
            pills={false}
            buttons={[
              'update',
              'updateAndPublish',
            ]}
            updateAction={updatePageAction}
            updateAndPublishAction={updateAndPublishPageAction}

            // Callbacks
            onUpdate={this.handleOnUpdate}
            onUpdateAndPublish={this.handleOnUpdateAndPublish}
            getHelpText={this.handleGetHelpText}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    homeForm,
  } = state.form;

  const {
    actions: sectionsActions,
    sections,
    pages,
  } = state.sections;

  return {
    // Form
    homeForm,

    // Sections
    sectionsActions,
    sections,
    pages,
  };
};

const mapDispatchToProps = {
  sectionsUpdatePage,
  sectionsUpdateAndPublishPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeEditor);
