/* eslint-disable max-len */
import { generateAction as generate, reduxTypes as types } from '@tmosystems/core';
import service from '../service';

// Action creators
import {
  // Async action creators
  SECTIONS__CREATE_SECTION,
  SECTIONS__CREATE_AND_PUBLISH_SECTION,
  SECTIONS__GET_SECTION,
  SECTIONS__UPDATE_SECTION,
  SECTIONS__UPDATE_AND_PUBLISH_SECTION,
  SECTIONS__PUBLISH_SECTION,
  SECTIONS__UNPUBLISH_SECTION,
  SECTIONS__DELETE_SECTION,
  SECTIONS__GET_PAGE,
  SECTIONS__UPDATE_PAGE,
  SECTIONS__UPDATE_AND_PUBLISH_PAGE,
  SECTIONS__CREATE_NOTICE,
  SECTIONS__CREATE_AND_PUBLISH_NOTICE,
  SECTIONS__GET_NOTICE,
  SECTIONS__UPDATE_NOTICE,
  SECTIONS__UPDATE_AND_PUBLISH_NOTICE,
  SECTIONS__PUBLISH_NOTICE,
  SECTIONS__UNPUBLISH_NOTICE,
  SECTIONS__DELETE_NOTICE,

  // Sync action creators
  SECTIONS__SELECT_SECTION,
  SECTIONS__UPDATE_SECTION_MODAL_META,
  SECTIONS__UPDATE_SECTION_TABLE_META,
  SECTIONS__UPDATE_SECTION_SEARCH_META,
  SECTIONS__REINITIALIZE_SECTION_STATE,
  SECTIONS__UPDATE_NOTICE_MODAL_META,
  SECTIONS__UPDATE_KEY,
} from './actionCreators';

// Async actions
export const sectionsCreateSection = generate(SECTIONS__CREATE_SECTION, {
  type: types.ASYNC,
  service: service.POST.createSection,
});
export const sectionsCreateAndPublishSection = generate(SECTIONS__CREATE_AND_PUBLISH_SECTION, {
  type: types.ASYNC,
  service: service.POST.createAndPublishSection,
});
export const sectionsGetSection = generate(SECTIONS__GET_SECTION, {
  type: types.ASYNC,
  service: service.GET.getSection,
});
export const sectionsUpdateSection = generate(SECTIONS__UPDATE_SECTION, {
  type: types.ASYNC,
  service: service.PUT.updateSection,
});
export const sectionsUpdateAndPublishSection = generate(SECTIONS__UPDATE_AND_PUBLISH_SECTION, {
  type: types.ASYNC,
  service: service.PUT.updateAndPublishSection,
});
export const sectionsPublishSection = generate(SECTIONS__PUBLISH_SECTION, {
  type: types.ASYNC,
  service: service.PUT.publishSection,
});
export const sectionsUnpublishSection = generate(SECTIONS__UNPUBLISH_SECTION, {
  type: types.ASYNC,
  service: service.PUT.unpublishSection,
});
export const sectionsDeleteSection = generate(SECTIONS__DELETE_SECTION, {
  type: types.ASYNC,
  service: service.DELETE.deleteSection,
});
export const sectionsGetPage = generate(SECTIONS__GET_PAGE, {
  type: types.ASYNC,
  service: service.GET.getPage,
});
export const sectionsUpdatePage = generate(SECTIONS__UPDATE_PAGE, {
  type: types.ASYNC,
  service: service.PUT.updatePage,
});
export const sectionsUpdateAndPublishPage = generate(SECTIONS__UPDATE_AND_PUBLISH_PAGE, {
  type: types.ASYNC,
  service: service.PUT.updateAndPublishPage,
});
export const sectionsCreateNotice = generate(SECTIONS__CREATE_NOTICE, {
  type: types.ASYNC,
  service: service.POST.createNotice,
});
export const sectionsCreateAndPublishNotice = generate(SECTIONS__CREATE_AND_PUBLISH_NOTICE, {
  type: types.ASYNC,
  service: service.POST.createAndPublishNotice,
});
export const sectionsGetNotice = generate(SECTIONS__GET_NOTICE, {
  type: types.ASYNC,
  service: service.GET.getNotice,
});
export const sectionsUpdateNotice = generate(SECTIONS__UPDATE_NOTICE, {
  type: types.ASYNC,
  service: service.PUT.updateNotice,
});
export const sectionsUpdateAndPublishNotice = generate(SECTIONS__UPDATE_AND_PUBLISH_NOTICE, {
  type: types.ASYNC,
  service: service.PUT.updateAndPublishNotice,
});
export const sectionsPublishNotice = generate(SECTIONS__PUBLISH_NOTICE, {
  type: types.ASYNC,
  service: service.PUT.publishNotice,
});
export const sectionsUnpublishNotice = generate(SECTIONS__UNPUBLISH_NOTICE, {
  type: types.ASYNC,
  service: service.PUT.unpublishNotice,
});
export const sectionsDeleteNotice = generate(SECTIONS__DELETE_NOTICE, {
  type: types.ASYNC,
  service: service.DELETE.deleteNotice,
});
// Sync actions
export const sectionsSelectSection = generate(SECTIONS__SELECT_SECTION, { type: types.SYNC });
export const sectionsUpdateSectionModalMeta = generate(SECTIONS__UPDATE_SECTION_MODAL_META, { type: types.SYNC });
export const sectionsUpdateSectionTableMeta = generate(SECTIONS__UPDATE_SECTION_TABLE_META, { type: types.SYNC });
export const sectionsUpdateSectionSearchMeta = generate(SECTIONS__UPDATE_SECTION_SEARCH_META, { type: types.SYNC });
export const sectionsReinitializeSectionState = generate(SECTIONS__REINITIALIZE_SECTION_STATE, { type: types.SYNC });
export const sectionsUpdateNoticeModalMeta = generate(SECTIONS__UPDATE_NOTICE_MODAL_META, { type: types.SYNC });
export const sectionsUpdateKey = generate(SECTIONS__UPDATE_KEY, { type: types.SYNC });
