/* eslint-disable max-len */
import { generateActionCreator as generate, reduxTypes as types } from '@tmosystems/core';

// Async action creators
export const NEWS__CREATE_STORY = generate('NEWS__CREATE_STORY', { type: types.ASYNC });
export const NEWS__CREATE_AND_PUBLISH_STORY = generate('NEWS__CREATE_AND_PUBLISH_STORY', { type: types.ASYNC });
export const NEWS__GET_STORY = generate('NEWS__GET_STORY', { type: types.ASYNC });
export const NEWS__UPDATE_STORY = generate('NEWS__UPDATE_STORY', { type: types.ASYNC });
export const NEWS__UPDATE_AND_PUBLISH_STORY = generate('NEWS__UPDATE_AND_PUBLISH_STORY', { type: types.ASYNC });
export const NEWS__PUBLISH_STORY = generate('NEWS__PUBLISH_STORY', { type: types.ASYNC });
export const NEWS__UNPUBLISH_STORY = generate('NEWS__UNPUBLISH_STORY', { type: types.ASYNC });
export const NEWS__DELETE_STORY = generate('NEWS__DELETE_STORY', { type: types.ASYNC });
export const NEWS__PIN_STORY = generate('NEWS__PIN_STORY', { type: types.ASYNC });

// Sync actions creators
export const NEWS__SELECT_STORY = generate('NEWS__SELECT_STORY', { type: types.SYNC });
export const NEWS__UPDATE_STORY_TABLE_META = generate('NEWS__UPDATE_STORY_TABLE_META', { type: types.SYNC });
export const NEWS__REINITIALIZE_STORY_STATE = generate('NEWS__REINITIALIZE_STORY_STATE', { type: types.SYNC });
