import React from 'react';
import PropTypes from 'prop-types';
import { squashString } from 'ggtmo-utils';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import TableCell from '@material-ui/core/TableCell';
import MatTable from '../../../../shared/components/table/MatTable';
import StylelessButton from '../../../../shared/components/StylelessButton';

const tableColumns = [
  {
    id: 'name', label: 'Name', disablePadding: false, numeric: false, align: 'left',
  },
  {
    id: 'email', label: 'Email', disablePadding: false, numeric: false, align: 'left',
  },
  {
    id: 'mobileNumber', label: 'Mobile Number', disablePadding: false, numeric: false, align: 'left',
  },
  {
    id: 'actions', label: 'Actions', disablePadding: false, numeric: false, align: 'left',
  },
];


const TableRow = ({
  id,
  name,
  email,
  mobileNumber,
}, { customCallbacks }) => (
  <>
    <TableCell className="material-table__cell">
      {squashString(name)}
    </TableCell>
    <TableCell className="material-table__cell">
      {squashString(email)}
    </TableCell>
    <TableCell className="material-table__cell">
      {squashString(mobileNumber)}
    </TableCell>
    <TableCell>
      <StylelessButton className="text-primary" data-id={id} onClick={customCallbacks.onEdit}>
        <FontAwesomeIcon icon={faEdit} className="mr-1" />Edit
      </StylelessButton>
    </TableCell>
  </>
);

TableRow.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  mobileNumber: PropTypes.string.isRequired,
};


const ContactsTable = ({
  children,
  instances,
  selected,
  page,
  count,

  // Callbacks
  onSelect,
  onSelectByRow,
  onPageChange,
  onEdit,
}) => (
  <div>
    <MatTable
      rowComponent={TableRow}
      columns={tableColumns}
      data={instances}
      selected={selected}
      selectOnlyByCheckbox
      page={page}
      count={count}
      rowsPerPage={15}
      rowsPerPageOptions={['15']}
      emptyRows={false}
      keepHeadOnEmpty
      canSelect={false}

      // Callbacks
      onSelect={onSelect}
      onSelectByRow={onSelectByRow}
      onPageChange={onPageChange}
      customCallbacks={{
        onEdit,
      }}
    >
      {children}
    </MatTable>
  </div>
);

ContactsTable.propTypes = {
  children: PropTypes.node,
  instances: PropTypes.instanceOf(Array).isRequired,
  selected: PropTypes.instanceOf(Array),
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,

  // Callbacks
  onSelect: PropTypes.func.isRequired,
  onSelectByRow: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

ContactsTable.defaultProps = {
  children: null,
  selected: [],
};

export default ContactsTable;
