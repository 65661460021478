import React from 'react';

// Components
import { Row, Col } from 'reactstrap';
import ReceivedMessageLabelList from './ReceivedMessageLabelList';
import MessagesTable from './MessagesTable';

const Inbox = () => (
  <Row>
    <Col className="border-right" xs={2}>
      <ReceivedMessageLabelList />
    </Col>
    <MessagesTable />
  </Row>
);

export default Inbox;
