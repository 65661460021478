import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { wrapper } from 'ggtmo-utils';
import moment from 'moment';

// Components
import {
  Row, Col, Button,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCommentAltPlus, faComments, faUsers, faUser,
} from '@fortawesome/pro-light-svg-icons';
import TextMessagesTable from './TextMessagesTable';
import ContactGroups from '../Contacts/ContactGroups';
import AsyncComponent from '../../../../shared/components/AsyncComponent';
import { ContactGroupPill, RecipientPill } from '../../styled';

// Actions
import {
  communicationsGetTextMessage,
  communicationsGetEmailMessage,
  communicationsUpdateTextMessageTableMeta,
  communicationsSetContactGroupId,
} from '../../redux/actions';
import {
  COMMUNICATIONS_GET_TEXT_MESSAGE,
  COMMUNICATIONS_GET_EMAIL_MESSAGE,
} from '../../redux/actionCreators';

class Outbox extends PureComponent {
  static propTypes = {
    viewingAll: PropTypes.bool.isRequired,
    viewingTextMessage: PropTypes.bool.isRequired,
    viewingEmailMessage: PropTypes.bool.isRequired,

    // Router
    history: ReactRouterPropTypes.history.isRequired,

    // Communications
    communicationsActions: PropTypes.instanceOf(Object).isRequired,
    contactGroups: PropTypes.instanceOf(Object).isRequired,
    textMessages: PropTypes.instanceOf(Object).isRequired,
    // emailMessages: PropTypes.instanceOf(Object).isRequired,
    contactGroupId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,

    // Actions
    communicationsGetTextMessage: PropTypes.func.isRequired,
    communicationsGetEmailMessage: PropTypes.func.isRequired,
    communicationsUpdateTextMessageTableMeta: PropTypes.func.isRequired,
    communicationsSetContactGroupId: PropTypes.func.isRequired,

    // Callbacks
    setViewType: PropTypes.func.isRequired,
    setViewingAll: PropTypes.func.isRequired,
    toggleCreateContactGroupModal: PropTypes.func.isRequired,
    contactGroupOnClick: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {
      communicationsGetTextMessage: getTextMessage, contactGroupId, setViewingAll, setViewType,
    } = this.props;
    getTextMessage({ list: true, contactGroupId }, { asyncId: 0 })
      .then((action) => {
        if (action.type === COMMUNICATIONS_GET_TEXT_MESSAGE.SUCCESS) {
          setViewingAll(contactGroupId === 'all');
          setViewType('none');
        }
      });
  }

  handleGetAllTextMessages = () => {
    const {
      communicationsGetTextMessage: getTextMessage,
      communicationsSetContactGroupId: setContactGroupId,
      setViewType,
      setViewingAll,
    } = this.props;
    getTextMessage({ list: true }, { asyncId: 0 })
      .then((action) => {
        if (action.type === COMMUNICATIONS_GET_TEXT_MESSAGE.SUCCESS) {
          setContactGroupId({ id: 'all' });
          setViewType('none');
          setViewingAll(true);
        }
      });
  };

  handleTextMessageOnClick = (event, id) => {
    const { communicationsGetTextMessage: getTextMessage, setViewType } = this.props;
    getTextMessage({ list: false, id }, { asyncId: 1 })
      .then((action) => {
        if (action.type === COMMUNICATIONS_GET_TEXT_MESSAGE.SUCCESS) {
          setViewType('textMessage');
        }
      });
  };

  handleEmailMessageOnClick = (event, id) => {
    const { communicationsGetEmailMessage: getEmailMessage, setViewType } = this.props;
    getEmailMessage({ list: false, id })
      .then((action) => {
        if (action.type === COMMUNICATIONS_GET_EMAIL_MESSAGE.SUCCESS) {
          setViewType('emailMessage');
        }
      });
  };

  handleContactGroupOnClick = (event) => {
    const {
      communicationsGetTextMessage: getTextMessage,
      communicationsSetContactGroupId: setContactGroupId,
      textMessages: { tableMeta },
      setViewingAll,
      setViewType,
    } = this.props;
    const { id } = event.currentTarget.dataset;
    getTextMessage({
      list: true, contactGroupId: id, page: tableMeta.page, size: tableMeta.rowsPerPage,
    }, { asyncId: 0 })
      .then((action) => {
        if (action.type === COMMUNICATIONS_GET_TEXT_MESSAGE.SUCCESS) {
          setContactGroupId({ id });
          setViewingAll(id === 'all');
          setViewType('none');
        }
      });
  };

  handleOpenMessageEditor = () => {
    const { history } = this.props;
    history.push('/communications/new');
  };

  handleTextMessageTableOnPageChange = (page) => {
    const {
      communicationsUpdateTextMessageTableMeta: updateTextMessageTableMeta,
      communicationsGetTextMessage: getTextMessage,
    } = this.props;
    updateTextMessageTableMeta({ page });
    getTextMessage({ list: true, page }, { asyncId: 0 });
  };

  render() {
    // Props
    const {
      viewingAll,
      viewingTextMessage,
      viewingEmailMessage,

      // Communications
      communicationsActions: {
        COMMUNICATIONS_GET_TEXT_MESSAGE: getTextMessageAction,
      },
      contactGroups,
      textMessages,

      // Callbacks
      toggleCreateContactGroupModal,
    } = this.props;

    return (
      <>
        <Row>
          <Col xs={2} className="border-right">
            <div>
              <Button
                className="w-100"
                outline
                color="primary"
                onClick={this.handleOpenMessageEditor}
              >
                <h4 className="text-primary">
                  <FontAwesomeIcon icon={faCommentAltPlus} className="mr-1" />New message
                </h4>
              </Button>
              <Button
                className="w-100"
                outline
                onClick={this.handleGetAllTextMessages}
                color="secondary"
              >
                <h4>
                  <FontAwesomeIcon icon={faComments} className="mr-1" />All messages
                </h4>
              </Button>
            </div>
            <ContactGroups
              contactGroups={contactGroups}

              // Callbacks
              contactGroupOnClick={this.handleContactGroupOnClick}
              toggleCreateContactGroupModal={toggleCreateContactGroupModal}
            />
          </Col>
          <Col>
            <AsyncComponent action={getTextMessageAction} asyncId={0}>
              <div>
                <h3 className="mb-0">
                  {viewingAll ? 'All Messages' : (
                    <>
                      <span>
                        Messages sent to
                      </span>{' '}
                      <span className="font-weight-bold">
                        {contactGroups.instance.name}
                      </span>
                    </>
                  )}
                </h3>
                <p className="m-0 text-black-50">
                  {textMessages.count} message
                  {textMessages.count === 1 ? '' : 's'} sent
                </p>
              </div>
              <hr />
              <Row>
                <Col
                  xs={(viewingTextMessage || viewingEmailMessage) ? 7 : null}
                  className="border-right"
                >
                  <TextMessagesTable
                    {...textMessages}
                    {...textMessages.tableMeta}

                    // Callbacks
                    onSelectByRow={this.handleTextMessageOnClick}
                    onPageChange={this.handleTextMessageTableOnPageChange}
                  />
                </Col>
                {viewingTextMessage && (
                  <Col>
                    <AsyncComponent action={getTextMessageAction} asyncId={1}>
                      <div>
                        <h5>Message Information</h5>
                        <hr />
                        <div className="mb-3">
                          <p className="font-weight-bold mb-2">
                            To:
                          </p>
                          <div>
                            {textMessages.instance.contactGroups.map(contactGroup => (
                              <ContactGroupPill>
                                <FontAwesomeIcon icon={faUsers} className="mr-1" />
                                {contactGroup.name}
                              </ContactGroupPill>
                            ))}
                            {textMessages.instance.recipients.map(recipient => (
                              <RecipientPill>
                                <FontAwesomeIcon icon={faUser} className="mr-1" />
                                {recipient.name}
                              </RecipientPill>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <p className="font-weight-bold mb-2">
                          Sent:
                        </p>
                        <div>
                          {moment(textMessages.instance.dateTimeSent).calendar()}
                        </div>
                      </div>
                      <div>
                        <p className="font-weight-bold mb-2">
                          Body:
                        </p>
                        <div>
                          <textarea
                            readOnly
                            className="w-100 border rounded p-3"
                            value={textMessages.instance.body}
                            rows={20}
                            style={{ resize: 'none' }}
                          />
                        </div>
                      </div>
                    </AsyncComponent>
                  </Col>
                )}
              </Row>
            </AsyncComponent>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    actions: communicationsActions,
    contactGroups,
    textMessages,
    emailMessages,
    contactGroupId,
  } = state.communications;

  return {
    // Communications
    communicationsActions,
    contactGroups,
    textMessages,
    emailMessages,
    contactGroupId,
  };
};

const mapDispatchToProps = {
  communicationsUpdateTextMessageTableMeta,
  communicationsSetContactGroupId,
  communicationsGetTextMessage,
  communicationsGetEmailMessage,
};

export default wrapper({
  component: Outbox,
  wrappers: [
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
  ],
});
