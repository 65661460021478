import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { wrapper } from 'ggtmo-utils';
import styled from 'styled-components';

// Components
import { Link } from 'react-router-dom';
import { Form } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle as faExclamation } from '@fortawesome/pro-light-svg-icons';
import FormComponent from '../../../../shared/components/form/FormComponent';
import AsyncButton from '../../../../shared/components/AsyncButton';
import Input from '../../../../shared/components/form/Input/ReduxFormInput';

const InputWrapper = styled.div`
  margin-bottom: 40px;

  input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    
    &:focus {
      outline: none;
      box-shadow: none;
      border-bottom: 1px solid ${({ theme }) => theme.color.primary};
    }
  }
`;

class RequestAccountForm extends FormComponent {
  static propTypes = {
    // Form
    requestAccountForm: PropTypes.instanceOf(Object).isRequired,

    // Account
    action: PropTypes.instanceOf(Object).isRequired,

    // Callbacks
    onSubmit: PropTypes.func.isRequired,
  };

  syncValidate = onSubmit => (event) => {
    event.preventDefault();
    const { requestAccountForm } = this.props;
    const { values } = requestAccountForm;
    this.setState({
      errors: {
        sync: {},
        async: {},
      },
    });
    let hasSyncErrors = false;
    if (values) {
      if (!values.name) {
        hasSyncErrors = this.setError('sync', {
          name: 'Please provide your email.',
        });
      }
      if (!values.email) {
        hasSyncErrors = this.setError('sync', {
          email: 'Please provide your email.',
        });
      }
      if (!values.password1) {
        hasSyncErrors = this.setError('sync', {
          password1: 'Please provide a password',
        });
      }
      if (values.password1 && !values.password2) {
        hasSyncErrors = this.setError('sync', {
          password2: 'Please repeat your password',
        });
      }
      if (!hasSyncErrors) {
        onSubmit(values).then(this.asyncValidate);
      }
    }
  };

  render() {
    // State
    const {
      errors,
    } = this.state;

    // Props
    const {
      action,

      // Callbacks
      onSubmit,
    } = this.props;

    return (
      <Form onSubmit={this.syncValidate(onSubmit)}>
        {errors.async.serverError && (
          <div>
            <p className="text-danger">
              {errors.async.serverError}
            </p>
          </div>
        )}
        {errors.async.nonFieldErrors && (
          <div className="pb-4">
            {errors.async.nonFieldErrors.map(nonFieldError => (
              <h4 className="text-danger">
                <FontAwesomeIcon icon={faExclamation} fixedWidth className="mr-1" />{nonFieldError}
              </h4>
            ))}
          </div>
        )}
        <InputWrapper>
          <Field
            name="name"
            component={Input}
            type="text"
            placeholder="Name"
            errors={errors}
            showLabel={false}
          />
        </InputWrapper>
        <InputWrapper>
          <Field
            name="email"
            component={Input}
            type="email"
            placeholder="example@mail.com"
            errors={errors}
            showLabel={false}
          />
        </InputWrapper>
        <InputWrapper>
          <Field
            name="password1"
            label="Password"
            component={Input}
            type="password"
            placeholder="Password"
            errors={errors}
            showLabel={false}
          />
        </InputWrapper>
        <InputWrapper>
          <Field
            name="password2"
            label="Repeat password"
            component={Input}
            type="password"
            placeholder="Repeat password"
            errors={errors}
            showLabel={false}
          />
        </InputWrapper>
        <div className="account__btns">
          <AsyncButton className="btn btn-primary account__btn" type="submit" action={action}>
            Request account
          </AsyncButton>
          <Link className="btn btn-outline-primary account__btn" to="/login">
            Login
          </Link>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    requestAccountForm,
  } = state.form;

  return {
    // Form
    requestAccountForm,
  };
};

export default wrapper({
  component: RequestAccountForm,
  wrappers: [
    connect(mapStateToProps),
    reduxForm({ form: 'requestAccountForm' }),
  ],
});
