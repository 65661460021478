import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StylelessButtonStyled = styled.button`
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    background-color: transparent;
  }
  
  &:active,
  &:focus {
    outline: none;
    background-color: transparent;
  }
`;

const StylelessButton = ({
  icon,
  children,

  ...rest
}) => (
  <StylelessButtonStyled {...rest}>
    {icon && (
      <FontAwesomeIcon icon={icon} className="mr-1" />
    )}
    {children}
  </StylelessButtonStyled>
);

StylelessButton.propTypes = {
  icon: PropTypes.instanceOf(Object),
  children: PropTypes.node.isRequired,
};

StylelessButton.defaultProps = {
  icon: undefined,
};

export default StylelessButton;
