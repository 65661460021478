import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import Select from 'react-select';
import DatePicker from './DatePicker';
import VerticalTabs from '../VerticalTabs';


const options = [
  { value: '0', label: 'none' },
  { value: '1', label: 'daily' },
  { value: '2', label: 'weekly' },
  { value: '3', label: 'monthly' },
  { value: '4', label: 'yearly' },
];


class DateField extends Component {
  static propTypes = {
    value: PropTypes.instanceOf(Object).isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.getInitialTab(),
      init: true,
    };
  }

  componentDidUpdate() {
    const { init } = this.state;

    if (init) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        activeTab: this.getInitialTab(),
        init: false,
      });
    }
  }

  handleToggleActiveTab = (tab) => {
    const { activeTab } = this.state;
    const { onChange, value } = this.props;

    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });

      onChange({
        ...value,
        dateTimeEnd: tab !== 1 ? null : value.dateTimeEnd,
        recurrence: tab !== 2 ? { value: '0', label: 'none' } : value.recurrence,
      });
    }
  };

  handleOnChangeStart = (dateTimeStart) => {
    const { onChange, value } = this.props;

    onChange({
      ...value,
      dateTimeStart,
    });
  };

  handleOnChangeEnd = (dateTimeEnd) => {
    const { onChange, value } = this.props;

    onChange({
      ...value,
      dateTimeEnd,
    });
  };

  handleOnChangeRecurrence = (recurrence) => {
    const { onChange, value } = this.props;

    onChange({
      ...value,
      recurrence,
    });
  };

  getInitialTab = () => {
    const { value } = this.props;

    if (value.recurrence && value.recurrence.value !== '0') {
      return 2;
    }

    if (value.dateTimeStart && value.dateTimeEnd) {
      return 1;
    }

    return 0;
  };

  getDate = (type) => {
    const { value } = this.props;

    if (value[type]) {
      return new Date(value[type]);
    }

    return null;
  };

  onIncludeTimeChange = (type, includeTime) => {
    const { onChange, value } = this.props;

    onChange({
      ...value,
      [`include${type.slice(0, 1).toUpperCase()}${type.slice(1)}Time`]: includeTime,
    });
  };

  render() {
    // State
    const {
      activeTab,
    } = this.state;

    // Props
    const {
      className,
      value,
    } = this.props;

    return (
      <div className={className}>
        <VerticalTabs
          activeTab={activeTab}

          // Callbacks
          toggle={this.handleToggleActiveTab}
        >
          {/* Tab 1 */}
          <div data-name="Single date">
            <div>
              <p className="mb-1">Date</p>
              <DatePicker
                type="start"
                showTimeSelect={value.includeStartTime}
                selected={this.getDate('dateTimeStart')}

                // Callbacks
                onChange={this.handleOnChangeStart}
                onIncludeTimeChange={this.onIncludeTimeChange}
              />
            </div>
          </div>

          {/* Tab 2 */}
          <div data-name="Date range">
            <div>
              <p className="mb-1">Start date</p>
              <DatePicker
                type="start"
                showTimeSelect={value.includeStartTime}
                selected={this.getDate('dateTimeStart')}

                // Callbacks
                onChange={this.handleOnChangeStart}
                onIncludeTimeChange={this.onIncludeTimeChange}
              />
            </div>
            <div className="mt-3">
              <p className="mb-1">End date</p>
              <DatePicker
                type="end"
                showTimeSelect={value.includeEndTime}
                selected={this.getDate('dateTimeEnd')}

                // Callbacks
                onChange={this.handleOnChangeEnd}
                onIncludeTimeChange={this.onIncludeTimeChange}
              />
            </div>
          </div>

          {/* Tab 3 */}
          <div data-name="Recurring">
            <p className="mb-1">Date</p>
            <DatePicker
              type="start"
              showTimeSelect={value.includeStartTime}
              selected={this.getDate('dateTimeStart')}

              // Callbacks
              onChange={this.handleOnChangeStart}
              onIncludeTimeChange={this.onIncludeTimeChange}
            />
            <p className="mb-1">Frequency</p>
            <Select
              value={value.recurrence}
              onChange={this.handleOnChangeRecurrence}
              options={options}
            />
          </div>
        </VerticalTabs>
      </div>
    );
  }
}


const renderDateField = ({
  input, className,
}) => (
  <DateField {...input} className={className} />
);

renderDateField.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
};

renderDateField.defaultProps = {
  className: '',
};

export default renderDateField;
