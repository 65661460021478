import { create } from 'axios';
import { createAxiosInstance } from '@tmosystems/core';


const axios = createAxiosInstance(create, { baseURL: '/private/permissions/api/v1/' });

// GET
const getSubmissionGroup = params => axios.get('', { params });

// POST
const createSubmission = requestData => axios.post('', requestData);

// PUT
const updateSubmissionGroup = requestData => axios.put('', requestData);

export default {
  GET: {
    submissionGroup: getSubmissionGroup,
  },
  POST: {
    submission: createSubmission,
  },
  PUT: {
    submissionGroup: updateSubmissionGroup,
  },
};
