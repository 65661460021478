import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field, reduxForm } from 'redux-form';
import { wrapper } from 'ggtmo-utils';
import { Spring } from 'react-spring/renderprops';
import { toast } from 'react-toastify';

// Components
import {
  Row, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { faFilePlus } from '@fortawesome/pro-light-svg-icons';
import Modal from '../../../shared/components/Modal';
import AsyncButton from '../../../shared/components/AsyncButton';
import DropZoneMultipleField from '../../../shared/components/form/DropZoneMultipleFiles';
import {
  LoadingBarWrapper,
  LoadingBar,
  LoadingBarText,
  LoadingBarPercentage,
} from '../../../shared/ProgressBar';
import ModalHeader from '../../../shared/components/ModalHeader';
import ModalSidebar from '../../../shared/components/ModalSidebar';
import ModalContent from '../../../shared/components/ModalContent';
import ModalFooter from '../../../shared/components/ModalFooter';

// Redux
import { createDocument } from '../redux/actions';
import { DOCUMENTS_CREATE_DOCUMENT } from '../redux/actionCreators';

class UploadFileModal extends Component {
  static propTypes = {
    open: PropTypes.bool,

    // Documents
    documentsActions: PropTypes.instanceOf(Object).isRequired,
    document: PropTypes.instanceOf(Object).isRequired,

    // Form
    reset: PropTypes.func.isRequired,
    uploadFileForm: PropTypes.instanceOf(Object).isRequired,

    // Redux
    createDocument: PropTypes.func.isRequired,

    // Callbacks
    toggle: PropTypes.func.isRequired,
  };

  static defaultProps = {
    open: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      uploadProgress: 0,
    };
  }

  handleOnSubmit = (event) => {
    event.preventDefault();
    const {
      createDocument: create, uploadFileForm, toggle, document,
    } = this.props;
    const { files } = uploadFileForm.values;

    const formData = new FormData();
    for (let i = 0; i < files.length; i += 1) {
      formData.append(`file${i}`, files[i]);
    }
    formData.append('type', 'file');
    formData.append('parent', document.id || null);

    create(formData, {
      additionalConfig: {
        onUploadProgress: progressEvent => this.setState({
          uploadProgress: Math.round(progressEvent.loaded * 100 / progressEvent.total),
        }),
      },
    })
      .then((action) => {
        if (action.type === DOCUMENTS_CREATE_DOCUMENT.SUCCESS) {
          toast(`Successfully uploaded file${files.length === 1 ? '' : 's'}.`, {
            type: toast.TYPE.SUCCESS,
          });
          toggle();
        }
      });
  };

  handleOnClosed = () => {
    const { reset } = this.props;

    reset();
  };

  render() {
    // State
    const {
      uploadProgress,
    } = this.state;

    // Props
    const {
      open,

      // Documents
      documentsActions: {
        DOCUMENTS_CREATE_DOCUMENT: createDocumentAction,
      },

      // Form
      uploadFileForm,

      // Callbacks
      toggle,
    } = this.props;

    return (
      <Modal
        open={open}
        onClosed={this.handleOnClosed}
        backdrop={uploadFileForm && uploadFileForm.values && uploadFileForm.values.files.length ? 'static' : true}
        noPadding
        centered

        // Callbacks
        toggle={toggle}
        width="xxl"
      >
        <ModalHeader
          title="Upload files(s)"

          // Callbacks
          onClose={toggle}
        />
        <Row noGutters>
          <Col xs={2}>
            <ModalSidebar
              icon={faFilePlus}
              heading="Upload file(s)"
              body="By uploading files you can add them to sections on the public site where
                users can download them."
            />
          </Col>
          <Col>
            <ModalContent>
              <Form className="material-form mt-4 text-left">
                <div className="form__form-group">
                  <Field
                    name="files"
                    accept=""
                    component={DropZoneMultipleField}
                  />
                </div>
                {createDocumentAction.loading && (
                  <div className="w-100">
                    <Spring from={{ progress: 0 }} to={{ progress: uploadProgress }}>
                      {({ progress }) => (
                        <LoadingBarWrapper className="rounded">
                          <LoadingBar progress={progress} className="rounded">
                            <LoadingBarText>Uploading...</LoadingBarText>
                            {uploadProgress > 20 && (
                              <LoadingBarPercentage>
                                {Math.round(progress)}%
                              </LoadingBarPercentage>
                            )}
                          </LoadingBar>
                        </LoadingBarWrapper>
                      )}
                    </Spring>
                  </div>
                )}
              </Form>
              <ModalFooter>
                <ButtonToolbar className="justify-content-end">
                  <Button onClick={toggle} size="sm">{createDocumentAction.loading ? 'Close' : 'Cancel'}</Button>
                  <AsyncButton
                    color="primary"
                    size="sm"
                    disabled={createDocumentAction.loading}
                    onClick={this.handleOnSubmit}
                  >
                    Upload
                  </AsyncButton>
                </ButtonToolbar>
              </ModalFooter>
            </ModalContent>
          </Col>
        </Row>
      </Modal>
    );
  }
}


const mapDispatchToProps = {
  createDocument,
};


const mapStateToProps = (state) => {
  const {
    actions: documentsActions,
    document,
  } = state.documents;

  const {
    uploadFileForm,
  } = state.form;

  return {
    // Documents
    documentsActions,
    document,

    // Form
    uploadFileForm,
  };
};


const withForm = {
  form: 'uploadFileForm',
};

export default wrapper({
  component: UploadFileModal,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(withForm),
  ],
});
