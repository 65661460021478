/* eslint-disable max-len */
import { generateAction as generate, reduxTypes as types } from '@tmosystems/core';
import service from '../service';

// Action creators
import {
  // Async action creators
  EVENTS__CREATE_EVENT,
  EVENTS__CREATE_AND_PUBLISH_EVENT,
  EVENTS__GET_EVENT,
  EVENTS__UPDATE_EVENT,
  EVENTS__UPDATE_AND_PUBLISH_EVENT,
  EVENTS__PUBLISH_EVENT,
  EVENTS__UNPUBLISH_EVENT,
  EVENTS__DELETE_EVENT,
  EVENTS__CONVERT_EVENT_TO_STORY,

  // Sync action creators
  EVENTS__SELECT_EVENT,
  EVENTS__UPDATE_EVENT_TABLE_META,
  EVENTS__REINITIALIZE_EVENT_STATE,
} from './actionCreators';

// Async actions
export const eventsCreateEvent = generate(EVENTS__CREATE_EVENT, {
  type: types.ASYNC,
  service: service.POST.createEvent,
});
export const eventsCreateAndPublishEvent = generate(EVENTS__CREATE_AND_PUBLISH_EVENT, {
  type: types.ASYNC,
  service: service.POST.createAndPublishEvent,
});
export const eventsGetEvent = generate(EVENTS__GET_EVENT, {
  type: types.ASYNC,
  service: service.GET.getEvent,
});
export const eventsUpdateEvent = generate(EVENTS__UPDATE_EVENT, {
  type: types.ASYNC,
  service: service.PUT.updateEvent,
});
export const eventsUpdateAndPublishEvent = generate(EVENTS__UPDATE_AND_PUBLISH_EVENT, {
  type: types.ASYNC,
  service: service.PUT.updateAndPublishEvent,
});
export const eventsPublishEvent = generate(EVENTS__PUBLISH_EVENT, {
  type: types.ASYNC,
  service: service.PUT.publishEvent,
});
export const eventsUnpublishEvent = generate(EVENTS__UNPUBLISH_EVENT, {
  type: types.ASYNC,
  service: service.PUT.unpublishEvent,
});
export const eventsDeleteEvent = generate(EVENTS__DELETE_EVENT, {
  type: types.ASYNC,
  service: service.DELETE.deleteEvent,
});
export const eventsConvertEventToStory = generate(EVENTS__CONVERT_EVENT_TO_STORY, {
  type: types.ASYNC,
  service: service.POST.convertEventToStory,
});
// Sync actions
export const eventsSelectEvent = generate(EVENTS__SELECT_EVENT, { type: types.SYNC });
export const eventsUpdateEventTableMeta = generate(EVENTS__UPDATE_EVENT_TABLE_META, { type: types.SYNC });
export const eventsReinitializeEventState = generate(EVENTS__REINITIALIZE_EVENT_STATE, { type: types.SYNC });
