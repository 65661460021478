import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { squashString } from 'ggtmo-utils';

// Components
import TableCell from '@material-ui/core/TableCell';
import MatTable from '../../../../shared/components/table/MatTable';

const tableColumns = [
  {
    id: 'body', label: 'Body', disablePadding: false, numeric: false, align: 'left',
  },
  {
    id: 'type', label: 'Type', disablePadding: false, numeric: false, align: 'left',
  },
  {
    id: 'dateTimeSent', label: 'Date Sent', disablePadding: false, numeric: false, align: 'left',
  },
];

const TableRow = ({
  body,
  type,
  dateTimeSent,
}) => (
  <>
    <TableCell className="material-table__cell">
      {squashString(body)}
    </TableCell>
    <TableCell className="material-table__cell">{type}</TableCell>
    <TableCell className="material-table__cell">{moment(dateTimeSent).format('DD/MM/YYYY')}</TableCell>
  </>
);

TableRow.propTypes = {
  body: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  dateTimeSent: PropTypes.string.isRequired,
};

const TextMessagesTable = ({
  children,
  instances,
  selected,
  page,
  count,

  // Callbacks
  onSelect,
  onSelectByRow,
  onPageChange,
}) => (
  <div>
    <MatTable
      rowComponent={TableRow}
      columns={tableColumns}
      data={instances}
      selected={selected || []}
      selectOnlyByCheckbox
      page={page}
      count={count}
      rowsPerPage={10}
      rowsPerPageOptions={['10', '25', '50']}
      emptyRows={false}
      keepHeadOnEmpty
      canSelect={false}

      // Callbacks
      onSelect={onSelect}
      onSelectByRow={onSelectByRow}
      onPageChange={onPageChange}
    >
      {children}
    </MatTable>
  </div>
);

TextMessagesTable.propTypes = {
  children: PropTypes.node,
  instances: PropTypes.instanceOf(Array).isRequired,
  selected: PropTypes.instanceOf(Array).isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,

  // Callbacks
  onSelect: PropTypes.func.isRequired,
  onSelectByRow: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

TextMessagesTable.defaultProps = {
  children: null,
};

export default TextMessagesTable;
