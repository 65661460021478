import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { wrapper } from '@tmosystems/core';

// Components
import { Form, ButtonToolbar } from 'reactstrap';
import FormComponent from '../../../shared/components/form/FormComponent';
import Input from '../../../shared/components/form/Input/ReduxFormInput';
import AsyncButton from '../../../shared/components/AsyncButton';
import ImageField from '../../images/components/ImageField';

class ReceivedMessageLabelForm extends FormComponent {
  static propTypes = {
    action: PropTypes.instanceOf(Object).isRequired,

    // Form
    createCategoryForm: PropTypes.instanceOf(Object).isRequired,

    // Callbacks
    onSubmit: PropTypes.func.isRequired,
  };

  syncValidate = onSubmit => (event) => {
    event.preventDefault();
    const { createCategoryForm } = this.props;
    const { values } = createCategoryForm;
    this.setState({
      errors: {
        sync: {},
        async: {},
      },
    });
    let hasSyncErrors = false;
    if (!values.name) {
      hasSyncErrors = this.setError('sync', {
        name: 'please provide a category name',
      });
    }
    if (!hasSyncErrors) {
      onSubmit(values).then(this.asyncValidate);
    }
  };

  render() {
    // State
    const {
      errors,
    } = this.state;

    // Props
    const {
      action,

      // Callbacks
      onSubmit,
    } = this.props;

    return (
      <Form onSubmit={this.syncValidate(onSubmit)} className="form text-left">
        {errors.async.serverError && (
          <div>
            <p className="text-danger">
              {errors.async.serverError}
            </p>
          </div>
        )}
        <div className="form__form-group">
          <Field
            name="name"
            className="border rounded"
            component={Input}
            errors={errors}
          />
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Icon</span>
          <Field
            uploadImageClassName="w-100 m-0"
            imageClassName="m-0"
            name="icon"
            component={ImageField}
            errors={errors}
          />
        </div>
        <ButtonToolbar className="w-100">
          <AsyncButton action={action} outline size="sm" color="primary" className="w-100">
            Create
          </AsyncButton>
        </ButtonToolbar>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    createCategoryForm,
  } = state.form;

  return {
    // Form
    createCategoryForm,
  };
};

export default wrapper({
  component: ReceivedMessageLabelForm,
  wrappers: [
    connect(mapStateToProps),
    reduxForm({ form: 'createCategoryForm' }),
  ],
});
