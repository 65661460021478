import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Form, Field } from 'redux-form';
import { wrapper } from 'ggtmo-utils';

// Components
import SelectField from '../../../../shared/components/form/Select';
import RecipientsField from './RecipientsField';
import FroalaEditor from '../../../../shared/components/form/FroalaEditor/ReduxFormFroalaEditor';

const renderTextMessageFormSection = () => (
  <div className="form__form-group">
    <span className="form__form-group-label">Body</span>
    <Field
      name="body"
      component="textarea"
      className="w-100 border rounded p-3"
      rows={20}
    />
  </div>
);

const renderEmailMessageFormSection = () => (
  <>
    <Field
      name="body"
      component={FroalaEditor}
    />
  </>
);

const MessageEditorForm = ({
  // Form
  messageEditorForm,
}) => (
  <Form>
    <div className="form__form-group d-flex">
      <span className="form__form-group-label" style={{ lineHeight: '34px', width: 50 }}>Type</span>
      <Field
        name="type"
        component={SelectField}
        options={[
          { label: 'Text Message', value: 'textMessage' },
          { label: 'Email', value: 'emailMessage' },
        ]}
      />
    </div>
    <div className="form__form-group d-flex">
      <span className="form__form-group-label" style={{ lineHeight: '34px', width: 50 }}>To</span>
      <Field
        name="recipients"
        component={RecipientsField}
      />
    </div><hr />
    {messageEditorForm.values
    && messageEditorForm.values.type.value === 'textMessage' && renderTextMessageFormSection()}
    {messageEditorForm.values
    && messageEditorForm.values.type.value === 'emailMessage' && renderEmailMessageFormSection()}
  </Form>
);

MessageEditorForm.propTypes = {
  // Form
  messageEditorForm: PropTypes.instanceOf(Object),
};

MessageEditorForm.defaultProps = {
  // Form
  messageEditorForm: {
    values: {
      type: { value: 'textMessage' },
    },
  },
};

const withForm = {
  form: 'messageEditorForm',
};

const mapStateToProps = (state) => {
  const {
    messageEditorForm,
  } = state.form;

  return {
    // Form
    messageEditorForm,
  };
};

export default wrapper({
  component: MessageEditorForm,
  wrappers: [
    reduxForm(withForm),
    connect(mapStateToProps),
  ],
});
