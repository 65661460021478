import chroma from 'chroma-js';


// eslint-disable-next-line import/prefer-default-export
export const color = {
  // Base
  black: '#000',
  blackLighter: chroma([0, 0, 0]).brighten(),
  blackLightest: chroma([0, 0, 0]).brighten(5),

  white: '#fff',
  whiteDark: chroma([255, 255, 255]).darken(0.25),
  whiteDarker: chroma([255, 255, 255]).darken(0.5),

  // Theme
  primary: '#209399',
  primaryWhitened: chroma([32, 147, 153]).brighten(3.7),
  primaryLightest: chroma([32, 147, 153]).brighten(0.5),
  primaryLighter: chroma([32, 147, 153]).brighten(0.25),
  primaryDarker: chroma([32, 147, 153]).darken(),
  primaryDarkest: chroma([32, 147, 153]).darken(1.5),

  // Variable
  variable: {
    black: ({ brighten } = {}) => (brighten ? chroma([0, 0, 0]).brighten(brighten) : '#000'),
  },
};
