import React from 'react';
import PropTypes from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import StylelessButton from './StylelessButton';

const StylelessCreateButton = ({
  children,
  className,
  ...rest
}) => (
  <StylelessButton className={className} {...rest}>
    <FontAwesomeIcon icon={faPlus} fixedWidth />{children}
  </StylelessButton>
);

StylelessCreateButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

StylelessCreateButton.defaultProps = {
  className: 'text-primary',
};

export default StylelessCreateButton;
