import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { wrapper } from 'ggtmo-utils';

// Components
import AsyncComponent from '../../../shared/components/AsyncComponent';
import RequestsTable from './RequestsTable';

// Redux
import { repairsGetRequest } from '../redux/actions';

// Images
import repairsImage from '../../../shared/img/repairs.png';

class RequestsManager extends PureComponent {
  static propTypes = {
    // Redux
    requestsActions: PropTypes.instanceOf(Object).isRequired,
    requests: PropTypes.instanceOf(Array).isRequired,
    getRequest: PropTypes.func.isRequired,

    // Router
    history: RouterPropTypes.history.isRequired,
  };

  componentDidMount() {
    const { getRequest: get } = this.props;
    get({ list: true });
  }

  handleOnSelectByRow = (event, id) => {
    const { history } = this.props;
    history.push(`/repairs/requests/${id}`);
  };

  render() {
    // Props
    const {
      requestsActions: {
        REPAIRS__GET_REQUEST: getRequestAction,
      },
      requests: {
        instances,
        selected,
      },
    } = this.props;

    return (
      <AsyncComponent action={getRequestAction}>
        <RequestsTable
          requests={instances}
          selected={selected}

          // Callbacks
          onSelectByRow={this.handleOnSelectByRow}
        >
          <div className="editor__no-instances mt-5">
            <h4>There are no open repair requests. Change the filter to{' '}
              <span className="font-weight-bold">closed</span> to see closed requests.
            </h4>
            <img src={repairsImage} alt="" />
          </div>
        </RequestsTable>
      </AsyncComponent>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    actions: requestsActions,
    requests,
  } = state.repairs;

  return {
    // Repairs
    requestsActions,
    requests,
  };
};

const mapDispatchToProps = {
  getRequest: repairsGetRequest,
};

export default wrapper({
  component: RequestsManager,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
  ],
});
