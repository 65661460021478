import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { wrapper } from 'ggtmo-utils';
import moment from 'moment';
import styled from 'styled-components';
import { toast } from 'react-toastify';

// Components
import {
  Container, Row, Col, Card, CardBody,
} from 'reactstrap';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import AsyncComponent from '../../../shared/components/AsyncComponent';

// Redux
import { repairsGetRequest } from '../redux/actions';

const Category = styled.div`
  display: flex;
  justify-content: space-between;
  
  h3 {
    line-height: 36px;
  }
`;

const CategoryImage = styled.img`
  width: 36px;
  height: 36px;
`;

const CopyMessage = styled.span`
  visibility: hidden;
`;

const CopyBox = styled.div`
  font-size: 1rem;
  padding: 0.5rem;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  margin-bottom: 16px;
  
  &:hover {
    border: 2px solid ${({ theme }) => theme.color.primary};
    cursor: copy;
    background-color: rgb(250, 250, 250);
  }
  
  &:hover ${CopyMessage} {
    visibility: visible;
  }
`;

class RequestViewer extends PureComponent {
  static propTypes = {
    // Redux
    getRequest: PropTypes.func.isRequired,

    // Router
    match: RouterPropTypes.match.isRequired,

    // Repairs
    repairsActions: PropTypes.instanceOf(Object).isRequired,
    requests: PropTypes.instanceOf(Object).isRequired,
  };

  componentDidMount() {
    const { getRequest, match } = this.props;
    getRequest({
      list: false,
      id: match.params.id,
    });
  }

  copyToClipboardFallback = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
      toast('Copied to clipboard.');
    } catch (error) {
      toast('Error copying to clipboard. Please try again.');
    }
  };

  handleOnCopyToClipboard = (event) => {
    const { text } = event.currentTarget.dataset;
    if (!navigator.clipboard) {
      this.copyToClipboardFallback(text);
      return;
    }
    navigator.clipboard.writeText(text)
      .then(
        () => {
          toast('Copied to clipboard.');
        },
        () => {
          toast('Error copying to clipboard. Please try again.');
        },
      );
  };

  renderCopyBox = (name, value) => (
    <CopyBox onClick={this.handleOnCopyToClipboard} data-text={value}>
      <span className="text-black-50">{name}:</span> {value}
      <CopyMessage className="float-right text-black-50">
        <FontAwesomeIcon icon={faCopy} className="mr-1" />
        Click to Copy
      </CopyMessage>
    </CopyBox>
  );

  render() {
    // Props
    const {
      repairsActions: {
        REPAIRS__GET_REQUEST: getRequestAction,
      },
      requests: {
        instance,
      },
    } = this.props;

    return (
      <Container>
        <AsyncComponent action={getRequestAction}>
          <h3 className="page-title">Managing request: ID{instance.id}</h3>
          <p className="page-subhead">Manage this request and converse with the resident.</p>
          <Row>
            <Col xl={8}>
              <Card className="h-100">
                <CardBody>
                  {instance.repairType ? (
                    <>
                      <Category>
                        <div className="d-flex">
                          <CategoryImage
                            src={instance.repairType.category.imageSet.images[0].url}
                            alt={instance.repairType.category.imageSet.images[0].name}
                          />
                          <h3 className="ml-2">{instance.repairType.category.name} - {instance.repairType.name}</h3>
                        </div>
                        <h4>
                          <span className="text-black-50">Submitted:</span>
                          {' '}{moment(instance.dateTimeSubmitted).calendar()}
                        </h4>
                      </Category>
                      <br />
                      <FroalaEditorView model={instance.repairType.description} />
                      <hr />
                      <Row>
                        <Col>
                          <h3 className="mb-2">Personal details</h3>
                          {this.renderCopyBox('Name', instance.resident.user.name)}
                          {this.renderCopyBox('Email', instance.resident.user.email)}
                          {this.renderCopyBox('Address', instance.resident.address)}
                          {this.renderCopyBox('Rent Account Number', instance.resident.rentAccountNumber)}
                          {this.renderCopyBox('Mobile Number', instance.resident.mobileNumber)}
                          {this.renderCopyBox('Home Phone Number', instance.resident.homePhoneNumber)}
                        </Col>
                        <Col>
                          <h3 className="mb-2">Repair details</h3>
                          {this.renderCopyBox('Category', instance.repairType.category.name)}
                          {this.renderCopyBox('Type', instance.repairType.name)}
                          {this.renderCopyBox('Time', moment(instance.dateTimeSubmitted).calendar())}
                        </Col>
                      </Row>
                    </>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </AsyncComponent>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    actions: repairsActions,
    requests,
  } = state.repairs;

  return {
    // Repairs
    repairsActions,
    requests,
  };
};

const mapDispatchToProps = {
  getRequest: repairsGetRequest,
};

export default wrapper({
  component: RequestViewer,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
  ],
});
