import styled from 'styled-components';

export const SearchBarWrapper = styled.div`
  position: relative;
 
  svg {
    position: absolute;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
    height: 43px;
    left: 24px;
    z-index: 1;
  }
`;

export const SearchBar = styled.input`
  width: 100%;
  height: 43px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  outline: none;
  font-size: 24px;
  padding: 0 64px;
  border-radius: 8px;
  border-bottom-left-radius: ${({ hasSuggestions }) => (hasSuggestions ? 0 : '8px')};
  border-bottom-right-radius: ${({ hasSuggestions }) => (hasSuggestions ? 0 : '8px')};
`;

export const SearchSuggestions = styled.div`
  position: absolute;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  font-size: 24px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const SearchSuggestion = styled.div`
  height: 43px;
  line-height: 43px;
  padding: 0 64px;
  border-bottom-left-radius: ${({ lastSuggestion }) => (lastSuggestion ? '8px' : 0)};
  border-bottom-right-radius: ${({ lastSuggestion }) => (lastSuggestion ? '8px' : 0)};
  background-color: ${({ selected }) => (selected ? '#fbfbfb' : 'white')};
  
  &:hover {
    cursor: pointer;
  }
`;
