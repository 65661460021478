import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { wrapper } from 'ggtmo-utils';
import styled from 'styled-components';

// Components
import LoginForm from './components/LoginForm';
import ModalHeader from '../../../shared/components/ModalHeader';
import ModalContent from '../../../shared/components/ModalContent';

// Redux
import { login } from '../redux/actions';
import { ACCOUNT_LOGIN } from '../redux/actionCreators';

// Utils
import getTimeOfDay from '../../../shared/utils/getTimeOfDay';

const PageWrapper = styled.div`
  position: relative;
  padding: 1rem;
  height: 100vh;
  background-color: #f2f4f7;
`;

const StaticModal = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  width: 40%;
  margin: auto;
  border-radius: 1rem;
`;

class Login extends PureComponent {
  static propTypes = {
    // Redux
    dispatch: PropTypes.func.isRequired,
    accountActions: PropTypes.instanceOf(Object).isRequired,

    // Router
    history: ReactRouterPropTypes.history.isRequired,
  };

  handleOnSubmit = (values) => {
    const { dispatch, history } = this.props;
    return dispatch(login({
      ...values,
      userType: 'staff',
    }))
      .then((action) => {
        if (action.type === ACCOUNT_LOGIN.SUCCESS) {
          sessionStorage.setItem('token', action.response.token);
          localStorage.setItem('token', action.response.token);
          localStorage.setItem('rememberMe', values.rememberMe);
          localStorage.setItem('user', JSON.stringify(action.response.user));
          if (!values.rememberMe) {
            window.onunload = () => {
              localStorage.removeItem('token');
            };
          }
          history.push('/');
        }
        return action;
      });
  };

  render() {
    // Props
    const {
      accountActions: {
        ACCOUNT_LOGIN: loginAction,
      },
    } = this.props;

    return (
      <PageWrapper>
        <StaticModal className="shadow">
          <ModalHeader
            title={`Good ${getTimeOfDay()}, please login`}
            isStatic
          />
          <ModalContent style={{ borderBottomLeftRadius: '1rem' }}>
            <LoginForm
              action={loginAction}

              // Callbacks
              onSubmit={this.handleOnSubmit}
            />
          </ModalContent>
        </StaticModal>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    actions: accountActions,
  } = state.account;

  return {
    // Account
    accountActions,
  };
};

export default wrapper({
  component: Login,
  wrappers: [
    connect(mapStateToProps),
  ],
});
