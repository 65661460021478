/* eslint-disable react/no-unused-state */
import { Component } from 'react';
import PropTypes from 'prop-types';

class FormComponent extends Component {
  static propTypes = {
    initialValues: PropTypes.instanceOf(Object),
  };

  static defaultProps = {
    initialValues: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      focused: '',
      values: props.initialValues,
      errors: {
        sync: {},
        async: {},
      },
    };
  }

  onFocus = (event) => {
    this.setState({
      focused: event.currentTarget.name,
    });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      values: {
        ...prevState.values,
        [name]: value,
      },
    }));
  };

  revertChanges = (event) => {
    event.preventDefault();
    const { initialValues } = this.props;
    this.setState({
      values: initialValues,
      errors: {
        sync: {},
        async: {},
      },
    });
  };

  setError = (errorType, errorObject) => {
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [errorType]: {
          ...prevState.errors[errorType],
          ...errorObject,
        },
      },
    }));
    return true;
  };

  syncValidate = () => (event) => {
    event.preventDefault();
    throw Error('You must implement this method.');
  };

  asyncValidate = (action) => {
    if (action.error && action.error.response) {
      if (action.error.response.status === 500) {
        this.setError('async', {
          serverError: 'There was an error with the server. Please try again later or contact us.',
        });
      }
      if (action.error.response.status === 400) {
        this.setError('async', {
          ...action.error.response.data,
        });
      }
    }
  };
}

export default FormComponent;
