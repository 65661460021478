import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { wrapper } from 'ggtmo-utils';
import { toast } from 'react-toastify';

// Components
import {
  Row, Col, ButtonToolbar, Button,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faArrowRight, faUsers } from '@fortawesome/pro-light-svg-icons';
import AsyncButton from '../../../../shared/components/AsyncButton';
import ModalHeader from '../../../../shared/components/ModalHeader';
import ModalSidebar from '../../../../shared/components/ModalSidebar';
import ModalContent from '../../../../shared/components/ModalContent';
import ModalFooter from '../../../../shared/components/ModalFooter';
import UpdateContactGroupForm from './UpdateContactGroupForm';

// Classes
import BaseModal from '../../../../shared/BaseModal';

// Actions
import {
  communicationsCreateContactGroup,
  communicationsToggleContactGroupModal,
  communicationsUpdateContactGroup,
} from '../../redux/actions';
import {
  COMMUNICATIONS_CREATE_CONTACT_GROUP,
  COMMUNICATIONS_UPDATE_CONTACT_GROUP,
} from '../../redux/actionCreators';

class ContactGroupModal extends BaseModal {
  static propTypes = {
    modalMeta: PropTypes.instanceOf(Object).isRequired,

    // Form
    contactGroupForm: PropTypes.instanceOf(Object).isRequired,
    destroy: PropTypes.func.isRequired,

    // Communications
    communicationsCreateContactGroup: PropTypes.func.isRequired,
    communicationsActions: PropTypes.instanceOf(Object).isRequired,
    contactGroups: PropTypes.instanceOf(Object).isRequired,

    // Redux
    communicationsUpdateContactGroup: PropTypes.func.isRequired,
  };

  properties = {
    centered: true,
    noPadding: true,
    width: 'xxl',
    contentClassName: 'rounded',
  };

  handleOnCreate = (event) => {
    event.preventDefault();
    const { communicationsCreateContactGroup: createContactGroup, contactGroupForm } = this.props;
    createContactGroup({
      name: contactGroupForm.values.name,
      contacts: contactGroupForm.values.contacts.map(contact => contact.id),
    })
      .then((action) => {
        if (action.type === COMMUNICATIONS_CREATE_CONTACT_GROUP.SUCCESS) {
          this.handleToggle();
          toast('Successfully created contact group.', { type: toast.TYPE.SUCCESS });
        }
      });
  };

  handleOnUpdate = (event) => {
    event.preventDefault();
    const { communicationsUpdateContactGroup: updateContactGroup, contactGroupForm, contactGroups } = this.props;
    updateContactGroup({
      ...contactGroups.instance,
      name: contactGroupForm.values.name,
      contacts: contactGroupForm.values.contacts.map(contact => contact.id),
    })
      .then((action) => {
        if (action.type === COMMUNICATIONS_UPDATE_CONTACT_GROUP.SUCCESS) {
          this.handleToggle();
          toast('Successfully updated contact group.', { type: toast.TYPE.SUCCESS });
        }
      });
  };

  handleOnClosed = () => {
    const { destroy } = this.props;
    destroy();
  };

  renderCreateModal = () => {
    // Props
    const {
      // Communications
      communicationsActions: {
        COMMUNICATIONS_CREATE_CONTACT_GROUP: createContactGroupAction,
      },
    } = this.props;

    return (
      <div>
        <ModalHeader
          title="Create contact group"

          // Callbacks
          onClose={this.handleToggle}
        />
        <Row noGutters>
          <Col xs={2}>
            <ModalSidebar
              icon={faUsers}
              heading="Create contact group"
              body="Search for existing contacts or create new ones and add them to the group."
            />
          </Col>
          <Col>
            <ModalContent>
              <UpdateContactGroupForm />
              <ModalFooter>
                <ButtonToolbar className="justify-content-end mr-3">
                  <Button size="sm" outline>Cancel</Button>
                  <AsyncButton
                    size="sm"
                    outline
                    onClick={this.handleOnCreate}
                    color="primary"
                    action={createContactGroupAction}
                  >
                    Create
                  </AsyncButton>
                </ButtonToolbar>
              </ModalFooter>
            </ModalContent>
          </Col>
        </Row>
      </div>
    );
  };

  renderUpdateModal = () => {
    // Props
    const {
      // Communications
      communicationsActions: {
        COMMUNICATIONS_UPDATE_CONTACT_GROUP: updateContactGroupAction,
      },
      contactGroups,
    } = this.props;

    return (
      <div>
        <ModalHeader
          title="Edit contact group"

          // Callbacks
          onClose={this.handleToggle}
        />
        <Row noGutters>
          <Col xs={2}>
            <ModalSidebar className="rounded-left">
              <div className="p-4 text-center">
                <div className="mb-4">
                  <FontAwesomeIcon icon={faUser} size="3x" />
                  <FontAwesomeIcon icon={faArrowRight} size="2x" className="mx-3" />
                  <FontAwesomeIcon icon={faUsers} size="3x" />
                </div>
                <h3>Add contacts</h3>
                <p>
                  Search for existing contacts or create new ones and add them to the group.
                </p>
              </div>
            </ModalSidebar>
          </Col>
          <Col>
            <ModalContent>
              <UpdateContactGroupForm
                initialValues={contactGroups.instance}
                enableReinitialize
              />
              <ModalFooter>
                <ButtonToolbar className="justify-content-end mr-3">
                  <Button size="sm" outline>Cancel</Button>
                  <AsyncButton
                    size="sm"
                    outline
                    onClick={this.handleOnUpdate}
                    color="primary"
                    action={updateContactGroupAction}
                  >
                    Save
                  </AsyncButton>
                </ButtonToolbar>
              </ModalFooter>
            </ModalContent>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    contactGroupForm,
  } = state.form;

  const {
    actions: communicationsActions,
    contactGroups,
  } = state.communications;

  return {
    // Form
    contactGroupForm,

    // Communications
    communicationsActions,
    contactGroups,
    modalMeta: contactGroups.modalMeta,
  };
};

const mapDispatchToProps = {
  toggle: communicationsToggleContactGroupModal,
  communicationsCreateContactGroup,
  communicationsUpdateContactGroup,
};

const withForm = {
  form: 'contactGroupForm',
};

export default wrapper({
  component: ContactGroupModal,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(withForm),
  ],
});
