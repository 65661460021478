import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';

// Components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from 'reactstrap';
import AsyncComponent from '../../../shared/components/AsyncComponent';
import ResidentsTable from './components/ResidentsTable';
import ResidentAccountRequestsTable from './components/ResidentAccountRequestsTable';

// Redux
import { RESIDENTS_GET_RESIDENT, RESIDENTS_GET_ACCOUNT_REQUEST } from './redux/actionCreators';
import { getResident, getResidentAccountRequest } from './redux/actions';

// Images
import residentAccountRequest from '../../../shared/img/residentAccountRequest.png';

class Residents extends PureComponent {
  static propTypes = {
    // Router
    history: RouterPropTypes.history.isRequired,

    // Residents
    residentsActions: PropTypes.instanceOf(Object).isRequired,
    residents: PropTypes.instanceOf(Array).isRequired,
    selectedResidents: PropTypes.instanceOf(Array).isRequired,
    residentAccountRequests: PropTypes.instanceOf(Array).isRequired,
    selectedResidentAccountRequests: PropTypes.instanceOf(Array).isRequired,

    // Actions
    getResident: PropTypes.func.isRequired,
    getResidentAccountRequest: PropTypes.func.isRequired,
  };

  componentDidMount() {
    // Get the list of approved residents.
    const {
      getResident: getResidentAction,
      getResidentAccountRequest: getResidentAccountRequestAction,
    } = this.props;
    getResidentAction({ list: true });
    getResidentAccountRequestAction({ list: true });
  }

  handleOnView = (event, id) => {
    const { history } = this.props;
    history.push(`/residents/requests/${id}`);
  };

  render() {
    // Props
    const {
      // Residents
      residentsActions: {
        [RESIDENTS_GET_RESIDENT.NAME]: getResidentAction,
        [RESIDENTS_GET_ACCOUNT_REQUEST.NAME]: getResidentAccountRequestAction,
      },
      residents,
      selectedResidents,
      residentAccountRequests,
      selectedResidentAccountRequests,
    } = this.props;

    return (
      <Container>
        <Row>
          <Col>
            <h3 className="page-title">Resident Accounts</h3>
            <p className="page-subhead">View and manage resident accounts.</p>
            <Card style={{ minHeight: 680 }}>
              <CardBody>
                <AsyncComponent action={getResidentAction}>
                  <ResidentsTable
                    residents={residents}
                    selected={selectedResidents}
                    // count={}
                    // page={}

                    // Callbacks
                    onSelectByRow={this.handleOnView}
                    // onPageChange={}
                  />
                </AsyncComponent>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <h3 className="page-title">Resident Account Requests</h3>
            <p className="page-subhead">Approve or reject resident account requests.</p>
            <Card style={{ minHeight: 680 }}>
              <CardBody>
                <AsyncComponent action={getResidentAccountRequestAction}>
                  <ResidentAccountRequestsTable
                    residentAccountRequests={residentAccountRequests}
                    selected={selectedResidentAccountRequests}

                    // Callbacks
                    onSelectByRow={this.handleOnView}
                  >
                    <div className="editor__no-instances mt-5">
                      <h4 className="mb-5">There are no open resident account requests.
                      </h4>
                      <img src={residentAccountRequest} alt="" />
                    </div>
                  </ResidentAccountRequestsTable>
                </AsyncComponent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    actions: residentsActions,
    residents,
    selectedResidents,
    residentAccountRequests,
    selectedResidentAccountRequests,
  } = state.residents;

  return {
    // Residents
    residentsActions,
    residents,
    selectedResidents,
    residentAccountRequests,
    selectedResidentAccountRequests,
  };
};

const mapDispatchToProps = {
  getResident,
  getResidentAccountRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Residents);
