import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationSquare } from '@fortawesome/pro-light-svg-icons/faExclamationSquare';

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.05);
`;

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: '',
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      errorMessage: error.message,
    };
  }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, errorInfo) {
    // TODO: Implement Sentry for error logging.
  }

  render() {
    // State
    const {
      hasError,
      errorMessage,
    } = this.state;

    // Props
    const {
      children,
    } = this.props;

    if (hasError) {
      return (
        <Wrapper className="p-5 rounded">
          <h3>
            <FontAwesomeIcon icon={faExclamationSquare} fixedWidth /> Oops! An has error occurred with this element.
          </h3>
          <p className="lead">
            Refreshing the page may resolve this error, if not the support team have been automatically notified and are
            working to resolve it as quickly as possible. If you wish to contact the support team about this error,
            please make sure to provide the following error code:
          </p>
          <br />
          <pre>
            Error code: {errorMessage}
          </pre>
        </Wrapper>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
