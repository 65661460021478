import React from 'react';
import PropTypes from 'prop-types';

// Components
import { ButtonToolbar, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-light-svg-icons';


const Toolbar = ({
  selected,

  // Callbacks
  onView,
}) => (
  <div className="mb-4">
    <ButtonToolbar>
      <Button
        size="sm"
        className="btn btn-secondary rounded"
        disabled={selected.length !== 1}
        onClick={onView}
      >
        <FontAwesomeIcon icon={faEye} fixedWidth /> View
      </Button>
    </ButtonToolbar>
  </div>
);

Toolbar.propTypes = {
  selected: PropTypes.instanceOf(Array).isRequired,

  // Callbacks
  onView: PropTypes.func.isRequired,
};

export default Toolbar;
