import { create } from 'axios';
import { createAxiosInstance } from '@tmosystems/core';

const axios = createAxiosInstance(create, { baseURL: '/private/sections/api/v1' });

// POST
const createSection = requestData => axios.post('/', requestData);
const createNotice = requestData => axios.post('/notice/', requestData);
const createAndPublishSection = requestData => axios.post('/', { createAndPublish: true, ...requestData });
const createAndPublishNotice = requestData => axios.post('/notice/', { createAndPublish: true, ...requestData });

// GET
const getSection = requestParams => axios.get('/', { params: requestParams });
const getPage = requestParams => axios.get('/page/', { params: requestParams });
const getNotice = requestParams => axios.get('/notice/', { params: requestParams });

// PUT
const updateSection = requestData => axios.put('/', requestData);
const updatePage = requestData => axios.put('/page/', requestData);
const updateNotice = requestData => axios.put('/notice/', requestData);
const updateAndPublishSection = requestData => axios.put('/', { updateAndPublish: true, ...requestData });
const updateAndPublishPage = requestData => axios.put('/page/', { updateAndPublish: true, ...requestData });
const updateAndPublishNotice = requestData => axios.put('/notice/', { updateAndPublish: true, ...requestData });
const publishSection = requestData => axios.put('/', { publish: true, ...requestData });
const publishNotice = requestData => axios.put('/notice/', { publish: true, ...requestData });
const unpublishSection = requestData => axios.put('/', { unpublish: true, ...requestData });
const unpublishNotice = requestData => axios.put('/notice/', { unpublish: true, ...requestData });

// DELETE
const deleteSection = requestData => axios.delete('/', { data: requestData });
const deleteNotice = requestData => axios.delete('/notice/', { data: requestData });

export default {
  POST: {
    createSection,
    createNotice,
    createAndPublishSection,
    createAndPublishNotice,
  },
  GET: {
    getSection,
    getPage,
    getNotice,
  },
  PUT: {
    updateSection,
    updatePage,
    updateNotice,
    updateAndPublishSection,
    updateAndPublishPage,
    updateAndPublishNotice,
    publishSection,
    publishNotice,
    unpublishSection,
    unpublishNotice,
  },
  DELETE: {
    deleteSection,
    deleteNotice,
  },
};
