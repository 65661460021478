import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

// Components
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faPlus, faTag } from '@fortawesome/pro-light-svg-icons';
import ReceivedMessageLabelModal from './ReceivedMessageLabelModal';
import StylelessButton from '../../../../shared/components/StylelessButton';

// Redux
import {
  communicationsGetReceivedMessageLabel,
  communicationsToggleReceivedMessageLabelModal,
  communicationsSelectReceivedMessageLabel,
  communicationsGetReceivedMessage,
} from '../../redux/actions';
import { COMMUNICATIONS_GET_RECEIVED_MESSAGE } from '../../redux/actionCreators';

const ReceivedMessageLabelListStyled = styled.div`
  padding-bottom: 10px;
`;

const ReceivedMessageLabelListItemStyled = styled.div`
  padding: 5px 0;
  transition: 0.15s ease-in-out;
  background-color: ${({ theme, selected }) => (selected ? theme.color.primary : 'transparent')};
  padding-left: ${({ selected }) => (selected ? '10px' : '0')};
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  
  h4 {
    color: ${({ selected }) => (selected ? 'white' : '')};
  }
  
  &:hover {
    background-color: ${({ theme }) => theme.color.primaryLighter};
    cursor: pointer;
    padding-left: 10px;
    
    h4 {
      color: white;
    }
  }
`;

class ReceivedMessageLabelList extends PureComponent {
  static propTypes = {
    // Communications
    receivedMessageLabels: PropTypes.instanceOf(Object).isRequired,

    // Redux
    communicationsGetReceivedMessageLabel: PropTypes.func.isRequired,
    communicationsToggleReceivedMessageLabelModal: PropTypes.func.isRequired,
    communicationsSelectReceivedMessageLabel: PropTypes.func.isRequired,
    communicationsGetReceivedMessage: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { communicationsGetReceivedMessageLabel: getReceivedMessageLabel } = this.props;
    getReceivedMessageLabel({ list: true });
  }

  handleGetAllReceivedMessages = () => {
    const {
      communicationsGetReceivedMessage: getReceivedMessage,
      communicationsSelectReceivedMessageLabel: selectReceivedMessageLabel,
    } = this.props;
    getReceivedMessage({ list: true })
      .then((action) => {
        if (action.type === COMMUNICATIONS_GET_RECEIVED_MESSAGE.SUCCESS) {
          selectReceivedMessageLabel({ selected: [] });
        }
      });
  };

  handleReceivedMessageLabelOnClick = (event) => {
    const {
      communicationsGetReceivedMessage: getReceivedMessage,
      communicationsGetReceivedMessageLabel: getReceivedMessageLabel,
      communicationsSelectReceivedMessageLabel: selectReceivedMessageLabel,
    } = this.props;
    const { id } = event.currentTarget.dataset;
    getReceivedMessageLabel({ list: false, id });
    getReceivedMessage({ list: true, labelId: id })
      .then((action) => {
        if (action.type === COMMUNICATIONS_GET_RECEIVED_MESSAGE.SUCCESS) {
          selectReceivedMessageLabel({ selected: [parseInt(id, 10)] });
        }
      });
  };

  handleToggleReceivedMessageLabelModal = () => {
    const { communicationsToggleReceivedMessageLabelModal: toggleReceivedMessageLabelModal } = this.props;
    toggleReceivedMessageLabelModal({ openMode: 'create' });
  };

  render() {
    // Props
    const {
      receivedMessageLabels: {
        instances,
        selected,
      },
    } = this.props;

    return (
      <>
        <Button
          className="w-100"
          outline
          color="secondary"
          onClick={this.handleGetAllReceivedMessages}
        >
          <h4>
            <FontAwesomeIcon icon={faComments} className="mr-1" />All messages
          </h4>
        </Button>
        <h4>
          Message labels
        </h4>
        <hr />
        <ReceivedMessageLabelListStyled>
          {instances.map(instance => (
            <ReceivedMessageLabelListItemStyled
              key={instance.id}
              data-id={instance.id}
              onClick={this.handleReceivedMessageLabelOnClick}
              selected={selected.length && instance.id === selected[0]}
            >
              <h4>
                {}
                <FontAwesomeIcon icon={faTag} className="mr-2" />
                {instance.name}
              </h4>
            </ReceivedMessageLabelListItemStyled>
          ))}
        </ReceivedMessageLabelListStyled>
        <StylelessButton icon={faPlus} onClick={this.handleToggleReceivedMessageLabelModal}>
          Create new label
        </StylelessButton>

        {/* Modals */}
        <ReceivedMessageLabelModal
          // Callbacks
          toggle={this.handleToggleReceivedMessageLabelModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    receivedMessageLabels,
  } = state.communications;

  return {
    // Communications
    receivedMessageLabels,
  };
};

const mapDispatchToProps = {
  communicationsGetReceivedMessageLabel,
  communicationsToggleReceivedMessageLabelModal,
  communicationsSelectReceivedMessageLabel,
  communicationsGetReceivedMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReceivedMessageLabelList);
