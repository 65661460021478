import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

// Components
import { UncontrolledTooltip } from 'reactstrap';
import ImagesModal from '../../apps/images/components/ImagesModal';

const Wrapper = styled.div`
  transition: all 100ms;
  
  img {
    object-fit: cover;
    border: 2px solid ${({ theme, hovered }) => (hovered ? theme.color.primary : 'transparent')};
  }
  
  &:hover {
    cursor: pointer;
  }
`;

class EditableText extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    updateAction: PropTypes.instanceOf(Object).isRequired,
    imgStyle: PropTypes.instanceOf(Object),

    // Images
    images: PropTypes.instanceOf(Object).isRequired,

    // Callbacks
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    imgStyle: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
      editing: false,
    };
  }

  handleOnMouseEnter = () => {
    this.setState({
      hovered: true,
    });
  };

  handleOnMouseLeave = () => {
    this.setState({
      hovered: false,
    });
  };

  handleOnEdit = () => {
    this.setState({
      editing: true,
    });
  };

  handleOnSubmit = () => {
    const { onSubmit, images } = this.props;
    onSubmit(images.instances.filter(image => images.selected.includes(image.id))[0])
      .then(() => {
        this.setState({
          // TODO: Check this for usability.
          hovered: false,
          editing: false,
        });
      });
  };

  handleToggleMediaModal = () => {
    this.setState(prevState => ({
      editing: !prevState.editing,
    }));
  };

  render() {
    // State
    const {
      hovered,
      editing,
    } = this.state;

    // Props
    const {
      updateAction,
      imgStyle,
      value,

      // Images
      images,
    } = this.props;

    return (
      <>
        <Wrapper
          id="image"
          className="d-flex rounded"
          hovered={hovered}

          // Callbacks
          onMouseEnter={this.handleOnMouseEnter}
          onMouseLeave={this.handleOnMouseLeave}
          onClick={this.handleOnEdit}
        >
          <img
            className="rounded"
            style={imgStyle}
            src={value.url}
            alt=""
          />
        </Wrapper>

        {/* Tooltips */}
        <UncontrolledTooltip target="image" placement="left">
          Click to edit image
        </UncontrolledTooltip>

        {/* Modals */}
        <ImagesModal
          isOpen={editing}
          buttonText="Select image"
          asyncAddButton
          maxCanSelect={1}
          selected={images.selected}
          action={updateAction}

          // Callbacks
          toggle={this.handleToggleMediaModal}
          onAdd={this.handleOnSubmit}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    images,
  } = state.images;

  return {
    // Images
    images,
  };
};

export default connect(mapStateToProps)(EditableText);
