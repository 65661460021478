import moment from 'moment';

const getTimeOfDay = () => {
  const time = moment().hours();
  if (time > 0 && time < 5) {
    return 'evening';
  }
  if (time >= 5 && time < 12) {
    return 'morning';
  }
  if (time >= 12 && time < 18) {
    return 'afternoon';
  }
  if (time >= 18 && time < 24) {
    return 'evening';
  }
  return 'day';
};

export default getTimeOfDay;
