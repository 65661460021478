import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird';
import {
  SearchBarWrapper,
  SearchBar,
  SearchSuggestions,
  SearchSuggestion,
} from './styled';

class Search extends PureComponent {
  static propTypes = {
    actionCreators: PropTypes.shape({
      GET: PropTypes.instanceOf(Object),
    }).isRequired,
    placeholder: PropTypes.string.isRequired,
    suggest: PropTypes.bool,
    suggestions: PropTypes.instanceOf(Array).isRequired,
    searchMeta: PropTypes.instanceOf(Object).isRequired,

    // Components
    searchBarWrapperComponent: PropTypes.node,
    searchBarComponent: PropTypes.node,
    searchSuggestionsComponent: PropTypes.node,
    searchSuggestionComponent: PropTypes.node,
    suggestionComponent: PropTypes.node,

    // Callbacks
    setSearchMeta: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onGet: PropTypes.func.isRequired,
  };

  static defaultProps = {
    suggestionComponent: null,
    suggest: true,
    searchBarWrapperComponent: null,
    searchBarComponent: null,
    searchSuggestionsComponent: null,
    searchSuggestionComponent: null,
  };

  handleOnChange = (event) => {
    const { value } = event.target;
    const {
      actionCreators,
      suggestions,
      suggest,
      searchMeta: {
        selectedSuggestion,
      },

      // Callbacks
      setSearchMeta,
      onSearch,
    } = this.props;

    setSearchMeta({ searchTerm: value });
    if (value && suggest) {
      onSearch(value)
        .then((action) => {
          setSearchMeta({ searchDone: true });
          if (action.type === actionCreators.GET.SUCCESS) {
            if (selectedSuggestion > suggestions.length) {
              setSearchMeta({ selectedSuggestion: suggestions.length });
            }
          }
        });
    }
  };

  handleOnKeyDown = (event) => {
    const {
      suggest,
      suggestions,
      searchMeta: {
        searchTerm,
        selectedSuggestion,
      },

      // Callbacks
      onSearch,
      onGet,
      setSearchMeta,
    } = this.props;

    if (!suggest) {
      if (event.key === 'Enter') {
        if (searchTerm) {
          onSearch(searchTerm, false);
        } else {
          setSearchMeta({ hasFocus: false });
          onGet();
        }
        return;
      }
      return;
    }

    if (event.key === 'ArrowDown' || event.key === 'Down') {
      if (selectedSuggestion < suggestions.length) {
        const newSelectedSuggestion = selectedSuggestion + 1;
        const newSearchTerm = newSelectedSuggestion > 0
          ? suggestions[newSelectedSuggestion - 1].text.toLowerCase()
          : '';

        setSearchMeta({ searchTerm: newSearchTerm, selectedSuggestion: newSelectedSuggestion });
      }
      return;
    }
    if (event.key === 'ArrowUp' || event.key === 'Up') {
      if (selectedSuggestion > 0) {
        const newSelectedSuggestion = selectedSuggestion - 1;
        const newSearchTerm = newSelectedSuggestion > 0
          ? suggestions[newSelectedSuggestion - 1].text.toLowerCase()
          : '';

        setSearchMeta({ searchTerm: newSearchTerm, selectedSuggestion: newSelectedSuggestion });
      }
      return;
    }
    if (event.key === 'Enter') {
      if (searchTerm) {
        const newSearchTerm = suggestions[selectedSuggestion - 1]
          ? suggestions[selectedSuggestion - 1].text.toLowerCase()
          : searchTerm;
        setSearchMeta({ searchTerm: newSearchTerm, hasFocus: false });
        onSearch(newSearchTerm, false);
      } else {
        setSearchMeta({ hasFocus: false });
        onGet();
      }
      return;
    }
    if (event.key === 'Escape' || event.key === 'Esc') {
      setSearchMeta({ hasFocus: false });
      return;
    }
    setSearchMeta({ hasFocus: true });
  };

  handleOnClick = (event) => {
    const { text: newSearchTerm } = event.currentTarget.dataset;
    const { onSearch, setSearchMeta } = this.props;
    setSearchMeta({ searchTerm: newSearchTerm, hasFocus: false });
    onSearch(newSearchTerm, false);
  };

  handleOnMouseEnter = (event) => {
    const { index } = event.currentTarget.dataset;
    const { setSearchMeta } = this.props;
    const newSelectedSuggestion = parseInt(index, 10) + 1;
    setSearchMeta({ selectedSuggestion: newSelectedSuggestion });
  };

  handleOnMouseLeave = () => {
    const { setSearchMeta } = this.props;
    setSearchMeta({ selectedSuggestion: 0 });
  };

  handleOnFocus = () => {
    const { setSearchMeta } = this.props;
    setSearchMeta({ hasFocus: true });
  };

  handleOnBlur = (event) => {
    const { setSearchMeta } = this.props;
    if (!event.currentTarget.contains(event.target)) {
      setSearchMeta({ hasFocus: false });
    }
  };

  render() {
    // Props
    const {
      suggest,
      suggestions,
      placeholder,
      searchMeta: {
        searchTerm,
        selectedSuggestion,
        hasFocus,
      },
      actionCreators: {
        GET,
      },

      // Components
      suggestionComponent,
      searchBarWrapperComponent,
      searchBarComponent,
      searchSuggestionsComponent,
      searchSuggestionComponent,
    } = this.props;

    const SearchBarWrapperComponent = searchBarWrapperComponent || SearchBarWrapper;
    const SearchBarComponent = searchBarComponent || SearchBar;
    const SearchSuggestionsComponent = searchSuggestionsComponent || SearchSuggestions;
    const SearchSuggestionComponent = searchSuggestionComponent || SearchSuggestion;
    const { loading: searchLoading } = GET.search || { loading: false };

    return (
      <SearchBarWrapperComponent
        onFocus={this.handleOnFocus}
        onBlur={this.handleOnBlur}
      >
        <FontAwesomeIcon icon={searchLoading ? faSpinnerThird : faSearch} fixedWidth spin={searchLoading} />
        <SearchBarComponent
          type="text"
          name="search"
          onChange={this.handleOnChange}
          value={searchTerm}
          autoComplete="off"
          hasSuggestions={suggest && hasFocus && suggestions.length !== 0}
          onKeyDown={this.handleOnKeyDown}
          placeholder={placeholder}
        />
        {(suggest && hasFocus && suggestions.length !== 0) && (
          <SearchSuggestionsComponent>
            {suggestions.map((suggestion, index) => (
              <SearchSuggestionComponent
                key={suggestion.text}
                lastSuggestion={suggestion.text === suggestions.slice(-1)[0].text}
                selected={selectedSuggestion === index + 1}
                onClick={this.handleOnClick}
                onMouseEnter={this.handleOnMouseEnter}
                onMouseLeave={this.handleOnMouseLeave}
                data-text={suggestion.text.toLowerCase()}
                data-index={index}
              >
                {suggestionComponent ? suggestionComponent(suggestion, searchTerm) : (
                  <>
                    <FontAwesomeIcon icon={faSearch} fixedWidth />
                    <span>{suggestion.text.toLowerCase().slice(0, searchTerm.length)}</span>
                    <span className="font-weight-bold">{suggestion.text.toLowerCase().slice(searchTerm.length)}</span>
                  </>
                )}
              </SearchSuggestionComponent>
            ))}
          </SearchSuggestionsComponent>
        )}
      </SearchBarWrapperComponent>
    );
  }
}

export default Search;
