import React from 'react';
import PropTypes from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const renderField = ({
  input, label, placeholder, type, meta: { touched, error, warning }, icon,
}) => (
  <div className="form__form-group">
    <span className="form__form-group-label">{label}</span>
    <div className="form__form-group-field">
      <div className="form__form-group-icon">
        <FontAwesomeIcon icon={icon} />
      </div>
      <input {...input} placeholder={placeholder || label} type={type} />
    </div>
    <p className="text-danger">
      {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
    </p>
  </div>
);

renderField.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  icon: PropTypes.instanceOf(Object).isRequired,
};

renderField.defaultProps = {
  placeholder: null,
};

export default renderField;
