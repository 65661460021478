import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

// Components
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight, faPen, faArrows, faSpinnerThird,
} from '@fortawesome/pro-light-svg-icons';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import StylelessCreateButton from '../../../shared/components/StylelessCreateButton';
import NoticeModal from './NoticeModal';

// Actions
import { sectionsGetNotice, sectionsUpdateNoticeModalMeta } from '../redux/actions';
import { SECTIONS__GET_NOTICE } from '../redux/actionCreators';

const Wrapper = styled.div``;

const Notice = styled.div`
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 10px;
  background-color: white;

  span {
    font-size: 20px;
    line-height: 40px;
  }
`;

const NoticeButton = styled.div`
  padding: 6px 8.75px;
  font-size: 18px;
  border-radius: 5px;
  
  svg {
    transition: transform 200ms;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0 , 0.04);
  }
`;

class NoticeSetField extends Component {
  static propTypes = {
    value: PropTypes.instanceOf(Array).isRequired,

    // Sections
    sectionsActions: PropTypes.instanceOf(Object).isRequired,
    sections: PropTypes.instanceOf(Object).isRequired,
    notices: PropTypes.instanceOf(Object).isRequired,

    // Actions
    sectionsGetNotice: PropTypes.func.isRequired,
    sectionsUpdateNoticeModalMeta: PropTypes.func.isRequired,

    // Callbacks
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      openNotice: -1,
    };
  }

  handleToggleNoticeModal = (event, openMode, state) => {
    if (event) {
      event.preventDefault();
    }
    const { sectionsUpdateNoticeModalMeta: updateNoticeModalMeta } = this.props;
    updateNoticeModalMeta({ toggle: true, openMode, state });
  };

  handleToggleCreateNoticeModal = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.handleToggleNoticeModal(null, 'create');
  };

  handleToggleUpdateNoticeModal = (event) => {
    if (event) {
      const { id: idString } = event.currentTarget.dataset;
      const { sectionsGetNotice: getNotice } = this.props;
      const id = parseInt(idString, 10);
      getNotice({ list: false, id }, { asyncId: id })
        .then((action) => {
          if (action.type === SECTIONS__GET_NOTICE.SUCCESS) {
            this.handleToggleNoticeModal(null, 'update', { id });
          }
        });
    } else {
      this.handleToggleNoticeModal(null, 'update');
    }
  };

  handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { value: { notices }, onChange } = this.props;
    const toMove = notices.filter(notice => notice.id === result.draggableId)[0];
    const noticesCopy = [...notices];
    noticesCopy.splice(result.source.index, 1);
    noticesCopy.splice(result.destination.index, 0, toMove);
    onChange({ notices: noticesCopy });
  };

  handleNoticeOnClick = (event) => {
    const { id: idString } = event.currentTarget.dataset;
    const id = parseInt(idString, 10);
    this.setState(prevState => ({
      openNotice: prevState.openNotice === id ? -1 : id,
    }));
  };

  render() {
    // State
    const {
      openNotice,
    } = this.state;

    // Props
    const {
      value,

      // Sections
      sectionsActions: {
        SECTIONS__GET_NOTICE: getNoticeAction,
      },
      notices,
    } = this.props;

    return (
      <>
        <Wrapper className="mt-3">
          <DragDropContext onDragEnd={this.handleOnDragEnd}>
            <Droppable droppableId="notificationField" direction="vertical">
              {droppableProvided => (
                <div
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.dropabbleProps}
                >
                  {/* eslint-disable-next-line max-len */}
                  {(value.notices || []).map((notification, index) => (
                    <Draggable key={notification.id} draggableId={notification.id} index={index}>
                      {draggableProps => (
                        <div
                          ref={draggableProps.innerRef}
                          {...draggableProps.draggableProps}
                        >
                          <Notice>
                            <div className="d-flex justify-content-between">
                              <span>
                                {notification.title}
                              </span>
                              <div className="d-flex">
                                <NoticeButton onClick={this.handleNoticeOnClick} data-id={notification.id}>
                                  <FontAwesomeIcon
                                    icon={faChevronRight}
                                    fixedWidth
                                    rotation={openNotice === notification.id ? 90 : 180}
                                  />
                                </NoticeButton>
                                <NoticeButton
                                  onClick={this.handleToggleUpdateNoticeModal}
                                  data-id={notification.id}
                                >
                                  {(getNoticeAction[notification.id] || { loading: false }).loading ? (
                                    <FontAwesomeIcon icon={faSpinnerThird} fixedWidth spin />
                                  ) : (
                                    <FontAwesomeIcon icon={faPen} fixedWidth />
                                  )}
                                </NoticeButton>
                                <NoticeButton {...draggableProps.dragHandleProps}>
                                  <FontAwesomeIcon icon={faArrows} fixedWidth />
                                </NoticeButton>
                              </div>
                            </div>
                            {openNotice === notification.id && (
                              <div>
                                <FroalaEditorView model={notification.body} />
                              </div>
                            )}
                          </Notice>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeHolder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <StylelessCreateButton className="mt-3 text-primary" onClick={this.handleToggleCreateNoticeModal}>
            Create notice
          </StylelessCreateButton>
        </Wrapper>

        {/* Modals */}
        <NoticeModal
          modalMeta={notices.modalMeta}

          // Callbacks
          toggleCreate={this.handleToggleCreateNoticeModal}
          toggleUpdate={this.handleToggleUpdateNoticeModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    actions: sectionsActions,
    sections,
    notices,
  } = state.sections;

  return {
    // Sections
    sectionsActions,
    sections,
    notices,
  };
};

const mapDispatchToProps = {
  sectionsGetNotice,
  sectionsUpdateNoticeModalMeta,
};

const ConnectedNoticeSetField = connect(mapStateToProps, mapDispatchToProps)(NoticeSetField);

const renderNoticeSetField = ({ input, ...rest }) => (
  <ConnectedNoticeSetField {...input} {...rest} />
);

renderNoticeSetField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.instanceOf(Array),
    onChange: PropTypes.func,
  }).isRequired,
};

export default renderNoticeSetField;
