import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import AsyncSelect from 'react-select/async';

// Actions
import { communicationsGetRecipientSuggestion } from '../../redux/actions';
import { COMMUNICATIONS_GET_RECIPIENT_SUGGESTION } from '../../redux/actionCreators';

const styles = {
  menu: provided => ({
    ...provided,
    zIndex: 9999,
  }),
};

class RecipientsField extends PureComponent {
  static propTypes = {
    // Actions
    communicationsGetRecipientSuggestion: PropTypes.func.isRequired,

    // Callbacks
    onChange: PropTypes.func.isRequired,
  };

  handleGetContactSuggestions = (value, callback) => {
    const { communicationsGetRecipientSuggestion: getRecipientSuggestion } = this.props;
    getRecipientSuggestion({ searchTerm: value })
      .then((action) => {
        if (action.type === COMMUNICATIONS_GET_RECIPIENT_SUGGESTION.SUCCESS) {
          callback(action.response.recipientSuggestions);
        }
      });
  };

  handleOnChange = (value) => {
    const { onChange } = this.props;
    onChange(value);
  };

  render() {
    return (
      <AsyncSelect
        className="w-100"
        isMulti
        loadOptions={this.handleGetContactSuggestions}
        onChange={this.handleOnChange}
        styles={styles}
      />
    );
  }
}

const renderRecipientField = ({ input, ...rest }) => (
  <RecipientsField {...input} {...rest} />
);

renderRecipientField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.instanceOf(Array),
    onChange: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = (state) => {
  const {
    recipientSuggestions,
  } = state.communications;

  return {
    // Communications
    recipientSuggestions,
  };
};

const mapDispatchToProps = {
  communicationsGetRecipientSuggestion,
};

export default connect(mapStateToProps, mapDispatchToProps)(renderRecipientField);
