import React from 'react';
import { reduxForm, Field } from 'redux-form';

// Components
import TextField from '../../../../shared/components/form/TextField';

const CreateContactForm = () => (
  <form className="material-form form--horizontal text-left">
    <div className="form__form-group">
      <span className="form__form-group-label">Name</span>
      <Field
        className="material-form__field"
        name="name"
        component={TextField}
      />
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Email</span>
      <Field
        className="material-form__field"
        name="email"
        component={TextField}
      />
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Mobile Number</span>
      <Field
        className="material-form__field"
        name="mobileNumber"
        component={TextField}
      />
    </div>
  </form>
);

const withForm = {
  form: 'createContactForm',
};

export default reduxForm(withForm)(CreateContactForm);
