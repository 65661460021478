/* eslint-disable max-len */
import { generateAction as generate, reduxTypes as types } from '@tmosystems/core';
import service from '../service';

// Action creators
import {
  // Async action creators
  IMAGES__CREATE_IMAGE,
  IMAGES__GET_IMAGE,
  IMAGES__UPDATE_IMAGE,
  IMAGES__DELETE_IMAGE,
  IMAGES__GET_MORE_IMAGES,
  IMAGES__CREATE_CATEGORY,
  IMAGES__GET_CATEGORY,
  IMAGES__DELETE_CATEGORY,

  // Sync action creators
  IMAGES__SELECT_IMAGE,
  IMAGES__UPDATE_IMAGE_MODAL_META,
  IMAGES__UPDATE_IMAGE_TABLE_META,
  IMAGES__UPDATE_IMAGE_SCROLL_META,
  IMAGES__UPDATE_IMAGE_SEARCH_META,
  IMAGES__REINITIALIZE_IMAGE_STATE,
  IMAGES__SELECT_CATEGORY,
} from './actionCreators';

// Async actions
export const imagesCreateImage = generate(IMAGES__CREATE_IMAGE, {
  type: types.ASYNC,
  service: service.POST.image,
});

export const imagesGetImage = generate(IMAGES__GET_IMAGE, {
  type: types.ASYNC,
  service: service.GET.image,
});

export const imagesUpdateImage = generate(IMAGES__UPDATE_IMAGE, {
  type: types.ASYNC,
  service: service.PUT.image,
});

export const imagesDeleteImage = generate(IMAGES__DELETE_IMAGE, {
  type: types.ASYNC,
  service: service.DELETE.image,
});

export const imagesGetMoreImages = generate(IMAGES__GET_MORE_IMAGES, {
  type: types.ASYNC,
  service: service.GET.image,
});

export const imagesCreateCategory = generate(IMAGES__CREATE_CATEGORY, {
  type: types.ASYNC,
  service: service.POST.category,
});

export const imagesGetCategory = generate(IMAGES__GET_CATEGORY, {
  type: types.ASYNC,
  service: service.GET.category,
});

export const imagesDeleteCategory = generate(IMAGES__DELETE_CATEGORY, {
  type: types.ASYNC,
  service: service.DELETE.category,
});

// Sync actions
export const imagesSelectImage = generate(IMAGES__SELECT_IMAGE, { type: types.SYNC });
export const imagesUpdateImageModalMeta = generate(IMAGES__UPDATE_IMAGE_MODAL_META, { type: types.SYNC });
export const imagesUpdateImageTableMeta = generate(IMAGES__UPDATE_IMAGE_TABLE_META, { type: types.SYNC });
export const imagesUpdateImageScrollMeta = generate(IMAGES__UPDATE_IMAGE_SCROLL_META, { type: types.SYNC });
export const imagesUpdateImageSearchMeta = generate(IMAGES__UPDATE_IMAGE_SEARCH_META, { type: types.SYNC });
export const imagesReinitializeImageState = generate(IMAGES__REINITIALIZE_IMAGE_STATE, { type: types.SYNC });
export const imagesSelectCategory = generate(IMAGES__SELECT_CATEGORY, { type: types.SYNC });
