import { create } from 'axios';
import { createAxiosInstance } from '@tmosystems/core';

const axios = createAxiosInstance(create, { baseURL: '/private/images/api/v1' });

// POST
const createImage = requestData => axios.post('/', requestData);
const createCategory = requestData => axios.post('/category/', requestData);

// GET
const getImage = requestParams => axios.get('/', { params: requestParams });
const getCategory = requestParams => axios.get('/category/', { params: requestParams });

// PUT
const updateImage = requestData => axios.put('/', requestData);

// DELETE
const deleteImage = requestData => axios.delete('/', { data: requestData });
const deleteCategory = requestData => axios.delete('/category/', { data: requestData });

export default {
  POST: {
    image: createImage,
    category: createCategory,
  },
  GET: {
    image: getImage,
    category: getCategory,
  },
  PUT: {
    image: updateImage,
  },
  DELETE: {
    image: deleteImage,
    category: deleteCategory,
  },
};
