import React from 'react';
import PropTypes from 'prop-types';

// Components
import { ButtonToolbar, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePlus, faFolderPlus, faEdit } from '@fortawesome/pro-light-svg-icons';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';


const DocumentsToolbar = ({
  selected,

  // Callbacks
  onUploadFile,
  onCreateFolder,
  onEdit,
  onDelete,
}) => (
  <div className="mb-4">
    <ButtonToolbar>
      <Button
        size="sm"
        className="btn btn-primary rounded"
        onClick={onUploadFile}
      >
        <FontAwesomeIcon icon={faFilePlus} fixedWidth /> Upload file
      </Button>
      <Button
        size="sm"
        className="btn btn-primary rounded"
        onClick={onCreateFolder}
      >
        <FontAwesomeIcon icon={faFolderPlus} fixedWidth /> Create folder
      </Button>
      <Button
        size="sm"
        className="btn btn-secondary rounded"
        disabled={selected.length !== 1}
        onClick={onEdit}
      >
        <FontAwesomeIcon icon={faEdit} fixedWidth /> Edit
      </Button>
      <Button
        size="sm"
        className="btn btn-secondary rounded"
        disabled={selected.length === 0}
        onClick={onDelete}
      >
        <FontAwesomeIcon icon={faTrash} fixedWidth /> Delete
      </Button>
    </ButtonToolbar>
  </div>
);

DocumentsToolbar.propTypes = {
  selected: PropTypes.instanceOf(Array).isRequired,

  // Callbacks
  onUploadFile: PropTypes.func.isRequired,
  onCreateFolder: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DocumentsToolbar;
