import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getHeaders } from 'ggtmo-utils';

// Components
import FroalaEditorComponent from 'react-froala-wysiwyg';

class FroalaEditor extends PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    config: PropTypes.instanceOf(Object),
    plainText: PropTypes.bool,

    // Redux
    refreshKey: PropTypes.number.isRequired,
  };

  static defaultProps = {
    className: '',
    config: {},
    plainText: false,
  };

  getPlainText = (htmlString) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    return tempElement.innerContent || tempElement.innerText || '';
  };

  handleOnChange = (model) => {
    const { onChange, plainText } = this.props;
    if (plainText) {
      onChange(model);
    } else {
      onChange(model);
    }
  };

  render() {
    // Env
    const {
      REACT_APP_BASE_URL: baseUrl,
      REACT_APP_FROALA_KEY: froalaKey,
    } = process.env;

    const mediaApiUrl = `${baseUrl}/private/images/api/v1/`;
    const mediaParams = {
      froala: true,
    };

    // Props
    const {
      value,
      className,
      config,

      // Redux
      refreshKey,
    } = this.props;

    return (
      <div className={className}>
        <FroalaEditorComponent
          key={refreshKey}
          model={value}
          onModelChange={this.handleOnChange}
          config={{
            heightMin: 500,
            toolbarSticky: true,
            toolbarStickyOffset: 60,
            imageManagerLoadMethod: 'GET',
            imageManagerLoadURL: mediaApiUrl,
            imageManagerLoadParams: mediaParams,
            imageManagerDeleteMethod: 'DELETE',
            imageManagerDeleteURL: mediaApiUrl,
            imageManagerDeleteParams: mediaParams,
            imageUploadURL: mediaApiUrl,
            imageUploadParams: mediaParams,
            requestHeaders: { ...getHeaders().headers },
            requestWithCORS: false,
            key: froalaKey,
            attribution: false,
            toolbarButtons: {
              /* eslint-disable max-len */
              moreText: {
                buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],
              },
              moreParagraph: {
                buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
              },
              moreRich: {
                buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
              },
              moreMisc: {
                buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
                align: 'right',
                buttonsVisible: 2,
              },
            },
            ...config,
          }}
        />
      </div>
    );
  }
}

const renderFroalaEditor = ({ input, ...rest }) => (
  <FroalaEditor {...input} {...rest} />
);

renderFroalaEditor.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = (state) => {
  const {
    key,
  } = state.sections;

  return {
    refreshKey: key,
  };
};

export default connect(mapStateToProps)(renderFroalaEditor);
