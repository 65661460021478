import { generateReducerElement as generate, reduxTypes as types, adjust } from '@tmosystems/core';

// Action creators
import {
  // Async action creators
  REPAIRS__GET_REQUEST,
  REPAIRS__UPDATE_REQUEST,
  REPAIRS__DELETE_REQUEST,
  REPAIRS__CREATE_CATEGORY,
  REPAIRS__GET_CATEGORY,
  REPAIRS__UPDATE_CATEGORY,
  REPAIRS__DELETE_CATEGORY,
  REPAIRS__CREATE_TYPE,
  REPAIRS__GET_TYPE,
  REPAIRS__UPDATE_TYPE,
  REPAIRS__DELETE_TYPE,

  // Sync action creators
  REPAIRS__UPDATE_REQUEST_TABLE_META,
  REPAIRS__UPDATE_CATEGORY_MODAL_META,
  REPAIRS__UPDATE_CATEGORY_TABLE_META,
  REPAIRS__UPDATE_TYPE_MODAL_META,
  REPAIRS__UPDATE_TYPE_TABLE_META,
  REPAIRS__UPDATE_TYPE_SEARCH_META,
  REPAIRS__UPDATE_TYPE_STATE,
} from './actionCreators';

import { initialState } from './reducer';

export default (state, action) => ({
  // Async
  ...generate(state, action, REPAIRS__GET_REQUEST, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, REPAIRS__GET_REQUEST.SUCCESS) && {
        requests: action.response.append ? {
          ...state.requests,
          instance: action.response.instance || state.requests.instance,
          instances: [
            ...state.requests.instances,
            ...action.response.instances,
          ],
          count: action.response.count,
        } : {
          ...state.requests,
          instance: action.response.instance || state.requests.instance,
          instances: action.response.instances || state.requests.instances,
          count: action.response.count,
        },
      },
    },
  }),
  ...generate(state, action, REPAIRS__UPDATE_REQUEST, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, REPAIRS__UPDATE_REQUEST.SUCCESS) && {
        requests: {
          ...state.requests,
          instance: action.response.instance,
          instances: state.requests.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.requests.selected,
        },
      },
    },
  }),
  ...generate(state, action, REPAIRS__DELETE_REQUEST, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, REPAIRS__DELETE_REQUEST.SUCCESS) && {
        requests: {
          ...state.requests,
          instance: state.requests.instance,
          instances: state.requests.instances
            .filter(instance => instance.id !== action.response.id),
          selected: initialState.requests.selected,
        },
      },
    },
  }),
  ...generate(state, action, REPAIRS__CREATE_CATEGORY, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, REPAIRS__CREATE_CATEGORY.SUCCESS) && {
        categories: {
          ...state.categories,
          instance: action.response.instance,
          instances: [
            ...state.categories.instances,
            action.response.instance,
          ],
        },
      },
    },
  }),
  ...generate(state, action, REPAIRS__GET_CATEGORY, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, REPAIRS__GET_CATEGORY.SUCCESS) && {
        categories: action.response.append ? {
          ...state.categories,
          instance: action.response.instance || state.categories.instance,
          instances: [
            ...state.categories.instances,
            ...action.response.instances,
          ],
          count: action.response.count,
        } : {
          ...state.categories,
          instance: action.response.instance || state.categories.instance,
          instances: action.response.instances || state.categories.instances,
          count: action.response.count,
        },
      },
    },
  }),
  ...generate(state, action, REPAIRS__UPDATE_CATEGORY, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, REPAIRS__UPDATE_CATEGORY.SUCCESS) && {
        categories: {
          ...state.categories,
          instance: action.response.instance,
          instances: state.categories.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.categories.selected,
        },
      },
    },
  }),
  ...generate(state, action, REPAIRS__DELETE_CATEGORY, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, REPAIRS__DELETE_CATEGORY.SUCCESS) && {
        categories: {
          ...state.categories,
          instance: state.categories.instance,
          instances: state.categories.instances
            .filter(instance => instance.id !== action.response.id),
          selected: initialState.categories.selected,
        },
      },
    },
  }),
  ...generate(state, action, REPAIRS__CREATE_TYPE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, REPAIRS__CREATE_TYPE.SUCCESS) && {
        types: {
          ...state.types,
          instance: action.response.instance,
          instances: [
            ...state.types.instances,
            action.response.instance,
          ],
        },
      },
    },
  }),
  ...generate(state, action, REPAIRS__GET_TYPE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, REPAIRS__GET_TYPE.SUCCESS) && {
        types: action.response.append ? {
          ...state.types,
          instance: action.response.instance || state.types.instance,
          instances: [
            ...state.types.instances,
            ...action.response.instances,
          ],
          count: action.response.count,
        } : {
          ...state.types,
          instance: action.response.instance || state.types.instance,
          instances: action.response.instances || state.types.instances,
          count: action.response.count,
        },
      },
    },
  }),
  ...generate(state, action, REPAIRS__UPDATE_TYPE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, REPAIRS__UPDATE_TYPE.SUCCESS) && {
        types: {
          ...state.types,
          instance: action.response.instance,
          instances: state.types.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.types.selected,
        },
      },
    },
  }),
  ...generate(state, action, REPAIRS__DELETE_TYPE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, REPAIRS__DELETE_TYPE.SUCCESS) && {
        types: {
          ...state.types,
          instance: state.types.instance,
          instances: state.types.instances
            .filter(instance => instance.id !== action.response.id),
          selected: initialState.types.selected,
        },
      },
    },
  }),

  // Sync
  ...generate(state, action, REPAIRS__UPDATE_REQUEST_TABLE_META, {
    type: types.SYNC,
    adjustment: {
      requests: {
        ...state.requests,
        tableMeta: {
          ...state.requests.tableMeta,
          ...action,
        },
      },
    },
  }),
  ...generate(state, action, REPAIRS__UPDATE_CATEGORY_MODAL_META, {
    type: types.SYNC,
    adjustment: {
      categories: {
        ...state.categories,
        modalMeta: {
          isOpen: (() => {
            if (action.toggle) {
              return !state.categories.modalMeta.isOpen;
            }
            return action.isOpen;
          })(),
          openMode: action.openMode,
          ...action.state,
        },
      },
    },
  }),
  ...generate(state, action, REPAIRS__UPDATE_CATEGORY_TABLE_META, {
    type: types.SYNC,
    adjustment: {
      categories: {
        ...state.categories,
        tableMeta: {
          ...state.categories.tableMeta,
          ...action,
        },
      },
    },
  }),
  ...generate(state, action, REPAIRS__UPDATE_TYPE_MODAL_META, {
    type: types.SYNC,
    adjustment: {
      types: {
        ...state.types,
        modalMeta: {
          isOpen: (() => {
            if (action.toggle) {
              return !state.types.modalMeta.isOpen;
            }
            return action.isOpen;
          })(),
          openMode: action.openMode,
          ...action.state,
        },
      },
    },
  }),
  ...generate(state, action, REPAIRS__UPDATE_TYPE_TABLE_META, {
    type: types.SYNC,
    adjustment: {
      types: {
        ...state.types,
        tableMeta: {
          ...state.types.tableMeta,
          ...action,
        },
      },
    },
  }),
  ...generate(state, action, REPAIRS__UPDATE_TYPE_SEARCH_META, {
    type: types.SYNC,
    adjustment: {
      types: {
        ...state.types,
        searchMeta: {
          ...state.types.searchMeta,
          ...action,
        },
      },
    },
  }),
  ...generate(state, action, REPAIRS__UPDATE_TYPE_STATE, {
    type: types.SYNC,
    adjustment: {
      types: action,
    },
  }),
}[action.type] || state);
