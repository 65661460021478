import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { squashString } from 'ggtmo-utils';
import classNames from 'classnames';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

const ImageBody = ({ src }) => (
  <div>
    <img
      src={src}
      alt=""
      className="img-fluid"
    />
  </div>
);

ImageBody.propTypes = {
  src: PropTypes.string.isRequired,
};

const ImageInfo = ({
  id, name, date, onRemove,
}) => (
  <div className="form__image-set-field__image__info">
    <div>
      <p>{squashString(name, { filename: true, length: 15 })}</p>
      <p className="text-black-50 mt-0"><small>Uploaded: {moment(date).format('DD/MM/YY')}</small></p>
    </div>
    <div className="text-right">
      <FontAwesomeIcon icon={faTimes} className="hover-text-red" onClick={onRemove} data-id={id} />
    </div>
  </div>
);

ImageInfo.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,

  // Callbacks
  onRemove: PropTypes.func.isRequired,
};

const Image = ({
  id, name, dateTimeUploaded: date, url, primary, onRemove, className,
}) => (
  <div className={classNames('bg-white rounded form__image-set-field__image', className)}>
    <ImageBody src={url} />
    <ImageInfo
      id={id}
      name={name}
      date={date}

      // Callbacks
      onRemove={onRemove}
    />
    {primary && (
      <div className="form__image-set-field__image__primary-overlay">
        Primary Image
      </div>
    )}
  </div>
);

Image.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  dateTimeUploaded: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  primary: PropTypes.bool,
  className: PropTypes.string.isRequired,

  // Callbacks
  onRemove: PropTypes.func.isRequired,
};

Image.defaultProps = {
  primary: false,
};

export default Image;
