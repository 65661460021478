import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { wrapper } from 'ggtmo-utils';
import { Field, reduxForm } from 'redux-form';

// Components
import { Form } from 'reactstrap';
import { faKey } from '@fortawesome/pro-light-svg-icons';
import renderField from '../../../shared/components/form/renderField';
import AsyncResetPasswordFormErrors from './AsyncResetPasswordFormErrors';

// Redux
import {
  resetPassword,
} from '../redux/actions';
import {
  ACCOUNT_RESET_PASSWORD,
} from '../redux/actionCreators';

// Validation
import validate from './validateResetPasswordForm';

// Images
import logo from '../../../shared/img/logo/logo_light.png';
import AsyncButton from '../../../shared/components/AsyncButton';


class ResetPassword extends PureComponent {
  static propTypes = {
    // Redux
    accountActions: PropTypes.instanceOf(Object).isRequired,
    resetPassword: PropTypes.func.isRequired,

    // Form
    resetPasswordForm: PropTypes.instanceOf(Object),
    invalid: PropTypes.bool.isRequired,

    // Router
    history: RouterPropTypes.history.isRequired,
    match: RouterPropTypes.match.isRequired,
  };

  static defaultProps = {
    // Form
    resetPasswordForm: {},
  };

  handleOnSubmit = (event) => {
    event.preventDefault();
    const {
      resetPassword: resetPasswordAction, resetPasswordForm, history, match: { params },
    } = this.props;

    const requestData = {
      ...resetPasswordForm.values,
      uid: params.uid,
      token: params.token,
    };

    resetPasswordAction(requestData)
      .then((action) => {
        if (action.type === ACCOUNT_RESET_PASSWORD.SUCCESS) {
          history.push('/login');
        }
      });
  };

  render() {
    // Props
    const {
      accountActions: {
        ACCOUNT_RESET_PASSWORD: resetPasswordAction,
      },
      invalid,
    } = this.props;

    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card shadow">
            <img src={logo} alt="" className="mb-4" />
            <div className="text-danger mb-3">
              <AsyncResetPasswordFormErrors action={resetPasswordAction} />
            </div>
            <Form className="form" onSubmit={this.handleOnSubmit}>
              <Field
                name="newPassword1"
                type="password"
                component={renderField}
                label={null}
                placeholder="New password"
                icon={faKey}
              />
              <Field
                name="newPassword2"
                type="password"
                component={renderField}
                label={null}
                placeholder="Repeat new password"
                icon={faKey}
              />
              <AsyncButton
                action={resetPasswordAction}
                color="primary"
                className="mx-auto"
                type="submit"
                disabled={invalid}
              >
                Reset password
              </AsyncButton>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  const {
    actions: accountActions,
  } = state.account;

  const {
    resetPasswordForm,
  } = state.form;

  return {
    // Account
    accountActions,

    // Form
    resetPasswordForm,
  };
};


const mapDispatchToProps = {
  resetPassword,
};


const withForm = {
  form: 'resetPasswordForm',
  validate,
};


export default wrapper({
  component: ResetPassword,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    reduxForm(withForm),
  ],
});
