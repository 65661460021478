import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { wrapper } from 'ggtmo-utils';
import { toast } from 'react-toastify';

// Components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
} from 'reactstrap';
import MessageEditorForm from './MessageEditorForm';
import ConfirmTextMessageModal from './ConfirmTextMessageModal';

// Actions
import { communicationsCreateTextMessage, communicationsCreateEmailMessage } from '../../redux/actions';
import { COMMUNICATIONS_CREATE_TEXT_MESSAGE } from '../../redux/actionCreators';

class MessageEditor extends Component {
  static propTypes = {
    // Router
    history: ReactRouterPropTypes.history.isRequired,

    // Form
    messageEditorForm: PropTypes.instanceOf(Object).isRequired,

    // Communications
    communicationsActions: PropTypes.instanceOf(Object).isRequired,

    // Actions
    communicationsCreateTextMessage: PropTypes.func.isRequired,
    communicationsCreateEmailMessage: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      confirmTextMessageModalOpen: false,
    };
  }

  handleToggleConfirmTextMessageModal = () => {
    this.setState(prevState => ({
      confirmTextMessageModalOpen: !prevState.confirmTextMessageModalOpen,
    }));
  };

  getPlainText = (htmlString) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    return tempElement.innerContent || tempElement.innerText || '';
  };

  handleOnCreateTextMessage = () => {
    const {
      communicationsCreateTextMessage: createTextMessage,
      messageEditorForm,
      history,
    } = this.props;
    createTextMessage({
      ...messageEditorForm.values,
      body: this.getPlainText(messageEditorForm.values.body),
    })
      .then((action) => {
        if (action.type === COMMUNICATIONS_CREATE_TEXT_MESSAGE.SUCCESS) {
          const { errors } = action.response;
          if (errors.length === 0) {
            history.push('/communications');
            toast('Message sent successfully', { type: toast.TYPE.SUCCESS });
          } else {
            const { totalAttempts } = action.response;
            const totalErrors = errors.length;
            if (totalAttempts === totalErrors) {
              toast(`Message${totalAttempts === 1 ? '' : 's'} failed to send.`, {
                type: toast.TYPE.ERROR,
              });
              this.handleToggleConfirmTextMessageModal();
            } else {
              toast(`Message sent to ${totalAttempts - totalErrors} out of ${totalAttempts} contacts.`, {
                type: toast.TYPE.WARNING,
              });
              history.push('/communications');
            }
          }
        }
      });
  };

  handleOnCreateEmailMessage = () => {
    const { communicationsCreateEmailMessage: createEmailMessage, messageEditorForm } = this.props;
    createEmailMessage(messageEditorForm.values);
  };

  handleOnSubmit = () => {
    const { messageEditorForm } = this.props;
    if (messageEditorForm.values.type.value === 'textMessage') {
      this.handleOnCreateTextMessage();
    }
    if (messageEditorForm.values.type.value === 'emailMessage') {
      this.handleOnCreateEmailMessage();
    }
  };

  getDisabled = () => {
    const { messageEditorForm } = this.props;
    if (!messageEditorForm) {
      return true;
    }
    if (!messageEditorForm.values) {
      return true;
    }
    if (!messageEditorForm.values.recipients) {
      return true;
    }
    if (messageEditorForm.values.recipients.length === 0) {
      return true;
    }
    return !messageEditorForm.values.body;
  };

  render() {
    // State
    const {
      confirmTextMessageModalOpen,
    } = this.state;

    // Props
    const {
      // Form
      messageEditorForm,

      // Communications
      communicationsActions: {
        COMMUNICATIONS_CREATE_TEXT_MESSAGE: createTextMessageAction,
      },
    } = this.props;

    return (
      <>
        <Container>
          <h3 className="page-title">Create new message</h3>
          <p className="page-subhead">Use the form to create a new message.</p>
          <Row>
            <Col xl={10}>
              <Card>
                <CardBody>
                  <MessageEditorForm
                    initialValues={{ type: { label: 'Text Message', value: 'textMessage' } }}
                    enableReinitalize
                  />
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody className="shadow">
                  <Button
                    outline
                    color="primary"
                    className="w-100"
                    onClick={this.handleToggleConfirmTextMessageModal}
                    disabled={this.getDisabled()}
                  >
                    Send
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* Modals */}
        {messageEditorForm && (
          <ConfirmTextMessageModal
            isOpen={confirmTextMessageModalOpen}
            action={createTextMessageAction}
            form={{
              ...messageEditorForm,
              values: {
                ...messageEditorForm.values,
                body: this.getPlainText(messageEditorForm.values.body),
              },
            }}

            // Confirm
            toggle={this.handleToggleConfirmTextMessageModal}
            onConfirm={this.handleOnCreateTextMessage}
          />
        )}
      </>
    );
  }
}

const withForm = {
  form: 'messageEditorForm',
};

const mapStateToProps = (state) => {
  const {
    messageEditorForm,
  } = state.form;

  const {
    actions: communicationsActions,
  } = state.communications;

  return {
    // Form
    messageEditorForm,

    // Communications
    communicationsActions,
  };
};

const mapDispatchToProps = {
  communicationsCreateTextMessage,
  communicationsCreateEmailMessage,
};

export default wrapper({
  component: MessageEditor,
  wrappers: [
    reduxForm(withForm),
    connect(mapStateToProps, mapDispatchToProps),
  ],
});
