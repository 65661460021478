import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';


export default class MatTableHead extends PureComponent {
  static propTypes = {
    columns: PropTypes.instanceOf(Array).isRequired,
    numSelected: PropTypes.number.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    canSelect: PropTypes.bool,

    // Callbacks
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    canSelect: true,
  };

  createSortHandler = property => (event) => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };

  render() {
    const {
      columns,
      order,
      orderBy,
      numSelected,
      rowCount,
      canSelect,

      // Callbacks
      onSelectAllClick,
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          {canSelect && (
            <TableCell padding="checkbox">
              <Checkbox
                className={
                  `material-table__checkbox ${numSelected === rowCount && 'material-table__checkbox--checked'}`
                }
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </TableCell>
          )}
          {columns.map(column => (
            <TableCell
              className="material-table__cell material-table__cell--sort"
              key={column.id}
              align={column.align}
              padding={column.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === column.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={order}
                onClick={this.createSortHandler(column.id)}
                className="material-table__sort-label"
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          ), this)}
        </TableRow>
      </TableHead>
    );
  }
}
