import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUsers } from '@fortawesome/pro-light-svg-icons';
import {
  ContactGroupsUnorderedList,
  ContactGroupsListItem,
  ContactGroupIcon,
} from './styled';

const ContactGroupsList = ({
  instances,
  createButton,
  border,

  // Callbacks
  onClick,
  toggle,
}) => (
  <ContactGroupsUnorderedList>
    {instances.map(instance => (
      <ContactGroupsListItem
        className={classNames({ 'border-bottom': border })}
        role="presentation"
        onClick={onClick}
        data-slug={instance.slug}
      >
        <ContactGroupIcon>
          <FontAwesomeIcon icon={faUsers} />
        </ContactGroupIcon>
        {instance.name}
      </ContactGroupsListItem>
    ))}
    {createButton && (
      <div className="mt-3 text-center hover-pointer" role="presentation" onClick={toggle}>
        <FontAwesomeIcon icon={faPlus} /> Create contact group
      </div>
    )}
  </ContactGroupsUnorderedList>
);

ContactGroupsList.propTypes = {
  instances: PropTypes.instanceOf(Array).isRequired,
  createButton: PropTypes.bool,
  border: PropTypes.bool,

  // Callbacks
  onClick: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
};

ContactGroupsList.defaultProps = {
  createButton: false,
  border: true,
};

export default ContactGroupsList;
