import { create } from 'axios';
import { createAxiosInstance } from '@tmosystems/core';

const axios = createAxiosInstance(create, { baseURL: '/private/events/api/v1' });

// POST
const createEvent = requestData => axios.post('/', requestData);
const createAndPublishEvent = requestData => axios.post('/', { createAndPublish: true, ...requestData });

// GET
const getEvent = requestParams => axios.get('/', { params: requestParams });

// PUT
const updateEvent = requestData => axios.put('/', requestData);
const updateAndPublishEvent = requestData => axios.put('/', { updateAndPublish: true, ...requestData });
const publishEvent = requestData => axios.put('/', { publish: true, ...requestData });
const unpublishEvent = requestData => axios.put('/', { unpublish: true, ...requestData });

// DELETE
const deleteEvent = requestData => axios.delete('/', { data: requestData });

export default {
  POST: {
    createEvent,
    createAndPublishEvent,
  },
  GET: {
    getEvent,
  },
  PUT: {
    updateEvent,
    updateAndPublishEvent,
    publishEvent,
    unpublishEvent,
  },
  DELETE: {
    deleteEvent,
  },
};
