import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { wrapper } from 'ggtmo-utils';

// Components
import { Container, Card, CardBody } from 'reactstrap';
import NewsTable from './components/NewsTable';
import Toolbar from './components/Toolbar';
import AsyncComponent from '../../shared/components/AsyncComponent';

// Redux
import {
  newsGetStory,
  newsSelectStory,
  newsDeleteStory,
  newsUpdateStoryTableMeta,
} from './redux/actions';
import {
  NEWS__DELETE_STORY,
} from './redux/actionCreators';

// Classes
import BaseEditorWrapper from '../../shared/BaseEditorWrapper';

// Images
import newsImage from '../../shared/img/news.png';

class News extends BaseEditorWrapper {
  static propTypes = {
    // News
    newsActions: PropTypes.instanceOf(Object).isRequired,
    stories: PropTypes.instanceOf(Array).isRequired,
  };

  appName = 'news';

  actionCreators = {
    delete: NEWS__DELETE_STORY,
  };

  toastMessages = {
    delete: {
      SUCCESS: length => `Successfully deleted ${length === 1 ? 'story' : 'stories'}.`,
    },
  };

  render() {
    // Props
    const {
      // News
      newsActions: {
        NEWS__GET_STORY: getStoryAction,
      },
      stories,
    } = this.props;

    return (
      <Container>
        <h3 className="page-title">News</h3>
        <p className="page-subhead">Add and edit news stories.</p>
        <Card style={{ minHeight: 753 }}>
          <CardBody>
            <Toolbar
              selected={stories.selected}

              // Callbacks
              onCreate={this.handleOnCreate}
              onEdit={this.handleOnEdit}
              onDelete={this.handleOnDelete}
            />
            <AsyncComponent action={getStoryAction}>
              <NewsTable
                stories={stories.instances}
                selected={stories.selected}
                page={stories.tableMeta.page}
                count={stories.count}

                // Callbacks
                onSelect={this.handleOnSelect}
                onPageChange={this.handleOnPageChange}
              >
                <div className="editor__no-instances mt-5">
                  <h4>You&#39;ve not created any stories yet. Hit the create button to get started.</h4>
                  <img src={newsImage} alt="" />
                </div>
              </NewsTable>
            </AsyncComponent>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    actions: newsActions,
    stories,
  } = state.news;

  return {
    // News
    newsActions,
    stories,
    selected: stories.selected,
  };
};

const mapDispatchToProps = {
  get: newsGetStory,
  delete: newsDeleteStory,
  select: newsSelectStory,
  updateTableMeta: newsUpdateStoryTableMeta,
};

export default wrapper({
  component: News,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
  ],
});
