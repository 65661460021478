import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import { Button, ButtonToolbar } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import Modal from './Modal';
import AsyncButton from './AsyncButton';
import ModalHeader from './ModalHeader';
import ModalContent from './ModalContent';
import ModalFooter from './ModalFooter';

class DeleteConfirmModal extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    action: PropTypes.instanceOf(Object).isRequired,
    instanceType: PropTypes.string.isRequired,

    // Callbacks
    toggle: PropTypes.func.isRequired,
    onOpened: PropTypes.func,
    onClosed: PropTypes.func,
    deleteAnyway: PropTypes.func.isRequired,
  };

  static defaultProps = {
    open: false,

    // Callbacks
    onOpened: () => {},
    onClosed: () => {},
  };

  render() {
    // Props
    const {
      open,
      action,
      instanceType,

      // Callbacks
      toggle,
      onOpened,
      onClosed,
      deleteAnyway,
    } = this.props;

    return (
      <Modal
        open={open}
        width="xl"
        noPadding

        // Callbacks
        toggle={toggle}
        onOpened={onOpened}
        onClosed={onClosed}
      >
        <ModalHeader
          title="Confirm delete"

          // Callbacks
          onClose={toggle}
        />
        <ModalContent className="text-center" style={{ borderBottomLeftRadius: '0.75rem' }}>
          <div className="modal__body gallery__delete-confirm">
            <h3 className="text-danger">
              <FontAwesomeIcon icon={faExclamationCircle} /> Delete {instanceType}?
            </h3>
            <br />
            <h5 className="mb-5">
              Are you sure you want to delete this {instanceType}?{' '}
              <span className="font-weight-bold">This action is permanent.</span>
            </h5>
          </div>
          <ModalFooter>
            <ButtonToolbar className="justify-content-center">
              <Button outline size="sm" onClick={toggle}>Cancel</Button>
              <AsyncButton action={action} outline size="sm" color="danger" onClick={deleteAnyway}>
                Delete
              </AsyncButton>
            </ButtonToolbar>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
}

export default DeleteConfirmModal;
