import reduceReducers from 'reduce-reducers';
import { initializeActions } from '@tmosystems/core';

// Reducers
import baseReducer from './baseReducer';
import customReducer from './customReducer';

// Action creators
import {
  // Async action creators
  IMAGES__CREATE_IMAGE,
  IMAGES__GET_IMAGE,
  IMAGES__UPDATE_IMAGE,
  IMAGES__DELETE_IMAGE,
  IMAGES__GET_MORE_IMAGES,
  IMAGES__CREATE_CATEGORY,
  IMAGES__GET_CATEGORY,
  IMAGES__DELETE_CATEGORY,
} from './actionCreators';

const initialState = {
  images: {
    instance: {},
    instances: [],
    selected: [],
    selectedIndices: [],
    modalMeta: {
      isOpen: false,
      openMode: 'create',
    },
    tableMeta: {
      page: 0,
      rowsPerPage: 10,
    },
    scrollMeta: {
      page: 0,
      size: 24,
      noMoreInstances: false,
    },
    searchMeta: {
      searchTerm: '',
    },
  },
  categories: {
    instance: {},
    instances: [],
    selected: [],
    selectedIndices: [],
    modalMeta: {
      isOpen: false,
      openMode: 'create',
    },
    tableMeta: {
      page: 0,
      rowsPerPage: 10,
    },
    scrollMeta: {
      page: 0,
      size: 24,
      noMoreInstances: false,
    },
    searchMeta: {
      searchTerm: '',
    },
  },
  selectedCategory: -1,
  actions: initializeActions([
    IMAGES__CREATE_IMAGE,
    IMAGES__GET_IMAGE,
    IMAGES__UPDATE_IMAGE,
    IMAGES__DELETE_IMAGE,
    IMAGES__GET_MORE_IMAGES,
    IMAGES__CREATE_CATEGORY,
    IMAGES__GET_CATEGORY,
    IMAGES__DELETE_CATEGORY,
  ]),
};

export default reduceReducers(initialState, baseReducer, customReducer);
