import React from 'react';
import PropTypes from 'prop-types';

// Components
import {
  Row, Col, ButtonToolbar, Button,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt, faArrowRight, faUsers } from '@fortawesome/pro-light-svg-icons';
import Modal from '../../../../shared/components/Modal';
import AsyncButton from '../../../../shared/components/AsyncButton';
import ModalHeader from '../../../../shared/components/ModalHeader';
import ModalSidebar from '../../../../shared/components/ModalSidebar';
import ModalContent from '../../../../shared/components/ModalContent';
import ModalFooter from '../../../../shared/components/ModalFooter';

const getRecipientCount = recipients => recipients
  // eslint-disable-next-line no-underscore-dangle
  .reduce((sum, recipient) => sum + (recipient.data._source.size || 1), 0);

const ConfirmTextMessageModal = ({
  isOpen,
  action,
  form,

  // Callbacks
  toggle,
  onConfirm,
}) => (
  <Modal
    open={isOpen}
    noPadding
    toggle={toggle}
    width="xl"
    centered
  >
    <ModalHeader
      title="Confirm message"

      // Callbacks
      onClose={toggle}
    />
    <Row noGutters>
      <Col xs={4}>
        <ModalSidebar>
          <div className="text-center p-4 pt-5">
            <div className="mb-3">
              <FontAwesomeIcon icon={faCommentAlt} size="3x" />
              <FontAwesomeIcon icon={faArrowRight} size="2x" className="mx-3" />
              <FontAwesomeIcon icon={faUsers} size="3x" />
            </div>
            <h3>Confirm</h3>
            <p>
              Once sent this message cannot be deleted from its recipient&#39;s device.
            </p>
          </div>
        </ModalSidebar>
      </Col>
      <Col>
        <ModalContent>
          {form.values.recipients && (
            <div className="pb-5">
              <h4>Please confirm that you want to send this message</h4>
              <textarea
                readOnly
                className="w-100 border rounded p-3"
                value={form.values.body}
                rows={10}
                style={{ resize: 'none' }}
              />
              <p className="text-black-50">
                This message will be sent to {getRecipientCount(form.values.recipients)}{' '}
                recipient{getRecipientCount(form.values.recipients) === 1 ? '' : 's'}.
              </p>
              <p className="text-black-50">
                Cost: £{(getRecipientCount(form.values.recipients) * 0.031).toFixed(2)}
              </p>
            </div>
          )}
          <ModalFooter>
            <ButtonToolbar className="justify-content-end">
              <Button
                outline
                size="sm"
                onClick={toggle}
              >
                Cancel
              </Button>
              <AsyncButton
                outline
                size="sm"
                color="primary"
                onClick={onConfirm}
                action={action}
              >
                Send
              </AsyncButton>
            </ButtonToolbar>
          </ModalFooter>
        </ModalContent>
      </Col>
    </Row>
  </Modal>
);

ConfirmTextMessageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  action: PropTypes.instanceOf(Object).isRequired,
  form: PropTypes.instanceOf(Object),

  // Callbacks
  toggle: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

ConfirmTextMessageModal.defaultProps = {
  form: {
    values: {
      recipients: [],
    },
  },
};

export default ConfirmTextMessageModal;
