import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { squashString } from 'ggtmo-utils';

// Components
import TableCell from '@material-ui/core/TableCell';
import MatTable from '../../../shared/components/table/MatTable';

const tableColumns = [
  {
    id: 'name', label: 'Resident name', disablePadding: false, numeric: false, align: 'left',
  },
  {
    id: 'type', label: 'Repair type', disablePadding: false, numeric: false, align: 'left',
  },
  {
    id: 'status', label: 'Status', disablePadding: false, numeric: false, align: 'left',
  },
  {
    id: 'dateTimeSubmitted', label: 'Date submitted', disablePadding: false, numeric: false, align: 'left',
  },
];

const TableRow = ({
  resident,
  repairType,
  status,
  dateTimeSubmitted,
}) => (
  <>
    <TableCell className="material-table__cell">{squashString(resident.user.name)}</TableCell>
    <TableCell className="material-table__cell">{squashString(repairType.name)}</TableCell>
    <TableCell className="material-table__cell">
      {status === 'open' && (
        <small className="pill-primary p-1 rounded">
          Open
        </small>
      )}
      {status === 'closed' && (
        <small className="pill-danger p-1 rounded">
          Closed
        </small>
      )}
    </TableCell>
    <TableCell className="material-table__cell">{moment(dateTimeSubmitted).format('DD/MM/YYYY')}</TableCell>
  </>
);

TableRow.propTypes = {
  resident: PropTypes.instanceOf(Object).isRequired,
  repairType: PropTypes.instanceOf(Object).isRequired,
  status: PropTypes.string.isRequired,
  dateTimeSubmitted: PropTypes.string.isRequired,
};

const RepairsTable = ({
  children,
  requests,
  selected,

  // Callbacks
  onSelectByRow,
}) => (
  <div>
    <MatTable
      rowComponent={TableRow}
      columns={tableColumns}
      data={requests}
      selected={selected}
      canSelect={false}
      selectOnlyByCheckbox

      // Callbacks
      onSelectByRow={onSelectByRow}
    >
      {children}
    </MatTable>
  </div>
);

RepairsTable.propTypes = {
  children: PropTypes.node,
  requests: PropTypes.instanceOf(Array).isRequired,
  selected: PropTypes.instanceOf(Array).isRequired,

  // Callbacks
  onSelectByRow: PropTypes.func.isRequired,
};

RepairsTable.defaultProps = {
  children: null,
};

export default RepairsTable;
