import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field, reduxForm } from 'redux-form';
import { wrapper } from 'ggtmo-utils';
import { toast } from 'react-toastify';

// Components
import { Button, ButtonToolbar } from 'reactstrap';
import Modal from '../../../shared/components/Modal';
import AsyncButton from '../../../shared/components/AsyncButton';
import TextField from '../../../shared/components/form/TextField';

// Redux
import { updateDocument } from '../redux/actions';
import { DOCUMENTS_UPDATE_DOCUMENT } from '../redux/actionCreators';

class EditDocumentModal extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,

    // Actions
    updateDocument: PropTypes.func.isRequired,

    // Documents
    documents: PropTypes.instanceOf(Array).isRequired,
    selectedDocuments: PropTypes.instanceOf(Array).isRequired,

    // Form
    editDocumentForm: PropTypes.instanceOf(Object).isRequired,
    reset: PropTypes.func.isRequired,

    // Callbacks
    toggle: PropTypes.func.isRequired,
  };

  static defaultProps = {
    open: false,
  };

  handleOnSubmit = (event) => {
    event.preventDefault();
    const {
      updateDocument: update, editDocumentForm, toggle, documents, selectedDocuments: [id],
    } = this.props;
    const [document] = documents.filter(d => d.id === id);
    update({
      name: editDocumentForm.values.name,
      id,
    })
      .then((action) => {
        if (action.type === DOCUMENTS_UPDATE_DOCUMENT.SUCCESS) {
          toast(`Successfully edited ${document.type === '1' ? 'file' : 'folder'}.`, {
            type: toast.TYPE.SUCCESS,
          });
          toggle();
        }
      });
  };

  handleOnClosed = () => {
    const { reset } = this.props;
    reset();
  };

  render() {
    // Props
    const {
      open,

      // Documents
      documents,
      selectedDocuments: [id],

      // Callbacks
      toggle,
    } = this.props;

    let document = {};
    if (id) {
      [document] = documents.filter(d => d.id === id);
    }
    return (
      <Modal
        open={open}
        onClosed={this.handleOnClosed}

        // Callbacks
        toggle={toggle}
      >
        <h3 className="page-title">
          Edit {document.type === '1' ? 'file' : 'folder'}
        </h3>
        <Form className="material-form mt-4 text-left" onSubmit={this.handleOnSubmit}>
          <div className="form__form-group">
            <span className="form__form-group-label">Name</span>
            <Field
              className="material-form__field"
              name="name"
              component={TextField}
            />
          </div>
          <ButtonToolbar className="justify-content-end">
            <Button onClick={toggle} size="sm">Cancel</Button>
            <AsyncButton size="sm" color="primary" type="submit">Edit</AsyncButton>
          </ButtonToolbar>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    documents,
    selectedDocuments,
  } = state.documents;

  const {
    editDocumentForm,
  } = state.form;

  return {
    // Documents
    documents,
    selectedDocuments,

    // Form
    editDocumentForm,
  };
};

const mapDispatchToProps = {
  updateDocument,
};

const withForm = {
  form: 'editDocumentForm',
};

export default wrapper({
  component: EditDocumentModal,
  wrappers: [
    reduxForm(withForm),
    connect(mapStateToProps, mapDispatchToProps),
  ],
});
