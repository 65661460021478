import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import {
  Row, Col, ButtonToolbar, Button,
} from 'reactstrap';
import AsyncButton from '../../../shared/components/AsyncButton';
import Modal from '../../../shared/components/Modal';
import ModalHeader from '../../../shared/components/ModalHeader';
import ModalSidebar from '../../../shared/components/ModalSidebar';
import ModalContent from '../../../shared/components/ModalContent';
import ModalFooter from '../../../shared/components/ModalFooter';
import Sidebar from './ImagesSidebar';
import Gallery from './ImageGallery';
import ImageSearch from './ImageSearch';
import UploadModal from './UploadModal';

// PropTypes
import { modalPropTypes, modalDefaultProps } from '../../../shared/prop-types/modalPropTypes';

// Redux
import {
  imagesGetImage,
  imagesSelectImage,
  imagesUpdateImageScrollMeta,
} from '../redux/actions';

class ImagesModal extends PureComponent {
  static propTypes = {
    ...modalPropTypes,
    action: PropTypes.instanceOf(Object),
    asyncAddButton: PropTypes.bool,
    selected: PropTypes.instanceOf(Array).isRequired,
    maxCanSelect: PropTypes.number,
    buttonText: PropTypes.string,

    // Form
    editorForm: PropTypes.instanceOf(Object).isRequired,

    // Redux
    imagesGetImage: PropTypes.func.isRequired,
    imagesSelectImage: PropTypes.func.isRequired,
    imagesUpdateImageScrollMeta: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ...modalDefaultProps,
    action: { loading: false },
    asyncAddButton: false,
    buttonText: null,
    maxCanSelect: undefined,
  };

  handleOnClosed = () => {
    const { imagesSelectImage: selectImage, imagesUpdateImageScrollMeta: updateImageScrollMeta } = this.props;
    selectImage({ selected: [] });
    updateImageScrollMeta({ page: 0, noMoreInstances: false });
  };

  handleGetImage = (requestParams) => {
    const { imagesGetImage: getImage, editorForm } = this.props;
    if (editorForm && editorForm.values && editorForm.values.imageSet) {
      const idsToExclude = editorForm.values.imageSet.images.map(image => image.id);
      return getImage({ ...requestParams, exclude: idsToExclude });
    }
    return getImage({ ...requestParams });
  };

  renderAddButton = () => {
    // Props
    const {
      action,
      asyncAddButton,
      selected,
      maxCanSelect,
      buttonText,

      // Callbacks
      onAdd,
    } = this.props;

    if (asyncAddButton) {
      return (
        <AsyncButton
          size="sm"
          outline
          color="primary"
          className="m-0"
          onClick={onAdd}
          action={action}
          disabled={(maxCanSelect && selected.length > maxCanSelect) || selected.length === 0}
        >
          {buttonText || `Add image${selected.length <= 1 ? '' : 's'}`}
        </AsyncButton>
      );
    }
    return (
      <Button
        size="sm"
        outline
        color="primary"
        className="m-0"
        onClick={onAdd}
        disabled={(maxCanSelect && selected.length > maxCanSelect) || selected.length === 0}
      >
        Add image{selected.length <= 1 ? '' : 's'}
      </Button>
    );
  };

  render() {
    // Props
    const {
      isOpen,

      // Callbacks
      toggle,
    } = this.props;

    return (
      <>
        <Modal
          open={isOpen}
          width="xxl"
          noPadding
          centered
          rounded

          // Callbacks
          toggle={toggle}
          onClosed={this.handleOnClosed}
        >
          <ModalHeader
            title="Choose images"

            // Callbacks
            onClose={toggle}
          />
          <Row noGutters>
            <Col lg={3} xl={2}>
              <ModalSidebar>
                <Sidebar
                  // Callbacks
                  getImage={this.handleGetImage}
                />
              </ModalSidebar>
            </Col>
            <Col>
              <ModalContent>
                <ImageSearch />
                <Gallery
                  // Callbacks
                  getImage={this.handleGetImage}
                />
                <ModalFooter className="border-top">
                  <ButtonToolbar className="justify-content-end">
                    {this.renderAddButton()}
                  </ButtonToolbar>
                </ModalFooter>
              </ModalContent>
            </Col>
          </Row>
        </Modal>

        {/* Modals */}
        <UploadModal inModal />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    editorForm,
  } = state.form;

  return {
    // Form
    editorForm,
  };
};

const mapDispatchToProps = {
  imagesGetImage,
  imagesSelectImage,
  imagesUpdateImageScrollMeta,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImagesModal);
