import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import Input from '.';

class ReduxFormInput extends PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,

    // Callbacks
    onChange: PropTypes.func.isRequired,
  };

  handleOnChange = (event) => {
    const { onChange } = this.props;
    const { value } = event.target;
    onChange(value);
  };

  render() {
    // Props
    const {
      value,
      ...rest
    } = this.props;

    return (
      <Input
        value={value}
        {...rest}

        // Callbacks
        onChange={this.handleOnChange}
      />
    );
  }
}

const renderReduxFormInput = ({ input, ...rest }) => (
  <ReduxFormInput {...input} {...rest} />
);

renderReduxFormInput.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
};

export default renderReduxFormInput;
