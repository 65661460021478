import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight, faUser, faTimes, faPlus, faCheck,
} from '@fortawesome/pro-light-svg-icons';
import ContactsList from './ContactsList';
import StylessButton from '../../../../shared/components/StylelessButton';
import CreateContactModal from './ContactModal';
import ContactSearchBar from './ContactSearchBar';
import { ContactIcon, ContactsListItem, ContactsListItemAdded } from './styled';

// Redux
import { communicationsGetContact, communicationsToggleContactModal } from '../../redux/actions';

class ContactPickerField extends PureComponent {
  static propTypes = {
    value: PropTypes.instanceOf(Array),

    // Redux
    contacts: PropTypes.instanceOf(Array).isRequired,
    communicationsGetContact: PropTypes.func.isRequired,
    communicationsToggleContactModal: PropTypes.func.isRequired,

    // Callbacks
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: [],
  };

  componentDidMount() {
    const { communicationsGetContact: getContact } = this.props;
    getContact({ list: true });
  }

  handleGetContactSuggestions = () => {};

  handleOnChange = (event) => {
    const { onChange, value, contacts } = this.props;
    const { id } = event.currentTarget.dataset;
    onChange([
      ...value,
      contacts.instances.filter(contact => contact.id === parseInt(id, 10))[0],
    ]);
  };

  handleOnToggleCreateContactModal = (event) => {
    event.preventDefault();
    const { communicationsToggleContactModal: toggleContactModal } = this.props;
    toggleContactModal({ openMode: 'create' });
  };

  handleOnSearchSuccess = () => {};

  handleOnSearchBarCleared = () => {};

  renderContactListAddComponent = (contact) => {
    // Props
    const {
      value,
    } = this.props;
    const alreadyAdded = (value || []).map(f => f.id).includes(contact.id);

    if (alreadyAdded) {
      return (
        <ContactsListItemAdded
          className="border-bottom justify-content-between"
          onClick={this.handleOnChange}
          data-id={contact.id}
        >
          <div className="d-flex">
            <ContactIcon>
              <FontAwesomeIcon icon={faUser} />
            </ContactIcon>
            <div>
              <span className="mb-0 d-block text-nowrap">
                {contact.name}
              </span>
              <span className="text-black-50 text-nowrap">
                {contact.mobileNumber}
              </span>
            </div>
          </div>
          <div style={{ lineHeight: '36px' }}>
            Successfully added
            <FontAwesomeIcon icon={faCheck} className="ml-2" />
          </div>
        </ContactsListItemAdded>
      );
    }
    return (
      <ContactsListItem
        className="border-bottom justify-content-between"
        onClick={this.handleOnChange}
        data-id={contact.id}
      >
        <div className="d-flex">
          <ContactIcon>
            <FontAwesomeIcon icon={faUser} />
          </ContactIcon>
          <div>
            <span className="mb-0 d-block text-nowrap">
              {contact.name}
            </span>
            <span className="text-black-50 text-nowrap">
              {contact.mobileNumber}
            </span>
          </div>
        </div>
        <div style={{ lineHeight: '36px' }}>
          Add to group
          <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
        </div>
      </ContactsListItem>
    );
  };

  renderContactListRemoveComponent = contact => (
    <ContactsListItem
      className="border-bottom justify-content-between"
      onClick={this.handleOnChange}
      data-id={contact.id}
    >
      <div className="d-flex">
        <ContactIcon>
          <FontAwesomeIcon icon={faUser} />
        </ContactIcon>
        <div>
          <span className="mb-0 d-block text-nowrap">
            {contact.name}
          </span>
          <span className="text-black-50 text-nowrap">
            {contact.mobileNumber}
          </span>
        </div>
      </div>
      <div className="text-black-50" style={{ lineHeight: '36px' }}>
        <FontAwesomeIcon icon={faTimes} className="mr-2" />
        Remove from group
      </div>
    </ContactsListItem>
  );

  render() {
    // Props
    const {
      value,

      // Redux
      contacts,
    } = this.props;

    return (
      <>
        <Row>
          <Col className="border-right">
            <div className="d-flex justify-content-between mb-3">
              <h4>
                Existing contacts
              </h4>
              <StylessButton
                onClick={this.handleOnToggleCreateContactModal}
                color="primary"
                className="text-primary"
              >
                <FontAwesomeIcon icon={faPlus} className="mr-1" />
                create new contact
              </StylessButton>
            </div>
            <div className="pb-3">
              <ContactSearchBar
                // Callbacks
                onSearchSuccess={this.handleOnSearchSuccess}
                onSearchBarCleared={this.handleOnSearchBarCleared}
              />
            </div>
            <ContactsList
              contactListItemComponent={this.renderContactListAddComponent}
              contacts={contacts.instances}
            />
          </Col>
          <Col>
            <h4 className="mb-3">Contacts in group</h4>
            <ContactsList
              contactListItemComponent={this.renderContactListRemoveComponent}
              contacts={value || []}
            />
          </Col>
        </Row>

        {/* Modals */}
        <CreateContactModal
          isOpen={contacts.createModalOpen}

          // Callbacks
          toggle={this.handleOnToggleCreateContactModal}
        />
      </>
    );
  }
}

const renderContactPickerField = ({ input, ...rest }) => (
  <ContactPickerField {...input} {...rest} />
);

renderContactPickerField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.instanceOf(Array),
    onChange: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = (state) => {
  const {
    contacts,
  } = state.communications;

  return {
    // Communications
    contacts,
  };
};

const mapDispatchToProps = {
  communicationsGetContact,
  communicationsToggleContactModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(renderContactPickerField);
