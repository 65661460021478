/* eslint-disable max-len */
import { generateAction as generate, reduxTypes as types } from '@tmosystems/core';
import service from '../service';

// Action creators
import {
  // Async action creators
  NEWS__CREATE_STORY,
  NEWS__CREATE_AND_PUBLISH_STORY,
  NEWS__GET_STORY,
  NEWS__UPDATE_STORY,
  NEWS__UPDATE_AND_PUBLISH_STORY,
  NEWS__PUBLISH_STORY,
  NEWS__UNPUBLISH_STORY,
  NEWS__DELETE_STORY,
  NEWS__PIN_STORY,

  // Sync action creators
  NEWS__SELECT_STORY,
  NEWS__UPDATE_STORY_TABLE_META,
  NEWS__REINITIALIZE_STORY_STATE,
} from './actionCreators';

// Async actions
export const newsCreateStory = generate(NEWS__CREATE_STORY, {
  type: types.ASYNC,
  service: service.POST.createStory,
});
export const newsCreateAndPublishStory = generate(NEWS__CREATE_AND_PUBLISH_STORY, {
  type: types.ASYNC,
  service: service.POST.createAndPublishStory,
});
export const newsGetStory = generate(NEWS__GET_STORY, {
  type: types.ASYNC,
  service: service.GET.getStory,
});
export const newsUpdateStory = generate(NEWS__UPDATE_STORY, {
  type: types.ASYNC,
  service: service.PUT.updateStory,
});
export const newsUpdateAndPublishStory = generate(NEWS__UPDATE_AND_PUBLISH_STORY, {
  type: types.ASYNC,
  service: service.PUT.updateAndPublishStory,
});
export const newsPublishStory = generate(NEWS__PUBLISH_STORY, {
  type: types.ASYNC,
  service: service.PUT.publishStory,
});
export const newsUnpublishStory = generate(NEWS__UNPUBLISH_STORY, {
  type: types.ASYNC,
  service: service.PUT.unpublishStory,
});
export const newsDeleteStory = generate(NEWS__DELETE_STORY, {
  type: types.ASYNC,
  service: service.DELETE.deleteStory,
});
export const newsPinStory = generate(NEWS__PIN_STORY, {
  type: types.ASYNC,
  service: service.PUT.updateStory,
});
// Sync actions
export const newsSelectStory = generate(NEWS__SELECT_STORY, { type: types.SYNC });
export const newsUpdateStoryTableMeta = generate(NEWS__UPDATE_STORY_TABLE_META, { type: types.SYNC });
export const newsReinitializeStoryState = generate(NEWS__REINITIALIZE_STORY_STATE, { type: types.SYNC });
