import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import { Input as DefaultInput, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird';
import StylelessButton from './StylelessButton';

const Wrapper = styled.div`
  border: 2px solid ${({ theme, hovered }) => (hovered ? theme.color.primary : 'transparent')};
  transition: all 100ms;
  
  &:hover {
    cursor: pointer;
  }
`;

const Input = styled(DefaultInput)`
  display: inline-block;
  outline: none;
  border-radius: 0;
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.30);
  padding: 0;
  margin: 0;
  font-size: 14px;
  
  &:focus {
    outline: none;
    box-shadow: none;
    border-bottom: 2px solid ${({ theme }) => theme.color.primary};
  }
`;

const ActionButton = styled(StylelessButton)`
  padding: 6px 8.75px;
  font-size: 18px;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0 , 0.04);
  }
`;

class EditableText extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    updateAction: PropTypes.instanceOf(Object).isRequired,
    tagName: PropTypes.string,

    // Callbacks
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
  };

  static defaultProps = {
    tagName: 'p',

    // Callbacks
    onCancel: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
      editing: false,
      value: props.value,
      initialValue: props.value,
    };
  }

  handleOnMouseEnter = () => {
    this.setState({
      hovered: true,
    });
  };

  handleOnMouseLeave = () => {
    this.setState({
      hovered: false,
    });
  };

  handleOnEdit = () => {
    this.setState({
      editing: true,
    });
  };

  handleOnChange = (event) => {
    const { value } = event.target;
    this.setState({
      value,
    });
  };

  handleOnSubmit = () => {
    const { value } = this.state;
    const { onSubmit } = this.props;
    onSubmit(value)
      .then(() => {
        this.setState({
          // TODO: Check this for usability.
          hovered: false,
          editing: false,
        });
      });
  };

  handleOnCancel = () => {
    const { onCancel } = this.props;
    this.setState(prevState => ({
      hovered: false,
      editing: false,
      value: prevState.initialValue,
    }), onCancel);
  };

  renderEditingMode = () => {
    // State
    const {
      value,
    } = this.state;

    // Props
    const {
      updateAction: { loading },
      tagName,
    } = this.props;

    return (
      <div className="d-flex">
        <h1>
          <Input
            value={value}
            style={{
              fontSize: {
                h1: '24px',
                h2: '',
                h3: '',
                h4: '',
                h5: '',
                p: '',
              }[tagName],
              height: 36,
            }}

            // Callbacks
            onChange={this.handleOnChange}
          />
        </h1>
        <div>
          <ActionButton onClick={this.handleOnSubmit}>
            <FontAwesomeIcon icon={loading ? faSpinnerThird : faCheck} spin={loading} />
          </ActionButton>
          <ActionButton onClick={this.handleOnCancel} disabled={loading}>
            <FontAwesomeIcon icon={faTimes} />
          </ActionButton>
        </div>
      </div>
    );
  };

  render() {
    // State
    const {
      hovered,
      editing,
      value,
    } = this.state;

    // Props
    const {
      tagName,
    } = this.props;

    if (editing) {
      return this.renderEditingMode();
    }
    const Tag = tagName;
    return (
      <>
        <Wrapper
          id="text"
          className="d-flex rounded px-2"
          hovered={hovered}

          // Callbacks
          onMouseEnter={this.handleOnMouseEnter}
          onMouseLeave={this.handleOnMouseLeave}
          onClick={this.handleOnEdit}
        >
          <Tag>
            {value}
          </Tag>
        </Wrapper>

        {/* Tooltips */}
        <UncontrolledTooltip target="text" placement="right">
          Click to edit name
        </UncontrolledTooltip>
      </>
    );
  }
}

export default EditableText;
