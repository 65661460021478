import { generateAction as generate, reduxTypes as types } from 'ggtmo-utils';
import service from '../service';

// Action creators
import {
  DOCUMENTS_GET_DOCUMENT,
  DOCUMENTS_CREATE_DOCUMENT,
  DOCUMENTS_UPDATE_DOCUMENT,
  DOCUMENTS_DELETE_DOCUMENT,
  DOCUMENTS_SELECT_DOCUMENT,
  DOCUMENTS_TOGGLE_CREATE_FILE_MODAL,
  DOCUMENTS_TOGGLE_CREATE_FOLDER_MODAL,
  DOCUMENTS_TOGGLE_EDIT_DOCUMENT_MODAL,
  DOCUMENTS_CHANGE_PAGE,
  DOCUMENTS_TOGGLE_DOCUMENTS_MODAL,
} from './actionCreators';


export const getDocument = generate(DOCUMENTS_GET_DOCUMENT, {
  type: types.ASYNC,
  service: service.GET.document,
});


export const createDocument = generate(DOCUMENTS_CREATE_DOCUMENT, {
  type: types.ASYNC,
  service: service.POST.document,
});


export const updateDocument = generate(DOCUMENTS_UPDATE_DOCUMENT, {
  type: types.ASYNC,
  service: service.PUT.document,
});


export const deleteDocument = generate(DOCUMENTS_DELETE_DOCUMENT, {
  type: types.ASYNC,
  service: service.DELETE.document,
});


export const selectDocument = generate(DOCUMENTS_SELECT_DOCUMENT, {
  type: types.SYNC,
});


export const toggleCreateFileModal = generate(DOCUMENTS_TOGGLE_CREATE_FILE_MODAL, {
  type: types.SYNC,
});


export const toggleCreateFolderModal = generate(DOCUMENTS_TOGGLE_CREATE_FOLDER_MODAL, {
  type: types.SYNC,
});


export const toggleEditDocumentModal = generate(DOCUMENTS_TOGGLE_EDIT_DOCUMENT_MODAL, {
  type: types.SYNC,
});


export const changePage = generate(DOCUMENTS_CHANGE_PAGE, {
  type: types.SYNC,
});


export const toggleDocumentsModal = generate(DOCUMENTS_TOGGLE_DOCUMENTS_MODAL, {
  type: types.SYNC,
});
