import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col,
} from 'reactstrap';


const ProfileMain = ({ profile }) => (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody className="profile__card shadow">
        <div className="profile__information">
          <div className="profile__data">
            <p className="profile__name">{profile.name}</p>
            <p className="profile__contact">{profile.email}</p>
          </div>
        </div>
      </CardBody>
    </Card>
  </Col>
);

ProfileMain.propTypes = {
  profile: PropTypes.instanceOf(Object).isRequired,
};

export default ProfileMain;
