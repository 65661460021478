import { create } from 'axios';
import { createAxiosInstance } from '@tmosystems/core';

const axios = createAxiosInstance(create, { baseURL: '/private/communications/api/v1/' });

// POST
const createTextMessage = requestData => axios.post('text_message/', requestData);
const createEmailMessage = requestData => axios.post('email_message/', requestData);
const createContact = requestData => axios.post('contact/', requestData);
const createContactGroup = requestData => axios.post('contact_group/', requestData);
const createReceivedMessageLabel = requestData => axios.post('received_message_label/', requestData);

// GET
const getTextMessage = requestParams => axios.get('text_message/', { params: requestParams });
const getEmailMessage = requestParams => axios.get('email_message/', { params: requestParams });
const getContact = requestParams => axios.get('contact/', { params: requestParams });
const getContactGroup = requestParams => axios.get('contact_group/', { params: requestParams });
const getRecipientSuggestion = requestParams => axios.get('recipient_suggestion/', { params: requestParams });
const getReceivedMessage = requestParams => axios.get('received_message/', { params: requestParams });
const getReceivedMessageLabel = requestParams => axios.get('received_message_label/', { params: requestParams });

// PUT
const updateContact = requestData => axios.put('contact/', requestData);
const updateContactGroup = requestData => axios.put('contact_group/', requestData);
const updateReceivedMessageLabel = requestData => axios.put('received_message_label/', requestData);

// DELETE
const deleteContact = requestData => axios.delete('contact/', { data: requestData });
const deleteContactGroup = requestData => axios.delete('contact_group/', { data: requestData });
const deleteReceivedMessageLabel = requestData => axios.delete('received_message_label/', { data: requestData });

export default {
  POST: {
    textMessage: createTextMessage,
    emailMessage: createEmailMessage,
    contact: createContact,
    contactGroup: createContactGroup,
    receivedMessageLabel: createReceivedMessageLabel,
  },
  GET: {
    textMessage: getTextMessage,
    emailMessage: getEmailMessage,
    contact: getContact,
    contactGroup: getContactGroup,
    recipientSuggestion: getRecipientSuggestion,
    receivedMessage: getReceivedMessage,
    receivedMessageLabel: getReceivedMessageLabel,
  },
  PUT: {
    contact: updateContact,
    contactGroup: updateContactGroup,
    receivedMessageLabel: updateReceivedMessageLabel,
  },
  DELETE: {
    contact: deleteContact,
    contactGroup: deleteContactGroup,
    receivedMessageLabel: deleteReceivedMessageLabel,
  },
};
