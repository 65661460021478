import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Image from './Image';
import UploadImage from '../../sections/components/UploadImage';
import ImagesModal from './ImagesModal';

// Redux
import { imagesUpdateImageModalMeta, imagesSelectImage } from '../redux/actions';

class ImageField extends PureComponent {
  static propTypes = {
    uploadImageClassName: PropTypes.string,
    imageClassName: PropTypes.string,

    // Form
    value: PropTypes.instanceOf(Object).isRequired,
    onChange: PropTypes.func.isRequired,

    // Redux
    imagesUpdateImageModalMeta: PropTypes.func.isRequired,
    images: PropTypes.instanceOf(Array).isRequired,
  };

  static defaultProps = {
    uploadImageClassName: '',
    imageClassName: '',
  };

  handleOnAdd = () => {
    const { onChange, images } = this.props;
    onChange(images.instances.filter(image => image.id === images.selected[0])[0]);
    this.handleToggleImagesModal();
  };

  handleToggleImagesModal = () => {
    const { imagesUpdateImageModalMeta: updateModalMeta } = this.props;
    updateModalMeta({ toggle: true });
  };

  render() {
    // Props
    const {
      uploadImageClassName,
      imageClassName,

      // Form
      value,

      // Images
      images: {
        modalMeta,
        selected,
      },
    } = this.props;

    return (
      <>
        {value && Object.keys(value) !== 0 ? (
          <Image
            className={imageClassName}
            {...value}
          />
        ) : (
          <UploadImage
            className={uploadImageClassName}

            // Callbacks
            toggle={this.handleToggleImagesModal}
          />
        )}

        {/* Modals */}
        <ImagesModal
          {...modalMeta}
          selected={selected}
          maxCanSelect={1}

          // Callbacks
          toggle={this.handleToggleImagesModal}
          onAdd={this.handleOnAdd}
        />
      </>
    );
  }
}

const renderImageField = ({ input, ...rest }) => (
  <ImageField {...input} {...rest} />
);

renderImageField.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = (state) => {
  const {
    images,
  } = state.images;

  return {
    // Images
    images,
  };
};

const mapDispatchToProps = {
  imagesUpdateImageModalMeta,
  imagesSelectImage,
};

export default connect(mapStateToProps, mapDispatchToProps)(renderImageField);
