/* eslint-disable max-len */
import { generateAction as generate, reduxTypes as types } from '@tmosystems/core';
import service from '../service';

// Action creators
import {
  // Async action creators
  REPAIRS__GET_REQUEST,
  REPAIRS__UPDATE_REQUEST,
  REPAIRS__DELETE_REQUEST,
  REPAIRS__CREATE_CATEGORY,
  REPAIRS__GET_CATEGORY,
  REPAIRS__UPDATE_CATEGORY,
  REPAIRS__DELETE_CATEGORY,
  REPAIRS__CREATE_TYPE,
  REPAIRS__GET_TYPE,
  REPAIRS__UPDATE_TYPE,
  REPAIRS__DELETE_TYPE,

  // Sync action creators
  REPAIRS__UPDATE_REQUEST_TABLE_META,
  REPAIRS__UPDATE_CATEGORY_MODAL_META,
  REPAIRS__UPDATE_CATEGORY_TABLE_META,
  REPAIRS__UPDATE_TYPE_MODAL_META,
  REPAIRS__UPDATE_TYPE_TABLE_META,
  REPAIRS__UPDATE_TYPE_SEARCH_META,
  REPAIRS__UPDATE_TYPE_STATE,
} from './actionCreators';

// Async actions
export const repairsGetRequest = generate(REPAIRS__GET_REQUEST, {
  type: types.ASYNC,
  service: service.GET.getRequest,
});
export const repairsUpdateRequest = generate(REPAIRS__UPDATE_REQUEST, {
  type: types.ASYNC,
  service: service.PUT.updateRequest,
});
export const repairsDeleteRequest = generate(REPAIRS__DELETE_REQUEST, {
  type: types.ASYNC,
  service: service.DELETE.deleteRequest,
});
export const repairsCreateCategory = generate(REPAIRS__CREATE_CATEGORY, {
  type: types.ASYNC,
  service: service.POST.createCategory,
});
export const repairsGetCategory = generate(REPAIRS__GET_CATEGORY, {
  type: types.ASYNC,
  service: service.GET.getCategory,
});
export const repairsUpdateCategory = generate(REPAIRS__UPDATE_CATEGORY, {
  type: types.ASYNC,
  service: service.PUT.updateCategory,
});
export const repairsDeleteCategory = generate(REPAIRS__DELETE_CATEGORY, {
  type: types.ASYNC,
  service: service.DELETE.deleteCategory,
});
export const repairsCreateType = generate(REPAIRS__CREATE_TYPE, {
  type: types.ASYNC,
  service: service.POST.createType,
});
export const repairsGetType = generate(REPAIRS__GET_TYPE, {
  type: types.ASYNC,
  service: service.GET.getType,
});
export const repairsUpdateType = generate(REPAIRS__UPDATE_TYPE, {
  type: types.ASYNC,
  service: service.PUT.updateType,
});
export const repairsDeleteType = generate(REPAIRS__DELETE_TYPE, {
  type: types.ASYNC,
  service: service.DELETE.deleteType,
});
// Sync actions
export const repairsUpdateRequestTableMeta = generate(REPAIRS__UPDATE_REQUEST_TABLE_META, { type: types.SYNC });
export const repairsUpdateCategoryModalMeta = generate(REPAIRS__UPDATE_CATEGORY_MODAL_META, { type: types.SYNC });
export const repairsUpdateCategoryTableMeta = generate(REPAIRS__UPDATE_CATEGORY_TABLE_META, { type: types.SYNC });
export const repairsUpdateTypeModalMeta = generate(REPAIRS__UPDATE_TYPE_MODAL_META, { type: types.SYNC });
export const repairsUpdateTypeTableMeta = generate(REPAIRS__UPDATE_TYPE_TABLE_META, { type: types.SYNC });
export const repairsUpdateTypeSearchMeta = generate(REPAIRS__UPDATE_TYPE_SEARCH_META, { type: types.SYNC });
export const repairsUpdateTypeState = generate(REPAIRS__UPDATE_TYPE_STATE, { type: types.SYNC });
