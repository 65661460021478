import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Container, Card, CardBody } from 'reactstrap';

// Images
import fourOFourImage from '../img/404.png';
import errorImage from '../img/error.png';

const EditorError = ({
  instanceType,
  action,
}) => (
  <Container>
    <Card>
      <CardBody>
        <div className="text-center py-5">
          {action.error.response.status === 404 && (
            <>
              <h3>We couldn&#39;t find that {instanceType}.</h3>
              <img src={fourOFourImage} alt="" className="w-25 mt-5" />
            </>
          )}
          {action.error.response.status >= 500 && (
            <>
              <h3>There was a server error whilst finding this {instanceType}.</h3>
              <img src={errorImage} alt="" className="w-25 mt-5" />
            </>
          )}
        </div>
      </CardBody>
    </Card>
  </Container>
);

EditorError.propTypes = {
  action: PropTypes.instanceOf(Object).isRequired,
  instanceType: PropTypes.string.isRequired,
};

export default EditorError;
