import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { wrapper } from 'ggtmo-utils';

// Components
import { Col, Container, Row } from 'reactstrap';
import ProfileMain from './components/ProfileMain';
import ProfileTabs from './components/ProfileTabs';

// Redux
import {
  getProfile,
  updateProfile,
} from '../redux/actions';
import {
  ACCOUNT_UPDATE_PROFILE,
} from '../redux/actionCreators';


class Profile extends PureComponent {
  static propTypes = {
    // Redux
    dispatch: PropTypes.func.isRequired,
    profile: PropTypes.instanceOf(Object).isRequired,

    // Form
    profileForm: PropTypes.instanceOf(Object).isRequired,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getProfile());
  }

  updateProfile = (event) => {
    const { dispatch, profileForm } = this.props;

    event.preventDefault();

    dispatch(updateProfile(profileForm.values))
      .then(({ type }) => {
        if (type === ACCOUNT_UPDATE_PROFILE.SUCCESS) {
          toast('Successfully updated your profile.', {
            type: toast.TYPE.SUCCESS,
          });
        }
        if (type === ACCOUNT_UPDATE_PROFILE.ERROR) {
          toast('There was an error updating your profile. Please try again later or contact support.', {
            type: toast.TYPE.ERROR,
          });
        }
      });
  };

  render() {
    // Props
    const {
      profile,
    } = this.props;

    return (
      <Container>
        <div className="profile">
          <Row>
            <Col md={12} lg={12} xl={4}>
              <Row>
                <ProfileMain profile={profile} />
              </Row>
            </Col>
            <ProfileTabs
              // Settings
              updateProfile={this.updateProfile}
              profile={profile}
            />
          </Row>
        </div>
      </Container>
    );
  }
}


const mapStateToProps = (state) => {
  const { profile } = state.account;
  const { profileForm } = state.form;

  return {
    profile,
    profileForm,
  };
};

export default wrapper({
  component: Profile,
  wrappers: [
    connect(mapStateToProps),
  ],
});
