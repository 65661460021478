import React from 'react';
import PropTypes from 'prop-types';

// Components
import { ButtonToolbar, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit } from '@fortawesome/pro-light-svg-icons';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';


const Toolbar = ({
  selected,

  // Callbacks
  onCreate,
  onEdit,
  onDelete,
}) => (
  <div className="mb-4">
    <ButtonToolbar>
      <Button
        size="sm"
        className="btn btn-primary rounded"
        disabled={selected.length > 0}
        onClick={onCreate}
      >
        <FontAwesomeIcon icon={faPlus} style={{ marginRight: 2 }} fixedWidth />Create
      </Button>
      <Button
        size="sm"
        className="btn btn-secondary rounded"
        disabled={selected.length !== 1}
        onClick={onEdit}
      >
        <FontAwesomeIcon icon={faEdit} fixedWidth /> Edit
      </Button>
      <Button
        size="sm"
        className="btn btn-secondary rounded"
        disabled={selected.length === 0}
        onClick={onDelete}
      >
        <FontAwesomeIcon icon={faTrash} fixedWidth /> Delete
      </Button>
    </ButtonToolbar>
  </div>
);

Toolbar.propTypes = {
  selected: PropTypes.instanceOf(Array).isRequired,

  // Callbacks
  onCreate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Toolbar;
