import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import StylelessButton from './StylelessButton';

const ModalHeaderStyled = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  padding: 1rem 2rem;
  color: white;
  text-align: left;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  display: flex;
  justify-content: space-between;
  
  h4 {
    color: white;
  }
`;

const ModalHeader = ({
  title,
  isStatic,

  // Callbacks
  onHelp,
  onClose,
}) => (
  <ModalHeaderStyled>
    <h4>
      {title}
    </h4>
    <div style={{ height: 24 }}>
      <StylelessButton onClick={onHelp} className="mr-3">
        <FontAwesomeIcon color="white" style={{ fontSize: '1.5rem' }} icon={faQuestionCircle} />
      </StylelessButton>
      {isStatic ? null : (
        <StylelessButton onClick={onClose}>
          <FontAwesomeIcon color="white" style={{ fontSize: '1.5rem' }} icon={faTimesCircle} />
        </StylelessButton>
      )}
    </div>
  </ModalHeaderStyled>
);

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  isStatic: PropTypes.bool,

  // Callbacks
  onHelp: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

ModalHeader.defaultProps = {
  isStatic: false,
};

export default ModalHeader;
