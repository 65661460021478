/* eslint-disable max-len */
import { generateActionCreator as generate, reduxTypes as types } from '@tmosystems/core';

// Async action creators
export const IMAGES__CREATE_IMAGE = generate('IMAGES__CREATE_IMAGE', { type: types.ASYNC });
export const IMAGES__GET_IMAGE = generate('IMAGES__GET_IMAGE', { type: types.ASYNC });
export const IMAGES__UPDATE_IMAGE = generate('IMAGES__UPDATE_IMAGE', { type: types.ASYNC });
export const IMAGES__DELETE_IMAGE = generate('IMAGES__DELETE_IMAGE', { type: types.ASYNC });
export const IMAGES__GET_MORE_IMAGES = generate('IMAGES__GET_MORE_IMAGES', { type: types.ASYNC });
export const IMAGES__CREATE_CATEGORY = generate('IMAGES__CREATE_CATEGORY', { type: types.ASYNC });
export const IMAGES__GET_CATEGORY = generate('IMAGES__GET_CATEGORY', { type: types.ASYNC });
export const IMAGES__DELETE_CATEGORY = generate('IMAGES__DELETE_CATEGORY', { type: types.ASYNC });

// Sync actions creators
export const IMAGES__SELECT_IMAGE = generate('IMAGES__SELECT_IMAGE', { type: types.SYNC });
export const IMAGES__UPDATE_IMAGE_MODAL_META = generate('IMAGES__UPDATE_IMAGE_MODAL_META', { type: types.SYNC });
export const IMAGES__UPDATE_IMAGE_TABLE_META = generate('IMAGES__UPDATE_IMAGE_TABLE_META', { type: types.SYNC });
export const IMAGES__UPDATE_IMAGE_SCROLL_META = generate('IMAGES__UPDATE_IMAGE_SCROLL_META', { type: types.SYNC });
export const IMAGES__UPDATE_IMAGE_SEARCH_META = generate('IMAGES__UPDATE_IMAGE_SEARCH_META', { type: types.SYNC });
export const IMAGES__REINITIALIZE_IMAGE_STATE = generate('IMAGES__REINITIALIZE_IMAGE_STATE', { type: types.SYNC });
export const IMAGES__SELECT_CATEGORY = generate('IMAGES__SELECT_CATEGORY', { type: types.SYNC });
