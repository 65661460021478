/* eslint-disable max-len */
import { generateActionCreator as generate, reduxTypes as types } from '@tmosystems/core';

// Async action creators
export const SECTIONS__CREATE_SECTION = generate('SECTIONS__CREATE_SECTION', { type: types.ASYNC });
export const SECTIONS__CREATE_AND_PUBLISH_SECTION = generate('SECTIONS__CREATE_AND_PUBLISH_SECTION', { type: types.ASYNC });
export const SECTIONS__GET_SECTION = generate('SECTIONS__GET_SECTION', { type: types.ASYNC });
export const SECTIONS__UPDATE_SECTION = generate('SECTIONS__UPDATE_SECTION', { type: types.ASYNC });
export const SECTIONS__UPDATE_AND_PUBLISH_SECTION = generate('SECTIONS__UPDATE_AND_PUBLISH_SECTION', { type: types.ASYNC });
export const SECTIONS__PUBLISH_SECTION = generate('SECTIONS__PUBLISH_SECTION', { type: types.ASYNC });
export const SECTIONS__UNPUBLISH_SECTION = generate('SECTIONS__UNPUBLISH_SECTION', { type: types.ASYNC });
export const SECTIONS__DELETE_SECTION = generate('SECTIONS__DELETE_SECTION', { type: types.ASYNC });
export const SECTIONS__GET_PAGE = generate('SECTIONS__GET_PAGE', { type: types.ASYNC });
export const SECTIONS__UPDATE_PAGE = generate('SECTIONS__UPDATE_PAGE', { type: types.ASYNC });
export const SECTIONS__UPDATE_AND_PUBLISH_PAGE = generate('SECTIONS__UPDATE_AND_PUBLISH_PAGE', { type: types.ASYNC });
export const SECTIONS__CREATE_NOTICE = generate('SECTIONS__CREATE_NOTICE', { type: types.ASYNC });
export const SECTIONS__CREATE_AND_PUBLISH_NOTICE = generate('SECTIONS__CREATE_AND_PUBLISH_NOTICE', { type: types.ASYNC });
export const SECTIONS__GET_NOTICE = generate('SECTIONS__GET_NOTICE', { type: types.ASYNC });
export const SECTIONS__UPDATE_NOTICE = generate('SECTIONS__UPDATE_NOTICE', { type: types.ASYNC });
export const SECTIONS__UPDATE_AND_PUBLISH_NOTICE = generate('SECTIONS__UPDATE_AND_PUBLISH_NOTICE', { type: types.ASYNC });
export const SECTIONS__PUBLISH_NOTICE = generate('SECTIONS__PUBLISH_NOTICE', { type: types.ASYNC });
export const SECTIONS__UNPUBLISH_NOTICE = generate('SECTIONS__UNPUBLISH_NOTICE', { type: types.ASYNC });
export const SECTIONS__DELETE_NOTICE = generate('SECTIONS__DELETE_NOTICE', { type: types.ASYNC });

// Sync actions creators
export const SECTIONS__SELECT_SECTION = generate('SECTIONS__SELECT_SECTION', { type: types.SYNC });
export const SECTIONS__UPDATE_SECTION_MODAL_META = generate('SECTIONS__UPDATE_SECTION_MODAL_META', { type: types.SYNC });
export const SECTIONS__UPDATE_SECTION_TABLE_META = generate('SECTIONS__UPDATE_SECTION_TABLE_META', { type: types.SYNC });
export const SECTIONS__UPDATE_SECTION_SEARCH_META = generate('SECTIONS__UPDATE_SECTION_SEARCH_META', { type: types.SYNC });
export const SECTIONS__REINITIALIZE_SECTION_STATE = generate('SECTIONS__REINITIALIZE_SECTION_STATE', { type: types.SYNC });
export const SECTIONS__UPDATE_NOTICE_MODAL_META = generate('SECTIONS__UPDATE_NOTICE_MODAL_META', { type: types.SYNC });
export const SECTIONS__UPDATE_KEY = generate('SECTIONS__UPDATE_KEY', { type: types.SYNC });
