import { create } from 'axios';
import { createAxiosInstance } from '@tmosystems/core';

const axios = createAxiosInstance(create, { baseURL: '/private/repairs/api/v1' });

// POST
const createCategory = requestData => axios.post('/category/', requestData);
const createType = requestData => axios.post('/type/', requestData);

// GET
const getRequest = requestParams => axios.get('/request/', { params: requestParams });
const getCategory = requestParams => axios.get('/category/', { params: requestParams });
const getType = requestParams => axios.get('/type/', { params: requestParams });

// PUT
const updateRequest = requestData => axios.put('/request/', requestData);
const updateCategory = requestData => axios.put('/category/', requestData);
const updateType = requestData => axios.put('/type/', requestData);

// DELETE
const deleteRequest = requestData => axios.delete('/request/', { data: requestData });
const deleteCategory = requestData => axios.delete('/category/', { data: requestData });
const deleteType = requestData => axios.delete('/type/', { data: requestData });

export default {
  POST: {
    createCategory,
    createType,
  },
  GET: {
    getRequest,
    getCategory,
    getType,
  },
  PUT: {
    updateRequest,
    updateCategory,
    updateType,
  },
  DELETE: {
    deleteRequest,
    deleteCategory,
    deleteType,
  },
};
