import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import { Label, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { ConnectedImageSetField } from '../../../apps/images/components/ImageSetField';
import { CheckBoxField as CheckBox } from './CheckBox';

class OptionalImageSetField extends Component {
  static propTypes = {
    value: PropTypes.instanceOf(Object).isRequired,

    // Callbacks
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  handleOnShowChange = (event) => {
    this.setState({
      show: event.target.checked,
    });
  };

  render() {
    // State
    const {
      show: showInternal,
    } = this.state;

    // Props
    const {
      onChange,
      value,
    } = this.props;

    const show = showInternal || (value.images && value.images.length !== 0);

    return (
      <>
        <div
          className={classNames('d-flex p-2', { rounded: !show })}
          style={{ border: show ? '1px solid transparent' : '1px solid #CCCCCC' }}
        >
          <CheckBox
            setOnDidMount={false}
            id="showTimeSelect"
            value={show}
            onChange={this.handleOnShowChange}
          />
          <Label for="showTimeSelect" className="mb-0">
            Images{' '}
            {!show && (
              <>
                <FontAwesomeIcon icon={faQuestionCircle} fixedWidth id="documents-add-help" />
                <UncontrolledTooltip target="documents-add-help">
                  Tick this option to add images to this section.
                </UncontrolledTooltip>
              </>
            )}
          </Label>
        </div>
        {show && (
          <ConnectedImageSetField
            value={value}

            // Callbacks
            onChange={onChange}
          />
        )}
      </>
    );
  }
}

const renderOptionalImageSetField = ({ input, ...rest }) => (
  <OptionalImageSetField {...input} {...rest} />
);

renderOptionalImageSetField.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
};

export default renderOptionalImageSetField;
