import reduceReducers from 'reduce-reducers';
import { initializeActions } from '@tmosystems/core';

// Reducers
import baseReducer from './baseReducer';
import customReducer from './customReducer';

// Action creators
import {
  // Async action creators
  SECTIONS__CREATE_SECTION,
  SECTIONS__CREATE_AND_PUBLISH_SECTION,
  SECTIONS__GET_SECTION,
  SECTIONS__UPDATE_SECTION,
  SECTIONS__UPDATE_AND_PUBLISH_SECTION,
  SECTIONS__PUBLISH_SECTION,
  SECTIONS__UNPUBLISH_SECTION,
  SECTIONS__DELETE_SECTION,
  SECTIONS__GET_PAGE,
  SECTIONS__UPDATE_PAGE,
  SECTIONS__UPDATE_AND_PUBLISH_PAGE,
  SECTIONS__CREATE_NOTICE,
  SECTIONS__CREATE_AND_PUBLISH_NOTICE,
  SECTIONS__GET_NOTICE,
  SECTIONS__UPDATE_NOTICE,
  SECTIONS__UPDATE_AND_PUBLISH_NOTICE,
  SECTIONS__PUBLISH_NOTICE,
  SECTIONS__UNPUBLISH_NOTICE,
  SECTIONS__DELETE_NOTICE,
} from './actionCreators';

export const initialState = {
  sections: {
    instance: {},
    instances: [],
    selected: [],
    selectedIndices: [],
    modalMeta: {
      isOpen: false,
      openMode: 'create',
    },
    tableMeta: {
      page: 0,
      rowsPerPage: 10,
    },
    scrollMeta: {
      page: 0,
      size: 24,
      noMoreInstances: false,
    },
    searchMeta: {
      searchTerm: '',
    },
  },
  pages: {
    instance: {},
    instances: [],
    selected: [],
    selectedIndices: [],
    modalMeta: {
      isOpen: false,
      openMode: 'create',
    },
    tableMeta: {
      page: 0,
      rowsPerPage: 10,
    },
    scrollMeta: {
      page: 0,
      size: 24,
      noMoreInstances: false,
    },
    searchMeta: {
      searchTerm: '',
    },
  },
  notices: {
    instance: {},
    instances: [],
    selected: [],
    selectedIndices: [],
    modalMeta: {
      isOpen: false,
      openMode: 'create',
    },
    tableMeta: {
      page: 0,
      rowsPerPage: 10,
    },
    scrollMeta: {
      page: 0,
      size: 24,
      noMoreInstances: false,
    },
    searchMeta: {
      searchTerm: '',
    },
  },
  selectedCategory: -1,
  actions: initializeActions([
    SECTIONS__CREATE_SECTION,
    SECTIONS__CREATE_AND_PUBLISH_SECTION,
    SECTIONS__GET_SECTION,
    SECTIONS__UPDATE_SECTION,
    SECTIONS__UPDATE_AND_PUBLISH_SECTION,
    SECTIONS__PUBLISH_SECTION,
    SECTIONS__UNPUBLISH_SECTION,
    SECTIONS__DELETE_SECTION,
    SECTIONS__GET_PAGE,
    SECTIONS__UPDATE_PAGE,
    SECTIONS__UPDATE_AND_PUBLISH_PAGE,
    SECTIONS__CREATE_NOTICE,
    SECTIONS__CREATE_AND_PUBLISH_NOTICE,
    SECTIONS__GET_NOTICE,
    SECTIONS__UPDATE_NOTICE,
    SECTIONS__UPDATE_AND_PUBLISH_NOTICE,
    SECTIONS__PUBLISH_NOTICE,
    SECTIONS__UNPUBLISH_NOTICE,
    SECTIONS__DELETE_NOTICE,
  ]),
};

export default reduceReducers(initialState, baseReducer, customReducer);
