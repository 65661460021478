/* eslint-disable max-len */
import { generateActionCreator as generate, reduxTypes as types } from '@tmosystems/core';

// Async action creators
export const EVENTS__CREATE_EVENT = generate('EVENTS__CREATE_EVENT', { type: types.ASYNC });
export const EVENTS__CREATE_AND_PUBLISH_EVENT = generate('EVENTS__CREATE_AND_PUBLISH_EVENT', { type: types.ASYNC });
export const EVENTS__GET_EVENT = generate('EVENTS__GET_EVENT', { type: types.ASYNC });
export const EVENTS__UPDATE_EVENT = generate('EVENTS__UPDATE_EVENT', { type: types.ASYNC });
export const EVENTS__UPDATE_AND_PUBLISH_EVENT = generate('EVENTS__UPDATE_AND_PUBLISH_EVENT', { type: types.ASYNC });
export const EVENTS__PUBLISH_EVENT = generate('EVENTS__PUBLISH_EVENT', { type: types.ASYNC });
export const EVENTS__UNPUBLISH_EVENT = generate('EVENTS__UNPUBLISH_EVENT', { type: types.ASYNC });
export const EVENTS__DELETE_EVENT = generate('EVENTS__DELETE_EVENT', { type: types.ASYNC });
export const EVENTS__CONVERT_EVENT_TO_STORY = generate('EVENTS__CONVERT_EVENT_TO_STORY', { type: types.ASYNC });

// Sync actions creators
export const EVENTS__SELECT_EVENT = generate('EVENTS__SELECT_EVENT', { type: types.SYNC });
export const EVENTS__UPDATE_EVENT_TABLE_META = generate('EVENTS__UPDATE_EVENT_TABLE_META', { type: types.SYNC });
export const EVENTS__REINITIALIZE_EVENT_STATE = generate('EVENTS__REINITIALIZE_EVENT_STATE', { type: types.SYNC });
