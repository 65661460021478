import { generateReducerElement as generate, reduxTypes as types, adjust } from '@tmosystems/core';

// Action creators
import {
  // Async action creators
  SECTIONS__CREATE_SECTION,
  SECTIONS__CREATE_AND_PUBLISH_SECTION,
  SECTIONS__GET_SECTION,
  SECTIONS__UPDATE_SECTION,
  SECTIONS__UPDATE_AND_PUBLISH_SECTION,
  SECTIONS__PUBLISH_SECTION,
  SECTIONS__UNPUBLISH_SECTION,
  SECTIONS__DELETE_SECTION,
  SECTIONS__GET_PAGE,
  SECTIONS__UPDATE_PAGE,
  SECTIONS__UPDATE_AND_PUBLISH_PAGE,
  SECTIONS__GET_NOTICE,
  SECTIONS__PUBLISH_NOTICE,
  SECTIONS__UNPUBLISH_NOTICE,

  // Sync action creators
  SECTIONS__SELECT_SECTION,
  SECTIONS__UPDATE_SECTION_MODAL_META,
  SECTIONS__UPDATE_SECTION_TABLE_META,
  SECTIONS__UPDATE_SECTION_SEARCH_META,
  SECTIONS__REINITIALIZE_SECTION_STATE,
  SECTIONS__UPDATE_NOTICE_MODAL_META,
} from './actionCreators';

import { initialState } from './reducer';

export default (state, action) => ({
  // Async
  ...generate(state, action, SECTIONS__CREATE_SECTION, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, SECTIONS__CREATE_SECTION.SUCCESS) && {
        sections: {
          ...state.sections,
          instance: action.response.instance,
          instances: [
            ...state.sections.instances,
            action.response.instance,
          ],
        },
      },
    },
  }),
  ...generate(state, action, SECTIONS__CREATE_AND_PUBLISH_SECTION, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, SECTIONS__CREATE_AND_PUBLISH_SECTION.SUCCESS) && {
        sections: {
          ...state.sections,
          instance: action.response.instance,
          instances: [
            ...state.sections.instances,
            action.response.instance,
          ],
        },
      },
    },
  }),
  ...generate(state, action, SECTIONS__GET_SECTION, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, SECTIONS__GET_SECTION.SUCCESS) && {
        sections: action.response.append ? {
          ...state.sections,
          instance: action.response.instance || state.sections.instance,
          instances: [
            ...state.sections.instances,
            ...action.response.instances,
          ],
          count: action.response.count,
        } : {
          ...state.sections,
          instance: action.response.instance || state.sections.instance,
          instances: action.response.instances || state.sections.instances,
          count: action.response.count,
        },
      },
    },
  }),
  ...generate(state, action, SECTIONS__UPDATE_SECTION, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, SECTIONS__UPDATE_SECTION.SUCCESS) && {
        sections: {
          ...state.sections,
          instance: action.response.instance,
          instances: state.sections.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.sections.selected,
        },
      },
    },
  }),
  ...generate(state, action, SECTIONS__UPDATE_AND_PUBLISH_SECTION, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, SECTIONS__UPDATE_AND_PUBLISH_SECTION.SUCCESS) && {
        sections: {
          ...state.sections,
          instance: action.response.instance,
          instances: state.sections.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.sections.selected,
        },
      },
    },
  }),
  ...generate(state, action, SECTIONS__PUBLISH_SECTION, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, SECTIONS__PUBLISH_SECTION.SUCCESS) && {
        sections: {
          ...state.sections,
          instance: action.response.instance,
          instances: state.sections.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.sections.selected,
        },
      },
    },
  }),
  ...generate(state, action, SECTIONS__UNPUBLISH_SECTION, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, SECTIONS__UNPUBLISH_SECTION.SUCCESS) && {
        sections: {
          ...state.sections,
          instance: action.response.instance,
          instances: state.sections.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.sections.selected,
        },
      },
    },
  }),
  ...generate(state, action, SECTIONS__DELETE_SECTION, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, SECTIONS__DELETE_SECTION.SUCCESS) && {
        sections: {
          ...state.sections,
          instance: state.sections.instance,
          instances: state.sections.instances
            .filter(instance => !action.response.deletedIds.includes(instance.id)),
          selected: initialState.sections.selected,
        },
      },
    },
  }),
  ...generate(state, action, SECTIONS__GET_PAGE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, SECTIONS__GET_PAGE.SUCCESS) && {
        pages: action.response.append ? {
          ...state.pages,
          instance: action.response.instance || state.pages.instance,
          instances: [
            ...state.pages.instances,
            ...action.response.instances,
          ],
          count: action.response.count,
        } : {
          ...state.pages,
          instance: action.response.instance || state.pages.instance,
          instances: action.response.instances || state.pages.instances,
          count: action.response.count,
        },
      },
    },
  }),
  ...generate(state, action, SECTIONS__UPDATE_PAGE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, SECTIONS__UPDATE_PAGE.SUCCESS) && {
        pages: {
          ...state.pages,
          instance: action.response.instance,
          instances: state.pages.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.pages.selected,
        },
      },
    },
  }),
  ...generate(state, action, SECTIONS__UPDATE_AND_PUBLISH_PAGE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, SECTIONS__UPDATE_AND_PUBLISH_PAGE.SUCCESS) && {
        pages: {
          ...state.pages,
          instance: action.response.instance,
          instances: state.pages.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.pages.selected,
        },
      },
    },
  }),
  ...generate(state, action, SECTIONS__GET_NOTICE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, SECTIONS__GET_NOTICE.SUCCESS) && {
        notices: action.response.append ? {
          ...state.notices,
          instance: action.response.instance || state.notices.instance,
          instances: [
            ...state.notices.instances,
            ...action.response.instances,
          ],
          count: action.response.count,
        } : {
          ...state.notices,
          instance: action.response.instance || state.notices.instance,
          instances: action.response.instances || state.notices.instances,
          count: action.response.count,
        },
      },
    },
  }),
  ...generate(state, action, SECTIONS__PUBLISH_NOTICE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, SECTIONS__PUBLISH_NOTICE.SUCCESS) && {
        notices: {
          ...state.notices,
          instance: action.response.instance,
          instances: state.notices.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.notices.selected,
        },
      },
    },
  }),
  ...generate(state, action, SECTIONS__UNPUBLISH_NOTICE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, SECTIONS__UNPUBLISH_NOTICE.SUCCESS) && {
        notices: {
          ...state.notices,
          instance: action.response.instance,
          instances: state.notices.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.notices.selected,
        },
      },
    },
  }),

  // Sync
  ...generate(state, action, SECTIONS__SELECT_SECTION, {
    type: types.SYNC,
    adjustment: {
      sections: {
        ...state.sections,
        selected: action.selected,
        selectedIndices: action.selectedIndices,
      },
    },
  }),
  ...generate(state, action, SECTIONS__UPDATE_SECTION_MODAL_META, {
    type: types.SYNC,
    adjustment: {
      sections: {
        ...state.sections,
        modalMeta: {
          isOpen: (() => {
            if (action.toggle) {
              return !state.sections.modalMeta.isOpen;
            }
            return action.isOpen;
          })(),
          openMode: action.openMode,
          ...action.state,
        },
      },
    },
  }),
  ...generate(state, action, SECTIONS__UPDATE_SECTION_TABLE_META, {
    type: types.SYNC,
    adjustment: {
      sections: {
        ...state.sections,
        tableMeta: {
          ...state.sections.tableMeta,
          ...action,
        },
      },
    },
  }),
  ...generate(state, action, SECTIONS__UPDATE_SECTION_SEARCH_META, {
    type: types.SYNC,
    adjustment: {
      sections: {
        ...state.sections,
        searchMeta: {
          ...state.sections.searchMeta,
          ...action,
        },
      },
    },
  }),
  ...generate(state, action, SECTIONS__REINITIALIZE_SECTION_STATE, {
    type: types.SYNC,
    adjustment: {
      sections: initialState.sections,
    },
  }),
  ...generate(state, action, SECTIONS__UPDATE_NOTICE_MODAL_META, {
    type: types.SYNC,
    adjustment: {
      notices: {
        ...state.notices,
        modalMeta: {
          isOpen: (() => {
            if (action.toggle) {
              return !state.notices.modalMeta.isOpen;
            }
            return action.isOpen;
          })(),
          openMode: action.openMode,
          ...action.state,
        },
      },
    },
  }),
}[action.type] || state);
