import React, { PureComponent } from 'react';
import { reduxForm, Form, Field } from 'redux-form';

// Components
import TextField from '../../../../shared/components/form/TextField';

class ResidentAccountRequestForm extends PureComponent {
  handleOnSubmit = (event) => {
    event.preventDefault();
  };

  render() {
    return (
      <Form className="material-form form--horizontal" onSubmit={this.handleOnSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Name</span>
          <Field
            className="material-form__field"
            name="name"
            component={TextField}
          />
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">E-Mail</span>
          <Field
            className="material-form__field"
            name="email"
            component={TextField}
          />
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Address</span>
          <Field
            className="material-form__field"
            name="address"
            component={TextField}
          />
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Rent Account Number</span>
          <Field
            className="material-form__field"
            name="rentAccountNumber"
            component={TextField}
          />
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Mobile Number</span>
          <Field
            className="material-form__field"
            name="mobileNumber"
            component={TextField}
          />
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Home Phone Number</span>
          <Field
            className="material-form__field"
            name="homePhoneNumber"
            component={TextField}
          />
        </div>
      </Form>
    );
  }
}

const withForm = {
  form: 'residentAccountRequestForm',
};

export default reduxForm(withForm)(ResidentAccountRequestForm);
