import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import {
  Row, Col, Button,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faUserPlus,
} from '@fortawesome/pro-light-svg-icons';
import ContactsTable from './ContactsTable';
import ContactGroups from './ContactGroups';
import CreateContactModal from './ContactModal';
import StylelessButton from '../../../../shared/components/StylelessButton';
import AsyncComponent from '../../../../shared/components/AsyncComponent';

// Actions
import {
  communicationsGetContact,
  communicationsToggleContactModal,
  communicationsGetContactGroup,
  communicationsToggleContactGroupModal,
  communicationsUpdateContactTableMeta,
} from '../../redux/actions';
import {
  COMMUNICATIONS_GET_CONTACT,
  COMMUNICATIONS_GET_CONTACT_GROUP,
} from '../../redux/actionCreators';

class Contacts extends PureComponent {
  static propTypes = {
    // Communications
    communicationsActions: PropTypes.instanceOf(Object).isRequired,
    contactGroups: PropTypes.instanceOf(Array).isRequired,
    contactGroupId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    contacts: PropTypes.instanceOf(Object).isRequired,

    // Actions
    communicationsGetContact: PropTypes.func.isRequired,
    communicationsToggleContactModal: PropTypes.func.isRequired,
    communicationsGetContactGroup: PropTypes.func.isRequired,
    communicationsUpdateContactTableMeta: PropTypes.func.isRequired,

    // Callbacks
    toggleCreateContactGroupModal: PropTypes.func.isRequired,
    toggleUpdateContactGroupModal: PropTypes.func.isRequired,
    setViewingAll: PropTypes.func.isRequired,
    setViewType: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {
      communicationsGetContactGroup: getContactGroup, contactGroupId, setViewingAll, setViewType,
    } = this.props;
    getContactGroup({ list: false, id: contactGroupId, includeContacts: true })
      .then((action) => {
        if (action.type === COMMUNICATIONS_GET_CONTACT_GROUP.SUCCESS) {
          setViewingAll(contactGroupId === 'all');
          setViewType('none');
        }
      });
  }

  handleToggleCreateContactModal = () => {
    const { communicationsToggleContactModal: toggleContactModal } = this.props;
    toggleContactModal({ openMode: 'create' });
  };

  handleOnEditContact = (event) => {
    const {
      communicationsGetContact: getContact,
      communicationsToggleContactModal: toggleContactModal,
    } = this.props;
    event.preventDefault();
    const { id } = event.currentTarget.dataset;
    getContact({ list: false, id: parseInt(id, 10) })
      .then((action) => {
        if (action.type === COMMUNICATIONS_GET_CONTACT.SUCCESS) {
          toggleContactModal({ openMode: 'update' });
        }
      });
  };

  handleContactGroupOnClick = (event) => {
    const {
      communicationsGetContact: getContact,
      communicationsGetContactGroup: getContactGroup,
      communicationsUpdateContactTableMeta: updateTableMeta,
    } = this.props;
    const { slug } = event.currentTarget.dataset;
    getContactGroup({ list: false, slug, includeContacts: false })
      .then((action) => {
        if (action.type === COMMUNICATIONS_GET_CONTACT_GROUP.SUCCESS) {
          updateTableMeta({ page: 0 });
        }
      });
    getContact({ list: true, contactGroupSlug: slug });
  };

  handleContactsTableOnPageChange = (page) => {
    const {
      communicationsGetContact: getContact,
      communicationsUpdateContactTableMeta: updateTableMeta,
      contactGroups,
    } = this.props;
    getContact({ list: true, contactGroupSlug: contactGroups.instance.slug, page })
      .then((action) => {
        if (action.type === COMMUNICATIONS_GET_CONTACT.SUCCESS) {
          updateTableMeta({ page });
        }
      });
  };

  render() {
    // Props
    const {
      // Communications
      communicationsActions: {
        COMMUNICATIONS_GET_CONTACT_GROUP: getContactGroupAction,
      },
      contactGroups,
      contacts,

      // Callbacks
      toggleCreateContactGroupModal,
      toggleUpdateContactGroupModal,
    } = this.props;

    return (
      <>
        <Row>
          <Col xs={2} className="border-right">
            <Button className="w-100" outline color="primary" onClick={this.handleToggleCreateContactModal}>
              <h4 className="text-primary">
                <FontAwesomeIcon icon={faUserPlus} className="mr-1" />New Contact
              </h4>
            </Button>
            <ContactGroups
              contactGroups={contactGroups}

              // Callbacks
              contactGroupOnClick={this.handleContactGroupOnClick}
              toggleCreateContactGroupModal={toggleCreateContactGroupModal}
            />
          </Col>
          <Col>
            <AsyncComponent action={getContactGroupAction}>
              <div>
                <div>
                  <h3 className="mb-0">{contactGroups.instance.name}</h3>
                  <p className="m-0 text-black-50">
                    This group has {contactGroups.instance.contactsCount} member
                    {contactGroups.instance.contactsCount === 1 ? '' : 's'}
                  </p>
                </div>
                <div className="mt-3">
                  <ContactsTable
                    instances={contacts.instances}
                    count={contactGroups.instance.contactsCount}
                    {...contacts.tableMeta}

                    // Callbacks
                    onPageChange={this.handleContactsTableOnPageChange}
                    onEdit={this.handleOnEditContact}
                  >
                    <div className="editor__no-instances my-5">
                      <h5 className="mb-2">This contact group is empty.</h5>
                      <StylelessButton onClick={toggleUpdateContactGroupModal}>
                        <h4><FontAwesomeIcon icon={faPlus} className="mr-1" />Add contacts</h4>
                      </StylelessButton>
                    </div>
                  </ContactsTable>
                </div>
              </div>
            </AsyncComponent>
          </Col>
        </Row>

        {/* Modals */}
        <CreateContactModal />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    actions: communicationsActions,
    contacts,
    contactGroups,
    contactGroupId,
  } = state.communications;

  return {
    // Communications
    communicationsActions,
    contacts,
    contactGroups,
    contactGroupId,
  };
};

const mapDispatchToProps = {
  communicationsGetContact,
  communicationsToggleContactModal,
  communicationsGetContactGroup,
  communicationsToggleContactGroupModal,
  communicationsUpdateContactTableMeta,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
