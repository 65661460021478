/* eslint-disable max-len */
import { generateActionCreator as generate, reduxTypes as types } from 'ggtmo-utils';


export const DOCUMENTS_GET_DOCUMENT = generate('DOCUMENTS_GET_DOCUMENT', { type: types.ASYNC });
export const DOCUMENTS_CREATE_DOCUMENT = generate('DOCUMENTS_CREATE_DOCUMENT', { type: types.ASYNC });
export const DOCUMENTS_UPDATE_DOCUMENT = generate('DOCUMENTS_UPDATE_DOCUMENT', { type: types.ASYNC });
export const DOCUMENTS_DELETE_DOCUMENT = generate('DOCUMENTS_DELETE_DOCUMENT', { type: types.ASYNC });
export const DOCUMENTS_SELECT_DOCUMENT = generate('DOCUMENTS_SELECT_DOCUMENT', { type: types.SYNC });
export const DOCUMENTS_TOGGLE_CREATE_FILE_MODAL = generate('DOCUMENTS_TOGGLE_CREATE_FILE_MODAL', { type: types.SYNC });
export const DOCUMENTS_TOGGLE_CREATE_FOLDER_MODAL = generate('DOCUMENTS_TOGGLE_CREATE_FOLDER_MODAL', { type: types.SYNC });
export const DOCUMENTS_TOGGLE_EDIT_DOCUMENT_MODAL = generate('DOCUMENTS_TOGGLE_EDIT_DOCUMENT_MODAL', { type: types.SYNC });
export const DOCUMENTS_CHANGE_PAGE = generate('DOCUMENTS_CHANGE_PAGE', { type: types.SYNC });
export const DOCUMENTS_TOGGLE_DOCUMENTS_MODAL = generate('DOCUMENTS_TOGGLE_DOCUMENTS_MODAL', { type: types.SYNC });
