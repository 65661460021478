import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { wrapper } from 'ggtmo-utils';
import { toast } from 'react-toastify';

// Components
import {
  Container, Row, Col, Card, CardBody, UncontrolledTooltip,
} from 'reactstrap';
import ImageGallery from 'react-image-gallery';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import VisualDiff from 'react-visual-diff';
import HtmlDiff from 'htmldiff-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import AsyncButton from '../../../shared/components/AsyncButton';

// Redux
import {
  ADMIN_UPDATE_SUBMISSION_GROUP,
} from '../redux/actionCreators';
import {
  getSubmissionGroup,
  updateSubmissionGroup,
} from '../redux/actions';


class SubmissionView extends PureComponent {
  static propTypes = {
    // Redux
    getSubmissionGroup: PropTypes.func.isRequired,
    submissionGroup: PropTypes.instanceOf(Object).isRequired,
    updateSubmissionGroup: PropTypes.func.isRequired,

    // Router
    match: RouterPropTypes.match.isRequired,
    history: RouterPropTypes.history.isRequired,
  };

  componentDidMount() {
    const { getSubmissionGroup: get, match: { params } } = this.props;

    get({
      list: false,
      id: params.id,
    });
  }

  handleOnRespond = (event) => {
    const { response } = event.currentTarget.dataset;
    const approve = response === 'approve';
    const reject = response === 'reject';
    const { updateSubmissionGroup: put, submissionGroup, history } = this.props;

    put({
      approve,
      reject,
      id: submissionGroup.id,
    })
      .then((action) => {
        if (action.type === ADMIN_UPDATE_SUBMISSION_GROUP.SUCCESS) {
          if (approve) {
            toast('Approved submission', {
              type: toast.TYPE.SUCCESS,
            });
          }
          if (reject) {
            toast('Rejected submission', {
              type: toast.TYPE.ERROR,
            });
          }

          history.push('/admin');
        }
      });
  };

  renderSnapshot = snapshot => (
    <div className="mb-3">
      <p className="mt-5">Images</p>
      <hr />
      <div className="d-flex admin__image-diff">
        {snapshot.imageSet.images.map(image => (
          <img src={image.url.thumbnail} alt="" />
        ))}
      </div>
    </div>
  );

  renderPreview = snapshot => (
    <div className="mb-3">
      <h2 className="mb-3">{snapshot.title}</h2>
      {snapshot.imageSet.images.length !== 0 && (
        <ImageGallery
          items={snapshot.imageSet.images.map(image => ({ ...image.url }))}
          showThumbnails={snapshot.imageSet && snapshot.imageSet.images.length > 1}
          showPlayButton={false}
          showBullets={snapshot.imageSet && snapshot.imageSet.images.length > 1}
        />
      )}
    </div>
  );

  render() {
    const { submissionGroup } = this.props;
    const { snapshot: newSnapshot } = submissionGroup.submissions[0];
    let oldSnapshot;
    if (submissionGroup.submissions.length > 1) {
      // eslint-disable-next-line prefer-destructuring
      ({ snapshot: oldSnapshot } = submissionGroup.submissions[1]);
    }

    const tooltipDefaultProps = {
      placement: 'right',
      width: 500,
    };

    return (
      <Container>
        <Row>
          {submissionGroup.submissions.length > 1 && (
            <Col xl={6}>
              <h3 className="page-title">Changes
                <small id="changes-help" className="ml-1"><FontAwesomeIcon icon={faQuestionCircle} /></small>
              </h3>
              <p className="page-subhead">
                Here are the changes the user made to this instance since the last time it was reviewed.
              </p>
              <UncontrolledTooltip target="changes-help" {...tooltipDefaultProps}>
                <p className="page-subhead mt-0">
                  Content highlighted in a <ins>green box</ins> has been added
                  and content highlighted in a <del>red box</del> has been removed.
                </p>
              </UncontrolledTooltip>
              <Card className="h-auto">
                <CardBody>
                  <p>Title</p>
                  <hr />
                  <h2>
                    <FroalaEditorView
                      model={HtmlDiff.execute(oldSnapshot.title, newSnapshot.title)}
                    />
                  </h2>
                  <VisualDiff
                    left={this.renderSnapshot(oldSnapshot)}
                    right={this.renderSnapshot(newSnapshot)}
                    renderChange={({ type, children }) => (type === 'added' ? (
                      <span className="diffins-img">{children}</span>
                    ) : (
                      <span className="diffdel-img">{children}</span>
                    ))}
                  />
                  <p className="mt-5">Body</p>
                  <hr />
                  <FroalaEditorView
                    model={HtmlDiff.execute(oldSnapshot.body, newSnapshot.body)}
                  />
                </CardBody>
              </Card>
            </Col>
          )}
          <Col xl={6}>
            <div className="d-flex justify-content-between">
              {submissionGroup.submissions.length > 1 ? (
                <div>
                  <h3 className="page-title">Live preview
                    <small id="preview-help" className="ml-1"><FontAwesomeIcon icon={faQuestionCircle} /></small>
                  </h3>
                  <p className="page-subhead">Here is the new version as it will be seen on the public site.</p>
                  <UncontrolledTooltip target="preview-help" {...tooltipDefaultProps}>
                    <p className="page-subhead mt-0">
                      If you approve this instance it will be published and the changes will be visible on the public
                      site.
                    </p>
                  </UncontrolledTooltip>
                </div>
              ) : (
                <div>
                  <h3 className="page-title">Newly created version</h3>
                  <p className="page-subhead">Here is the new version as it will be seen on the public site.</p>
                </div>
              )}
              <div className="text-right">
                <div className="bg-white p-3 rounded shadow">
                  <AsyncButton
                    color="primary"
                    className="rounded-0 mb-0"
                    onClick={this.handleOnRespond}
                    data-response="approve"
                    outline
                    size="sm"
                  >
                    Approve & publish
                  </AsyncButton>
                  <AsyncButton
                    color="danger"
                    className="rounded-0 mb-0"
                    onClick={this.handleOnRespond}
                    data-response="reject"
                    outline
                    size="sm"
                  >
                    Reject
                  </AsyncButton>
                </div>
              </div>
            </div>
            <Card className="h-auto">
              <CardBody className="shadow">
                {this.renderPreview(newSnapshot)}
                <FroalaEditorView
                  model={newSnapshot.body}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}


const mapStateToProps = (state) => {
  const {
    submissionGroup,
  } = state.admin;

  return {
    // Admin
    submissionGroup,
  };
};


const mapDispatchToProps = {
  getSubmissionGroup,
  updateSubmissionGroup,
};

export default wrapper({
  component: SubmissionView,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
  ],
});
