import React from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router';
import { reduxForm } from 'redux-form';
import { wrapper } from 'ggtmo-utils';
import { toast } from 'react-toastify';

// Components
import {
  Container, Row, Col, Card, CardBody, UncontrolledTooltip,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRandom, faSpinner } from '@fortawesome/pro-light-svg-icons';
import EditorForm from './EditorForm';
import AsyncComponent from '../../../shared/components/AsyncComponent';
import EditorControls from '../../../shared/components/EditorControls';
import DeleteConfirmModal from '../../../shared/components/DeleteConfirmModal';
import EditorError from '../../../shared/components/EditorError';

// Utils
import getUserType from '../../../shared/utils/getUserType';

// Redux - editor
import {
  toggleDeleteConfirmModal,
} from '../../../redux/actions/editorActions';

// Redux - events
import {
  // Async
  EVENTS__CREATE_EVENT,
  EVENTS__CREATE_AND_PUBLISH_EVENT,
  EVENTS__UPDATE_EVENT,
  EVENTS__UPDATE_AND_PUBLISH_EVENT,
  EVENTS__DELETE_EVENT,
  EVENTS__PUBLISH_EVENT,
  EVENTS__UNPUBLISH_EVENT,
  EVENTS__CONVERT_EVENT_TO_STORY,
} from '../redux/actionCreators';
import {
  // Async
  eventsGetEvent,
  eventsCreateEvent,
  eventsCreateAndPublishEvent,
  eventsUpdateEvent,
  eventsUpdateAndPublishEvent,
  eventsDeleteEvent,
  eventsPublishEvent,
  eventsUnpublishEvent,
  eventsConvertEventToStory,

  // Sync
  eventsReinitializeEventState,
} from '../redux/actions';

// Redux - admin
import {
  // Async
  ADMIN_CREATE_SUBMISSION,
} from '../../submissions/redux/actionCreators';
import {
  // Async
  createSubmission,
} from '../../submissions/redux/actions';

// Classes
import BaseEditor from '../../../shared/BaseEditor';

class EventEditor extends BaseEditor {
  static propTypes = {
    // Redux
    eventsActions: PropTypes.instanceOf(Object).isRequired,
    events: PropTypes.instanceOf(Object).isRequired,
    mediaModalOpen: PropTypes.bool.isRequired,
    selectedFiles: PropTypes.instanceOf(Array).isRequired,
    images: PropTypes.instanceOf(Array).isRequired,
    deleteConfirmModalOpen: PropTypes.bool.isRequired,

    // Router
    history: RouterPropTypes.history.isRequired,

    // Form
    editorForm: PropTypes.instanceOf(Object),
    change: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    // Form
    editorForm: {},
  };

  actionCreators = {
    create: EVENTS__CREATE_EVENT,
    createAndPublish: EVENTS__CREATE_AND_PUBLISH_EVENT,
    update: EVENTS__UPDATE_EVENT,
    updateAndPublish: EVENTS__UPDATE_AND_PUBLISH_EVENT,
    delete: EVENTS__DELETE_EVENT,
    publish: EVENTS__PUBLISH_EVENT,
    unpublish: EVENTS__UNPUBLISH_EVENT,
    convertToStory: EVENTS__CONVERT_EVENT_TO_STORY,
    submit: ADMIN_CREATE_SUBMISSION,
  };

  appName = 'events';

  modelName = 'event';

  toastMessages = {
    create: {
      SUCCESS: 'Created event.',
      ERROR: 'Error creating event.',
    },
    createAndPublish: {
      SUCCESS: 'Created and published event. Your changes are visible to the public.',
      ERROR: 'Error creating event.',
    },
    update: {
      SUCCESS: 'Saved event.',
      ERROR: 'Error saving event.',
    },
    updateAndPublish: {
      SUCCESS: 'Saved and published event. Your changes are visible to the public.',
      ERROR: 'Error saving event.',
    },
    delete: {
      SUCCESS: 'Deleted event,',
      ERROR: 'Error deleting event.',
    },
    publish: {
      SUCCESS: 'Published event. Your changes are visible to the public.',
      ERROR: 'Error publishing event.',
    },
    unpublish: {
      SUCCESS: 'Unpublished event. It is no longer visible to the public.',
      ERROR: 'Error unpublishing event.',
    },
    submit: {
      SUCCESS: 'Submitted for review. You will be notified if it is approved for publish.',
      ERROR: 'Error submitting for review.',
    },
  };

  handleOnConvertToStory = () => {
    const { convertToStory: convert, events, history } = this.props;
    convert({ id: events.instance.id })
      .then((action) => {
        if (action.type === EVENTS__CONVERT_EVENT_TO_STORY.SUCCESS) {
          history.push(`/news/editor/${action.response.story.id}`);
          toast('Successfully converted event to a story', {
            type: toast.TYPE.SUCCESS,
          });
        }
      });
  };

  renderCustomPills = () => {
    // Props
    const {
      eventsActions: {
        EVENTS__CONVERT_EVENT_TO_STORY: convertToStoryAction,
      },
      events,
    } = this.props;

    return events.instance.isLive ? (
      <>
        <span
          role="presentation"
          id="convert-to-story"
          className="editor__controls__pin-btn shadow-sm hover-pointer"
          onClick={this.handleOnConvertToStory}
        >
          <FontAwesomeIcon
            icon={convertToStoryAction.loading ? faSpinner : faRandom}
            fixedWidth
            spin={convertToStoryAction.loading}
          />
          {' '}Convert to story
        </span>
        <UncontrolledTooltip target="convert-to-story">
          Unpublish this event and create a new story with the title and images copied over.
        </UncontrolledTooltip>
      </>
    ) : null;
  };

  render() {
    // Props
    const {
      eventsActions: {
        EVENTS__GET_EVENT: getEventAction,
        EVENTS__CREATE_EVENT: createEventAction,
        EVENTS__CREATE_AND_PUBLISH_EVENT: createAndPublishEventAction,
        EVENTS__UPDATE_EVENT: updateEventAction,
        EVENTS__UPDATE_AND_PUBLISH_EVENT: updateAndPublishEventAction,
        EVENTS__DELETE_EVENT: deleteEventAction,
        EVENTS__PUBLISH_EVENT: publishEventAction,
        EVENTS__UNPUBLISH_EVENT: unpublishEventAction,
      },
      events,
      pristine,
      dirty,
      match: { params },
      deleteConfirmModalOpen,
    } = this.props;

    if (getEventAction.error) {
      return (
        <EditorError
          instanceType="event"
          action={getEventAction}
        />
      );
    }
    return (
      <>
        <Prompt message="You have made unsaved changes. Are you sure you want to leave the page?" when={dirty} />
        <Container>
          <Row>
            <Col lg={9} xl={8}>
              <Card>
                <CardBody>
                  <AsyncComponent action={getEventAction}>
                    <EditorForm
                      initialValues={events.instance}
                      enableReinitialize
                    />
                  </AsyncComponent>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3} xl={3} style={{ height: '100%' }}>
              <Card>
                <CardBody className="shadow">
                  <EditorControls
                    userType={getUserType()}
                    instanceType="event"
                    isNew={params.id === 'new'}
                    instance={events.instance}
                    pristine={pristine}

                    // Components
                    customPills={this.renderCustomPills}

                    // Actions
                    createAction={createEventAction}
                    createAndPublishAction={createAndPublishEventAction}
                    updateAction={updateEventAction}
                    updateAndPublishAction={updateAndPublishEventAction}
                    deleteAction={deleteEventAction}
                    publishAction={publishEventAction}
                    unpublishAction={unpublishEventAction}

                    // Callbacks
                    onCreate={this.handleOnCreate}
                    onCreateAndPublish={this.handleOnCreateAndPublish}
                    onUpdate={this.handleOnUpdate}
                    onUpdateAndPublish={this.handleOnUpdateAndPublish}
                    onDelete={this.handleOnToggleDeleteConfirmModal}
                    onPublish={this.handleOnPublish}
                    onUnpublish={this.handleOnUnpublish}
                    onSubmit={this.handleOnSubmit}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* Modals */}
        <DeleteConfirmModal
          open={deleteConfirmModalOpen}
          action={deleteEventAction}
          deleteAnyway={this.handleOnDelete}
          instanceType="event"

          // Callbacks
          toggle={this.handleOnToggleDeleteConfirmModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    deleteConfirmModalOpen,
  } = state.editor;

  const {
    actions: eventsActions,
    events,
  } = state.events;

  const {
    editorForm,
  } = state.form;

  return {
    eventsActions,
    events,
    instance: events.instance,
    editorForm,
    deleteConfirmModalOpen,
  };
};

const mapDispatchToProps = {
  // Async
  get: eventsGetEvent,
  create: eventsCreateEvent,
  createAndPublish: eventsCreateAndPublishEvent,
  update: eventsUpdateEvent,
  updateAndPublish: eventsUpdateAndPublishEvent,
  delete: eventsDeleteEvent,
  publish: eventsPublishEvent,
  unpublish: eventsUnpublishEvent,
  convertToStory: eventsConvertEventToStory,
  submit: createSubmission,

  // Sync
  reinitialize: eventsReinitializeEventState,
  toggleDeleteConfirmModal,
};

const withForm = {
  form: 'editorForm',
};

export default wrapper({
  component: EventEditor,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    reduxForm(withForm),
  ],
});
