import { create } from 'axios';
import { createAxiosInstance } from '@tmosystems/core';

const axios = createAxiosInstance(create, { baseURL: '/users/api/v1/residents/' });

// GET
const getResident = requestParams => axios.get('', { params: requestParams });
const getResidentAccountRequest = requestParams => axios.get('request/', { params: requestParams });

// PUT
const updateResidentAccountRequest = requestData => axios.put('request/', requestData);

export default {
  GET: {
    resident: getResident,
    residentAccountRequest: getResidentAccountRequest,
  },
  PUT: {
    residentAccountRequest: updateResidentAccountRequest,
  },
};
