import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router';
import { reduxForm } from 'redux-form';
import { toast } from 'react-toastify';
import { wrapper } from 'ggtmo-utils';

// Components
import {
  Container, Row, Col, Card, CardBody, UncontrolledTooltip,
} from 'reactstrap';
import { faMapPin } from '@fortawesome/pro-light-svg-icons';
import EditorForm from './EditorForm';
import AsyncComponent from '../../../shared/components/AsyncComponent';
import EditorControls from '../../../shared/components/EditorControls';
import DeleteConfirmModal from '../../../shared/components/DeleteConfirmModal';
import EditorError from '../../../shared/components/EditorError';
import AsyncText from '../../../shared/components/AsyncText';

// Redux
import {
  toggleDeleteConfirmModal,
} from '../../../redux/actions/editorActions';
import {
  NEWS__CREATE_STORY,
  NEWS__CREATE_AND_PUBLISH_STORY,
  NEWS__UPDATE_STORY,
  NEWS__UPDATE_AND_PUBLISH_STORY,
  NEWS__PUBLISH_STORY,
  NEWS__UNPUBLISH_STORY,
  NEWS__DELETE_STORY,
  NEWS__PIN_STORY,
} from '../redux/actionCreators';
import {
  // Async
  newsGetStory,
  newsCreateStory,
  newsCreateAndPublishStory,
  newsUpdateStory,
  newsUpdateAndPublishStory,
  newsDeleteStory,
  newsPublishStory,
  newsUnpublishStory,
  newsPinStory,

  // Sync
  newsUpdateStoryTableMeta,
  newsReinitializeStoryState,
} from '../redux/actions';
import {
  ADMIN_CREATE_SUBMISSION,
} from '../../submissions/redux/actionCreators';
import {
  createSubmission,
} from '../../submissions/redux/actions';

// Classes
import BaseEditor from '../../../shared/BaseEditor';

// Utils
import getUserType from '../../../shared/utils/getUserType';

class Editor extends BaseEditor {
  static propTypes = {
    // Redux
    newsActions: PropTypes.instanceOf(Object).isRequired,
    adminActions: PropTypes.instanceOf(Object).isRequired,
    story: PropTypes.instanceOf(Object).isRequired,
    images: PropTypes.instanceOf(Array).isRequired,
    deleteConfirmModalOpen: PropTypes.bool.isRequired,

    // Form
    editorForm: PropTypes.instanceOf(Object),
    change: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    // Form
    editorForm: {},
  };

  appName = 'news';

  modelName = 'story';

  actionCreators = {
    create: NEWS__CREATE_STORY,
    createAndPublish: NEWS__CREATE_AND_PUBLISH_STORY,
    update: NEWS__UPDATE_STORY,
    updateAndPublish: NEWS__UPDATE_AND_PUBLISH_STORY,
    delete: NEWS__DELETE_STORY,
    publish: NEWS__PUBLISH_STORY,
    unpublish: NEWS__UNPUBLISH_STORY,
    pin: NEWS__PIN_STORY,
    submit: ADMIN_CREATE_SUBMISSION,
  };

  toastMessages = {
    create: {
      SUCCESS: 'Created story.',
      ERROR: 'Error creating story.',
    },
    createAndPublish: {
      SUCCESS: 'Created and published story. Your changes are visible to the public.',
      ERROR: 'Error creating story.',
    },
    update: {
      SUCCESS: 'Saved story.',
      ERROR: 'Error saving story.',
    },
    updateAndPublish: {
      SUCCESS: 'Saved and published story. Your changes are visible to the public.',
      ERROR: 'Error saving story.',
    },
    delete: {
      SUCCESS: 'Deleted story,',
      ERROR: 'Error deleting story.',
    },
    publish: {
      SUCCESS: 'Published story. Your changes are visible to the public.',
      ERROR: 'Error publishing story.',
    },
    unpublish: {
      SUCCESS: 'Unpublished story. It is no longer visible to the public.',
      ERROR: 'Error unpublishing story.',
    },
    submit: {
      SUCCESS: 'Submitted for review. You will be notified if it is approved for publish.',
      ERROR: 'Error submitting for review.',
    },
  };

  handleOnPinStory = () => {
    const { pinStory: pin, stories } = this.props;
    pin({
      id: stories.instance.id,
      pinned: !stories.instance.pinned,
      updateImageSet: false,
      updateDocumentSet: false,
    })
      .then((action) => {
        if (action.type === this.actionCreators.pin.SUCCESS) {
          toast(`Successfully ${action.response.story.pinned ? 'pinned' : 'unpinned'} story.`, {
            type: toast.TYPE.SUCCESS,
          });
        }
      });
  };

  renderCustomPills = () => {
    // Props
    const {
      newsActions: {
        NEWS__PIN_STORY: pinStoryAction,
      },
      stories,
    } = this.props;

    return (
      <>
        <span
          role="presentation"
          className="editor__controls__pin-btn shadow-sm hover-pointer"
          id="pin"
          onClick={this.handleOnPinStory}
        >
          <AsyncText action={pinStoryAction} icon={faMapPin} keepTextWithSpinner>
            {stories.instance.pinned
              ? ' Unpin'
              : ' Pin'}
          </AsyncText>
        </span>
        <UncontrolledTooltip target="pin" placement="top" delay={0}>
          {stories.instance.pinned
            ? 'Unpin this story'
            : 'Pin this story as the first on the home page.'
          }
        </UncontrolledTooltip>
      </>
    );
  };

  render() {
    // Props
    const {
      newsActions: {
        NEWS__GET_STORY: getStoryAction,
        NEWS__CREATE_STORY: createStoryAction,
        NEWS__CREATE_AND_PUBLISH_STORY: createAndPublishStoryAction,
        NEWS__UPDATE_STORY: updateStoryAction,
        NEWS__UPDATE_AND_PUBLISH_STORY: updateAndPublishStoryAction,
        NEWS__DELETE_STORY: deleteStoryAction,
        NEWS__PUBLISH_STORY: publishStoryAction,
        NEWS__UNPUBLISH_STORY: unpublishStoryAction,
        NEWS__PIN_STORY: pinStoryAction,
      },
      adminActions: {
        ADMIN_CREATE_SUBMISSION: createSubmissionAction,
      },
      stories,
      pristine,
      dirty,
      match: { params },
      deleteConfirmModalOpen,
    } = this.props;

    if (getStoryAction.error) {
      return (
        <EditorError
          instanceType="story"
          action={getStoryAction}
        />
      );
    }
    return (
      <>
        <Prompt message="You have made unsaved changes. Are you sure you want to leave the page?" when={dirty} />
        <Container>
          <Row className="pb-5 pb-md-0">
            <Col xl={8} lg={9} md={12} sm={12}>
              <Card>
                <CardBody>
                  <AsyncComponent action={getStoryAction}>
                    <EditorForm
                      initialValues={stories.instance}
                      enableReinitialize
                    />
                  </AsyncComponent>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} lg={3} md={12} sm={12} style={{ height: '100%' }}>
              <Card className="shadow pb-0">
                <CardBody>
                  <EditorControls
                    userType={getUserType()}
                    instanceType="story"
                    isNew={params.id === 'new'}
                    pristine={pristine}
                    instance={stories.instance}

                    // Components
                    customPills={this.renderCustomPills}

                    // Actions
                    createAction={createStoryAction}
                    createAndPublishAction={createAndPublishStoryAction}
                    updateAction={updateStoryAction}
                    updateAndPublishAction={updateAndPublishStoryAction}
                    deleteAction={deleteStoryAction}
                    publishAction={publishStoryAction}
                    unpublishAction={unpublishStoryAction}
                      // TODO: Check this, it is here just to trigger an update when the loading state changes.
                    pinAction={pinStoryAction}
                    submitAction={createSubmissionAction}

                    // Callbacks
                    onCreate={this.handleOnCreate}
                    onCreateAndPublish={this.handleOnCreateAndPublish}
                    onUpdate={this.handleOnUpdate}
                    onUpdateAndPublish={this.handleOnUpdateAndPublish}
                    onDelete={this.handleOnToggleDeleteConfirmModal}
                    onPublish={this.handleOnPublish}
                    onUnpublish={this.handleOnUnpublish}
                    onSubmit={this.handleOnSubmit}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* Modals */}
        <DeleteConfirmModal
          open={deleteConfirmModalOpen}
          action={deleteStoryAction}
          instanceType="story"

          // Callbacks
          toggle={this.handleOnToggleDeleteConfirmModal}
          deleteAnyway={this.handleOnDelete}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    deleteConfirmModalOpen,
  } = state.editor;

  const {
    actions: newsActions,
    stories,
  } = state.news;

  const {
    images,
  } = state.images;

  const {
    editorForm,
  } = state.form;

  const {
    actions: adminActions,
  } = state.admin;

  return {
    // Editor
    deleteConfirmModalOpen,

    // News
    newsActions,
    stories,
    instance: stories.instance,

    // Images
    images,

    // Form
    editorForm,

    // Admin
    adminActions,
  };
};

const mapDispatchToProps = {
  // Async
  get: newsGetStory,
  create: newsCreateStory,
  createAndPublish: newsCreateAndPublishStory,
  update: newsUpdateStory,
  updateAndPublish: newsUpdateAndPublishStory,
  delete: newsDeleteStory,
  publish: newsPublishStory,
  unpublish: newsUnpublishStory,
  pinStory: newsPinStory,
  submit: createSubmission,

  // Sync
  reinitialize: newsReinitializeStoryState,
  updateTableMeta: newsUpdateStoryTableMeta,
  toggleDeleteConfirmModal,
};

const withForm = {
  form: 'editorForm',
};

export default wrapper({
  component: Editor,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    reduxForm(withForm),
  ],
});
