import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field, reduxForm } from 'redux-form';
import { toast } from 'react-toastify';
import { wrapper } from 'ggtmo-utils';
import { Spring } from 'react-spring/renderprops-universal';

// Components
import { Row, Col, ButtonToolbar } from 'reactstrap';
import { faFilePlus } from '@fortawesome/pro-light-svg-icons';
import Modal from '../../../shared/components/Modal';
import ModalHeader from '../../../shared/components/ModalHeader';
import ModalSidebar from '../../../shared/components/ModalSidebar';
import ModalContent from '../../../shared/components/ModalContent';
import ModalFooter from '../../../shared/components/ModalFooter';
import AsyncButton from '../../../shared/components/AsyncButton';
import {
  LoadingBarWrapper,
  LoadingBar,
  LoadingBarText,
  LoadingBarPercentage,
} from '../../../shared/ProgressBar';

// Form
import renderDropZoneMultipleField from '../../../shared/components/form/DropZoneMultiple';

// Redux
import {
  imagesCreateImage,
  imagesSelectImage,
  imagesUpdateImageModalMeta,
} from '../redux/actions';
import { IMAGES__CREATE_IMAGE } from '../redux/actionCreators';

class UploadModal extends Component {
  static propTypes = {
    inModal: PropTypes.bool.isRequired,

    // Form
    uploadForm: PropTypes.instanceOf(Object),
    reset: PropTypes.func.isRequired,

    // Images
    imagesActions: PropTypes.instanceOf(Object).isRequired,
    images: PropTypes.instanceOf(Object).isRequired,
    categories: PropTypes.instanceOf(Object).isRequired,

    // Actions
    imagesCreateImage: PropTypes.func.isRequired,
    imagesSelectImage: PropTypes.func.isRequired,
    imagesUpdateImageModalMeta: PropTypes.func.isRequired,

    // Callbacks
    onOpened: PropTypes.func,
    onClosed: PropTypes.func,
  };

  static defaultProps = {
    // Form
    uploadForm: {
      values: {
        files: [],
      },
    },

    // Callbacks
    onOpened: () => {},
    onClosed: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      uploadProgress: 0,
    };
  }

  isDisabled = (values) => {
    if (!values || !values.files) return true;
    return values.files.length === 0;
  };

  handleOnSubmit = (event) => {
    event.preventDefault();
    const {
      uploadForm,
      categories,
      imagesCreateImage: createImage,
      imagesSelectImage: selectImage,
      imagesUpdateImageModalMeta: updateImageModalMeta,
      inModal,
    } = this.props;
    const { files } = uploadForm.values;
    const formData = new FormData();
    for (let i = 0; i < files.length; i += 1) {
      formData.append(`file${i}`, files[i]);
    }
    formData.append('categoryId', categories.selected[0]);
    createImage(formData, {
      additionalConfig: {
        onUploadProgress: progressEvent => this.setState({
          uploadProgress: Math.round(progressEvent.loaded * 100 / progressEvent.total),
        }),
      },
    })
      .then((action) => {
        if (action.type === IMAGES__CREATE_IMAGE.SUCCESS) {
          updateImageModalMeta({ isOpen: true, openMode: 'add' });
          if (inModal) {
            selectImage({ selected: action.response.instances.map(instance => instance.id) });
          }
          const { count } = action.response;
          toast(`Successfully uploaded ${count} file${count === 1 ? '' : 's'}.`, {
            type: toast.TYPE.SUCCESS,
          });
        }
      });
  };

  handleToggle = () => {
    const { imagesUpdateImageModalMeta: updateModalMeta, images: { modalMeta }, inModal } = this.props;
    updateModalMeta({
      isOpen: inModal ? true : !modalMeta.isOpen,
      openMode: modalMeta.openMode === 'upload' ? 'add' : 'upload',
    });
  };

  handleOnClosed = () => {
    const { reset } = this.props;
    reset();
  };

  render() {
    // State
    const {
      uploadProgress,
    } = this.state;

    // Props
    const {
      // Form
      uploadForm,

      // Images
      images: {
        modalMeta,
      },
      imagesActions: {
        IMAGES__CREATE_IMAGE: createImageAction,
      },

      // Callbacks
      onOpened,
      onClosed,
    } = this.props;

    return (
      <Modal
        open={modalMeta.isOpen && modalMeta.openMode === 'upload'}
        width="xxl"
        noPadding
        centered

        // Callbacks
        toggle={this.handleToggle}
        onOpened={onOpened}
        onClosed={onClosed || this.handleOnClosed}
      >
        <ModalHeader
          title="Upload files"

          // Callbacks
          onClose={this.handleToggle}
        />
        <Row noGutters>
          <Col xs={2}>
            <ModalSidebar
              icon={faFilePlus}
              heading="Upload files"
            />
          </Col>
          <Col>
            <ModalContent>
              <Form>
                <div className="form__form-group">
                  <Field
                    name="files"
                    component={renderDropZoneMultipleField}
                  />
                </div>
                {createImageAction.loading && (
                  <div className="w-100">
                    <Spring from={{ progress: 0 }} to={{ progress: uploadProgress }}>
                      {({ progress }) => (
                        <LoadingBarWrapper className="rounded">
                          <LoadingBar progress={progress} className="rounded">
                            <LoadingBarText>Uploading...</LoadingBarText>
                            {uploadProgress > 20 && (
                              <LoadingBarPercentage>
                                {Math.round(progress)}%
                              </LoadingBarPercentage>
                            )}
                          </LoadingBar>
                        </LoadingBarWrapper>
                      )}
                    </Spring>
                  </div>
                )}
              </Form>
              <ModalFooter>
                <ButtonToolbar className="justify-content-end">
                  <AsyncButton
                    color="primary"
                    type="submit"
                    disabled={this.isDisabled(uploadForm.values)}
                    onClick={this.handleOnSubmit}
                    action={createImageAction}
                  >
                    Upload
                  </AsyncButton>
                </ButtonToolbar>
              </ModalFooter>
            </ModalContent>
          </Col>
        </Row>
      </Modal>
    );
  }
}

const withForm = {
  form: 'uploadForm',
  initialValues: {
    category: { value: '1', label: 'Photo' },
  },
};

const mapStateToProps = (state) => {
  const {
    uploadForm,
  } = state.form;

  const {
    actions: imagesActions,
    images,
    categories,
  } = state.images;

  return {
    // Form
    uploadForm,

    // Images
    imagesActions,
    images,
    categories,
  };
};

const mapDispatchToProps = {
  imagesCreateImage,
  imagesSelectImage,
  imagesUpdateImageModalMeta,
};

export default wrapper({
  component: UploadModal,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(withForm),
  ],
});
