import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Icons
import {
  faGlobeEurope,
  faCalendarAlt,
  faUserCheck,
  faImages,
  faBook,
  faUser,
  faWrench,
  faFileAlt,
  faPlug,
  faQuestionCircle,
  faUsers,
  faComment,
} from '@fortawesome/pro-light-svg-icons';

// Components
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

// Utils
import getUserType from '../../../shared/utils/getUserType';


class SidebarContent extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const isAdmin = getUserType() === 'admin';

    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink title="Events" route="/events" icon={faCalendarAlt} onClick={this.hideSidebar} />
          <SidebarLink title="News" route="/news" icon={faGlobeEurope} onClick={this.hideSidebar} />
          {isAdmin && (
            <>
              <SidebarLink title="Submissions" route="/submissions" icon={faUserCheck} onClick={this.hideSidebar} />
            </>
          )}
        </ul>
        {isAdmin && (
          <ul className="sidebar__block">
            <SidebarLink title="Content" route="/content" icon={faBook} onClick={this.hideSidebar} />
            <SidebarLink title="Repairs" route="/repairs" icon={faWrench} onClick={this.hideSidebar} />
            <SidebarLink title="Residents" route="/residents" icon={faUsers} onClick={this.hideSidebar} />
            <SidebarLink title="Communications" route="/communications" icon={faComment} onClick={this.hideSidebar} />
          </ul>
        )}
        <ul className="sidebar__block">
          <SidebarLink title="Images" route="/images" icon={faImages} onClick={this.hideSidebar} />
          {isAdmin && (
            <>
              <SidebarLink title="Documents" route="/documents" icon={faFileAlt} onClick={this.hideSidebar} />
            </>
          )}
        </ul>
        <ul className="sidebar__block">
          <SidebarLink title="Help" route="/help" onClick={this.hideSidebar} icon={faQuestionCircle} />
          {isAdmin && (
            <SidebarLink title="Integrations" route="/integrations" icon={faPlug} onClick={this.hideSidebar} />
          )}
          <SidebarCategory title="Account" icon={faUser}>
            <SidebarLink title="Profile" route="/account/profile" onClick={this.hideSidebar} />
            <SidebarLink title="Log Out" route="/logout" onClick={this.hideSidebar} />
          </SidebarCategory>
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
