import {
  generateReducerElement as generate, reduxTypes as types, adjust, initializeActions,
} from 'ggtmo-utils';
import {
  ACCOUNT_REGISTER,
  ACCOUNT_LOGIN,
  ACCOUNT_GET_PROFILE,
  ACCOUNT_UPDATE_PROFILE,
  ACCOUNT_VERIFY,
  ACCOUNT_FORGOT_PASSWORD,
  ACCOUNT_RESET_PASSWORD,
} from './actionCreators';


const initialState = {
  profile: {},
  actions: initializeActions([
    ACCOUNT_REGISTER,
    ACCOUNT_LOGIN,
    ACCOUNT_GET_PROFILE,
    ACCOUNT_UPDATE_PROFILE,
    ACCOUNT_VERIFY,
    ACCOUNT_FORGOT_PASSWORD,
    ACCOUNT_RESET_PASSWORD,
  ]),
};


export default (state = initialState, action) => ({
  ...generate(state, action, ACCOUNT_REGISTER, {
    type: types.ASYNC,
  }),

  ...generate(state, action, ACCOUNT_LOGIN, {
    type: types.ASYNC,
  }),

  ...generate(state, action, ACCOUNT_GET_PROFILE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, ACCOUNT_GET_PROFILE.SUCCESS) && {
        profile: action.response,
      },
    },
  }),

  ...generate(state, action, ACCOUNT_UPDATE_PROFILE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, ACCOUNT_UPDATE_PROFILE.SUCCESS) && {
        profile: action.response,
      },
    },
  }),

  ...generate(state, action, ACCOUNT_VERIFY, {
    type: types.ASYNC,
  }),

  ...generate(state, action, ACCOUNT_FORGOT_PASSWORD, {
    type: types.ASYNC,
  }),

  ...generate(state, action, ACCOUNT_RESET_PASSWORD, {
    type: types.ASYNC,
  }),
}[action.type] || state);
