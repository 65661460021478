export const navbar = {
  topHeight: '140px',
  bottomHeight: '50px',
  toggleButtonHeight: '35px',
  toggleButtonWidth: '45px',
};


export const backToTop = {
  buttonSize: '45px',
};


export const homeIcons = {
  iconSize: '100%',
};


export default {
  navbar,
  backToTop,
  homeIcons,
};
