import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { wrapper } from 'ggtmo-utils';
import classNames from 'classnames';

// Components
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import AsyncComponent from '../../../shared/components/AsyncComponent';
import CategoryModal from './CategoryModal';
import { Category } from './styled';

// Redux
import { repairsGetCategory, repairsUpdateCategoryModalMeta } from '../redux/actions';
import { REPAIRS__GET_CATEGORY } from '../redux/actionCreators';

class CategoryEditor extends PureComponent {
  static propTypes = {
    // Repairs
    repairsActions: PropTypes.instanceOf(Object).isRequired,
    categories: PropTypes.instanceOf(Array).isRequired,

    // Actions
    getCategory: PropTypes.func.isRequired,
    updateCategoryModalMeta: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { getCategory } = this.props;
    getCategory({ list: true });
  }

  handleToggle = () => {
    const { updateCategoryModalMeta, categories } = this.props;
    updateCategoryModalMeta({
      toggle: true,
      openMode: categories.modalMeta.openMode,
    });
  };

  handleToggleCreateCategoryModal = () => {
    const { updateCategoryModalMeta } = this.props;
    updateCategoryModalMeta({
      toggle: true,
      openMode: 'create',
    });
  };

  handleToggleEditCategoryModal = () => {
    const { updateCategoryModalMeta } = this.props;
    updateCategoryModalMeta({
      toggle: true,
      openMode: 'update',
    });
  };

  handleOpenEditCategoryModal = (event) => {
    // Get the category info
    const { slug } = event.currentTarget.dataset;
    const { getCategory } = this.props;
    getCategory({
      list: false,
      slug,
    }).then(({ type }) => {
      if (type === REPAIRS__GET_CATEGORY.SUCCESS) {
        this.handleToggleEditCategoryModal();
      }
    });
  };

  renderCategoryCard = ({
    name,
    imageSet,
    slug,
    isNew,
  }, index) => {
    // Props
    const {
      categories: {
        instances,
      },
    } = this.props;
    const width = 12 / 2;
    const { length } = instances;
    const position = index + 1;
    const hasBottomMargin = width * (Math.ceil(length / width) - 1) >= position;

    return (
      <Col xl={2} key={slug}>
        {isNew ? (
          <Category
            className="border p-3 rounded shadow-sm"
            onClick={this.handleToggleCreateCategoryModal}
            data-id={slug}
          >
            <h4 style={{ padding: '37px 0' }}>
              <FontAwesomeIcon icon={faPlus} /> Create category
            </h4>
          </Category>
        ) : (
          <Category
            className={classNames('border p-3 rounded shadow-sm', { 'mb-4': hasBottomMargin })}
            onClick={this.handleOpenEditCategoryModal}
            data-slug={slug}
          >
            <img
              src={imageSet.images[0].url}
              alt={imageSet.images[0].name}
            />
            <h4 className="text-black-75">
              {name}
            </h4>
          </Category>
        )}
      </Col>
    );
  };

  render() {
    // Props
    const {
      repairsActions: {
        REPAIRS__GET_CATEGORY: getCategoriesAction,
      },
      categories: {
        instances,
        modalMeta,
      },
    } = this.props;

    return (
      <AsyncComponent action={getCategoriesAction} onlyOnInitialLoad>
        <Row>
          {instances.map((instance, index) => this.renderCategoryCard({ ...instance, isNew: false }, index))}
          {this.renderCategoryCard({ isNew: true })}
        </Row>
        <CategoryModal
          modalMeta={modalMeta}

          // Callbacks
          toggle={this.handleToggle}
        />
      </AsyncComponent>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    actions: repairsActions,
    categories,
  } = state.repairs;

  const {
    createCategoryForm,
  } = state.form;

  return {
    // Repairs
    repairsActions,
    categories,

    // Form
    createCategoryForm,
  };
};

const mapDispatchToProps = {
  getCategory: repairsGetCategory,
  updateCategoryModalMeta: repairsUpdateCategoryModalMeta,
};

export default wrapper({
  component: CategoryEditor,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
  ],
});
