import React from 'react';

// Components
import { Container, Card, CardBody } from 'reactstrap';
import DocumentsExplorer from './components/DocumentsExplorer';

const Documents = () => (
  <Container>
    <h3 className="page-title">Documents</h3>
    <p className="page-subhead">Upload and manage documents.</p>
    <Card style={{ minHeight: 753 }}>
      <CardBody>
        <DocumentsExplorer />
      </CardBody>
    </Card>
  </Container>
);

export default Documents;
