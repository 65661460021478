import styled from 'styled-components';

export const BasePill = styled.span`
  padding: 5px 10px;
  margin-right: 10px;
  color: white;
  border-radius: 0.5em;
`;

export const ContactGroupPill = styled(BasePill)`
  background-color: ${({ theme }) => theme.color.primary};
`;

export const RecipientPill = styled(BasePill)`
  background-color: ${({ theme }) => theme.color.primaryLighter};
`;
