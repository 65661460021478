import React from 'react';
import PropTypes from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { ContactsUnorderedList, ContactsListItem, ContactIcon } from './styled';

const ContactsList = ({
  contacts,
  contactListItemComponent,
}) => (
  <ContactsUnorderedList>
    {contacts.map(contact => (contactListItemComponent ? contactListItemComponent(contact) : (
      <ContactsListItem className="border-bottom">
        <ContactIcon>
          <FontAwesomeIcon icon={faUser} />
        </ContactIcon>
        {contact.name}
      </ContactsListItem>
    )))}
  </ContactsUnorderedList>
);

ContactsList.propTypes = {
  contacts: PropTypes.instanceOf(Array).isRequired,
  contactListItemComponent: PropTypes.node,
};

ContactsList.defaultProps = {
  contactListItemComponent: null,
};

export default ContactsList;
