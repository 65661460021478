import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { squashString } from '@tmosystems/core';
import moment from 'moment';

// Components
import { Col } from 'reactstrap';
import TableCell from '@material-ui/core/TableCell';
import MatTable from '../../../../shared/components/table/MatTable';
import AsyncComponent from '../../../../shared/components/AsyncComponent';

// Redux
import { communicationsGetReceivedMessage } from '../../redux/actions';
import { COMMUNICATIONS_GET_RECEIVED_MESSAGE } from '../../redux/actionCreators';

class MessagesTable extends Component {
  static propTypes = {
    // Communications
    communicationsActions: PropTypes.instanceOf(Object).isRequired,
    receivedMessages: PropTypes.instanceOf(Object).isRequired,
    receivedMessageLabels: PropTypes.instanceOf(Object).isRequired,

    // Redux
    communicationsGetReceivedMessage: PropTypes.func.isRequired,
  };

  tableColumns = [
    {
      id: 'Message', label: 'Message', disablePadding: false, numeric: false, align: 'left',
    },
    {
      id: 'name', label: 'Name', disablePadding: false, numeric: false, align: 'left',
    },
    {
      id: 'email', label: 'Email', disablePadding: false, numeric: false, align: 'left',
    },
    {
      id: 'dateReceived', label: 'Date Received', disablePadding: false, numeric: false, align: 'left',
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      viewingMessage: false,
    };
  }

  componentDidMount() {
    const { communicationsGetReceivedMessage: getReceivedMessage } = this.props;
    getReceivedMessage({ list: true });
  }

  handleEmailMessageOnClick = (event, id) => {
    const { communicationsGetReceivedMessage: getReceivedMessage } = this.props;
    getReceivedMessage({ list: false, id })
      .then((action) => {
        if (action.type === COMMUNICATIONS_GET_RECEIVED_MESSAGE.SUCCESS) {
          this.setState({ viewingMessage: true });
        }
      });
  };

  handleOnPageChange = () => {};

  renderTableRow = ({
    body,
    name,
    email,
    dateTimeReceived,
  }) => (
    <>
      <TableCell className="material-table__cell">
        {squashString(body)}
      </TableCell>
      <TableCell className="material-table__cell">
        {squashString(name)}
      </TableCell>
      <TableCell className="material-table__cell">
        {squashString(email)}
      </TableCell>
      <TableCell>
        {moment(dateTimeReceived).format('DD/MM/YYYY')}
      </TableCell>
    </>
  );

  render() {
    // State
    const {
      viewingMessage,
    } = this.state;

    // Props
    const {
      // Communications
      communicationsActions: {
        COMMUNICATIONS_GET_RECEIVED_MESSAGE: getReceivedMessageAction,
      },
      receivedMessages: {
        instance,
        instances,
        selected,
        count,
        tableMeta: {
          page,
          rowsPerPage,
        },
      },
      receivedMessageLabels,
    } = this.props;

    return (
      <>
        <Col>
          <div>
            {receivedMessageLabels.selected.length ? (
              <h3>
                {receivedMessageLabels.instance.name}
              </h3>
            ) : (
              <h3>
                All messages
              </h3>
            )}
          </div>
          <hr />
          <MatTable
            rowComponent={this.renderTableRow}
            columns={this.tableColumns}
            data={instances}
            selected={selected}
            selectOnlyByCheckbox
            page={page}
            count={count}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={['25', '50']}
            emptyRows={false}
            keepHeadOnEmpty
            canSelect={false}

            // Callbacks
            onSelectByRow={this.handleEmailMessageOnClick}
            onPageChange={this.handleOnPageChange}
          />
        </Col>
        {viewingMessage && (
          <Col xs={4} className="border-left">
            <AsyncComponent action={getReceivedMessageAction}>
              <h5>Message Information</h5>
              <hr />
              <div className="mb-3">
                <p className="font-weight-bold mb-2">
                  From:
                </p>
                <div>
                  {instance.name} <span className="text-black-50">({instance.email})</span>
                </div>
              </div>
              <div className="mb-3">
                <p className="font-weight-bold mb-2">
                  Sent:
                </p>
                <div>
                  {moment(instance.dateTimeReceived).format('DD/MM/YYYY')}
                </div>
              </div>
              <div className="mb-3">
                <p className="font-weight-bold mb-2">
                  Body:
                </p>
                <div>
                  <textarea
                    readOnly
                    className="w-100 border rounded p-3"
                    value={instance.body}
                    rows={20}
                    style={{ resize: 'none' }}
                  />
                </div>
              </div>
            </AsyncComponent>
          </Col>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    actions: communicationsActions,
    receivedMessages,
    receivedMessageLabels,
  } = state.communications;

  return {
    // Communications
    communicationsActions,
    receivedMessages,
    receivedMessageLabels,
  };
};

const mapDispatchToProps = {
  communicationsGetReceivedMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesTable);
