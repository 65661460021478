import { generateReducerElement as generate, reduxTypes as types, adjust } from '@tmosystems/core';

// Action creators
import {
  // Async action creators
  IMAGES__CREATE_IMAGE,
  IMAGES__GET_IMAGE,
  IMAGES__UPDATE_IMAGE,
  IMAGES__DELETE_IMAGE,
  IMAGES__GET_MORE_IMAGES,
  IMAGES__CREATE_CATEGORY,
  IMAGES__GET_CATEGORY,
  IMAGES__DELETE_CATEGORY,

  // Sync action creators
  IMAGES__SELECT_IMAGE,
  IMAGES__UPDATE_IMAGE_MODAL_META,
  IMAGES__UPDATE_IMAGE_TABLE_META,
  IMAGES__UPDATE_IMAGE_SCROLL_META,
  IMAGES__UPDATE_IMAGE_SEARCH_META,
  IMAGES__REINITIALIZE_IMAGE_STATE,
  IMAGES__SELECT_CATEGORY,
} from './actionCreators';

import initialState from './reducer';

export default (state, action) => ({
  // Async
  ...generate(state, action, IMAGES__CREATE_IMAGE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, IMAGES__CREATE_IMAGE.SUCCESS) && {
        images: {
          ...state.images,
          instances: [
            ...action.response.instances,
            ...state.images.instances,
          ],
        },
      },
    },
  }),
  ...generate(state, action, IMAGES__GET_IMAGE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, IMAGES__GET_IMAGE.SUCCESS) && {
        images: action.response.append ? {
          ...state.images,
          instance: action.response.instance || state.images.instance,
          instances: [
            ...state.images.instances,
            ...action.response.instances,
          ],
          count: action.response.count,
        } : {
          ...state.images,
          instance: action.response.instance || state.images.instance,
          instances: action.response.instances,
          count: action.response.count,
        },
      },
    },
  }),
  ...generate(state, action, IMAGES__UPDATE_IMAGE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, IMAGES__UPDATE_IMAGE.SUCCESS) && {
        images: {
          ...state.images,
          instance: action.response.instance,
          instances: state.images.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.images.selected,
        },
      },
    },
  }),
  ...generate(state, action, IMAGES__DELETE_IMAGE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, IMAGES__DELETE_IMAGE.SUCCESS) && {
        images: {
          ...state.images,
          instance: state.images.instance,
          instances: state.images.instances
            .filter(instance => !action.response.deletedIds.includes(instance.id)),
          selected: initialState.images.selected,
        },
      },
    },
  }),
  ...generate(state, action, IMAGES__GET_MORE_IMAGES, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, IMAGES__GET_MORE_IMAGES.SUCCESS) && {
        images: {
          ...state.images,
          instance: action.response.instance || state.images.instance,
          instances: [
            ...state.images.instances,
            ...action.response.instances,
          ],
        },
      },
    },
  }),
  ...generate(state, action, IMAGES__CREATE_CATEGORY, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, IMAGES__CREATE_CATEGORY.SUCCESS) && {
        categories: {
          ...state.categories,
          instance: action.response.instance,
          instances: [
            ...state.categories.instances,
            action.response.instance,
          ],
        },
      },
    },
  }),
  ...generate(state, action, IMAGES__GET_CATEGORY, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, IMAGES__GET_CATEGORY.SUCCESS) && {
        categories: action.response.append ? {
          ...state.categories,
          instance: action.response.instance || state.categories.instance,
          instances: [
            ...state.categories.instances,
            ...action.response.instances,
          ],
          count: action.response.count,
        } : {
          ...state.categories,
          instance: action.response.instance || state.categories.instance,
          instances: action.response.instances,
          count: action.response.count,
        },
      },
    },
  }),
  ...generate(state, action, IMAGES__DELETE_CATEGORY, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, IMAGES__DELETE_CATEGORY.SUCCESS) && {
        categories: {
          ...state.categories,
          instance: state.categories.instance,
          instances: state.categories.instances
            .filter(instance => !action.response.deletedIds.includes(instance.id)),
          selected: initialState.categories.selected,
        },
      },
    },
  }),

  // Sync
  ...generate(state, action, IMAGES__SELECT_IMAGE, {
    type: types.SYNC,
    adjustment: {
      images: {
        ...state.images,
        selected: action.selected,
        selectedIndices: action.selectedIndices,
      },
    },
  }),
  ...generate(state, action, IMAGES__UPDATE_IMAGE_MODAL_META, {
    type: types.SYNC,
    adjustment: {
      images: {
        ...state.images,
        modalMeta: {
          isOpen: (() => {
            if (action.toggle) {
              return !state.images.modalMeta.isOpen;
            }
            return action.isOpen;
          })(),
          openMode: action.openMode,
        },
      },
    },
  }),
  ...generate(state, action, IMAGES__UPDATE_IMAGE_TABLE_META, {
    type: types.SYNC,
    adjustment: {
      images: {
        ...state.images,
        tableMeta: {
          ...state.images.tableMeta,
          ...action,
        },
      },
    },
  }),
  ...generate(state, action, IMAGES__UPDATE_IMAGE_SCROLL_META, {
    type: types.SYNC,
    adjustment: {
      images: {
        ...state.images,
        scrollMeta: {
          ...state.images.scrollMeta,
          ...action,
        },
      },
    },
  }),
  ...generate(state, action, IMAGES__UPDATE_IMAGE_SEARCH_META, {
    type: types.SYNC,
    adjustment: {
      images: {
        ...state.images,
        searchMeta: {
          ...state.images.searchMeta,
          ...action,
        },
      },
    },
  }),
  ...generate(state, action, IMAGES__REINITIALIZE_IMAGE_STATE, {
    type: types.SYNC,
    adjustment: {
      images: initialState.images,
    },
  }),
  ...generate(state, action, IMAGES__SELECT_CATEGORY, {
    type: types.SYNC,
    adjustment: {
      categories: {
        ...state.categories,
        selected: action.selected,
        selectedIndices: action.selectedIndices,
      },
    },
  }),
}[action.type] || state);
