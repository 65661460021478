import styled from 'styled-components';

export const Image = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
`;

export const Name = styled.h4`
  line-height: 50px;
`;

export const Edit = styled.span`
  opacity: 0;
  float: right;
`;

export const Type = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  text-align: left;
  padding: 4px 8px;
  
  &:hover {
    cursor: pointer;
  }
  
  &:hover ${Edit} {
    opacity: 1;
  }
  
  svg {
    transition: all 0.2s;
  }
  
  span::selection {
    background-color: white;
    color: black;
  }
`;

export const Category = styled.div`
  text-align: center;
  
  img {
    display: block;
    height: 64px;
    width: 64px;
    margin: 0 auto 10px;
    object-fit: cover;
  }
  
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
