import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { wrapper } from 'ggtmo-utils';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';

// Redux
import {
  logout,
} from '../redux/actions';
import {
  ACCOUNT_LOGOUT,
} from '../redux/actionCreators';

// Images
import logo from '../../../shared/img/logo/logo_light.png';


class Logout extends PureComponent {
  static propTypes = {
    // Redux
    dispatch: PropTypes.func.isRequired,

    // Router
    history: ReactRouterPropTypes.history.isRequired,
  };

  componentDidMount() {
    const { dispatch, history } = this.props;

    setTimeout(() => dispatch(logout())
      .then(({ type }) => {
        if (type === ACCOUNT_LOGOUT.SUCCESS) {
          localStorage.removeItem('token');
          sessionStorage.removeItem('token');

          history.push('/login');
        }
      }), 1000);
  }

  render() {
    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card shadow text-center text-primary">
            <img src={logo} alt="" className="mb-4" />
            <FontAwesomeIcon icon={faSpinner} spin size="3x" />
          </div>
        </div>
      </div>
    );
  }
}


export default wrapper({
  component: Logout,
  wrappers: [
    connect(),
    withRouter,
  ],
});
