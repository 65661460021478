import styled from 'styled-components';

export const ContactsUnorderedList = styled.div`

`;

export const ContactsListItem = styled.div`
  display: flex;
  padding: 8px 5px;
  
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const ContactsListItemAdded = styled(ContactsListItem)`
  opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.04);
`;

export const ContactIcon = styled.div`
  color: white;
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 50%;
  width: 36px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  margin-right: 8px;
`;

export const ContactSearchBarWrapper = styled.div`

`;

export const ContactSearchBarInput = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25em;
  height: 24px;
  font-size: 1rem;
  
  &:focus {
    outline: none;
  }
`;

export const ContactGroupsUnorderedList = styled.div`

`;

export const ContactGroupsListItem = styled.div`
  display: flex;
  line-height: 36px;
  padding: 8px 5px;
  
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const ContactGroupIcon = styled.div`
  color: white;
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 50%;
  width: 36px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  margin-right: 8px;
`;
