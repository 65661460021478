import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Card, CardBody, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const DashboardIcons = ({ items }) => items.map(({ visible = true, ...rest }) => visible && (
  <Col lg={6} xl={4} key={rest.title}>
    <Link to={rest.to}>
      <Card>
        <CardBody className="d-flex h-auto">
          <FontAwesomeIcon className="text-primary" icon={rest.icon} size="4x" />
          <div className="ml-3">
            <h3 className="page-title">{rest.title}</h3>
            <p className="page-subhead my-0">{rest.subtitle}</p>
          </div>
        </CardBody>
      </Card>
    </Link>
  </Col>
));

DashboardIcons.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
};

export default DashboardIcons;
