import React from 'react';
import PropTypes from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird as spinner } from '@fortawesome/pro-regular-svg-icons';


const AsyncText = ({
  children, action, keepTextWithSpinner, icon,
}) => (action.loading ? (
  <span>
    <FontAwesomeIcon icon={spinner} fixedWidth spin />{keepTextWithSpinner && ` ${children}`}
  </span>
) : (
  <span>
    {icon && (
      <>
        <FontAwesomeIcon icon={icon} fixedWidth />{' '}
      </>
    )}
    {children}
  </span>
));

AsyncText.propTypes = {
  children: PropTypes.node.isRequired,
  action: PropTypes.instanceOf(Object).isRequired,
  keepTextWithSpinner: PropTypes.bool,
  icon: PropTypes.instanceOf(Object),
};

AsyncText.defaultProps = {
  keepTextWithSpinner: false,
  icon: null,
};

export default AsyncText;
