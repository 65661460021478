import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import {
  Row, Col, ButtonToolbar, Button,
} from 'reactstrap';
import { faFilePlus } from '@fortawesome/pro-light-svg-icons';
import Modal from '../../../shared/components/Modal';
import ModalHeader from '../../../shared/components/ModalHeader';
import ModalSidebar from '../../../shared/components/ModalSidebar';
import ModalContent from '../../../shared/components/ModalContent';
import ModalFooter from '../../../shared/components/ModalFooter';
import DocumentsExplorer from './DocumentsExplorer';

class DocumentsModal extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,

    // Documents
    selectedDocuments: PropTypes.instanceOf(Object).isRequired,

    // Callbacks
    toggle: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,

    // Shared usages
    instance: PropTypes.instanceOf(Object),
  };

  static defaultProps = {
    open: false,

    // Shared usages
    instance: {},
  };

  render() {
    // Props
    const {
      open,

      // Documents
      selectedDocuments,

      // Callbacks
      toggle,
      onAdd,

      // Shared usages
      instance,
    } = this.props;

    return (
      <Modal
        open={open}
        width="xxl"
        noPadding

        // Callbacks
        toggle={toggle}
      >
        <ModalHeader
          title="Choose files"

          // Callbacks
          onClose={toggle}
        />
        <Row noGutters>
          <Col xs={2}>
            <ModalSidebar
              icon={faFilePlus}
              heading="Choose files"
              body="Add files to this section. Users can download the files by clicking
                on them on the public site."
            />
          </Col>
          <Col>
            <ModalContent style={{ minHeight: 700 }}>
              <DocumentsExplorer
                rowsPerPage={5}

                // Shared usages
                instance={instance}
              />
              <ModalFooter>
                <ButtonToolbar className="float-right">
                  <Button onClick={toggle} size="sm">Cancel</Button>
                  <Button onClick={onAdd} size="sm" color="primary" disabled={selectedDocuments.length === 0}>
                    Add
                  </Button>
                </ButtonToolbar>
              </ModalFooter>
            </ModalContent>
          </Col>
        </Row>
      </Modal>
    );
  }
}


const mapStateToProps = (state) => {
  const {
    selectedDocuments,
  } = state.documents;

  return {
    selectedDocuments,
  };
};

export default connect(mapStateToProps)(DocumentsModal);
