import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import {
  Row, Col, ButtonToolbar, Button,
} from 'reactstrap';
import { faInfoCircle as faInfo, faEdit } from '@fortawesome/pro-light-svg-icons';
import ModalHeader from '../../../shared/components/ModalHeader';
import ModalSidebar from '../../../shared/components/ModalSidebar';
import ModalContent from '../../../shared/components/ModalContent';
import NoticeForm from './NoticeForm';
import AsyncButton from '../../../shared/components/AsyncButton';

// Classes
import BaseModal from '../../../shared/BaseModal';

// Actions
import {
  sectionsGetNotice,
  sectionsDeleteNotice,
  sectionsUpdateNoticeModalMeta,
} from '../redux/actions';
import { SECTIONS__DELETE_NOTICE } from '../redux/actionCreators';

class NoticeModal extends BaseModal {
  static propTypes = {
    modalMeta: PropTypes.instanceOf(Object).isRequired,

    // Sections
    sectionsActions: PropTypes.instanceOf(Object).isRequired,
    notices: PropTypes.instanceOf(Object).isRequired,

    // Actions
    sectionsGetNotice: PropTypes.func.isRequired,
    sectionsUpdateNoticeModalMeta: PropTypes.func.isRequired,
  };

  properties = {
    centered: true,
    noPadding: true,
    width: 'xxl',
    contentClassName: 'rounded',
  };

  handleToggle = () => {
    const { toggleCreate, toggleUpdate, modalMeta } = this.props;
    if (modalMeta.openMode === 'create') {
      toggleCreate();
    }
    if (modalMeta.openMode === 'update') {
      toggleUpdate();
    }
  };

  handleOnConfirmDelete = () => {
    const {
      notices,
      sectionsDeleteNotice: deleteNotice,
      sectionsUpdateNoticeModalMeta: updateNoticeModalMeta,
    } = this.props;
    deleteNotice({ id: notices.instance.id })
      .then((action) => {
        if (action.type === SECTIONS__DELETE_NOTICE.SUCCESS) {
          updateNoticeModalMeta({ isOpen: false });
        }
      });
  };

  handleOnCancelDelete = () => {
    const { sectionsUpdateNoticeModalMeta: updateNoticeModalMeta } = this.props;
    updateNoticeModalMeta({ isOpen: true, openMode: 'update' });
  };

  renderCreateModal = () => {
    // Props
    const {
      // Callbacks
      onCreate,
    } = this.props;

    return (
      <div>
        <ModalHeader
          title="Create notice"

          // Callbacks
          onClose={this.handleToggle}
        />
        <Row noGutters>
          <Col xs={2}>
            <ModalSidebar
              icon={faInfo}
              heading="Create Notice"
              body="A notice will be displayed near the top of the page. Use notices to inform residents and convey
            essential information."
            />
          </Col>
          <Col>
            <ModalContent>
              <NoticeForm
                isNew

                // Callbacks
                toggle={this.handleToggle}
                onCreate={onCreate}
              />
            </ModalContent>
          </Col>
        </Row>
      </div>
    );
  };

  renderUpdateModal = () => {
    // Props
    const {
      notices,

      // Callbacks
      onUpdate,
    } = this.props;

    return (
      <div>
        <ModalHeader
          title="Update notice"

          // Callbacks
          onClose={this.handleToggle}
        />
        <Row noGutters>
          <Col xs={2}>
            <ModalSidebar
              icon={faEdit}
              heading="Update Notice"
              body="A notice will be displayed near the top of the page. Use notices to inform residents and convey
            essential information."
            />
          </Col>
          <Col>
            <ModalContent>
              <NoticeForm
                isNew={false}
                initialValues={notices.instance}

                // Callbacks
                toggle={this.handleToggle}
                onUpdate={onUpdate}
              />
            </ModalContent>
          </Col>
        </Row>
      </div>
    );
  };

  renderDeleteConfirmModal = () => {
    // Props
    const {
      sectionsActions: {
        SECTIONS__DELETE_NOTICE: deleteNoticeAction,
      },
    } = this.props;

    return (
      <div>
        <ModalHeader
          title="Delete notice?"

          // Callbacks
          onClose={this.handleToggle}
        />
        <Row noGutters>
          <Col xs={2}>
            <ModalSidebar />
          </Col>
          <Col>
            <ModalContent style={{ height: 690 }}>
              Are you sure?
              <ButtonToolbar>
                <AsyncButton onClick={this.handleOnConfirmDelete} action={deleteNoticeAction}>
                  Delete
                </AsyncButton>
                <Button onClick={this.handleOnCancelDelete}>
                  Cancel
                </Button>
              </ButtonToolbar>
            </ModalContent>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    actions: sectionsActions,
    notices,
  } = state.sections;

  return {
    // Sections
    sectionsActions,
    notices,
  };
};

const mapDispatchToProps = {
  sectionsGetNotice,
  sectionsDeleteNotice,
  sectionsUpdateNoticeModalMeta,
};

export default connect(mapStateToProps, mapDispatchToProps)(NoticeModal);
