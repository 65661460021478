import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { wrapper } from 'ggtmo-utils';

// Components
import { Container, Card, CardBody } from 'reactstrap';
import EventTable from './components/EventTable';
import Toolbar from './components/Toolbar';
import AsyncComponent from '../../shared/components/AsyncComponent';

// Redux
import {
  eventsGetEvent,
  eventsSelectEvent,
  eventsDeleteEvent,
  eventsUpdateEventTableMeta,
} from './redux/actions';
import {
  EVENTS__DELETE_EVENT,
} from './redux/actionCreators';

// Classes
import BaseEditorWrapper from '../../shared/BaseEditorWrapper';

// Images
import eventsImage from '../../shared/img/events.png';

class Events extends BaseEditorWrapper {
  static propTypes = {
    // Events
    actions: PropTypes.instanceOf(Object).isRequired,
    events: PropTypes.instanceOf(Object).isRequired,
  };

  appName = 'events';

  actionCreators = {
    delete: EVENTS__DELETE_EVENT,
  };

  toastMessages = {
    delete: {
      SUCCESS: length => `Successfully deleted ${length === 1 ? 'event' : 'events'}.`,
    },
  };

  render() {
    // Props
    const {
      actions: {
        EVENTS__GET_EVENT: getEventAction,
      },
      events,
    } = this.props;

    return (
      <Container>
        <h3 className="page-title">Events</h3>
        <p className="page-subhead">Add and edit events.</p>
        <Card style={{ minHeight: 753 }}>
          <CardBody>
            <Toolbar
              selected={events.selected}

              // Callbacks
              onCreate={this.handleOnCreate}
              onEdit={this.handleOnEdit}
              onDelete={this.handleOnDelete}
            />
            <AsyncComponent action={getEventAction}>
              <EventTable
                events={events.instances}
                selected={events.selected}
                page={events.tableMeta.page}
                rowsPerPage={events.tableMeta.rowsPerPage}
                count={events.count}

                // Callbacks
                onSelect={this.handleOnSelect}
                onPageChange={this.handleOnPageChange}
              >
                <div className="editor__no-instances mt-5">
                  <h4>You&#39;ve not created any events yet. Hit the create button to get started.</h4>
                  <img src={eventsImage} alt="" />
                </div>
              </EventTable>
            </AsyncComponent>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    actions,
    events,
  } = state.events;

  return {
    // Events
    actions,
    events,
    selected: events.selected,
  };
};

const mapDispatchToProps = {
  get: eventsGetEvent,
  delete: eventsDeleteEvent,
  select: eventsSelectEvent,
  updateTableMeta: eventsUpdateEventTableMeta,
};

export default wrapper({
  component: Events,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
  ],
});
