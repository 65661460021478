import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import { Label } from 'reactstrap';
import DatePickerComponent from 'react-datepicker';
import { CheckBoxField as CheckBox } from './CheckBox';


class DatePicker extends PureComponent {
  static propTypes = {
    type: PropTypes.oneOf(['start', 'end']).isRequired,
    showTimeSelect: PropTypes.bool.isRequired,

    // Callbacks
    onIncludeTimeChange: PropTypes.func.isRequired,
  };

  onChangeShowTimeSelect = (event) => {
    const { onIncludeTimeChange, type } = this.props;

    onIncludeTimeChange(type, event.target.checked);
  };

  render() {
    // Props
    const {
      showTimeSelect,
      ...rest
    } = this.props;

    return (
      <div className="d-flex">
        <DatePickerComponent
          {...rest}
          timeFormat="h:mm aa"
          showTimeSelect={showTimeSelect}
          dateFormat={showTimeSelect ? 'dd/MM/yyyy h:mm aa' : 'dd/MM/yyyy'}
        />
        <div className="ml-3 d-flex">
          <CheckBox
            setOnDidMount={false}
            id="showTimeSelect"
            value={showTimeSelect}
            onChange={this.onChangeShowTimeSelect}
          />
          <Label for="showTimeSelect" className="text-black-50">Include time</Label>
        </div>
      </div>
    );
  }
}

export default DatePicker;
