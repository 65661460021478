import {
  generateReducerElement as generate, reduxTypes as types, initializeActions, adjust,
} from '@tmosystems/core';

// Action creators
import {
  // Async action creators
  COMMUNICATIONS_CREATE_TEXT_MESSAGE,
  COMMUNICATIONS_GET_TEXT_MESSAGE,
  COMMUNICATIONS_CREATE_EMAIL_MESSAGE,
  COMMUNICATIONS_GET_EMAIL_MESSAGE,
  COMMUNICATIONS_CREATE_CONTACT,
  COMMUNICATIONS_GET_CONTACT,
  COMMUNICATIONS_UPDATE_CONTACT,
  COMMUNICATIONS_DELETE_CONTACT,
  COMMUNICATIONS_CREATE_CONTACT_GROUP,
  COMMUNICATIONS_GET_CONTACT_GROUP,
  COMMUNICATIONS_UPDATE_CONTACT_GROUP,
  COMMUNICATIONS_DELETE_CONTACT_GROUP,
  COMMUNICATIONS_GET_RECIPIENT_SUGGESTION,
  COMMUNICATIONS_GET_RECEIVED_MESSAGE,
  COMMUNICATIONS_CREATE_RECEIVED_MESSAGE_LABEL,
  COMMUNICATIONS_GET_RECEIVED_MESSAGE_LABEL,
  COMMUNICATIONS_UPDATE_RECEIVED_MESSAGE_LABEL,
  COMMUNICATIONS_DELETE_RECEIVED_MESSAGE_LABEL,

  // Sync action creators
  COMMUNICATIONS_SELECT_TEXT_MESSAGE,
  COMMUNICATIONS_UPDATE_TEXT_MESSAGE_TABLE_META,
  COMMUNICATIONS_SELECT_EMAIL_MESSAGE,
  COMMUNICATIONS_SELECT_CONTACT,
  COMMUNICATIONS_TOGGLE_CONTACT_MODAL,
  COMMUNICATIONS_UPDATE_CONTACT_TABLE_META,
  COMMUNICATIONS_SELECT_CONTACT_GROUP,
  COMMUNICATIONS_TOGGLE_CONTACT_GROUP_MODAL,
  COMMUNICATIONS_SET_CONTACT_GROUP_ID,
  COMMUNICATIONS_UPDATE_RECEIVED_MESSAGE_TABLE_META,
  COMMUNICATIONS_SELECT_RECEIVED_MESSAGE_LABEL,
  COMMUNICATIONS_TOGGLE_RECEIVED_MESSAGE_LABEL_MODAL,
} from './actionCreators';

const initialState = {
  textMessages: {
    instance: {},
    instances: [],
    selected: [],
    modalMeta: {
      isOpen: false,
      openMode: 'create',
    },
    tableMeta: {
      page: 0,
      rowsPerPage: 10,
    },
  },
  emailMessages: {
    instance: {},
    instances: [],
    selected: [],
    modalMeta: {
      isOpen: false,
      openMode: 'create',
    },
    tableMeta: {
      page: 0,
      rowsPerPage: 10,
    },
  },
  contacts: {
    instance: {},
    instances: [],
    selected: [],
    modalMeta: {
      isOpen: false,
      openMode: 'create',
    },
    tableMeta: {
      page: 0,
      rowsPerPage: 10,
    },
  },
  contactGroups: {
    instance: {},
    instances: [],
    selected: [],
    modalMeta: {
      isOpen: false,
      openMode: 'create',
    },
    tableMeta: {
      page: 0,
      rowsPerPage: 10,
    },
  },
  recipientSuggestions: {
    instance: {},
    instances: [],
    selected: [],
    modalMeta: {
      isOpen: false,
      openMode: 'create',
    },
    tableMeta: {
      page: 0,
      rowsPerPage: 10,
    },
  },
  receivedMessages: {
    instance: {},
    instances: [],
    selected: [],
    modalMeta: {
      isOpen: false,
      openMode: 'create',
    },
    tableMeta: {
      page: 0,
      rowsPerPage: 10,
    },
  },
  receivedMessageLabels: {
    instance: {},
    instances: [],
    selected: [],
    modalMeta: {
      isOpen: false,
      openMode: 'create',
    },
    tableMeta: {
      page: 0,
      rowsPerPage: 10,
    },
  },
  actions: initializeActions([
    COMMUNICATIONS_CREATE_TEXT_MESSAGE,
    [COMMUNICATIONS_GET_TEXT_MESSAGE, [0, 1]],
    COMMUNICATIONS_CREATE_EMAIL_MESSAGE,
    COMMUNICATIONS_GET_EMAIL_MESSAGE,
    COMMUNICATIONS_CREATE_CONTACT,
    COMMUNICATIONS_GET_CONTACT,
    COMMUNICATIONS_UPDATE_CONTACT,
    COMMUNICATIONS_DELETE_CONTACT,
    COMMUNICATIONS_CREATE_CONTACT_GROUP,
    COMMUNICATIONS_GET_CONTACT_GROUP,
    COMMUNICATIONS_UPDATE_CONTACT_GROUP,
    COMMUNICATIONS_DELETE_CONTACT_GROUP,
    COMMUNICATIONS_GET_RECIPIENT_SUGGESTION,
    COMMUNICATIONS_GET_RECEIVED_MESSAGE,
    COMMUNICATIONS_CREATE_RECEIVED_MESSAGE_LABEL,
    COMMUNICATIONS_GET_RECEIVED_MESSAGE_LABEL,
    COMMUNICATIONS_UPDATE_RECEIVED_MESSAGE_LABEL,
    COMMUNICATIONS_DELETE_RECEIVED_MESSAGE_LABEL,
  ]),
};

export default (state = initialState, action) => ({
  // Async
  ...generate(state, action, COMMUNICATIONS_CREATE_TEXT_MESSAGE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, COMMUNICATIONS_CREATE_TEXT_MESSAGE.SUCCESS) && {
        textMessages: {
          ...state.textMessages,
          instance: action.response.textMessage,
          instances: [
            ...state.textMessages.instances,
            action.response.textMessage,
          ],
        },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_GET_TEXT_MESSAGE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, COMMUNICATIONS_GET_TEXT_MESSAGE.SUCCESS) && {
        textMessages: {
          ...state.textMessages,
          ...action.response,
        },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_CREATE_EMAIL_MESSAGE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, COMMUNICATIONS_CREATE_EMAIL_MESSAGE.SUCCESS) && {
        emailMessages: {
          ...state.emailMessages,
          instance: action.response.emailMessage,
          instances: [
            ...state.emailMessages.instances,
            action.response.emailMessage,
          ],
        },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_GET_EMAIL_MESSAGE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, COMMUNICATIONS_GET_EMAIL_MESSAGE.SUCCESS) && {
        emailMessages: {
          ...state.emailMessages,
          ...action.response,
        },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_CREATE_CONTACT, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, COMMUNICATIONS_CREATE_CONTACT.SUCCESS) && {
        contacts: {
          ...state.contacts,
          instance: action.response.contact,
          instances: [
            ...state.contacts.instances,
            action.response.contact,
          ],
        },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_GET_CONTACT, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, COMMUNICATIONS_GET_CONTACT.SUCCESS) && {
        contacts: {
          ...state.contacts,
          ...action.response,
        },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_UPDATE_CONTACT, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, COMMUNICATIONS_UPDATE_CONTACT.SUCCESS) && {
        contacts: {
          ...state.contacts,
          instance: action.response.contact,
          instances: state.contacts.instances.map((instance) => {
            if (instance.id === action.response.contact.id) {
              return action.response.contact;
            }
            return instance;
          }),
          selected: initialState.contacts.selected,
        },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_DELETE_CONTACT, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, COMMUNICATIONS_DELETE_CONTACT.SUCCESS) && {
        contacts: {
          ...state.contacts,
          instance: state.contacts.instance,
          instances: state.contacts.instances
            .filter(instance => !action.response.deletedIds.includes(instance.id)),
          selected: initialState.contacts.selected,
        },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_CREATE_CONTACT_GROUP, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, COMMUNICATIONS_CREATE_CONTACT_GROUP.SUCCESS) && {
        contactGroups: {
          ...state.contactGroups,
          instance: action.response.contactGroup,
          instances: [
            ...state.contactGroups.instances,
            action.response.contactGroup,
          ],
        },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_GET_CONTACT_GROUP, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, COMMUNICATIONS_GET_CONTACT_GROUP.SUCCESS) && {
        contactGroups: {
          ...state.contactGroups,
          ...action.response,
        },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_UPDATE_CONTACT_GROUP, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, COMMUNICATIONS_UPDATE_CONTACT_GROUP.SUCCESS) && {
        contactGroups: {
          ...state.contactGroups,
          instance: action.response.contactGroup,
          instances: state.contactGroups.instances.map((instance) => {
            if (instance.id === action.response.contactGroup.id) {
              return action.response.contactGroup;
            }
            return instance;
          }),
          selected: initialState.contactGroups.selected,
        },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_DELETE_CONTACT_GROUP, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, COMMUNICATIONS_DELETE_CONTACT_GROUP.SUCCESS) && {
        contactGroups: {
          ...state.contactGroups,
          instance: state.contactGroups.instance,
          instances: state.contactGroups.instances
            .filter(instance => !action.response.deletedIds.includes(instance.id)),
          selected: initialState.contactGroups.selected,
        },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_GET_RECIPIENT_SUGGESTION, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, COMMUNICATIONS_GET_RECIPIENT_SUGGESTION.SUCCESS) && {
        recipientSuggestions: {
          ...state.recipientSuggestions,
          ...action.response,
        },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_GET_RECEIVED_MESSAGE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, COMMUNICATIONS_GET_RECEIVED_MESSAGE.SUCCESS) && {
        receivedMessages: {
          ...state.receivedMessages,
          ...action.response,
        },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_CREATE_RECEIVED_MESSAGE_LABEL, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, COMMUNICATIONS_CREATE_RECEIVED_MESSAGE_LABEL.SUCCESS) && {
        receivedMessageLabels: {
          ...state.receivedMessageLabels,
          instance: action.response.receivedMessageLabel,
          instances: [
            ...state.receivedMessageLabels.instances,
            action.response.receivedMessageLabel,
          ],
        },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_GET_RECEIVED_MESSAGE_LABEL, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, COMMUNICATIONS_GET_RECEIVED_MESSAGE_LABEL.SUCCESS) && {
        receivedMessageLabels: {
          ...state.receivedMessageLabels,
          ...action.response,
        },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_UPDATE_RECEIVED_MESSAGE_LABEL, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, COMMUNICATIONS_UPDATE_RECEIVED_MESSAGE_LABEL.SUCCESS) && {
        receivedMessageLabels: {
          ...state.receivedMessageLabels,
          instance: action.response.receivedMessageLabel,
          instances: state.receivedMessageLabels.instances.map((instance) => {
            if (instance.id === action.response.receivedMessageLabel.id) {
              return action.response.receivedMessageLabel;
            }
            return instance;
          }),
          selected: initialState.receivedMessageLabels.selected,
        },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_DELETE_RECEIVED_MESSAGE_LABEL, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, COMMUNICATIONS_DELETE_RECEIVED_MESSAGE_LABEL.SUCCESS) && {
        receivedMessageLabels: {
          ...state.receivedMessageLabels,
          instance: state.receivedMessageLabels.instance,
          instances: state.receivedMessageLabels.instances
            .filter(instance => !action.response.deletedIds.includes(instance.id)),
          selected: initialState.receivedMessageLabels.selected,
        },
      },
    },
  }),

  // Sync
  ...generate(state, action, COMMUNICATIONS_SELECT_TEXT_MESSAGE, {
    type: types.SYNC,
    adjustment: {
      textMessages: {
        ...state.textMessages,
        selected: action.selected,
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_UPDATE_TEXT_MESSAGE_TABLE_META, {
    type: types.SYNC,
    adjustment: {
      textMessages: {
        ...state.textMessages,
        tableMeta: {
          ...state.textMessages.tableMeta,
          ...action,
        },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_SELECT_EMAIL_MESSAGE, {
    type: types.SYNC,
    adjustment: {
      emailMessages: {
        ...state.emailMessages,
        selected: action.selected,
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_SELECT_CONTACT, {
    type: types.SYNC,
    adjustment: {
      contacts: {
        ...state.contacts,
        selected: action.selected,
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_TOGGLE_CONTACT_MODAL, {
    type: types.SYNC,
    adjustment: {
      contacts: {
        ...state.contacts,
        modalMeta: {
          ...action,
          isOpen: !state.contacts.modalMeta.isOpen,
          openMode: action.openMode,
        },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_UPDATE_CONTACT_TABLE_META, {
    type: types.SYNC,
    adjustment: {
      contacts: {
        ...state.contacts,
        tableMeta: {
          ...state.contacts.tableMeta,
          ...action,
        },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_SELECT_CONTACT_GROUP, {
    type: types.SYNC,
    adjustment: {
      contactGroups: {
        ...state.contactGroups,
        selected: action.selected,
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_TOGGLE_CONTACT_GROUP_MODAL, {
    type: types.SYNC,
    adjustment: {
      contactGroups: {
        ...state.contactGroups,
        modalMeta: {
          ...action,
          isOpen: !state.contactGroups.modalMeta.isOpen,
          openMode: action.openMode,
        },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_SET_CONTACT_GROUP_ID, {
    type: types.SYNC,
    adjustment: {
      contactGroupsId: action.id,
      contactGroups: {
        ...state.contactGroups,
        instance: state.contactGroups.instances.filter(instance => instance.id === parseInt(action.id, 10))[0]
        || { id: 1, name: 'All Contacts' },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_UPDATE_RECEIVED_MESSAGE_TABLE_META, {
    type: types.SYNC,
    adjustment: {
      receivedMessages: {
        ...state.receivedMessages,
        tableMeta: {
          ...state.receivedMessages.tableMeta,
          ...action,
        },
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_SELECT_RECEIVED_MESSAGE_LABEL, {
    type: types.SYNC,
    adjustment: {
      receivedMessageLabels: {
        ...state.receivedMessageLabels,
        selected: action.selected,
      },
    },
  }),
  ...generate(state, action, COMMUNICATIONS_TOGGLE_RECEIVED_MESSAGE_LABEL_MODAL, {
    type: types.SYNC,
    adjustment: {
      receivedMessageLabels: {
        ...state.receivedMessageLabels,
        modalMeta: {
          ...action,
          isOpen: !state.receivedMessageLabels.modalMeta.isOpen,
          openMode: action.openMode,
        },
      },
    },
  }),
}[action.type] || state);
