import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

// Components
import { Form, Row, Col } from 'reactstrap';
import FormComponent from '../../../shared/components/form/FormComponent';
import Input from '../../../shared/components/form/Input';
import FroalaEditor from '../../../shared/components/form/FroalaEditor';
import NoticePriorityField from './NoticePriorityField';
import EditorControls from '../../../shared/components/EditorControls';

// Actions
import {
  sectionsCreateNotice,
  sectionsCreateAndPublishNotice,
  sectionsUpdateNotice,
  sectionsUpdateAndPublishNotice,
  sectionsUpdateNoticeModalMeta,
} from '../redux/actions';
import {
  SECTIONS__CREATE_NOTICE,
  SECTIONS__CREATE_AND_PUBLISH_NOTICE,
  SECTIONS__UPDATE_NOTICE,
  SECTIONS__UPDATE_AND_PUBLISH_NOTICE,
} from '../redux/actionCreators';

const InputWrapper = styled.div`
  margin-bottom: 40px;

  input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0;
    
    &:focus {
      outline: none;
      box-shadow: none;
      border-bottom: 1px solid ${({ theme }) => theme.color.primary};
    }
  }
`;

class NoticeForm extends FormComponent {
  static propTypes = {
    isNew: PropTypes.bool.isRequired,
    initialValues: PropTypes.instanceOf(Object),

    // Sections
    sectionsActions: PropTypes.instanceOf(Object).isRequired,
    pages: PropTypes.instanceOf(Object).isRequired,
    notices: PropTypes.instanceOf(Object).isRequired,

    // Actions
    sectionsCreateNotice: PropTypes.func.isRequired,
    sectionsCreateAndPublishNotice: PropTypes.func.isRequired,
    sectionsUpdateNotice: PropTypes.func.isRequired,
    sectionsUpdateAndPublishNotice: PropTypes.func.isRequired,
    sectionsUpdateNoticeModalMeta: PropTypes.func.isRequired,

    // Callbacks
    onCreate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    initialValues: {
      title: '',
      body: '',
      priority: { label: 'Information', value: 1 },
    },
  };

  syncValidate = onSubmit => (event) => {
    event.preventDefault();
    const { values } = this.state;
    this.setState({
      errors: {
        sync: {},
        async: {},
      },
    });
    let hasSyncErrors = false;
    if (!values.title) {
      hasSyncErrors = this.setError('sync', {
        title: 'Please give this notice a title',
      });
    }
    if (!hasSyncErrors) {
      onSubmit(values);
    }
  };

  handleOnCreate = (values) => {
    const {
      sectionsCreateNotice: createNotice,
      sectionsUpdateNoticeModalMeta: updateModalMeta,
      pages,
    } = this.props;
    createNotice({
      ...values,
      pageSlug: pages.instance.slug,
    })
      .then((action) => {
        if (action.type === SECTIONS__CREATE_NOTICE.SUCCESS) {
          updateModalMeta({ isOpen: true, openMode: 'update' });
          this.setState({
            values: action.response.instance,
          });
        }
      });
  };

  handleOnCreateAndPublish = (values) => {
    const {
      sectionsCreateAndPublishNotice: createAndPublishNotice,
      sectionsUpdateNoticeModalMeta: updateModalMeta,
      pages,
    } = this.props;
    createAndPublishNotice({
      ...values,
      pageSlug: pages.instance.slug,
    })
      .then((action) => {
        if (action.type === SECTIONS__CREATE_AND_PUBLISH_NOTICE.SUCCESS) {
          updateModalMeta({ isOpen: true, openMode: 'update' });
          this.setState({
            values: action.response.instance,
          });
        }
      });
  };

  handleOnUpdate = (values) => {
    const { sectionsUpdateNotice: updateNotice } = this.props;
    updateNotice(values)
      .then((action) => {
        if (action.type === SECTIONS__UPDATE_NOTICE.SUCCESS) {
          this.setState({
            values: action.response.instance,
          });
        }
      });
  };

  handleOnUpdateAndPublish = (values) => {
    const { sectionsUpdateAndPublishNotice: updateAndPublishNotice } = this.props;
    updateAndPublishNotice(values)
      .then((action) => {
        if (action.type === SECTIONS__UPDATE_AND_PUBLISH_NOTICE.SUCCESS) {
          this.setState({
            values: action.response.instance,
          });
        }
      });
  };

  handleOnPublish = () => {};

  handleOnUnpublish = () => {};

  handleOnDelete = () => {
    const { sectionsUpdateNoticeModalMeta: updateModalMeta } = this.props;
    updateModalMeta({ isOpen: true, openMode: 'deleteConfirm' });
  };

  render() {
    // State
    const {
      values,
      errors,
    } = this.state;

    // Props
    const {
      isNew,

      // Sections
      sectionsActions: {
        SECTIONS__CREATE_NOTICE: createNoticeAction,
        SECTIONS__UPDATE_NOTICE: updateNoticeAction,
        SECTIONS__UPDATE_AND_PUBLISH_NOTICE: updateAndPublishNoticeAction,
      },
    } = this.props;

    return (
      <Row>
        <Col>
          <Form>
            <InputWrapper>
              <Input
                name="title"
                values={values}
                errors={errors}

                // Callbacks
                onChange={this.onChange}
              />
            </InputWrapper>
            <InputWrapper>
              <FroalaEditor
                name="body"
                values={values}
                errors={errors}
                config={{
                  heightMin: 300,
                  toolbarBottom: false,
                }}

                // Callbacks
                onChange={this.onChange}
              />
            </InputWrapper>
            <InputWrapper>
              <NoticePriorityField
                name="priority"
                values={values}
                errors={errors}
                options={[
                  { label: 'Information', value: 1 },
                  { label: 'Warning', value: 2, style: { color: 'red' } },
                ]}

                // Callbacks
                onChange={this.onChange}
              />
            </InputWrapper>
          </Form>
        </Col>
        <Col xl={3} className="border-left">
          <EditorControls
            userType="admin"
            instanceType="notice"
            instance={values}
            isNew={isNew}

            // Actions
            createAction={createNoticeAction}
            updateAction={updateNoticeAction}
            updateAndPublishAction={updateAndPublishNoticeAction}

            // Callbacks
            onCreate={this.syncValidate(this.handleOnCreate)}
            onCreateAndPublish={this.syncValidate(this.handleOnCreateAndPublish)}
            onUpdate={this.syncValidate(this.handleOnUpdate)}
            onUpdateAndPublish={this.syncValidate(this.handleOnUpdateAndPublish)}
            onDelete={this.handleOnDelete}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    actions: sectionsActions,
    pages,
    notices,
  } = state.sections;

  return {
    // Sections
    sectionsActions,
    pages,
    notices,
  };
};

const mapDispatchToProps = {
  sectionsCreateNotice,
  sectionsCreateAndPublishNotice,
  sectionsUpdateNotice,
  sectionsUpdateAndPublishNotice,
  sectionsUpdateNoticeModalMeta,
};

export default connect(mapStateToProps, mapDispatchToProps)(NoticeForm);
