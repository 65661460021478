/* eslint-disable max-len */
import { generateActionCreator as generate, reduxTypes as types } from '@tmosystems/core';

// Async action creators
export const REPAIRS__GET_REQUEST = generate('REPAIRS__GET_REQUEST', { type: types.ASYNC });
export const REPAIRS__UPDATE_REQUEST = generate('REPAIRS__UPDATE_REQUEST', { type: types.ASYNC });
export const REPAIRS__DELETE_REQUEST = generate('REPAIRS__DELETE_REQUEST', { type: types.ASYNC });
export const REPAIRS__CREATE_CATEGORY = generate('REPAIRS__CREATE_CATEGORY', { type: types.ASYNC });
export const REPAIRS__GET_CATEGORY = generate('REPAIRS__GET_CATEGORY', { type: types.ASYNC });
export const REPAIRS__UPDATE_CATEGORY = generate('REPAIRS__UPDATE_CATEGORY', { type: types.ASYNC });
export const REPAIRS__DELETE_CATEGORY = generate('REPAIRS__DELETE_CATEGORY', { type: types.ASYNC });
export const REPAIRS__CREATE_TYPE = generate('REPAIRS__CREATE_TYPE', { type: types.ASYNC });
export const REPAIRS__GET_TYPE = generate('REPAIRS__GET_TYPE', { type: types.ASYNC });
export const REPAIRS__UPDATE_TYPE = generate('REPAIRS__UPDATE_TYPE', { type: types.ASYNC });
export const REPAIRS__DELETE_TYPE = generate('REPAIRS__DELETE_TYPE', { type: types.ASYNC });

// Sync actions creators
export const REPAIRS__UPDATE_REQUEST_TABLE_META = generate('REPAIRS__UPDATE_REQUEST_TABLE_META', { type: types.SYNC });
export const REPAIRS__UPDATE_CATEGORY_MODAL_META = generate('REPAIRS__UPDATE_CATEGORY_MODAL_META', { type: types.SYNC });
export const REPAIRS__UPDATE_CATEGORY_TABLE_META = generate('REPAIRS__UPDATE_CATEGORY_TABLE_META', { type: types.SYNC });
export const REPAIRS__UPDATE_TYPE_MODAL_META = generate('REPAIRS__UPDATE_TYPE_MODAL_META', { type: types.SYNC });
export const REPAIRS__UPDATE_TYPE_TABLE_META = generate('REPAIRS__UPDATE_TYPE_TABLE_META', { type: types.SYNC });
export const REPAIRS__UPDATE_TYPE_SEARCH_META = generate('REPAIRS__UPDATE_TYPE_SEARCH_META', { type: types.SYNC });
export const REPAIRS__UPDATE_TYPE_STATE = generate('REPAIRS__UPDATE_TYPE_STATE', { type: types.SYNC });
