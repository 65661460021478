/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faPlus } from '@fortawesome/pro-solid-svg-icons';
import UploadFilesTable from './UploadFilesTable';


class DropZoneMultipleField extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
      })),
    ]).isRequired,
    accept: PropTypes.string,
  };

  static defaultProps = {
    accept: 'image/jpeg, image/png',
  };

  constructor() {
    super();
    this.state = {
      selected: [],
      page: 0,
    };
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(files) {
    const { onChange } = this.props;
    onChange(files.map(fl => Object.assign(fl, {
      preview: URL.createObjectURL(fl),
    })));
  }

  handleOnRemove = (index, e) => {
    const { value, onChange } = this.props;
    e.preventDefault();
    e.stopPropagation();
    onChange(value.filter((val, i) => i !== index));
  };

  handleOnSelect = (selected) => {
    this.setState({
      selected,
    });
  };

  handleOnPageChange = () => {};

  render() {
    // State
    const {
      selected,
      page,
    } = this.state;

    // Props
    const {
      name,
      value,
      accept,
    } = this.props;

    const files = value;

    return (
      <div className="dropzone dropzone--multiple">
        <Dropzone
          className="dropzone__input"
          accept={accept}
          name={name}
          onDrop={(filesToUpload) => {
            this.onDrop(value ? value.concat(filesToUpload) : filesToUpload);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="dropzone__input position-relative">
              {(!files || files.length === 0) && (
                <div className="dropzone__drop-here">
                  <FontAwesomeIcon icon={faUpload} /> Drop file(s) here to upload, or click here to open a file explorer
                </div>
              )}
              {files.length !== 0 && Array.isArray(files) && (
                <div className="w-100">
                  <UploadFilesTable
                    alwaysShowChildren
                    files={files}
                    count={files.length}
                    selected={selected}
                    page={page}

                    // Callbacks
                    onSelect={this.handleOnSelect}
                    onRemove={this.handleOnRemove}
                  >
                    <div className="text-center my-5 text-black-50">
                      <h5>
                        <FontAwesomeIcon icon={faPlus} fixedWidth className="mr-1" />Click to add more files
                      </h5>
                    </div>
                  </UploadFilesTable>
                </div>
              )}
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}


const renderDropZoneMultipleField = (props) => {
  const { input, ...rest } = props;
  return (
    <DropZoneMultipleField {...input} {...rest} />
  );
};

renderDropZoneMultipleField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
};

export default renderDropZoneMultipleField;
