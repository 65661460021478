import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Card, Col, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classNames from 'classnames';
import ProfileSettings from './ProfileSettings';

export default class ProfileTabs extends PureComponent {
  static propTypes = {
    // Settings
    updateProfile: PropTypes.func.isRequired,
    profile: PropTypes.instanceOf(Object).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
    };
  }

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    // State
    const {
      activeTab,
    } = this.state;

    // Props
    const {
      updateProfile, profile,
    } = this.props;

    return (
      <Col md={12} lg={12} xl={8}>
        <Card>
          <div className="profile__card tabs tabs--bordered-bottom rounded shadow">
            <div className="tabs__wrap">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classNames({ active: activeTab === '1' })}
                    onClick={() => {
                      this.toggle('1');
                    }}
                  >
                    Settings
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <ProfileSettings
                    handleSubmit={updateProfile}
                    initialValues={profile}
                    enableReinitialize
                  />
                </TabPane>
              </TabContent>
            </div>
          </div>
        </Card>
      </Col>
    );
  }
}
