import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

// Components
import { Form } from 'reactstrap';
import FormComponent from '../../../shared/components/form/FormComponent';
import ImageSetField from '../../images/components/ImageSetField';
import NoticeSetField from './NoticeSetField';

class HomeForm extends FormComponent {
  static propTypes = {
    // Callbacks
    onSubmit: PropTypes.func.isRequired,
  };

  syncValidate = onSubmit => (event) => {
    event.preventDefault();
    const { values } = this.state;
    this.setState({
      errors: {
        sync: {},
        async: {},
      },
    });
    // eslint-disable-next-line prefer-const
    let hasSyncErrors = false;
    if (!hasSyncErrors) {
      onSubmit(values).then(this.asyncValidate);
    }
  };

  render() {
    return (
      <Form>
        <span className="form__form-group-label">Heading images</span>
        <Field
          name="imageSet"
          component={ImageSetField}
        />
        <span className="form__form-group-label">Notices</span>
        <Field
          name="noticeSet"
          component={NoticeSetField}
        />
      </Form>
    );
  }
}

export default reduxForm({ form: 'homeForm' })(HomeForm);
