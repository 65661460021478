import { create } from 'axios';
import { createAxiosInstance } from '@tmosystems/core';

const axios = createAxiosInstance(create, { baseURL: '/private/documents/api/v1/' });

// GET
const getDocument = requestParams => axios.get('', { params: requestParams });

// POST
const createDocument = (requestData, { additionalConfig }) => axios
  .post('', requestData, { ...additionalConfig });

// PUT
const updateDocument = requestData => axios.put('', requestData);

// DELETE
const deleteDocument = requestData => axios.delete('', { data: requestData });

export default {
  GET: {
    document: getDocument,
  },
  POST: {
    document: createDocument,
  },
  PUT: {
    document: updateDocument,
  },
  DELETE: {
    document: deleteDocument,
  },
};
