import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import {
  Container, Row, Col, Card, CardBody,
} from 'reactstrap';
import ImageSidebar from './components/ImagesSidebar';
import ImageSearch from './components/ImageSearch';
import ImageGallery from './components/ImageGallery';
import UploadModal from './components/UploadModal';

// Actions
import { imagesUpdateImageScrollMeta } from './redux/actions';

class Images extends PureComponent {
  static propTypes = {
    // Actions
    imagesUpdateImageScrollMeta: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    const { imagesUpdateImageScrollMeta: updateImageScrollMeta } = this.props;
    updateImageScrollMeta({ page: 0, noMoreInstances: false });
  }

  render() {
    return (
      <>
        <Container>
          <h3 className="page-title">Images</h3>
          <p className="page-subhead">Upload and manage images.</p>
          <Card>
            <CardBody>
              <Row>
                <Col className="border-right" xs={2}>
                  <ImageSidebar />
                </Col>
                <Col>
                  <ImageSearch />
                  <ImageGallery />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>

        {/* Modals */}
        <UploadModal />
      </>
    );
  }
}

const mapDispatchToProps = {
  imagesUpdateImageScrollMeta,
};

export default connect(null, mapDispatchToProps)(Images);
