import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { squashString } from 'ggtmo-utils';

// Components
import TableCell from '@material-ui/core/TableCell';
import MatTable from '../../../shared/components/table/MatTable';


const tableColumns = [
  {
    id: 'user', label: 'User', disablePadding: false, numeric: false, align: 'left',
  },
  {
    id: 'userLevel', label: 'User level', disablePadding: false, numeric: false, align: 'left',
  },
  {
    id: 'instanceType', label: 'Instance type', disablePadding: false, numeric: false, align: 'left',
  },
  {
    id: 'dateSubmitted', label: 'Date submitted', disablePadding: false, numeric: false, align: 'left',
  },
];


const TableRow = ({
  instanceModelName, user, dateTimeSubmitted,
}) => {
  const createdByLevel = user.isStaff ? 'admin' : 'resident';

  return (
    <>
      <TableCell className="material-table__cell">{squashString(user.name)}</TableCell>
      <TableCell className="material-table__cell">{createdByLevel}</TableCell>
      <TableCell className="material-table__cell">{instanceModelName}</TableCell>
      <TableCell className="material-table__cell">{moment(dateTimeSubmitted).format('DD/MM/YY')}</TableCell>
    </>
  );
};

TableRow.propTypes = {
  instanceModelName: PropTypes.string.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  dateTimeSubmitted: PropTypes.string.isRequired,
};

const SubmissionGroupsTable = ({
  children,
  submissionGroups,
  selected,

  // Callbacks
  onSelect,
}) => (
  <div>
    <MatTable
      rowComponent={TableRow}
      columns={tableColumns}
      data={submissionGroups}
      selected={selected}

      // Callbacks
      onSelect={onSelect}
    >
      {children}
    </MatTable>
  </div>
);

SubmissionGroupsTable.propTypes = {
  children: PropTypes.node,
  submissionGroups: PropTypes.instanceOf(Array).isRequired,
  selected: PropTypes.instanceOf(Array).isRequired,

  // Callbacks
  onSelect: PropTypes.func.isRequired,
};

SubmissionGroupsTable.defaultProps = {
  children: null,
};

export default SubmissionGroupsTable;
