/* eslint-disable max-len */
import { generateActionCreator as generate, reduxTypes as types } from '@tmosystems/core';

// Async action creators
export const COMMUNICATIONS_CREATE_TEXT_MESSAGE = generate('COMMUNICATIONS_CREATE_TEXT_MESSAGE', { type: types.ASYNC });
export const COMMUNICATIONS_GET_TEXT_MESSAGE = generate('COMMUNICATIONS_GET_TEXT_MESSAGE', { type: types.ASYNC });
export const COMMUNICATIONS_CREATE_EMAIL_MESSAGE = generate('COMMUNICATIONS_CREATE_EMAIL_MESSAGE', { type: types.ASYNC });
export const COMMUNICATIONS_GET_EMAIL_MESSAGE = generate('COMMUNICATIONS_GET_EMAIL_MESSAGE', { type: types.ASYNC });
export const COMMUNICATIONS_CREATE_CONTACT = generate('COMMUNICATIONS_CREATE_CONTACT', { type: types.ASYNC });
export const COMMUNICATIONS_GET_CONTACT = generate('COMMUNICATIONS_GET_CONTACT', { type: types.ASYNC });
export const COMMUNICATIONS_UPDATE_CONTACT = generate('COMMUNICATIONS_UPDATE_CONTACT', { type: types.ASYNC });
export const COMMUNICATIONS_DELETE_CONTACT = generate('COMMUNICATIONS_DELETE_CONTACT', { type: types.ASYNC });
export const COMMUNICATIONS_CREATE_CONTACT_GROUP = generate('COMMUNICATIONS_CREATE_CONTACT_GROUP', { type: types.ASYNC });
export const COMMUNICATIONS_GET_CONTACT_GROUP = generate('COMMUNICATIONS_GET_CONTACT_GROUP', { type: types.ASYNC });
export const COMMUNICATIONS_UPDATE_CONTACT_GROUP = generate('COMMUNICATIONS_UPDATE_CONTACT_GROUP', { type: types.ASYNC });
export const COMMUNICATIONS_DELETE_CONTACT_GROUP = generate('COMMUNICATIONS_DELETE_CONTACT_GROUP', { type: types.ASYNC });
export const COMMUNICATIONS_GET_RECIPIENT_SUGGESTION = generate('COMMUNICATIONS_GET_RECIPIENT_SUGGESTION', { type: types.ASYNC });
export const COMMUNICATIONS_GET_RECEIVED_MESSAGE = generate('COMMUNICATIONS_GET_RECEIVED_MESSAGE', { type: types.ASYNC });
export const COMMUNICATIONS_CREATE_RECEIVED_MESSAGE_LABEL = generate('COMMUNICATIONS_CREATE_RECEIVED_MESSAGE_LABEL', { type: types.ASYNC });
export const COMMUNICATIONS_GET_RECEIVED_MESSAGE_LABEL = generate('COMMUNICATIONS_GET_RECEIVED_MESSAGE_LABEL', { type: types.ASYNC });
export const COMMUNICATIONS_UPDATE_RECEIVED_MESSAGE_LABEL = generate('COMMUNICATIONS_UPDATE_RECEIVED_MESSAGE_LABEL', { type: types.ASYNC });
export const COMMUNICATIONS_DELETE_RECEIVED_MESSAGE_LABEL = generate('COMMUNICATIONS_DELETE_RECEIVED_MESSAGE_LABEL', { type: types.ASYNC });

// Sync actions creators
export const COMMUNICATIONS_SELECT_TEXT_MESSAGE = generate('COMMUNICATIONS_SELECT_TEXT_MESSAGE', { type: types.SYNC });
export const COMMUNICATIONS_UPDATE_TEXT_MESSAGE_TABLE_META = generate('COMMUNICATIONS_UPDATE_TEXT_MESSAGE_TABLE_META', { type: types.SYNC });
export const COMMUNICATIONS_SELECT_EMAIL_MESSAGE = generate('COMMUNICATIONS_SELECT_EMAIL_MESSAGE', { type: types.SYNC });
export const COMMUNICATIONS_SELECT_CONTACT = generate('COMMUNICATIONS_SELECT_CONTACT', { type: types.SYNC });
export const COMMUNICATIONS_TOGGLE_CONTACT_MODAL = generate('COMMUNICATIONS_TOGGLE_CONTACT_MODAL', { type: types.SYNC });
export const COMMUNICATIONS_UPDATE_CONTACT_TABLE_META = generate('COMMUNICATIONS_UPDATE_CONTACT_TABLE_META', { type: types.SYNC });
export const COMMUNICATIONS_SELECT_CONTACT_GROUP = generate('COMMUNICATIONS_SELECT_CONTACT_GROUP', { type: types.SYNC });
export const COMMUNICATIONS_TOGGLE_CONTACT_GROUP_MODAL = generate('COMMUNICATIONS_TOGGLE_CONTACT_GROUP_MODAL', { type: types.SYNC });
export const COMMUNICATIONS_SET_CONTACT_GROUP_ID = generate('COMMUNICATIONS_SET_CONTACT_GROUP_ID', { type: types.SYNC });
export const COMMUNICATIONS_UPDATE_RECEIVED_MESSAGE_TABLE_META = generate('COMMUNICATIONS_UPDATE_RECEIVED_MESSAGE_TABLE_META', { type: types.SYNC });
export const COMMUNICATIONS_SELECT_RECEIVED_MESSAGE_LABEL = generate('COMMUNICATIONS_SELECT_RECEIVED_MESSAGE_LABEL', { type: types.SYNC });
export const COMMUNICATIONS_TOGGLE_RECEIVED_MESSAGE_LABEL_MODAL = generate('COMMUNICATIONS_TOGGLE_RECEIVED_MESSAGE_LABEL_MODAL', { type: types.SYNC });
