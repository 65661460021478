import { generateReducerElement as generate, reduxTypes as types, adjust } from '@tmosystems/core';

// Action Creators
import {
  // Async
  SECTIONS__CREATE_NOTICE,
  SECTIONS__CREATE_AND_PUBLISH_NOTICE,
  SECTIONS__UPDATE_NOTICE,
  SECTIONS__UPDATE_AND_PUBLISH_NOTICE,
  SECTIONS__DELETE_NOTICE,

  // Sync
  SECTIONS__SELECT_SECTION,
  SECTIONS__UPDATE_KEY,
} from './actionCreators';

import { initialState } from './reducer';

const customInitialState = {};

export default (state = customInitialState, action) => ({
  // Async
  ...generate(state, action, SECTIONS__CREATE_NOTICE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, SECTIONS__CREATE_NOTICE.SUCCESS) && {
        pages: {
          ...state.pages,
          instance: {
            ...state.pages.instance,
            noticeSet: {
              ...state.pages.instance.noticeSet,
              notices: [
                ...state.pages.instance.noticeSet.notices,
                action.response.instance,
              ],
            },
          },
        },
        notices: {
          ...state.notices,
          instance: action.response.instance,
          instances: [
            ...state.notices.instances,
            action.response.instance,
          ],
        },
      },
    },
  }),
  ...generate(state, action, SECTIONS__CREATE_AND_PUBLISH_NOTICE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, SECTIONS__CREATE_AND_PUBLISH_NOTICE.SUCCESS) && {
        pages: {
          ...state.pages,
          instance: {
            ...state.pages.instance,
            noticeSet: {
              ...state.pages.instance.noticeSet,
              notices: [
                ...state.pages.instance.noticeSet.notices,
                action.response.instance,
              ],
            },
          },
        },
        notices: {
          ...state.notices,
          instance: action.response.instance,
          instances: [
            ...state.notices.instances,
            action.response.instance,
          ],
        },
      },
    },
  }),
  ...generate(state, action, SECTIONS__UPDATE_NOTICE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, SECTIONS__UPDATE_NOTICE.SUCCESS) && {
        pages: {
          ...state.pages,
          instance: {
            ...state.pages.instance,
            noticeSet: {
              ...state.pages.instance.noticeSet,
              notices: state.pages.instance.noticeSet.notices.map((notice) => {
                if (notice.id === action.response.instance.id) {
                  return action.response.instance;
                }
                return notice;
              }),
            },
          },
        },
        notices: {
          ...state.notices,
          instance: action.response.instance,
          instances: state.notices.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.notices.selected,
        },
      },
    },
  }),
  ...generate(state, action, SECTIONS__UPDATE_AND_PUBLISH_NOTICE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, SECTIONS__UPDATE_AND_PUBLISH_NOTICE.SUCCESS) && {
        pages: {
          ...state.pages,
          instance: {
            ...state.pages.instance,
            noticeSet: {
              ...state.pages.instance.noticeSet,
              notices: state.pages.instance.noticeSet.notices.map((notice) => {
                if (notice.id === action.response.instance.id) {
                  return action.response.instance;
                }
                return notice;
              }),
            },
          },
        },
        notices: {
          ...state.notices,
          instance: action.response.instance,
          instances: state.notices.instances.map((instance) => {
            if (instance.id === action.response.instance.id) {
              return action.response.instance;
            }
            return instance;
          }),
          selected: initialState.notices.selected,
        },
      },
    },
  }),
  ...generate(state, action, SECTIONS__DELETE_NOTICE, {
    type: types.ASYNC,
    adjustments: {
      SUCCESS: adjust(action, SECTIONS__DELETE_NOTICE.SUCCESS) && {
        pages: {
          ...state.pages,
          instance: {
            ...state.pages.instance,
            noticeSet: {
              ...state.pages.instance.noticeSet,
              notices: state.pages.instance.noticeSet.notices
                .filter(notice => notice.id !== action.response.id),
            },
          },
        },
        notices: {
          ...state.notices,
          instance: initialState.notices.instance,
          instances: state.notices.instances.filter(instance => instance.id !== action.response.id),
          selected: initialState.notices.selected,
        },
      },
    },
  }),

  // Sync
  ...generate(state, action, SECTIONS__SELECT_SECTION, {
    type: types.SYNC,
    adjustment: {
      selectedSection: action.id,
    },
  }),
  ...generate(state, action, SECTIONS__UPDATE_KEY, {
    type: types.SYNC,
    adjustment: {
      key: action.key,
    },
  }),
}[action.type] || state);
