import { generateAction as generate, reduxTypes as types } from 'ggtmo-utils';
import service from '../service';

// Action creators
import {
  RESIDENTS_GET_RESIDENT,
  RESIDENTS_GET_ACCOUNT_REQUEST,
  RESIDENTS_UPDATE_ACCOUNT_REQUEST,
  RESIDENTS_SELECT_RESIDENT,
} from './actionCreators';

export const getResident = generate(RESIDENTS_GET_RESIDENT, {
  type: types.ASYNC,
  service: service.GET.resident,
});

export const getResidentAccountRequest = generate(RESIDENTS_GET_ACCOUNT_REQUEST, {
  type: types.ASYNC,
  service: service.GET.residentAccountRequest,
});

export const updateResidentAccountRequest = generate(RESIDENTS_UPDATE_ACCOUNT_REQUEST, {
  type: types.ASYNC,
  service: service.PUT.residentAccountRequest,
});

export const selectResident = generate(RESIDENTS_SELECT_RESIDENT, {
  type: types.SYNC,
});
