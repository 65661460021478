import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { wrapper } from 'ggtmo-utils';

// Components
import { Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';
import TopbarMenuLink from './TopbarMenuLink';


class TopbarProfile extends PureComponent {
  static propTypes = {
    // Redux
    profile: PropTypes.instanceOf(Object).isRequired,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  render() {
    // State
    const {
      collapse,
    } = this.state;

    // Props
    const {
      profile: { name },
    } = this.props;

    return (
      <div className="topbar__profile">
        <button type="button" className="topbar__avatar" onClick={this.toggle}>
          <p className="topbar__avatar-name">{name}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink title="Log Out" icon="exit" path="/logout" />
          </div>
        </Collapse>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  const { profile } = state.account;

  return {
    profile,
  };
};


export default wrapper({
  component: TopbarProfile,
  wrappers: [
    connect(mapStateToProps),
  ],
});
