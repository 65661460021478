import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';


const renderTextField = ({
  input, label, meta: { touched, error }, children, className, placeholder,
}) => (
  <TextField
    className={className}
    label={label}
    error={touched && error}
    value={input.value}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
    placeholder={placeholder}
  >
    {children}
  </TextField>
);

renderTextField.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
  placeholder: PropTypes.string,
};

renderTextField.defaultProps = {
  className: 'material-form__field',
  meta: null,
  label: '',
  children: [],
  placeholder: '',
};

export default renderTextField;
