import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { wrapper } from 'ggtmo-utils';
import { withRouter } from 'react-router-dom';

// Components
import { Card, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faNewspaper, faPlug } from '@fortawesome/pro-light-svg-icons';
import AsyncButton from '../../../shared/components/AsyncButton';

// Redux
import { twitterEnable, twitterAuthenticationSuccess } from '../redux/action';
import { INTEGRATIONS_TWITTER_ENABLE, INTEGRATIONS_TWITTER_AUTHENTICATION_SUCCESS } from '../redux/actionCreators';

class TwitterIntegration extends PureComponent {
  static propTypes = {
    // Integrations
    integrationsActions: PropTypes.instanceOf(Object).isRequired,
    twitterIntegration: PropTypes.shape({
      enable: PropTypes.bool,
    }).isRequired,

    // Actions
    twitterEnable: PropTypes.func.isRequired,
    twitterAuthenticationSuccess: PropTypes.func.isRequired,

    // Router
    match: RouterPropTypes.match.isRequired,
    history: RouterPropTypes.history.isRequired,
  };

  componentDidMount() {
    const {
      match: { params }, twitterAuthenticationSuccess: onSuccess, history,
    } = this.props;
    const { integration } = params;

    if (!integration) {
      return;
    }
    if (integration === 'twitter') {
      onSuccess({
        redirectUrl: window.location.href,
      })
        .then((action) => {
          if (action.type === INTEGRATIONS_TWITTER_AUTHENTICATION_SUCCESS.SUCCESS) {
            history.push('/integrations');
          }
        });
    }
  }

  handleOnEnable = () => {
    const { twitterEnable: enable } = this.props;

    enable()
      .then((action) => {
        if (action.type === INTEGRATIONS_TWITTER_ENABLE.SUCCESS) {
          window.location.replace(action.response.redirectUrl);
        }
      });
  };

  handleOnDisable = () => {};

  render() {
    // Props
    const {
      // Integrations
      integrationsActions: {
        INTEGRATIONS_TWITTER_ENABLE: enableAction,
      },
      twitterIntegration: {
        enabled,
      },
    } = this.props;

    return (
      <Card className="w-50">
        <CardBody className="d-flex justify-content-between">
          <div>
            <h1>
              <FontAwesomeIcon icon={faTwitter} fixedWidth />
              Twitter
            </h1>
            {enabled ? (
              <div>
                hi
              </div>
            ) : (
              <div className="mt-2">
                <p>
                  Enabling this integration will result in all your Tweets being displayed in the
                  {' '}<span className="text-primary"><FontAwesomeIcon icon={faNewspaper} fixedWidth /> News</span>
                  {' '}section of the homepage.
                </p>
              </div>
            )}
          </div>
          <div>
            {enabled ? (
              <AsyncButton
                color="danger"
                outline
                onClick={this.handleOnDisable}
              >
                Disable
              </AsyncButton>
            ) : (
              <AsyncButton
                color="primary"
                outline
                onClick={this.handleOnEnable}
                action={enableAction}
                icon={faPlug}
              >
                Enable
              </AsyncButton>
            )}
          </div>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    actions: integrationsActions,
    integrations: { twitter: twitterIntegration },
  } = state.integrations;

  return {
    // Integrations
    integrationsActions,
    twitterIntegration,
  };
};


const mapDispatchToProps = {
  twitterEnable,
  twitterAuthenticationSuccess,
};

export default wrapper({
  component: TwitterIntegration,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
  ],
});
