import {
  generateReducerElement as generate, reduxTypes as types, initializeActions,
} from 'ggtmo-utils';

// Action Creators
import {
  INTEGRATIONS_GET_INTEGRATION,
  INTEGRATIONS_TWITTER_ENABLE,
  INTEGRATIONS_TWITTER_AUTHENTICATION_SUCCESS,
} from './actionCreators';


const initialState = {
  integrations: {
    twitter: {},
  },
  actions: initializeActions([
    INTEGRATIONS_GET_INTEGRATION,
    INTEGRATIONS_TWITTER_ENABLE,
    INTEGRATIONS_TWITTER_AUTHENTICATION_SUCCESS,
  ]),
};


export default (state = initialState, action) => ({
  ...generate(state, action, INTEGRATIONS_GET_INTEGRATION, {
    type: types.ASYNC,
  }),

  ...generate(state, action, INTEGRATIONS_TWITTER_ENABLE, {
    type: types.ASYNC,
  }),

  ...generate(state, action, INTEGRATIONS_TWITTER_AUTHENTICATION_SUCCESS, {
    type: types.ASYNC,
  }),
}[action.type] || state);
