import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import { Label, Input as DefaultInput } from 'reactstrap';

const Input = ({
  name,
  label,
  showLabel,
  value,
  values,
  errors: {
    sync: syncErrors,
    async: asyncErrors,
  },
  ...rest
}) => {
  const hasSyncErrors = !!syncErrors[name];
  const hasAsyncErrors = !!asyncErrors[name];
  return (
    <>
      {showLabel && (
        // Base Reactstrap label component.
        <Label className={classNames('text-capitalize', { 'text-danger': hasAsyncErrors })}>
          {/* Append sync error to label text */}
          {label || name.split(/(?=[A-Z])/).join(' ')}
          {hasSyncErrors && (
            <span className="text-danger text-lowercase">
              {' '}- {syncErrors[name]}
            </span>
          )}
        </Label>
      )}

      {/* Async error list. */}
      {hasAsyncErrors && (
        <ul>
          {asyncErrors[name].map(asyncError => (
            <li className="text-danger">{asyncError}</li>
          ))}
        </ul>
      )}

      {/* Base Reactstrap input component. */}
      <DefaultInput
        name={name}
        value={values[name] || value}
        {...rest}
      />

      {hasSyncErrors && !showLabel ? (
        <span className="text-danger pl-2">
          {syncErrors[name]}
        </span>
      ) : null}
    </>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  value: PropTypes.string,
  values: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object).isRequired,
};

Input.defaultProps = {
  label: '',
  showLabel: true,
  value: null,
  values: {},
};

export default Input;
