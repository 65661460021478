import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import MatTableHead from './MatTableHead';

const getSorting = (order, orderBy) => (order === 'desc'
  ? (a, b) => b[orderBy] - a[orderBy]
  : (a, b) => a[orderBy] - b[orderBy]);

class MatTable extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    alwaysShowChildren: PropTypes.bool,
    columns: PropTypes.instanceOf(Array).isRequired,
    data: PropTypes.instanceOf(Array),
    selected: PropTypes.instanceOf(Array).isRequired,
    rowsPerPage: PropTypes.number,
    rowsPerPageOptions: PropTypes.instanceOf(Array),
    count: PropTypes.number,
    page: PropTypes.number,
    canSelect: PropTypes.bool,
    selectOnlyByCheckbox: PropTypes.bool,
    pagination: PropTypes.bool,
    emptyRows: PropTypes.bool,
    keepHeadOnEmpty: PropTypes.bool,

    // Components
    rowComponent: PropTypes.func.isRequired,

    // Callbacks
    onSelect: PropTypes.func,
    onSelectByRow: PropTypes.func,
    onPageChange: PropTypes.func,
    onChangePage: PropTypes.func,
    onChangeRowsPerPage: PropTypes.func,
    onRemove: PropTypes.func,
    customCallbacks: PropTypes.instanceOf(Object),
  };

  static defaultProps = {
    children: null,
    alwaysShowChildren: false,
    data: [],
    rowsPerPage: 10,
    rowsPerPageOptions: ['5', '10', '15'],
    count: null,
    page: 0,
    canSelect: true,
    selectOnlyByCheckbox: false,
    pagination: true,
    emptyRows: true,
    keepHeadOnEmpty: false,

    // Callbacks
    onSelect: () => {},
    onSelectByRow: () => {},
    // TODO: Depreciate onPageChange in favour of onChangePage.
    onPageChange: null,
    onChangePage: () => {},
    onChangeRowsPerPage: () => {},
    onRemove: () => {},
    customCallbacks: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'calories',
    };
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    const { data, onSelect } = this.props;

    if (checked) {
      const selected = data.map(instance => instance.id);
      onSelect(selected);
      return;
    }
    onSelect([]);
  };

  handleOnClick = (event, id) => {
    const { selected, onSelect } = this.props;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    onSelect(newSelected);
  };

  handleChangePage = (event, page) => {
    const { onPageChange, onChangePage } = this.props;
    if (onPageChange) {
      onPageChange(page);
    } else {
      onChangePage(page);
    }
  };

  handleChangeRowsPerPage = (event) => {
    const { value } = event.target;
    const { onChangeRowsPerPage } = this.props;
    onChangeRowsPerPage(value);
  };

  isSelected = (id) => {
    const { selected } = this.props;
    return selected.indexOf(id) !== -1;
  };

  renderTableRow = (rowData, index) => {
    // Props
    const {
      canSelect,
      selectOnlyByCheckbox,

      // Components
      rowComponent,

      // Callbacks
      onRemove,
      onSelectByRow,
      customCallbacks,
    } = this.props;
    const isSelected = canSelect && this.isSelected(rowData.id);

    return (
      <TableRow
        className="material-table__row"
        role={(canSelect && !selectOnlyByCheckbox) ? 'checkbox' : null}
        // TODO: Perform this logic in a class method
        onClick={
          // eslint-disable-next-line no-nested-ternary
          (canSelect && !selectOnlyByCheckbox)
            ? event => this.handleOnClick(event, rowData.id)
            : (selectOnlyByCheckbox ? event => onSelectByRow(event, rowData.id) : null)
        }
        aria-checked={isSelected}
        tabIndex={-1}
        key={rowData.id}
        selected={canSelect ? isSelected : false}
        style={rowData.customStyles}
      >
        {canSelect && (
          <TableCell className="material-table__cell" padding="checkbox">
            <Checkbox
              checked={isSelected}
              onChange={event => this.handleOnClick(event, rowData.id)}
              onClick={event => event.stopPropagation()}
              className="material-table__checkbox"
            />
          </TableCell>
        )}
        {rowComponent(rowData, { onRemove, index, customCallbacks })}
      </TableRow>
    );
  };

  render() {
    // State
    const {
      order,
      orderBy,
    } = this.state;

    // Props
    const {
      children,
      alwaysShowChildren,
      data,
      columns,
      selected,
      count,
      page,
      rowsPerPage,
      canSelect,
      pagination,
      emptyRows: hasEmptyRows,
      rowsPerPageOptions,
      keepHeadOnEmpty,

      // Callbacks
      onRemove,
    } = this.props;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length);

    if (data && data.length) {
      return (
        <>
          <div className="material-table__wrap">
            <Table className="material-table">
              <MatTableHead
                columns={columns}
                numSelected={selected ? selected.length : 0}
                order={order}
                orderBy={orderBy}
                rowCount={count}
                canSelect={canSelect}

                // Callbacks
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                onRemove={onRemove}
              />
              <TableBody>
                {data
                  .sort(getSorting(order, orderBy))
                  .map((rowData, index, { customStyles } = {}) => this.renderTableRow(rowData, index, customStyles))}
                {(hasEmptyRows && emptyRows > 0) && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {alwaysShowChildren && children}
          </div>
          {pagination && (
            <TablePagination
              component="div"
              className="material-table__pagination"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
            />
          )}
        </>
      );
    }

    if (keepHeadOnEmpty) {
      return (
        <div className="material-table__wrap">
          <Table className="material-table">
            <MatTableHead
              columns={columns}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              rowCount={count}
              canSelect={canSelect}

              // Callbacks
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              onRemove={onRemove}
            />
          </Table>
          {children}
        </div>
      );
    }

    return children;
  }
}

export default MatTable;
