import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import { Col } from 'reactstrap';
import Image from './Image';
import UploadImage from '../../sections/components/UploadImage';

// Redux
import { imagesUpdateImageModalMeta, imagesSelectImage } from '../redux/actions';
import ImagesModal from './ImagesModal';

export class ImageSetFieldComponent extends PureComponent {
  static propTypes = {
    // Actions
    imagesUpdateImageModalMeta: PropTypes.func.isRequired,
    imagesSelectImage: PropTypes.func.isRequired,

    // Images
    images: PropTypes.instanceOf(Object).isRequired,

    // Form
    value: PropTypes.instanceOf(Object),
    onChange: PropTypes.func.isRequired,
    editorForm: PropTypes.instanceOf(Object),
  };

  static defaultProps = {
    // Form
    value: {
      images: [],
    },
    editorForm: {},
  };

  handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { value: { images }, onChange } = this.props;
    const toMove = images.filter(image => image.id === result.draggableId)[0];
    const imagesCopy = [...images];
    imagesCopy.splice(result.source.index, 1);
    imagesCopy.splice(result.destination.index, 0, toMove);
    onChange({ images: imagesCopy });
  };

  handleToggleImageModal = () => {
    const { imagesUpdateImageModalMeta: updateImageModalMeta } = this.props;
    updateImageModalMeta({ toggle: true });
  };

  handleOnAdd = () => {
    const {
      onChange, value, images, imagesSelectImage: selectImage,
    } = this.props;

    let newImages = [];
    const { instances, selected } = images;
    if (!value || !value.images) {
      newImages = instances.filter(({ id }) => selected.includes(id));
    } else if (value.images) {
      newImages = [
        ...value.images,
        ...instances.filter(({ id }) => selected.includes(id)),
      ];
    }
    onChange({ images: newImages });
    this.handleToggleImageModal();
    selectImage({ selected: [] });
  };

  handleRemoveImage = (event) => {
    const { id } = event.currentTarget.dataset;
    const { value: { images }, onChange } = this.props;
    onChange({ images: images.filter(f => f.id !== parseInt(id, 10)) });
  };

  render() {
    // Props
    const {
      // Images
      images: {
        modalMeta,
        selected,
      },

      // Form
      value: { images },
    } = this.props;

    return (
      <>
        <DragDropContext onDragEnd={this.handleOnDragEnd}>
          <Droppable droppableId="imageSetField" direction="horizontal">
            {droppableProvided => (
              <div
                className="form__image-set-field mb-5 mt-3 mx-0"
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {images && images.map((image, index) => (
                  <Draggable key={image.id} draggableId={image.id} index={index}>
                    {draggableProvided => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                      >
                        <Image
                          {...image}
                          primary={index === 0}

                          // Callbacks
                          onRemove={this.handleRemoveImage}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
                <Col className="pl-0" xs={3}>
                  <UploadImage
                    // Callbacks
                    toggle={this.handleToggleImageModal}
                  />
                </Col>
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {/* Modals */}
        <ImagesModal
          {...modalMeta}
          selected={selected}

          // Callbacks
          toggle={this.handleToggleImageModal}
          onAdd={this.handleOnAdd}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    images,
  } = state.images;

  const {
    editorForm,
  } = state.form;

  return {
    // Images
    images,

    // Form
    editorForm,
  };
};

const mapDispatchToProps = {
  imagesUpdateImageModalMeta,
  imagesSelectImage,
};

export const ConnectedImageSetField = connect(mapStateToProps, mapDispatchToProps)(ImageSetFieldComponent);

const renderImageSetField = ({ input, ...rest }) => (
  <ConnectedImageSetField {...input} {...rest} />
);

renderImageSetField.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
};

export default renderImageSetField;
