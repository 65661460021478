import React from 'react';
import PropTypes from 'prop-types';

// Components
import ContactGroupsList from './ContactGroupsList';

const ContactGroups = ({
  contactGroups,

  // Callbacks
  contactGroupOnClick,
  toggleCreateContactGroupModal,
}) => (
  <>
    <h4 className="mb-2">Contact Groups</h4>
    <ContactGroupsList
      instances={[
        { slug: 'all-contacts', name: 'All Contacts' },
      ]}

      // Callbacks
      onClick={contactGroupOnClick}
      toggle={toggleCreateContactGroupModal}
    />
    <ContactGroupsList
      {...contactGroups}
      createButton

      // Callbacks
      onClick={contactGroupOnClick}
      toggle={toggleCreateContactGroupModal}
    />
  </>
);

ContactGroups.propTypes = {
  contactGroups: PropTypes.instanceOf(Object).isRequired,

  // Callbacks
  contactGroupOnClick: PropTypes.func.isRequired,
  toggleCreateContactGroupModal: PropTypes.func.isRequired,
};

export default ContactGroups;
