import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

// Components
import { Button, Input as DefaultInput } from 'reactstrap';
import Select from 'react-select';
import TableCell from '@material-ui/core/TableCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import StylelessButton from '../../../shared/components/StylelessButton';
import MatTable from '../../../shared/components/table/MatTable';
import Search from '../../../shared/components/Search';

// Utils
import getId from '../../../shared/utils/getId';

// Actions
import {
  repairsCreateType,
  repairsGetType,
  repairsUpdateType,
  repairsDeleteType,
  repairsUpdateTypeTableMeta,
  repairsUpdateTypeSearchMeta,
  repairsUpdateTypeState,
} from '../redux/actions';
import {
  REPAIRS__CREATE_TYPE,
  REPAIRS__DELETE_TYPE,
  REPAIRS__GET_TYPE,
  REPAIRS__UPDATE_TYPE,
} from '../redux/actionCreators';

// Images
import repairTypes from '../../../shared/img/repairTypes.png';

const Input = styled(DefaultInput)`
  outline: none;
  border-radius: 0;
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
  padding: 0;
  margin: 0;
  max-width: 80%;
  font-size: 14px;
  
  &:focus {
    outline: none;
    box-shadow: none;
    border-bottom: 2px solid ${({ theme }) => theme.color.primary};
  }
`;

const ActionButton = styled(StylelessButton)`
  padding: 6px 8.75px;
  font-size: 18px;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0 , 0.04);
  }
`;

class TypesTable extends Component {
  static propTypes = {
    // Repairs
    repairsActions: PropTypes.instanceOf(Object).isRequired,
    categories: PropTypes.instanceOf(Object).isRequired,
    types: PropTypes.instanceOf(Object).isRequired,

    // Actions
    createType: PropTypes.func.isRequired,
    getType: PropTypes.func.isRequired,
    updateType: PropTypes.func.isRequired,
    deleteType: PropTypes.func.isRequired,
    updateTypeTableMeta: PropTypes.func.isRequired,
    updateTypeSearchMeta: PropTypes.func.isRequired,
    updateTypeState: PropTypes.func.isRequired,
  };

  tableColumns = [
    {
      id: 'name', label: 'Name', disablePadding: false, numeric: false, align: 'left',
    },
    {
      id: 'description', label: 'Description', disablePadding: false, numeric: false, align: 'left',
    },
    {
      id: 'responsibility', label: 'Responsibility', disablePadding: false, numeric: false, align: 'left',
    },
    {
      id: 'actions', label: 'Actions', disablePadding: false, numeric: false, align: 'left',
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      editingRowIndex: -1,
      addingNewRow: false,
      deleting: false,
      deletingRowIndex: -1,
    };
  }

  componentDidMount() {
    this.handleOnGet();
  }

  handleCreateRow = () => {
    const { createType, categories, types } = this.props;
    createType({
      name: types.instance.name,
      description: types.instance.description,
      responsibility: types.instance.responsibility,
      categorySlug: categories.instance.slug,
    })
      .then((action) => {
        if (action.type === REPAIRS__CREATE_TYPE.SUCCESS) {
          this.setState({
            addingNewRow: false,
            editing: false,
            editingRowIndex: -1,
          });
        }
      });
  };

  handleOnAddRow = () => {
    const { updateTypeState, types } = this.props;
    const { length } = types.instances;
    const newTypesInstances = [
      ...(length === types.tableMeta.rowsPerPage ? types.instances.slice(0, length - 1) : types.instances),
    ];
    const newTypeInstance = {
      name: '',
      description: '',
      responsibility: {
        value: '1',
        label: 'Gloucester Grove TMO',
      },
    };
    const newTypeState = {
      ...types,
      instance: newTypeInstance,
      instances: [
        newTypeInstance,
        ...newTypesInstances,
      ],
    };
    if (length === types.tableMeta.rowsPerPage) {
      updateTypeState({
        ...newTypeState,
        removedInstance: types.instances[types.tableMeta.rowsPerPage - 1],
      });
    } else {
      updateTypeState(newTypeState);
    }
    this.setState({
      editing: true,
      editingRowIndex: 0,
      addingNewRow: true,
    });
  };

  handleOnCancelAddRow = () => {
    const { updateTypeState, types } = this.props;
    const newTypesInstances = types.instances.slice(1);
    const newTypeState = {
      ...types,
      instances: newTypesInstances,
    };
    if (types.removedInstance) {
      updateTypeState({
        ...newTypeState,
        instances: [
          ...newTypesInstances,
          types.removedInstance,
        ],
      });
    } else {
      updateTypeState(newTypeState);
    }
    this.setState({
      editing: false,
      editingRowIndex: -1,
      addingNewRow: false,
    });
  };

  handleOnEditRow = (event) => {
    event.preventDefault();
    const index = parseInt(event.currentTarget.dataset.index, 10);
    const { updateTypeState, types } = this.props;
    updateTypeState({
      ...types,
      instance: types.instances[index],
    });
    this.setState({
      editing: true,
      editingRowIndex: index,
    });
  };

  handleOnCancelEditRow = () => {
    this.setState({
      editing: false,
      editingRowIndex: -1,
    });
  };

  handleOnUpdateRow = (event) => {
    const id = getId(event);
    const { updateType, types } = this.props;
    updateType({
      id: types.instance.id,
      name: types.instance.name,
      description: types.instance.description,
      responsibility: types.instance.responsibility.value,
    }, { asyncId: id })
      .then((action) => {
        if (action.type === REPAIRS__UPDATE_TYPE.SUCCESS) {
          this.handleOnCancelEditRow();
        }
      });
  };

  handleOnDeleteType = (event) => {
    const index = parseInt(event.currentTarget.dataset.index, 10);
    this.setState({
      deleting: true,
      deletingRowIndex: index,
    });
  };

  handleOnConfirmDeleteType = () => {
    const { deletingRowIndex } = this.state;
    const { deleteType, types } = this.props;
    const { id } = types.instances[deletingRowIndex];
    deleteType({ id }, { asyncId: id })
      .then((action) => {
        if (action.type === REPAIRS__DELETE_TYPE.SUCCESS) {
          this.handleOnCancelDeleteRow();
        }
      });
  };

  handleOnCancelDeleteRow = () => {
    this.setState({
      deleting: false,
      deletingRowIndex: -1,
    });
  };

  handleOnSearch = (searchTerm) => {
    const {
      categories,

      // Actions
      getType,
      updateTypeTableMeta,
      updateTypeSearchMeta,
    } = this.props;
    return getType({
      search: true,
      searchTerm,
      'category.slug': categories.instance.slug,
    }, { asyncId: 'search' })
      .then((action) => {
        if (action.type === REPAIRS__GET_TYPE.SUCCESS) {
          updateTypeTableMeta({ page: 0 });
          updateTypeSearchMeta({ previousSearchTerm: searchTerm });
        }
      });
  };

  handleOnGet = ({ search, page, rowsPerPage } = {}) => {
    const { getType, types, categories } = this.props;
    return getType({
      list: true,
      page: page || types.tableMeta.page,
      size: rowsPerPage || types.tableMeta.rowsPerPage,
      'category.slug': categories.instance.slug,
    }, search ? { asyncId: search ? 'search' : undefined } : {});
  };

  handleSetSearchMeta = (searchMeta) => {
    const { updateTypeSearchMeta } = this.props;
    updateTypeSearchMeta(searchMeta);
  };

  handleOnChangeText = (event) => {
    const { name, value } = event.target;
    const { updateTypeState, types } = this.props;
    updateTypeState({
      ...types,
      instance: {
        ...types.instance,
        [name]: value,
      },
    });
  };

  handleOnChangeDropDown = (value) => {
    const { updateTypeState, types } = this.props;
    updateTypeState({
      ...types,
      instance: {
        ...types.instance,
        responsibility: value,
      },
    });
  };

  handleOnChangePage = (page) => {
    const { addingNewRow } = this.state;
    const { updateTypeTableMeta } = this.props;
    if (addingNewRow) {
      this.handleOnCancelAddRow();
    }
    this.handleOnGet({ page })
      .then((action) => {
        if (action.type === REPAIRS__GET_TYPE.SUCCESS) {
          updateTypeTableMeta({ page });
        }
      });
  };

  handleOnChangeRowsPerPage = (rowsPerPage) => {
    const { updateTypeTableMeta } = this.props;
    this.handleOnGet({ rowsPerPage })
      .then((action) => {
        if (action.type === REPAIRS__GET_TYPE.SUCCESS) {
          updateTypeTableMeta({ rowsPerPage });
        }
      });
  };

  renderEditableTextCell = ({
    name,
    index,
    children,
  }) => {
    const { editing, editingRowIndex } = this.state;
    const { types } = this.props;
    if (editing && editingRowIndex === index) {
      return (
        <TableCell className="material-table__cell">
          <Input
            onChange={this.handleOnChangeText}
            name={name}
            value={types.instance[name]}
          />
        </TableCell>
      );
    }
    return children;
  };

  renderEditableDropdownCell = ({ name, index, children }) => {
    const { editing, editingRowIndex } = this.state;
    const { types } = this.props;
    if (editing && editingRowIndex === index) {
      return (
        <TableCell className="material-table__cell">
          <Select
            options={[
              { value: '1', label: 'Gloucester Grove TMO' },
              { value: '2', label: 'Resident' },
              { value: '3', label: 'Southwark Council' },
            ]}
            onChange={this.handleOnChangeDropDown}
            value={types.instance[name]}
          />
        </TableCell>
      );
    }
    return children;
  };

  renderTableRow = ({
    id,
    name,
    description,
    responsibility,
  }, { index }) => {
    // State
    const {
      editing,
      editingRowIndex,
      addingNewRow,
      deleting,
      deletingRowIndex,
    } = this.state;

    // Props
    const {
      repairsActions: {
        REPAIRS__CREATE_TYPE: createTypeAction,
        REPAIRS__UPDATE_TYPE: updateTypeAction,
        REPAIRS__DELETE_TYPE: deleteTypeAction,
      },
    } = this.props;

    const { loading: loadingCreate } = createTypeAction;
    const { loading: loadingUpdate } = updateTypeAction[id] || {};
    const { loading: loadingDelete } = deleteTypeAction[id] || {};

    return (deleting && deletingRowIndex === index) ? (
      <>
        <TableCell className="material-table__cell">
          Are you sure you want to delete this row?
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell>
          <ActionButton
            onClick={this.handleOnConfirmDeleteType}
            disabled={editing && editingRowIndex !== index}
            data-index={index}
          >
            <FontAwesomeIcon icon={loadingDelete ? faSpinnerThird : faCheck} fixedWidth spin={loadingDelete} />
          </ActionButton>
          <ActionButton
            onClick={this.handleOnCancelDeleteRow}
            disabled={loadingDelete}
          >
            <FontAwesomeIcon icon={faTimes} fixedWidth />
          </ActionButton>
        </TableCell>
      </>
    ) : (
      <>
        <this.renderEditableTextCell name="name" value={name} index={index}>
          <TableCell className="material-table__cell">
            {name}
          </TableCell>
        </this.renderEditableTextCell>
        <this.renderEditableTextCell name="description" value={description} index={index}>
          <TableCell className="material-table__cell">
            {description}
          </TableCell>
        </this.renderEditableTextCell>
        <this.renderEditableDropdownCell name="responsibility" value={responsibility} index={index}>
          <TableCell className="material-table__cell">
            {responsibility.label}
          </TableCell>
        </this.renderEditableDropdownCell>
        <TableCell className="material-table__cell">
          {editing && editingRowIndex === index ? (
            <ActionButton onClick={addingNewRow ? this.handleCreateRow : this.handleOnUpdateRow} data-id={id}>
              <FontAwesomeIcon
                icon={(loadingCreate || loadingUpdate) ? faSpinnerThird : faCheck}
                fixedWidth
                spin={addingNewRow ? loadingCreate : loadingUpdate}
              />
            </ActionButton>
          ) : (
            <ActionButton
              onClick={this.handleOnEditRow}
              data-index={index}
              disabled={editing && editingRowIndex !== index}
            >
              <FontAwesomeIcon icon={faEdit} fixedWidth />
            </ActionButton>
          )}
          {editing && editingRowIndex === index ? (
            <ActionButton
              onClick={addingNewRow ? this.handleOnCancelAddRow : this.handleOnCancelEditRow}
              disabled={addingNewRow ? loadingCreate : loadingUpdate}
            >
              <FontAwesomeIcon icon={faTimes} fixedWidth />
            </ActionButton>
          ) : (
            <ActionButton
              onClick={this.handleOnDeleteType}
              disabled={editing && editingRowIndex !== index}
              data-index={index}
            >
              <FontAwesomeIcon icon={loadingUpdate ? faSpinnerThird : faTrash} fixedWidth spin={loadingDelete} />
            </ActionButton>
          )}
        </TableCell>
      </>
    );
  };

  render() {
    // State
    const {
      editing,
      editingRowIndex,
      addingNewRow,
    } = this.state;

    // Props
    const {
      repairsActions: {
        REPAIRS__GET_TYPE: getTypeAction,
      },
      types: {
        instances,
        tableMeta,
        searchMeta,
        count,
      },
    } = this.props;

    const data = instances.map((type, index) => {
      if (editing && editingRowIndex !== index) {
        return {
          ...type,
          customStyles: {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            opacity: 0.5,
          },
        };
      }
      return type;
    });

    return (
      <div>
        <div className="d-flex">
          <div className="flex-grow-1 mr-3">
            <Search
              suggest={false}
              searchMeta={searchMeta}
              actionCreators={{
                GET: getTypeAction,
              }}

              // Callbacks
              setSearchMeta={this.handleSetSearchMeta}
              onSearch={this.handleOnSearch}
              onGet={this.handleOnGet}
            />
          </div>
          <Button color="primary" outline className="px-5" onClick={this.handleOnAddRow} disabled={addingNewRow}>
            <FontAwesomeIcon icon={faPlus} fixedWidth /> Add new
          </Button>
        </div>
        <MatTable
          rowComponent={this.renderTableRow}
          columns={this.tableColumns}
          data={data}
          selected={[]}
          page={tableMeta.page}
          count={count}
          rowsPerPage={tableMeta.rowsPerPage}
          rowsPerPageOptions={[10, 25]}
          emptyRows
          keepHeadOnEmpty
          canSelect={false}

          // Callbacks
          onChangePage={this.handleOnChangePage}
          onChangeRowsPerPage={this.handleOnChangeRowsPerPage}
        >
          {/* Change editor__no-instances to a styled-component */}
          <div className="editor__no-instances mt-5">
            <h4>No types found for the search term <strong>{searchMeta.previousSearchTerm}</strong>.</h4>
            <p>
              Hit the
              {' '}
              <span className="text-primary border border-primary rounded p-1">
                <FontAwesomeIcon icon={faPlus} fixedWidth />Add new
              </span>
              {' '}
              button to add a new type.
            </p>
            <img src={repairTypes} alt="" />
          </div>
        </MatTable>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    actions: repairsActions,
    categories,
    types,
  } = state.repairs;

  return {
    // Repairs
    repairsActions,
    categories,
    types,
  };
};

const mapDispatchToProps = {
  createType: repairsCreateType,
  getType: repairsGetType,
  updateType: repairsUpdateType,
  deleteType: repairsDeleteType,
  updateTypeTableMeta: repairsUpdateTypeTableMeta,
  updateTypeSearchMeta: repairsUpdateTypeSearchMeta,
  updateTypeState: repairsUpdateTypeState,
};

export default connect(mapStateToProps, mapDispatchToProps)(TypesTable);
