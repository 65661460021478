import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Form, ButtonToolbar } from 'reactstrap';
import FormComponent from '../../../../shared/components/form/FormComponent';
import Input from '../../../../shared/components/form/Input';
import AsyncButton from '../../../../shared/components/AsyncButton';

class ReceivedMessageLabelForm extends FormComponent {
  static propTypes = {
    action: PropTypes.instanceOf(Object).isRequired,

    // Callbacks
    onSubmit: PropTypes.func.isRequired,
  };

  syncValidate = onSubmit => (event) => {
    event.preventDefault();
    const { values } = this.state;
    this.setState({
      errors: {
        sync: {},
        async: {},
      },
    });
    let hasSyncErrors = false;
    if (!values.name) {
      hasSyncErrors = this.setError('sync', {
        name: 'please provide a label name',
      });
    }
    if (!hasSyncErrors) {
      onSubmit(values).then(this.asyncValidate);
    }
  };

  render() {
    // State
    const {
      values,
      errors,
    } = this.state;

    // Props
    const {
      action,

      // Callbacks
      onSubmit,
    } = this.props;

    return (
      <Form onSubmit={this.syncValidate(onSubmit)}>
        {errors.async.serverError && (
          <div>
            <p className="text-danger">
              {errors.async.serverError}
            </p>
          </div>
        )}
        <Input
          type="text"
          name="name"
          values={values}
          errors={errors}
          onFocus={this.onFocus}
          onChange={this.onChange}
        />
        <ButtonToolbar className="justify-content-end">
          <AsyncButton action={action} outline size="sm" color="primary">
            Create label
          </AsyncButton>
        </ButtonToolbar>
      </Form>
    );
  }
}

export default ReceivedMessageLabelForm;
