import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

// Components
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import Section from '../../shared/components/Section';
import AsyncComponent from '../../shared/components/AsyncComponent';
import HomeEditor from './components/HomeEditor';

// Redux
import { sectionsGetPage } from './redux/actions';

const List = styled.div``;

const ListItem = styled.div`
  padding: 0.2rem 0 0.2rem;
  margin-bottom: 0.4rem;
  transition: all 50ms;
  
  &:hover {
    padding: 0.2rem;
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
`;

class Sections extends PureComponent {
  static propTypes = {
    // Sections
    sectionsActions: PropTypes.instanceOf(Object).isRequired,
    pages: PropTypes.instanceOf(Object).isRequired,

    // Actions
    get: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { get } = this.props;
    get({ list: true });
    get({ list: false, slug: '' });
  }

  render() {
    // Props
    const {
      sectionsActions: {
        SECTIONS__GET_PAGE: getPageAction,
      },
      pages,
    } = this.props;

    return (
      <Container className="pb-5">
        <Section
          title="Home Page"
          subtitle="Edit the home page."
          className="shadow-sm mb-5"
        >
          <HomeEditor />
        </Section>
        <Section
          title="Content"
          subtitle="Edit the site's content pages."
          className="shadow-sm"
        >
          <AsyncComponent action={getPageAction}>
            <List>
              {pages.instances.map(page => (page.slug === '' ? null : (
                <ListItem key={page.slug}>
                  <Link to={`/content/editor/${page.slug}`}>
                    <h4>{page.title}</h4>
                  </Link>
                </ListItem>
              )))}
            </List>
          </AsyncComponent>
        </Section>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    actions: sectionsActions,
    pages,
  } = state.sections;

  return {
    sectionsActions,
    pages,
  };
};

const mapDispatchToProps = {
  get: sectionsGetPage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Sections);
