import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';

const UploadImage = ({ toggle, className }) => (
  <div
    className={classNames('bg-white rounded form__image-set-field__upload-image text-center text-black-50', className)}
  >
    <div
      role="presentation"
      onClick={toggle}
    >
      <FontAwesomeIcon icon={faPlus} size="4x" />
      <p>Add image</p>
    </div>
  </div>
);

UploadImage.propTypes = {
  className: PropTypes.string,

  // Callbacks
  toggle: PropTypes.func.isRequired,
};

UploadImage.defaultProps = {
  className: '',
};

export default UploadImage;
