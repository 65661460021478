import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { wrapper } from 'ggtmo-utils';

// Components
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';

// Images
import logo from '../../../shared/img/logo/logo_light.png';

// Redux
import {
  verify,
} from '../redux/actions';
import {
  ACCOUNT_VERIFY,
} from '../redux/actionCreators';


// TODO: Should this use separate components?
class Register extends PureComponent {
  static propTypes = {
    // Redux
    dispatch: PropTypes.func.isRequired,
    accountActions: PropTypes.instanceOf(Object).isRequired,

    // Router
    history: ReactRouterPropTypes.history.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
  };

  verify = (event) => {
    const { dispatch, history, match } = this.props;
    const { key } = match.params;

    event.preventDefault();
    dispatch(verify({ key }))
      .then((action) => {
        if (action.type === ACCOUNT_VERIFY.SUCCESS) {
          history.push('/');
        }
      });
  };

  render() {
    // Props
    const {
      match: { params },
      accountActions: { ACCOUNT_VERIFY: { error } },
    } = this.props;

    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card text-center shadow">
            <img src={logo} alt="" className="mb-4" />
            <div className="text-danger mb-3">
              {error && (error.response ? (
                <span><FontAwesomeIcon icon={faExclamationTriangle} /> {error.response.data.detail}</span>
              ) : (
                <span><FontAwesomeIcon icon={faExclamationTriangle} /> {error.message}</span>
              ))}
            </div>
            {!params.key ? (
              <p>
                Check your email for a verification email and click the &#34;verify email&#34; button to get started.
              </p>
            ) : (
              <Button color="primary" onClick={this.verify}>Verify Email</Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  const { actions: accountActions } = state.account;

  return {
    accountActions,
  };
};


export default wrapper({
  component: Register,
  wrappers: [
    connect(mapStateToProps),
    withRouter,
  ],
});
