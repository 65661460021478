import { generateAction as generate, reduxTypes as types } from 'ggtmo-utils';
import service from '../service';

// Action Creators
import {
  ADMIN_GET_SUBMISSION_GROUP,
  ADMIN_UPDATE_SUBMISSION_GROUP,
  ADMIN_SELECT_SUBMISSION_GROUP,
  ADMIN_CREATE_SUBMISSION,
} from './actionCreators';


export const getSubmissionGroup = generate(ADMIN_GET_SUBMISSION_GROUP, {
  type: types.ASYNC,
  service: service.GET.submissionGroup,
});

export const selectSubmissionGroup = generate(ADMIN_SELECT_SUBMISSION_GROUP, {
  type: types.SYNC,
});

export const updateSubmissionGroup = generate(ADMIN_UPDATE_SUBMISSION_GROUP, {
  type: types.ASYNC,
  service: service.PUT.submissionGroup,
});


export const createSubmission = generate(ADMIN_CREATE_SUBMISSION, {
  type: types.ASYNC,
  service: service.POST.submission,
});
