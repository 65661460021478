import { create } from 'axios';
import { createAxiosInstance } from '@tmosystems/core';

const axios = createAxiosInstance(create, { baseURL: '/integrations/api/v1/' });

// GET
const getIntegration = params => axios.get('', { params });
const twitterEnable = params => axios.get('twitter/initiate_authentication/', { params });

// POST
const twitterAuthenticationSuccess = requestData => axios.post('twitter/authentication_success/', requestData);

export default {
  GET: {
    integration: getIntegration,
  },
  twitter: {
    GET: {
      enable: twitterEnable,
    },
    POST: {
      authenticationSuccess: twitterAuthenticationSuccess,
    },
  },
};
