import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import StylelessButton from './StylelessButton';
import ErrorBoundary from './ErrorBoundary';

const PageWrapperStyled = styled.div`
  border-radius: 0.5rem;
  background-color: white;
`;

const PageHeader = styled.div`
  padding: 1rem 2rem;
  border-bottom: 2px solid ${({ theme }) => theme.color.primary};
  display: flex;
  justify-content: space-between;
`;

const PageContent = styled.div`
  padding: 2rem;
`;

const Section = ({
  title,
  subtitle,
  children,
  className,

  // Callbacks
  onHelp,
}) => (
  <PageWrapperStyled className={className}>
    <PageHeader>
      <div>
        <h3 className="page-title">{title}</h3>
        <p className="page-subhead mb-0">{subtitle}</p>
      </div>
      <div>
        <StylelessButton
          onClick={onHelp}
          style={{
            fontSize: '1rem',
            lineHeight: '62px',
          }}
          className="mr-3 text-black-50"
        >
          <FontAwesomeIcon icon={faQuestionCircle} className="mr-1" />Help
        </StylelessButton>
      </div>
    </PageHeader>
    <PageContent>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </PageContent>
  </PageWrapperStyled>
);

Section.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,

  // Callbacks
  onHelp: PropTypes.func.isRequired,
};

Section.defaultProps = {
  className: '',
};

export default Section;
