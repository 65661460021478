import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import { Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';

const CheckboxWrapper = styled.label`
  display: flex;

  input {
    position: absolute;
    opacity: 0;
  }
  
  &:hover {
    cursor: pointer;
  }
`;

const Checkbox = styled.div`
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid ${({ theme, checked }) => (checked ? theme.color.primary : 'rgba(0, 0, 0, 0.05)')};
  border-radius: 0.2rem;
`;

const CheckboxLabel = styled.span`
  font-size: 1rem;
  user-select: none;
`;

const CheckboxCheck = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  svg {
    width: 1.5rem !important;
    height: 1.5rem !important;
    padding: 0.2rem;
  }
`;

class NoticePriorityField extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.instanceOf(Array).isRequired,
    values: PropTypes.instanceOf(Object),
    showLabel: PropTypes.bool,
    label: PropTypes.string,

    // Callbacks
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    label: '',
    showLabel: true,
    values: {},
  };

  handleOnChange = (event) => {
    const option = JSON.parse(event.currentTarget.dataset.option);
    const { name, onChange } = this.props;
    onChange({ target: { name, value: option } });
  };

  render() {
    // Props
    const {
      name,
      options,
      values,
      showLabel,
      label,
    } = this.props;
    const { value } = values[name] || {};

    return (
      <div>
        {showLabel && (
          // Base Reactstrap label component
          <Label className="text-capitalize">
            {/* Append sync error to label text */}
            {label || name.split(/(?=[A-Z])/).join(' ')}
          </Label>
        )}
        <div className="d-flex">
          {options.map(option => (
            <CheckboxWrapper className="mr-5" for={option.value} key={option.value}>
              <Checkbox checked={value === option.value}>
                {value === option.value && (
                  <CheckboxCheck className="text-primary">
                    <FontAwesomeIcon icon={faCheck} />
                  </CheckboxCheck>
                )}
              </Checkbox>
              <input
                name={name}
                type="radio"
                id={option.value}
                data-option={JSON.stringify(option)}

                // Callbacks
                onChange={this.handleOnChange}
              />
              <CheckboxLabel className="ml-2" style={option.style}>{option.label}</CheckboxLabel>
            </CheckboxWrapper>
          ))}
        </div>
      </div>
    );
  }
}

export default NoticePriorityField;
