import React from 'react';

// Components
import { Container } from 'reactstrap';
import Section from '../../shared/components/Section';
import RequestsManager from './components/RequestsManager';
import CategoryEditor from './components/CategoryEditor';

const Repairs = () => (
  <Container className="pb-5">
    <Section
      title="Requests"
      subtitle="View and respond to repair requests."
      className="mb-5 shadow-sm"
    >
      <RequestsManager />
    </Section>
    <Section
      title="Repair Request Types"
      subtitle="Add and edit repairs info on the public site."
      className="shadow-sm"
    >
      <CategoryEditor />
    </Section>
  </Container>
);

export default Repairs;
