import React from 'react';

// Components
import {
  Container, Row,
} from 'reactstrap';
import {
  faGlobeEurope,
  faCalendarAlt,
  faBook,
  faUserCheck,
  faWrench,
  faFileAlt,
  faUser,
  faImages,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import DashboardIcons from '../../shared/components/DashboardIcons';

// Utils
import getUserType from '../../shared/utils/getUserType';


const Dashboard = () => {
  const isAdmin = getUserType() === 'admin';

  const icons = [
    {
      title: 'Events',
      subtitle: 'Add and edit events',
      to: '/events',
      icon: faCalendarAlt,
    },
    {
      title: 'News',
      subtitle: 'Add and edit news stories',
      to: '/news',
      icon: faGlobeEurope,
    },
    {
      title: 'Submissions',
      subtitle: 'View and approve submitted content',
      to: '/submissions',
      icon: faUserCheck,
      visible: isAdmin,
    },
    {
      title: 'Content',
      subtitle: "Edit the site's content pages",
      to: '/content',
      icon: faBook,
      visible: isAdmin,
    },
    {
      title: 'Repairs',
      subtitle: 'Manage repair requests',
      to: '/repairs',
      icon: faWrench,
      visible: isAdmin,
    },
    {
      title: 'Residents',
      subtitle: 'Manage resident accounts',
      to: '/residents',
      icon: faUsers,
    },
    {
      title: 'Images',
      subtitle: 'Upload and manage images',
      to: '/images',
      icon: faImages,
      visible: isAdmin,
    },
    {
      title: 'Documents',
      subtitle: 'Upload and manage documents',
      to: '/documents',
      icon: faFileAlt,
      visible: isAdmin,
    },
    {
      title: 'Profile',
      subtitle: 'Manage your profile',
      to: '/account/profile',
      icon: faUser,
    },
  ];

  return (
    <Container>
      <h3 className="page-title">Dashboard</h3>
      <p className="page-subhead">Click on one of the icons to get started.</p>
      <hr />
      <Row>
        <DashboardIcons items={icons} />
      </Row>
    </Container>
  );
};

export default Dashboard;
