import variables from './abstracts/variables';
import scaffholding from './base/scaffholding';
import { color } from './themes/themes';


export default {
  variables,
  scaffholding,
  color,
};
