import React from 'react';
import PropTypes from 'prop-types';
import { squashString } from 'ggtmo-utils';

// Components
// import { Link } from 'react-router-dom';
import TableCell from '@material-ui/core/TableCell';
import MatTable from '../../../../shared/components/table/MatTable';

const tableColumns = [
  {
    id: 'name', label: 'Name', disablePadding: false, numeric: false, align: 'left',
  },
  {
    id: 'rentAccountNumber', label: 'Rent Account Number', disablePadding: false, numeric: false, align: 'left',
  },
];

const TableRow = ({
  resident: {
    user: {
      name,
    },
    rentAccountNumber,
  },
}) => (
  <>
    <TableCell className="material-table__cell">
      {squashString(name, { length: 80 })}
    </TableCell>
    <TableCell className="material-table__cell">
      {rentAccountNumber}
    </TableCell>
  </>
);

TableRow.propTypes = {
  resident: PropTypes.shape({
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    rentAccountNumber: PropTypes.string.isRequired,
  }).isRequired,
};

const ResidentAccountRequestsTable = ({
  children,
  residentAccountRequests,
  page,
  count,

  // Callbacks
  onPageChange,
  onSelectByRow,
}) => (
  <div>
    <MatTable
      rowComponent={TableRow}
      columns={tableColumns}
      data={residentAccountRequests}
      page={page}
      count={count}
      canSelect={false}
      selectOnlyByCheckbox

      // Callbacks
      onSelectByRow={onSelectByRow}
      onPageChange={onPageChange}
    >
      {children}
    </MatTable>
  </div>
);

ResidentAccountRequestsTable.propTypes = {
  children: PropTypes.node,
  residentAccountRequests: PropTypes.instanceOf(Array).isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,

  // Callbacks
  onSelectByRow: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

ResidentAccountRequestsTable.defaultProps = {
  children: null,
};

export default ResidentAccountRequestsTable;
