import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { wrapper } from 'ggtmo-utils';
import { Field, reduxForm } from 'redux-form';

// Components
// import { Form } from 'reactstrap';
import { faAt } from '@fortawesome/pro-light-svg-icons';
import renderField from '../../../shared/components/form/renderField';
import AsyncForgotPasswordFormErrors from './AsyncForgotPasswordFormErrors';

// Redux
import {
  forgotPassword,
} from '../redux/actions';
import {
  ACCOUNT_FORGOT_PASSWORD,
} from '../redux/actionCreators';


// Images
import logo from '../../../shared/img/logo/logo_light.png';
import AsyncButton from '../../../shared/components/AsyncButton';


class ForgetPassword extends Component {
  static propTypes = {
    // Redux
    accountActions: PropTypes.instanceOf(Object).isRequired,
    forgotPassword: PropTypes.func.isRequired,

    // Form
    forgotPasswordForm: PropTypes.instanceOf(Object),
  };

  static defaultProps = {
    // Form
    forgotPasswordForm: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      passwordResetEmailSent: false,
    };
  }

  handleOnSubmit = (event) => {
    event.preventDefault();
    const { forgotPassword: forgotPasswordAction, forgotPasswordForm } = this.props;

    forgotPasswordAction(forgotPasswordForm.values)
      .then((action) => {
        if (action.type === ACCOUNT_FORGOT_PASSWORD.SUCCESS) {
          this.setState({
            passwordResetEmailSent: true,
          });
        }
      });
  };

  render() {
    // State
    const {
      passwordResetEmailSent,
    } = this.state;

    // Props
    const {
      accountActions: {
        ACCOUNT_FORGOT_PASSWORD: forgotPasswordAction,
      },
    } = this.props;

    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card shadow">
            <img src={logo} alt="" className="mb-4" />
            <div className="text-danger mb-3">
              <AsyncForgotPasswordFormErrors action={forgotPasswordAction} />
            </div>
            {passwordResetEmailSent ? (
              <p className="text-center">Check your email for a password reset link.</p>
            ) : (
              <form className="form" onSubmit={this.handleOnSubmit}>
                <Field
                  name="email"
                  type="email"
                  component={renderField}
                  label={null}
                  placeholder="Email"
                  icon={faAt}
                />
                <AsyncButton action={forgotPasswordAction} color="primary" className="mx-auto">
                  Reset password
                </AsyncButton>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  const {
    actions: accountActions,
  } = state.account;

  const {
    forgotPasswordForm,
  } = state.form;

  return {
    // Account
    accountActions,

    // Form
    forgotPasswordForm,
  };
};


const mapDispatchToProps = {
  forgotPassword,
};


const withForm = {
  form: 'forgotPasswordForm',
};


export default wrapper({
  component: ForgetPassword,
  wrappers: [
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    reduxForm(withForm),
  ],
});
