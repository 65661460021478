import { generateReducerElement as generate, reduxTypes as types } from 'ggtmo-utils';

// Action Creators
import {
  EDITOR_TOGGLE_DELETE_CONFIRM_MODAL,
} from '../actionCreators/editorActionCreators';

const initialState = {
  deleteConfirmModalOpen: false,
};

export default (state = initialState, action) => ({
  ...generate(state, action, EDITOR_TOGGLE_DELETE_CONFIRM_MODAL, {
    type: types.SYNC,
    adjustment: {
      deleteConfirmModalOpen: !state.deleteConfirmModalOpen,
    },
  }),
}[action.type] || state);
