import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, Field, FormSection, reduxForm,
} from 'redux-form';

// Components
import {
  Row, Col, Card, CardBody,
} from 'reactstrap';
import SideMenuOrderField from './SideMenuOrderField';
import TextField from '../../../shared/components/form/TextField';
import OptionalImageSetField from '../../../shared/components/form/OptionalImageSetField';
import DocumentSetField from '../../../shared/components/form/DocumentSetField';
import FroalaEditorField from '../../../shared/components/form/FroalaEditor/ReduxFormFroalaEditor';

// Images
import contentImage from '../../../shared/img/content.png';

const EditorForm = ({
  // Actions
  getSectionAction,

  // Callbacks
  onEdit,
  onSubmit,
}) => (
  <Form className="material-form form--horizontal" onSubmit={onSubmit}>
    <Row>
      <Col xs={4} className="h-100">
        <Card>
          <CardBody className="p-3">
            <Field
              name="tree"
              component={SideMenuOrderField}
              onEdit={onEdit}
            />
          </CardBody>
        </Card>
      </Col>
      <Col xs={8}>
        <Card>
          <CardBody>
            {!(getSectionAction.loading || getSectionAction.init) && (
              <FormSection name="child">
                <div className="form__form-group">
                  <span className="form__form-group-label">Title</span>
                  <Field
                    className="material-form__field news__editor__form__title"
                    name="title"
                    component={TextField}
                  />
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Images</span>
                  <Field
                    name="imageSet"
                    component={OptionalImageSetField}
                  />
                </div>
                <div className="form__form-group">
                  <Field
                    name="documentSet"
                    component={DocumentSetField}
                  />
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Body</span>
                  <Field
                    className="pt-3 rounded"
                    name="body"
                    component={FroalaEditorField}
                  />
                </div>
              </FormSection>
            )}
            {getSectionAction.init && (
              <div className="text-center">
                <p className="lead">
                  Get started by clicking on a section in the menu to the left, or create a new section.
                </p>
                <img src={contentImage} alt="" style={{ opacity: 0.5 }} />
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Form>
);

EditorForm.propTypes = {
  // Actions
  getSectionAction: PropTypes.instanceOf(Object).isRequired,

  // Callbacks
  onEdit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'editorForm' })(EditorForm);
