/* eslint-disable max-len */
import { generateAction as generate, reduxTypes as types } from '@tmosystems/core';
import service from '../service';

// Action creators
import {
  // Async action creators
  COMMUNICATIONS_CREATE_TEXT_MESSAGE,
  COMMUNICATIONS_GET_TEXT_MESSAGE,
  COMMUNICATIONS_CREATE_EMAIL_MESSAGE,
  COMMUNICATIONS_GET_EMAIL_MESSAGE,
  COMMUNICATIONS_CREATE_CONTACT,
  COMMUNICATIONS_GET_CONTACT,
  COMMUNICATIONS_UPDATE_CONTACT,
  COMMUNICATIONS_DELETE_CONTACT,
  COMMUNICATIONS_CREATE_CONTACT_GROUP,
  COMMUNICATIONS_GET_CONTACT_GROUP,
  COMMUNICATIONS_UPDATE_CONTACT_GROUP,
  COMMUNICATIONS_DELETE_CONTACT_GROUP,
  COMMUNICATIONS_GET_RECIPIENT_SUGGESTION,
  COMMUNICATIONS_GET_RECEIVED_MESSAGE,
  COMMUNICATIONS_CREATE_RECEIVED_MESSAGE_LABEL,
  COMMUNICATIONS_GET_RECEIVED_MESSAGE_LABEL,
  COMMUNICATIONS_UPDATE_RECEIVED_MESSAGE_LABEL,
  COMMUNICATIONS_DELETE_RECEIVED_MESSAGE_LABEL,

  // Sync action creators
  COMMUNICATIONS_SELECT_TEXT_MESSAGE,
  COMMUNICATIONS_UPDATE_TEXT_MESSAGE_TABLE_META,
  COMMUNICATIONS_SELECT_EMAIL_MESSAGE,
  COMMUNICATIONS_SELECT_CONTACT,
  COMMUNICATIONS_TOGGLE_CONTACT_MODAL,
  COMMUNICATIONS_UPDATE_CONTACT_TABLE_META,
  COMMUNICATIONS_SELECT_CONTACT_GROUP,
  COMMUNICATIONS_TOGGLE_CONTACT_GROUP_MODAL,
  COMMUNICATIONS_SET_CONTACT_GROUP_ID,
  COMMUNICATIONS_UPDATE_RECEIVED_MESSAGE_TABLE_META,
  COMMUNICATIONS_SELECT_RECEIVED_MESSAGE_LABEL,
  COMMUNICATIONS_TOGGLE_RECEIVED_MESSAGE_LABEL_MODAL,
} from './actionCreators';

// Async actions
export const communicationsCreateTextMessage = generate(COMMUNICATIONS_CREATE_TEXT_MESSAGE, {
  type: types.ASYNC,
  service: service.POST.textMessage,
});

export const communicationsGetTextMessage = generate(COMMUNICATIONS_GET_TEXT_MESSAGE, {
  type: types.ASYNC,
  service: service.GET.textMessage,
});

export const communicationsCreateEmailMessage = generate(COMMUNICATIONS_CREATE_EMAIL_MESSAGE, {
  type: types.ASYNC,
  service: service.POST.emailMessage,
});

export const communicationsGetEmailMessage = generate(COMMUNICATIONS_GET_EMAIL_MESSAGE, {
  type: types.ASYNC,
  service: service.GET.emailMessage,
});

export const communicationsCreateContact = generate(COMMUNICATIONS_CREATE_CONTACT, {
  type: types.ASYNC,
  service: service.POST.contact,
});

export const communicationsGetContact = generate(COMMUNICATIONS_GET_CONTACT, {
  type: types.ASYNC,
  service: service.GET.contact,
});

export const communicationsUpdateContact = generate(COMMUNICATIONS_UPDATE_CONTACT, {
  type: types.ASYNC,
  service: service.PUT.contact,
});

export const communicationsDeleteContact = generate(COMMUNICATIONS_DELETE_CONTACT, {
  type: types.ASYNC,
  service: service.DELETE.contact,
});

export const communicationsCreateContactGroup = generate(COMMUNICATIONS_CREATE_CONTACT_GROUP, {
  type: types.ASYNC,
  service: service.POST.contactGroup,
});

export const communicationsGetContactGroup = generate(COMMUNICATIONS_GET_CONTACT_GROUP, {
  type: types.ASYNC,
  service: service.GET.contactGroup,
});

export const communicationsUpdateContactGroup = generate(COMMUNICATIONS_UPDATE_CONTACT_GROUP, {
  type: types.ASYNC,
  service: service.PUT.contactGroup,
});

export const communicationsDeleteContactGroup = generate(COMMUNICATIONS_DELETE_CONTACT_GROUP, {
  type: types.ASYNC,
  service: service.DELETE.contactGroup,
});

export const communicationsGetRecipientSuggestion = generate(COMMUNICATIONS_GET_RECIPIENT_SUGGESTION, {
  type: types.ASYNC,
  service: service.GET.recipientSuggestion,
});

export const communicationsGetReceivedMessage = generate(COMMUNICATIONS_GET_RECEIVED_MESSAGE, {
  type: types.ASYNC,
  service: service.GET.receivedMessage,
});

export const communicationsCreateReceivedMessageLabel = generate(COMMUNICATIONS_CREATE_RECEIVED_MESSAGE_LABEL, {
  type: types.ASYNC,
  service: service.POST.receivedMessageLabel,
});

export const communicationsGetReceivedMessageLabel = generate(COMMUNICATIONS_GET_RECEIVED_MESSAGE_LABEL, {
  type: types.ASYNC,
  service: service.GET.receivedMessageLabel,
});

export const communicationsUpdateReceivedMessageLabel = generate(COMMUNICATIONS_UPDATE_RECEIVED_MESSAGE_LABEL, {
  type: types.ASYNC,
  service: service.PUT.receivedMessageLabel,
});

export const communicationsDeleteReceivedMessageLabel = generate(COMMUNICATIONS_DELETE_RECEIVED_MESSAGE_LABEL, {
  type: types.ASYNC,
  service: service.DELETE.receivedMessageLabel,
});

// Sync actions
export const communicationsSelectTextMessage = generate(COMMUNICATIONS_SELECT_TEXT_MESSAGE, { type: types.SYNC });
export const communicationsUpdateTextMessageTableMeta = generate(COMMUNICATIONS_UPDATE_TEXT_MESSAGE_TABLE_META, { type: types.SYNC });
export const communicationsSelectEmailMessage = generate(COMMUNICATIONS_SELECT_EMAIL_MESSAGE, { type: types.SYNC });
export const communicationsSelectContact = generate(COMMUNICATIONS_SELECT_CONTACT, { type: types.SYNC });
export const communicationsToggleContactModal = generate(COMMUNICATIONS_TOGGLE_CONTACT_MODAL, { type: types.SYNC });
export const communicationsUpdateContactTableMeta = generate(COMMUNICATIONS_UPDATE_CONTACT_TABLE_META, { type: types.SYNC });
export const communicationsSelectContactGroup = generate(COMMUNICATIONS_SELECT_CONTACT_GROUP, { type: types.SYNC });
export const communicationsToggleContactGroupModal = generate(COMMUNICATIONS_TOGGLE_CONTACT_GROUP_MODAL, { type: types.SYNC });
export const communicationsSetContactGroupId = generate(COMMUNICATIONS_SET_CONTACT_GROUP_ID, { type: types.SYNC });
export const communicationsUpdateReceivedMessageTableMeta = generate(COMMUNICATIONS_UPDATE_RECEIVED_MESSAGE_TABLE_META, { type: types.SYNC });
export const communicationsSelectReceivedMessageLabel = generate(COMMUNICATIONS_SELECT_RECEIVED_MESSAGE_LABEL, { type: types.SYNC });
export const communicationsToggleReceivedMessageLabelModal = generate(COMMUNICATIONS_TOGGLE_RECEIVED_MESSAGE_LABEL_MODAL, { type: types.SYNC });
