import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { wrapper } from '@tmosystems/core';
import styled from 'styled-components';

// Components
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle as faExclamation } from '@fortawesome/pro-light-svg-icons';
import FormComponent from '../../../../shared/components/form/FormComponent';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import AsyncButton from '../../../../shared/components/AsyncButton';
import Input from '../../../../shared/components/form/Input/ReduxFormInput';

const Form = styled.form`
  background-color: white;
  padding: 20px;
  border-radius: 0.5rem;
`;

const InputWrapper = styled.div`
  margin-bottom: 40px;

  input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    
    &:focus {
      outline: none;
      box-shadow: none;
      border-bottom: 1px solid ${({ theme }) => theme.color.primary};
    }
  }
`;

class LoginForm extends FormComponent {
  static propTypes = {
    action: PropTypes.instanceOf(Object).isRequired,

    // Callbacks
    onSubmit: PropTypes.func.isRequired,
  };

  syncValidate = onSubmit => (event) => {
    event.preventDefault();
    const { loginForm } = this.props;
    const { values } = loginForm;
    this.setState({
      errors: {
        sync: {},
        async: {},
      },
    });
    let hasSyncErrors = false;
    if (!values.email) {
      hasSyncErrors = this.setError('sync', {
        email: 'Please provide your email.',
      });
    }
    if (!values.password) {
      hasSyncErrors = this.setError('sync', {
        password: 'Please provide a password',
      });
    }
    if (!hasSyncErrors) {
      onSubmit(values).then(this.asyncValidate);
    }
  };

  render() {
    // State
    const {
      errors,
    } = this.state;

    // Props
    const {
      action,

      // Callbacks
      onSubmit,
    } = this.props;

    const rememberMe = localStorage.getItem('rememberMe');
    const rememberMeBool = rememberMe === 'true' || !!rememberMe;

    return (
      <Form onSubmit={this.syncValidate(onSubmit)}>
        {errors.async.serverError && (
          <div>
            <p className="text-danger">
              {errors.async.serverError}
            </p>
          </div>
        )}
        {errors.async.nonFieldErrors && (
          <div className="pb-4">
            {errors.async.nonFieldErrors.map(nonFieldError => (
              <h4 className="text-danger">
                <FontAwesomeIcon icon={faExclamation} fixedWidth className="mr-1" />{nonFieldError}
              </h4>
            ))}
          </div>
        )}
        <InputWrapper>
          <Field
            name="email"
            component={Input}
            type="email"
            placeholder="Email"
            errors={errors}
            showLabel={false}
          />
        </InputWrapper>
        <InputWrapper>
          <Field
            name="password"
            component={Input}
            type="password"
            placeholder="Password"
            errors={errors}
            showLabel={false}
          />
        </InputWrapper>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="rememberMe"
              component={renderCheckBoxField}
              defaultChecked={rememberMeBool}
              label="Remember me"
            />
          </div>
        </div>
        <div className="account__btns">
          <AsyncButton className="btn btn-primary account__btn" action={action} size="sm">
            Login
          </AsyncButton>
          <Link className="btn btn-outline-primary account__btn" to="/request-account">
            Request account
          </Link>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    loginForm,
  } = state.form;

  return {
    // Form
    loginForm,
  };
};

export default wrapper({
  component: LoginForm,
  wrappers: [
    connect(mapStateToProps),
    reduxForm({ form: 'loginForm' }),
  ],
});
