import { create } from 'axios';
import { createAxiosInstance } from '@tmosystems/core';

const axios = createAxiosInstance(create, { baseURL: '/private/news/api/v1' });

// POST
const createStory = requestData => axios.post('/', requestData);
const createAndPublishStory = requestData => axios.post('/', { createAndPublish: true, ...requestData });

// GET
const getStory = requestParams => axios.get('/', { params: requestParams });

// PUT
const updateStory = requestData => axios.put('/', requestData);
const updateAndPublishStory = requestData => axios.put('/', { updateAndPublish: true, ...requestData });
const publishStory = requestData => axios.put('/', { publish: true, ...requestData });
const unpublishStory = requestData => axios.put('/', { unpublish: true, ...requestData });

// DELETE
const deleteStory = requestData => axios.delete('/', { data: requestData });

export default {
  POST: {
    createStory,
    createAndPublishStory,
  },
  GET: {
    getStory,
  },
  PUT: {
    updateStory,
    updateAndPublishStory,
    publishStory,
    unpublishStory,
  },
  DELETE: {
    deleteStory,
  },
};
