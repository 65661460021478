import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { squashString } from 'ggtmo-utils';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faFolder } from '@fortawesome/pro-light-svg-icons';
import TableCell from '@material-ui/core/TableCell';
import MatTable from '../../../shared/components/table/MatTable';


const tableColumns = [
  {
    id: 'name', label: 'Name', disablePadding: false, numeric: false, align: 'left',
  },
  {
    id: 'dateTimeCreated', label: 'Date Uploaded', disablePadding: false, numeric: false, align: 'left',
  },
];


const TableRow = ({
  name,
  type,
  dateTimeCreated,
}) => (
  <>
    <TableCell className="material-table__cell">
      <FontAwesomeIcon
        icon={type === '1' ? faFileAlt : faFolder}
        fixedWidth
        className="mr-1"
        style={{ fontSize: '1.25rem', lineHeight: '1.25rem' }}
      />
      {squashString(name, { filename: true })}
    </TableCell>
    <TableCell className="material-table__cell">{moment(dateTimeCreated).format('DD/MM/YYYY')}</TableCell>
  </>
);

TableRow.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.func.isRequired,
  dateTimeCreated: PropTypes.string.isRequired,
};


const DocumentsTable = ({
  children,
  documents,
  selected,
  page,
  count,

  // Callbacks
  onSelect,
  onSelectByRow,
  onPageChange,
}) => (
  <div>
    <MatTable
      rowComponent={TableRow}
      columns={tableColumns}
      data={documents}
      selected={selected}
      selectOnlyByCheckbox
      page={page}
      count={count}
      rowsPerPage={50}
      rowsPerPageOptions={['25', '50']}
      emptyRows={false}
      keepHeadOnEmpty

      // Callbacks
      onSelect={onSelect}
      onSelectByRow={onSelectByRow}
      onPageChange={onPageChange}
    >
      {children}
    </MatTable>
  </div>
);

DocumentsTable.propTypes = {
  children: PropTypes.node,
  documents: PropTypes.instanceOf(Array).isRequired,
  selected: PropTypes.instanceOf(Array).isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,

  // Callbacks
  onSelect: PropTypes.func.isRequired,
  onSelectByRow: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

DocumentsTable.defaultProps = {
  children: null,
};

export default DocumentsTable;
