import reduceReducers from 'reduce-reducers';
import { initializeActions } from '@tmosystems/core';

// Reducers
import baseReducer from './baseReducer';

// Action creators
import {
  // Async action creators
  REPAIRS__GET_REQUEST,
  REPAIRS__UPDATE_REQUEST,
  REPAIRS__DELETE_REQUEST,
  REPAIRS__CREATE_CATEGORY,
  REPAIRS__GET_CATEGORY,
  REPAIRS__UPDATE_CATEGORY,
  REPAIRS__DELETE_CATEGORY,
  REPAIRS__CREATE_TYPE,
  REPAIRS__GET_TYPE,
  REPAIRS__UPDATE_TYPE,
  REPAIRS__DELETE_TYPE,
} from './actionCreators';

export const initialState = {
  requests: {
    instance: {},
    instances: [],
    selected: [],
    selectedIndices: [],
    modalMeta: {
      isOpen: false,
      openMode: 'create',
    },
    tableMeta: {
      page: 0,
      rowsPerPage: 10,
    },
    scrollMeta: {
      page: 0,
      size: 24,
      noMoreInstances: false,
    },
    searchMeta: {
      searchTerm: '',
    },
  },
  categories: {
    instance: {},
    instances: [],
    selected: [],
    selectedIndices: [],
    modalMeta: {
      isOpen: false,
      openMode: 'create',
    },
    tableMeta: {
      page: 0,
      rowsPerPage: 10,
    },
    scrollMeta: {
      page: 0,
      size: 24,
      noMoreInstances: false,
    },
    searchMeta: {
      searchTerm: '',
    },
  },
  types: {
    instance: {},
    instances: [],
    selected: [],
    selectedIndices: [],
    modalMeta: {
      isOpen: false,
      openMode: 'create',
    },
    tableMeta: {
      page: 0,
      rowsPerPage: 10,
    },
    scrollMeta: {
      page: 0,
      size: 24,
      noMoreInstances: false,
    },
    searchMeta: {
      searchTerm: '',
    },
  },
  selectedCategory: -1,
  actions: initializeActions([
    REPAIRS__GET_REQUEST,
    REPAIRS__UPDATE_REQUEST,
    REPAIRS__DELETE_REQUEST,
    REPAIRS__CREATE_CATEGORY,
    REPAIRS__GET_CATEGORY,
    REPAIRS__UPDATE_CATEGORY,
    REPAIRS__DELETE_CATEGORY,
    REPAIRS__CREATE_TYPE,
    REPAIRS__GET_TYPE,
    REPAIRS__UPDATE_TYPE,
    REPAIRS__DELETE_TYPE,
  ]),
};

export default reduceReducers(initialState, baseReducer);
