import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

// Components

// Redux
import { imagesGetImage, imagesUpdateImageSearchMeta } from '../redux/actions';

const SearchBarInput = styled.input`
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  font-size: 1rem;
  padding: 0.5rem 0;
  transition: border-bottom 0.3s ease-in-out;
  
  &:focus {
    outline: none;
    border-bottom: 1px solid ${({ theme }) => theme.color.primary};
  }
`;

class ImageSearch extends PureComponent {
  static propTypes = {
    // Images
    images: PropTypes.instanceOf(Object).isRequired,
    categories: PropTypes.instanceOf(Object).isRequired,

    // Actions
    imagesGetImage: PropTypes.func.isRequired,
    imagesUpdateImageSearchMeta: PropTypes.func.isRequired,
  };

  handleOnChange = (event) => {
    const { imagesUpdateImageSearchMeta: updateSearchMeta } = this.props;
    const { value } = event.target;
    updateSearchMeta({ searchTerm: value });
  };

  handleOnSearch = () => {
    const { images: { searchMeta: { searchTerm } } } = this.props;
    const { imagesGetImage: getImage, categories } = this.props;
    const [categoryId] = categories.selected;
    getImage({ search: true, categoryId, searchTerm });
  };

  handleOnKeyDown = (event) => {
    const { key } = event;
    if (key === 'Enter') {
      this.handleOnSearch();
    }
  };

  render() {
    // State
    const {
      images: {
        searchMeta: {
          searchTerm,
        },
      },
    } = this.props;

    return (
      <div className="pb-3">
        <SearchBarInput
          name="searchTerm"
          onChange={this.handleOnChange}
          onKeyDown={this.handleOnKeyDown}
          placeholder="Search images..."
          value={searchTerm}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    images,
    categories,
  } = state.images;

  return {
    // Images
    images,
    categories,
  };
};

const mapDispatchToProps = {
  imagesGetImage,
  imagesUpdateImageSearchMeta,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageSearch);
