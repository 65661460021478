export default (values) => {
  const errors = {};

  if (!values.newPassword1) {
    errors.newPassword1 = 'Please enter a new password.';
  }

  if (!values.newPassword2) {
    errors.newPassword2 = 'Please repeat your new password.';
  }

  return errors;
};
